import { useState, useEffect, useRef } from "react";
import { DragAndDrop } from "../../../../components/global/DragAndDrop";
import { shapes, calcGapY, getCoordinates } from "../Shape3.data";
import { motion } from "framer-motion";
import { 
    animationDurationToShowQuestion,
} from "../../../../config";
import Sound from '../../../../components/global/Sound';


export const RightPart = ({state, answers, shape, checkAnswer, answerSound, hint}) => {
    const [index, setIndex] = useState("");
    const [val, setVal] = useState();
    const [update, setUpdate] = useState(false);
    const [type, setType] = useState(false);
    const soundRef = useRef();
    const soundWrongRef = useRef();

    const [animate, setAnimate] = useState(false);

    const [time, setTime] = useState(0);

    useEffect(()=>{
        if (!animate) return;
        setVal('');
        setAnimate(false);
        setIndex('');
        setTime(0);
        setUpdate(false);
    }, [answers]);

    useEffect(()=>{
        if (type) setTimeout(()=>{
          setType(false);
          document.querySelector('.result').style.opacity = 1;
        }, 1000);
    }, [type]);

    useEffect(()=>{
        if(!hint) return;
        const id = setInterval(()=>setTime(prev=>prev+1), 1000);
        return ()=>clearInterval(id);
    }, [time, hint]);

    return (
        <div className={`
            ${state ? "w-[67px] md:w-[73px] lg:w-[93px] xl:w-[114px]" : "w-[67px] md:w-[90px] lg:w-[133px] xl:w-[136px]"} 
            h-full border-[3px] rounded-[16px] lg:rounded-[20px] border-[#2F9EBA] bg-[#FFF] z-[50] flex flex-col items-center
        `}>
            {
               answers.map((answer, i)=>{
                  if(i===index) {
                    return (
                        <DragAndDrop
                            key={answer}
                            enableClick = {true}
                            drop={'shape'}
                            refresh={(event, type) => {
                                if (event==='mouseup') return;
                                if (val===answer) return; //prevnting multiple clicks on one element
                                setType(type);
                                setTime(0);
                                checkAnswer(event, type, answer);
                                setVal(answer);
                                setUpdate(false);
                                if(type) setTimeout(()=>document.querySelector('.result').style.opacity=0, 200);
                                if (answerSound) {
                                    soundRef.current.currentTime = 0;
                                    soundRef.current.play();
                                } 
                            }}
                            numbers = {[shape]}
                            item = {answer}
                            animateWrong = {(e)=>{
                                setIndex('');
                                setUpdate(i);
                                if (answerSound) {
                                    soundWrongRef.current.currentTime = 0;
                                    soundWrongRef.current.play();
                                } 
                            }}
                            
                        >
                            <div
                                style={{
                                    marginTop: calcGapY(state)+'px', 
                                    backgroundColor: shapes[answer].color,
                                    clipPath: answer!=="circle" && shapes[answer].path,
                                    borderRadius: answer === "circle" && "50%",
                                    transform: type && val === answer && `translate(${getCoordinates()[0]}px,${getCoordinates()[1]}px)`,
                                    transition: `transform ${0.2}s linear`
                                }}
                                className={`relative
                                   ${val === answer && !type? 'opacity-0' : 'opacity-100'} z-[100]
                                   answer cursor-pointer ${state ? 'md:w-[39px] md:h-[39px] lg:w-[50px] lg:h-[50px] xl:w-[70px] xl:h-[70px] ' : ' md:w-[49px] md:h-[49px]  lg:w-[69px] lg:h-[69px] xl:w-[89px] xl:h-[89px] '} w-[39px] h-[39px]`}
                            >
                            </div>
                        </DragAndDrop>
                    )
                  } else {
                    return (
                        <div className="relative">
                            <motion.div
                                animate={{y: animate ? [0, 0] : [(i+1)*100, 0], x:update === i ? [-5, 0, 5, 0] : [0, 0, 0, 0]}}
                                transition={{duration: update === index ? 0.4 : animationDurationToShowQuestion , type: "tween", ease: "linear"}}
                                onPointerEnter={(e)=>{
                                    setIndex(i);
                                    setAnimate(true);
                                    setUpdate(false);
                                }}
                                key={answer}
                                style={{
                                    marginTop: calcGapY(state)+'px', 
                                    backgroundColor: shapes[answer].color,
                                    clipPath: answer!=="circle" && shapes[answer].path,
                                    borderRadius: answer === "circle" && "50%",
                                }}
                                className={`cursor-pointer ${state ? 'md:w-[39px] md:h-[39px] lg:w-[50px] lg:h-[50px] xl:w-[70px] xl:h-[70px] ' : ' md:w-[49px] md:h-[49px]  lg:w-[69px] lg:h-[69px] xl:w-[89px] xl:h-[89px] '} w-[39px] h-[39px]`}
                            >
                                
                            </motion.div>
                            {hint && answer === shape && time>=10 && time%2===0 && <div className="bg-[url('/backGrounds/hint.svg')] bg-[position:50%_50%] bg-contain bg-no-repeat absolute w-[50px] h-[50px] top-[50%] z-[100]"></div>}
                        </div>

                    )
                  }
               })
            }
            <Sound ref={soundRef} url={"/backGrounds/right_answer.wav"} loop={false}/>
            <Sound ref={soundWrongRef} url={"/backGrounds/wrong_answer.wav"} loop={false}/>
        </div>
    )
}