import {useTranslation} from 'react-i18next';
import { useParams } from 'react-router-dom';
import { 
   IconGreen, 
   IconIncrease, 
   IconDecrease, 
   IconLevelRotate, 
   IconLevelDisableRotate 
} from '../elements/Icon';
import { CircleButton } from "../elements/CircleButton";
import { useState } from "react";

export const Levels = ({handleChange, state}) => {
  
   const [sort, setSort] = useState(1);
   const [rotate, setRotate] = 
      useState(localStorage.getItem('rotate') && localStorage.getItem('rotate')!== 'undefined'?  JSON.parse(localStorage.getItem('rotate')) : false);
   const {t} = useTranslation();
   const {name, gameName} = useParams();

   return(
      <div className="h-[100%] flex flex-col justify-center items-center w-full bg-[rgba(108,_108,_108,_0.37)] absolute z-[10] rounded-[17px]">
         {name==="shapes" &&  <div className={`flex top-[30.5%] ${state ? "md:top-[20%] lg:top-[15%] xl:top-[20%]" : "md:top-[26%] lg:top-[23%] xl:top-[26%]"}  z-[10] absolute items-center justify-between w-[58px] h-[26px] md:w-[75px] md:h-[38px] lg:w-[101px] lg:h-[52px] xl:w-[92px] xl:h-[46px] rounded-[30px] border-[1px] border-[#78A941] bg-[#FFF] mx-auto p-[1px]`}>
                  <div className="relative w-[22px] h-[22px] md:w-[32px] md:h-[32px] lg:w-[44px] lg:h-[44px] xl:w-[40px] xl:h-[40px] rounded-[50%] cursor-pointer" onClick={()=>setRotate(false)}>
                        {rotate===false && <IconGreen className="w-full h-full" />}
                        <IconLevelRotate className="absolute top-[15%] w-[20px] h-[18px] md:w-[33px] md:h-[23px] md:left-[0%] lg:w-[40px] lg:h-[30px] left-[6%] lg:left-[8%] xl:left-[0%]" color={!rotate ? "white" : "#78A941"}/>
                  </div>
                  <div className="relative w-[22px] h-[22px] md:w-[32px] md:h-[32px] lg:w-[44px] lg:h-[44px] xl:w-[40px] xl:h-[40px] rounded-[50%] cursor-pointer" onClick={()=>setRotate(true)}>
                        {rotate===true && <IconGreen className="w-full h-full" />}
                        <IconLevelDisableRotate className="absolute top-[15%] w-[20px] h-[18px] md:w-[33px] md:h-[23px] md:left-[0%] lg:w-[40px] lg:h-[30px] left-[6%] lg:left-[8%] xl:left-[0%]" color={rotate ? "white" : "#78A941"}/>
                  </div>

               </div>
         }
         {!(name==="schulte" && gameName.includes("step_by_step")) && <div className="w-[282px] h-[216px] md:w-[417px] md:h-[320px] lg:w-[577px] lg:h-[443px] pt-[58px] md:pt-[87px] lg:pt-[110.31px] text-center bg-[url('/backGrounds/levels.svg')] bg-[length:100%_100%] text-[#FFF] font-fredokaOne font-normal text-[11px] md:text-[15px] lg:text-[20px]">
            <div onClick={()=>{handleChange('beginner', sort, rotate)}} className="w-[132px] h-[29px] md:w-[181px] md:h-[40px] lg:h-[56px] flex items-center justify-center cursor-pointer lg:w-[265px] xl:w-[281px] xl:h-[60px] bg-[url('/backGrounds/level-bg.svg')] bg-[length:100%_100%] mx-auto">{t("beginner")}</div>
            <div onClick={()=>{handleChange('intermediate', sort, rotate)}} className="w-[132px] h-[29px] md:w-[181px] md:h-[40px] lg:h-[56px] flex items-center justify-center cursor-pointer lg:w-[265px] xl:w-[281px] xl:h-[60px] bg-[url('/backGrounds/level-bg.svg')] bg-[length:100%_100%] mt-[8.4px] md:mt-[11.54px] lg:mt-[18.87px] xl:mt-[20px] mx-auto">{t("intermediate")}</div>
            <div onClick={()=>{handleChange('advanced', sort, rotate)}} className="w-[132px] h-[29px] md:w-[181px] md:h-[40px] lg:h-[56px] flex items-center justify-center cursor-pointer lg:w-[265px] xl:w-[281px] xl:h-[60px] bg-[url('/backGrounds/level-bg.svg')] bg-[length:100%_100%] mt-[8.4px] md:mt-[11.54px] lg:mt-[18.87px] xl:mt-[20px] mx-auto">{t("advance")}</div>
         </div>}
         {
            (name==="schulte" && gameName.includes("step_by_step")) && <div className="relative flex flex-col items-center justify-center w-[269px] h-[226px] md:w-[377px] md:h-[326px] lg:w-[544px] lg:h-[469px] text-center bg-[url('/backGrounds/schulte_level_bg.svg')] bg-[length:100%_100%] text-[#FFF] font-fredokaOne font-normal text-[11px] md:text-[15px] lg:text-[20px]">
               <div className="w-[180px] md:w-[230px] lg:w-[350px] xl:h-[70px] flex justify-between mx-auto xl:w-[400px]">  
                  {
                    [2, 3, 4, 5].map((number, index)=>{
                       return (
                           <div
                              onClick={()=>{handleChange(number, sort)}}
                              key={number}
                           >
                              <CircleButton key={index} isActive={number}>
                                 {number}
                              </CircleButton>
                           </div>
                       )
                    })
                  }
               </div>
             </div>
         }
      </div>
   )
}