
import { DragAndDrop } from "../../../../components/global/DragAndDrop";
import { motion } from "framer-motion";
import { animationDurationToShowQuestion } from "../../../../config";
import { CircleButton } from "../../../../components/elements/CircleButton";
import { getBottomGapX } from "../Adding4.data";
import React, { useState, useEffect, useRef } from 'react';
import { checkHint } from "../../../../hooks/useCheckHint";
import { getCircleComponent } from "../../../../hooks/useCircleButtonColor";

let showHint;
export const BottomPart = ({state, answers, selected, refresh, result, answer, hint}) => {
   const colors = useRef(getCircleComponent(answers));
   const [i, setI] = useState("");
   const [animate, setAnimate] = useState(false);
   const [start, setStart] = useState({x:0, y:0});   
   const [end, setEnd] = useState({x:0, y:0});
   const [time, setTime] = useState(0);

   const [isDragging, setIsDragging] = useState(false);

   useEffect(()=>{
    if (!animate) return;
    setAnimate(false);
    setI('');
    setTime(0);
    colors.current = getCircleComponent(answers);
   }, [answers]);

   useEffect(()=>{
    showHint = true;
    if(result.indexOf(undefined)===-1) return;
    const start = document.querySelector('.answer');
    const end = document.querySelector('.drop'+(result.indexOf(undefined)));
    if (!start || !end) return;
    const startCoord = start.getBoundingClientRect();
    const endCoord = end.getBoundingClientRect();
    setStart({x:startCoord.x, y:startCoord.y});
    setEnd({x:endCoord.x+endCoord.width-endCoord.height, y:endCoord.y});
    setTime(0);
    }, [result]);

    useEffect(()=>{
        if (!hint) {
            setTime(0);
            return;
        }
        if (time===10) return;
        const id = setInterval(()=>setTime(prev=>prev+1), 1000);
        return ()=>clearInterval(id);
    }, [time, hint]);
   
   return(
       <div
            style={{columnGap: getBottomGapX(state)+'px'}}
            className={`z-[50] flex items-center justify-center w-full border-[3px] rounded-[16px] border-[#7648B5] bg-[#FFF] lg:rounded-[20px]
            ${state ? "h-[67px] md:h-[73px] lg:h-[93px] xl:h-[114px]" : "h-[67px] md:h-[90px] lg:h-[113px] xl:h-[136px]"}`}
       >
            {answers.map((number, index) => {
                        if (index === i) {
                            return (
                               <DragAndDrop
                                    key={index} 
                                    enableClick = {false}
                                    item = {number}
                                    cmp={'adding'} 
                                    drop={'adding'}
                                    dragging = {(state)=>setIsDragging(state)}
                                    refresh={(sound, event) => {
                                        showHint = false;
                                        refresh(event, index, number)
                                    }}
                               >                          
                                    <motion.div
                                        key={number}
                                        animate={{y: animate ? [0, 0] : [(index+1)*100, 0]}}
                                        transition={{duration: animationDurationToShowQuestion , type: "tween", ease: "linear"}}
                                        className={`${selected.includes(index)? 'opacity-0': 'opacity-100'} item ${ answer[result.indexOf(undefined)]==number ? 'z-[50]' : 'z-[40]'} cursor-pointer overflow-hidden`}
                                    >
                                        <CircleButton isActive={number || true} color={colors.current[index]}>
                                            {number}
                                        </CircleButton>
                                    </motion.div>
                               </DragAndDrop>
                            )
                        } else {
                            return (
                                <motion.div
                                   key={index} 
                                   animate={{y: animate ? [0, 0] : [(index+1)*100, 0]}}
                                   transition={{duration: animationDurationToShowQuestion , type: "tween", ease: "linear"}}
                                   className={`relative ${ answer[result.indexOf(undefined)]==number ? 'answer z-[50]' : 'z-[40]'} ${selected.includes(index)? 'opacity-0': 'opacity-100'} item cursor-pointer`}
                                   onMouseEnter={(e)=>{
                                       if (!isDragging) {
                                            setI(index);
                                            setAnimate(true);
                                       }
                                   }}
                                   onTouchStart={(e)=>{
                                    if (!isDragging) {
                                         setI(index);
                                         setAnimate(true);
                                    }
                                   }}
                                >
                                    <CircleButton isActive={number || true} color={colors.current[index]}>
                                        {number}
                                    </CircleButton>
                                    {hint && showHint && time==10 && answer[result.indexOf(undefined)]==number && checkHint(answers, result, answer[result.indexOf(undefined)])===index && <motion.div
                                        animate={{y: [0, end.y-start.y], x: [0, end.x-start.x]}}
                                        transition={{duration: 2 , type: "tween", ease: "linear", repeat: Infinity}}
                                        className="bg-[url('/backGrounds/hint.svg')] bg-[position:50%_50%] bg-contain bg-no-repeat absolute w-[50px] h-[50px] top-[50%] z-[1000]"></motion.div>}
                                </motion.div>
                            )
                        }
                })
            }
       </div>
   )
}