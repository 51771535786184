
import { motion } from "framer-motion";
import { animationDurationToShowQuestion } from "../../../../config";
import { DragAndDrop } from "../../../../components/global/DragAndDrop";
import { useState, useRef } from "react";


export const TopPart = ({state, result, refresh}) => {   
    const [index, setIndex] = useState('');
    const isDragging = useRef(false);


    return (
      <div
           className={`z-[10] w-full bg-[#FFF] text-[32px] text-[#fff] font-bigfatScript flex items-center justify-around ${state ? "h-[185px] md:h-[203px] lg:h-[257px] xl:h-[316px] lg:text-[42px] xl:text-[48px]" : "lg:text-[48px] xl:text-[64px] md:text-[42px] h-[185px] md:h-[252px] lg:h-[315px] xl:h-[380px]"} border-[2px] md:border-[3px] xl:border-[4px] border-[#D2461B] rounded-[14px] md:rounded-[16px] lg:rounded-[20px]`}>
           {
              result.map((val, i)=>{
                if (i === index) {
                    return (
                        <DragAndDrop
                            key={index}
                            enableClick = {false}
                            cmp={"sorting"}
                            drop={"dropAnswers"}
                            item = {val}
                            dragging = {(state)=>{
                                if (!state) return
                                isDragging.current = state;
                            }}
                            refresh={(sound, event) => {
                                setIndex('');
                                refresh(event, i, val, 'top', isDragging.current);
                            }}
                        >
                            <div key={i} className={`z-[50] ${'drop'+i} w-[110px] h-[55px] ${state ? "md:w-[114px] md:h-[57px] lg:w-[155px] lg:h-[77px] xl:w-[189px] xl:h-[95px]" : "md:w-[150px] md:h-[75px] lg:w-[176px] lg:h-[89px] xl:w-[243px] xl:h-[122px]"} ${val ? "bg-[#85B054]" : "bg-[#E4E4E4]"} rounded-[7px] lg:rounded-[10px] flex items-center justify-center`}>
                                { val || ""}
                            </div> 
                       </DragAndDrop>
                    )

                } else {
                    return (
                        <div
                            onMouseEnter={(e)=>{
                                setIndex(i);  
                            }}
                            key={i}
                            className={`z-[50] ${'drop'+i} w-[110px] h-[55px] ${state ? "md:w-[114px] md:h-[57px] lg:w-[155px] lg:h-[77px] xl:w-[189px] xl:h-[95px]" : "md:w-[150px] md:h-[75px] lg:w-[176px] lg:h-[89px] xl:w-[243px] xl:h-[122px]"} ${val ? "bg-[#85B054]" : "bg-[#E4E4E4]"} rounded-[7px] lg:rounded-[10px] flex items-center justify-center`}
                        >
                               { val || ""}
                        </div> 
                    )
                }

              })
           }
      </div>
  );
}