
import { generateArray, getSizes, getDivSize } from "./Memory.data";
import React, { useState, useRef, useEffect} from "react";
import { 
    animationDurationToShowscoresPopup,
} from "../../../config";
import Sound from '../../../components/global/Sound';
import { useParams } from 'react-router-dom';
import { updateStorage } from "../../../hooks/useStorage";
import { updateGameStorage } from "../../../hooks/useGameStorage";
import * as amplitude from '@amplitude/analytics-browser';
import { useUpdateReduxState } from "../../../hooks/useUpdateReduxState";
import { gameId } from "../../../hooks/getGameId";


export const Memory = ({state, update, handleOpenScore, handleUpdateProgress, answerSound, level, handleTimer, point, subscription}) => {
    const size = level==='beginner' ? 16 : level==='intermediate' ? 20 : 30;
    const data = useRef(generateArray(level));
    const {name, gameName} = useParams();
    const [result, setResult] = useState(data.current);
    // const [clickedIndex, setClickedIndex] = useState(null);
    const clickedIndex = useRef([]);
    const [rightAnswers, setRightAnswers] = useState(new Array(size));
    const [refresh, setRefresh] = useState(false);
    const soundWrongRef = useRef();
    const soundRef = useRef();
    const score = useRef(0);

    const updateState = useUpdateReduxState();

    useEffect(()=>{
        !update && !result.includes(undefined) && setResult([...new Array(size)]);
    }, [update]);

    useEffect(()=>{
        amplitude.track(`${name+"_"+gameName.replace("_", " ")+"_"+level+"_start"}`)
    }, []);

    const handleClick = (index) => {
        if (index === clickedIndex.current[clickedIndex.current.length-1]) return;
        if (!result[index]) {
            const newResult = [...result];
            newResult[index] = data.current[index];
            setResult(newResult);
            clickedIndex.current.push(index);
        }
    }

    useEffect(()=>{
        if (clickedIndex.current.length<=1) return
            if (data.current[clickedIndex.current[clickedIndex.current.length-1]] === data.current[clickedIndex.current[clickedIndex.current.length-2]]) {
                soundRef.current && soundRef.current.play();
                rightAnswers[clickedIndex.current[clickedIndex.current.length-1]] = true;
                rightAnswers[clickedIndex.current[clickedIndex.current.length-2]] = true;
                setRightAnswers([...rightAnswers]);
                clickedIndex.current = [];
            } else {
                setRefresh(!refresh);    
            }
    }, [result]);

    useEffect(()=>{
        const newResult = [...result];
        newResult[clickedIndex.current[clickedIndex.current.length-1]] = undefined;
        newResult[clickedIndex.current[clickedIndex.current.length-2]] = undefined;
        clickedIndex.current = [];
        const id = setTimeout(()=>setResult(newResult), 100);
        return ()=>clearTimeout(id);
    }, [refresh])

    useEffect(()=>{
       if (!rightAnswers.includes(undefined)) {
        score.current += point[level];
        if (!subscription) {
            updateStorage(point[level]);
        }
        if (subscription) {
            updateState({
                userId: localStorage.getItem('user'),
                profileId: JSON.parse(localStorage.getItem('profile'))?._id,
                game: name,
                score: score.current,
                id: gameId(name, gameName),
                collection: name,
            })
        } else {
            updateGameStorage(name, gameName, point[level], true, level);
            handleUpdateProgress();
        }
        handleTimer();
        setTimeout(()=>{
            handleOpenScore();
                data.current = generateArray(level);
                setResult(data.current);
                setRightAnswers(new Array(size));
            
        }, animationDurationToShowscoresPopup*1000);
        localStorage.setItem(`${name+'_'+gameName+'_'+level+'_score'}`, score.current);
       }
    }, [rightAnswers]);

    return (
        <div className="flex w-full h-full bg-transparent  items-center justify-center z-[30]">
            <div className={`border-[#2DA5D0] border-collapse border-[5px] rounded-[16px] ${getSizes(state, level)} grid  ${level==='beginner' ? 'grid-cols-4' : level==='intermediate' ? 'grid-cols-5' :  'grid-cols-6'}`}>
                {
                    result && result.map((item, index)=>{
                        return (
                            <div
                              onClick = {()=>handleClick(index)}
                              key={index}
                              style={{
                                  backgroundImage: item ? `url(/backGrounds/memory_bg.svg)` : `url(/backGrounds/memory_bg_default.svg)`,
                                  borderTopLeftRadius: !index && '10px',
                                  borderTopRightRadius: level === 'beginner' ? index===3 && '10px' : level === 'intermediate' ? index===4 && '10px' : index===5 && '10px',
                                  borderBottomLeftRadius: level === 'beginner' ? index===12 && '10px' : level === 'intermediate' ? index===15 && '10px' : index===24 && '10px',
                                  borderBottomRightRadius: level === 'beginner' ? index===15 && '10px' : level === 'intermediate' ? index===19 && '10px' : index===29 && '10px',
                                }}
                                className={`relative bg-[position:50%_50%] bg-[length:100%_100%] bg-no-repeat cursor-pointer flex justify-center items-center  ${getDivSize(state, level)} border-[1px] ${item ? 'border-[#BBDEEB]': 'border-[#3FA3C8]' }`}>
                                    <div
                                        className={`bg-[length:100%_100%] bg-no-repeat w-[39px] h-[39px] ${state ? 'md:w-[43px] md:h-[43px] lg:w-[55px] lg:h-[55px] xl:w-[67px] xl:h-[67px]' : 'md:w-[53px] lg:w-[67px] xl:w-[80px] md:h-[53px] lg:h-[67px] xl:h-[80px]'}`}
                                        style={{backgroundImage: item && `url(/backGrounds/counting/${item}.svg)`}}
                                    />
                                    {rightAnswers[index] && <div
                                        style={{
                                            borderTopLeftRadius: !index && '10px',
                                            borderTopRightRadius: level === 'beginner' ? index===3 && '10px' : level === 'intermediate' ? index===4 && '10px' : index===5 && '10px',
                                            borderBottomLeftRadius: level === 'beginner' ? index===12 && '10px' : level === 'intermediate' ? index===15 && '10px' : index===24 && '10px',
                                            borderBottomRightRadius: level === 'beginner' ? index===15 && '10px' : level === 'intermediate' ? index===19 && '10px' : index===29 && '10px',
                                        }}
                                        className="absolute w-full h-full bg-[rgba(0,_0,_0,_0.37)]"
                                    />}
                            </div>
                        )
                    })
                }
            </div>
            {answerSound+""=="true" && <Sound ref={soundRef} url={"/backGrounds/right_answer.wav"} loop={false}/>}
            {answerSound+""=="true" && <Sound ref={soundWrongRef} url={"/backGrounds/wrong_answer.wav"} loop={false}/>}
        </div>
    )
}