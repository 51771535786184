
import { calcGapY, shapes } from '../Shape5.data';
import { DragAndDrop } from "../../../../components/global/DragAndDrop";
import { useState, useEffect, useRef } from "react";
import { motion } from 'framer-motion';
import { 
   animationDurationToShowQuestion,
} from "../../../../config";

let showHint;

export const RightPart = ({state, answers, drop, refresh, selected, animate, val, hint}) => {
    const [index, setIndex] = useState("");
    const [animateOn, setAnimateOn] = useState(false);
    const [start, setStart] = useState({x:0, y:0});   
    const [end, setEnd] = useState({x:0, y:0});
    const [time, setTime] = useState(0);

    const [update, setUpdate] = useState(false);

    useEffect(()=>{
        if (!animateOn) return;
        setAnimateOn(false);
        setIndex('');
        setTime(0);
    }, [answers]);

    useEffect(()=>{
        showHint = true;
        const start = document.querySelector('.answer');
        const end = document.querySelector('.drop'+drop[0]+drop[1]);
        if (!start || !end) {
            setUpdate(!update);
            return;
        }
        const startCoord = start.getBoundingClientRect();
        const endCoord = end.getBoundingClientRect();
        setStart({x:startCoord.x, y:startCoord.y});
        setEnd({x:endCoord.x+endCoord.width-endCoord.height, y:endCoord.y});
        setTime(0);
    }, [drop, update]);

    useEffect(()=>{
        if(!hint) {
            setTime(0);
            return;
        }
        if(time===10) return;
        const id = setInterval(()=>setTime(prev=>prev+1), 1000);
        return ()=>clearInterval(id);
    }, [time, hint]);

    return (
        <div className={`
            ${state ? "w-[67px] md:w-[73px] lg:w-[93px] xl:w-[114px]" : "w-[67px] md:w-[90px] lg:w-[133px] xl:w-[136px]"} 
            h-full border-[3px] rounded-[16px] lg:rounded-[20px] border-[#2F9EBA] bg-[#FFF] z-[50] flex flex-col items-center
        `}>
           {
             answers.map((answer, i)=>{
                if(i===index) {
                    return (
                        <DragAndDrop
                            key={answer} 
                            enableClick = {false}
                            cmp={'shape'} 
                            drop={"drop"+drop[0]+drop[1]}
                            item = {answer}
                            refresh={(sound, event) => {
                                showHint = false;
                                setIndex('');
                                refresh(event, i, answer);
                            }}
                        >
                            <div
                                key={answer}
                                style={{
                                    marginTop: calcGapY(state)+'px',
                                    backgroundColor: shapes[answer].color,
                                    clipPath: answer!=="circle" && shapes[answer].path,
                                    borderRadius: answer === "circle" && "50%",
                                }}
                                className={`cursor-pointer ${state ? 'md:w-[39px] md:h-[39px] lg:w-[50px] lg:h-[50px] xl:w-[70px] xl:h-[70px] ' : ' md:w-[49px] md:h-[49px]  lg:w-[69px] lg:h-[69px] xl:w-[89px] xl:h-[89px] '} w-[39px] h-[39px]
                                           ${selected.includes(answer) ? "opacity-0" : "opacity-100"}
                                         `}
                            >
                            </div>
                        </DragAndDrop>
                    )

                } else {
                    return(
                        <motion.div
                            animate={{y: animateOn ? [0, 0] : [animate*500*(i+1), 0]}}
                            transition={{duration: animationDurationToShowQuestion , type: "tween", ease: "linear"}}
                            onMouseEnter={(e)=>{
                                setIndex(i);
                                setAnimateOn(true);
                            }}
                            onTouchStart={(e)=>{
                                setIndex(i);
                                setAnimateOn(true);
                            }}
                            style={{
                                marginTop: calcGapY(state)+'px',
                            }}
                            key={answer}
                            className={`relative cursor-pointer ${state ? 'md:w-[39px] md:h-[39px] lg:w-[50px] lg:h-[50px] xl:w-[70px] xl:h-[70px] ' : ' md:w-[49px] md:h-[49px]  lg:w-[69px] lg:h-[69px] xl:w-[89px] xl:h-[89px] '} w-[39px] h-[39px]
                            ${selected.includes(answer) ? "opacity-0" : "opacity-100"} ${answer===val && "answer"}
                           `}
                        >
                            <div
                                className={`w-full h-full`}
                                style={{
                                    backgroundColor: shapes[answer].color,
                                    clipPath: answer!=="circle" && shapes[answer].path,
                                    borderRadius: answer === "circle" && "50%",
                                }}
                            />
                            {hint && showHint && time==10 && answer===val && <motion.div
                                        animate={{y: [0, end.y-start.y], x: [0, end.x-start.x]}}
                                        transition={{duration: 2 , type: "tween", ease: "linear", repeat: Infinity}}
                                        className="bg-[url('/backGrounds/hint.svg')] bg-[position:50%_50%] bg-contain bg-no-repeat absolute w-[50px] h-[50px] top-[50%] z-[1000]"></motion.div>}

                        </motion.div>

                            
                       
                    )
                }
             })
           }
        </div>
    )
}