
export const getCoordinates = () => {
    const elem = document.querySelector('.division');
    const answer = document.querySelector('.answer');
    const x1 = elem.getBoundingClientRect().x;
    const y1 = elem.getBoundingClientRect().y;
    const x2 = answer.getBoundingClientRect().x;
    const y2 = answer.getBoundingClientRect().y;
    const xDistance = x1 - x2;
    const yDistance = y1 - y2;
    return [
              xDistance-(answer.getBoundingClientRect().width-elem.getBoundingClientRect().width)/2, 
              yDistance-(answer.getBoundingClientRect().height-elem.getBoundingClientRect().height)/2
           ];
}

export const generateAnswers = () => {
    const arr = new Set();
    while(arr.size<3) {
        arr.add(Math.floor(Math.random() * (4 - 2 + 1) + 2));
   
    }
    return Array.from(arr);
}

export const getFirstNumber = (answer) => {
    let firstNumber = 0;
    while(true) {
      const number = Math.floor(Math.random() * (9 - 2 + 1) + 2);
      if (number%answer === 0 && number>answer) {
           firstNumber = number;
           break;
      }
    }
    return firstNumber;
}

export const getAnswers = (answer) => {
    const arr = new Set();
    while(arr.size<3) {
        arr.add(Math.floor(Math.random() * 8) + 2);
   
    }
    const newArr = Array.from(arr);
    if (!newArr.includes(answer)) {
        newArr[Math.floor(Math.random() * 2) + 0] = answer;
    }
   
    return newArr;
}

export const getAnimationValues = () => {
    const values = [Math.random()*100];
    while(values.length < 5) {
        values.push(Math.random()*100+values[values.length-1]);
    }
    return values;
}

export const calcGapY = (state) => {
    if (window.innerWidth<1024) {
        return (260-39*3) / 4;
    }
    if (window.innerWidth>=1024 && window.innerWidth<1440) {
       if (state) {
          return (286-39*3) / 4;
       } else {
          return (354-49*3) / 4;
       }
    }
    if (window.innerWidth>=1440 && window.innerWidth<1920) {
        if (state) {
            return (364-50*3) / 4;
        } else {
            return (446-69*3) / 4;
        }
    }
    if (window.innerWidth>=1920) {
        if (state) {
            return (448-70*3) / 4;
        } else {
            return (538-89*3) / 4;
        }
    }
}