import { TopPart } from "./components/TopPart";
import { useParams } from "react-router-dom";
import { BottomPart } from "./components/BottomPart";
import { getAnswers, mixArrayNumbers } from "./Subtracting3.data";
import React, { useState, useRef, useEffect } from "react";
import { updateStorage} from "../../../hooks/useStorage";
import { updateGameStorage } from "../../../hooks/useGameStorage";
import { 
    animationDurationToShowscoresPopup, 
    animationDurationToMoveCorrectAnswer,
} from "../../../config";
import Sound from '../../../components/global/Sound';
import * as amplitude from '@amplitude/analytics-browser';
import { useUpdateReduxState } from "../../../hooks/useUpdateReduxState";
import { gameId } from "../../../hooks/getGameId";


export const Subtracting3 = ({state, handleUpdateProgress, handleOpenScore, count, answerSound, level, point, hint, subscription}) => {
    const [answers, setAnswers] = useState(getAnswers(level));
    const copyAnswers = useRef(mixArrayNumbers(answers));
    const {name, gameName} = useParams();
    const gameCount = useRef(0);
    const selectedItems = useRef([]);
    const [result, setResult] = useState([null, null, null, null, null, null]);
    const score = useRef(0);

    const updateState = useUpdateReduxState();

    const soundRef = useRef();
    const soundWrongRef = useRef();

    useEffect(()=>{
      amplitude.track(`${"subtract"+"_"+gameName.replace("_", " ")+"_"+level+"_start"}`)
    }, []);

    const handleScore=()=>{
        score.current+=point[level];
        updateStorage(point[level]);
        if (gameCount.current === count-1) {
            if(subscription) {
              updateState({
                userId: localStorage.getItem('user'),
                profileId: JSON.parse(localStorage.getItem('profile'))?._id,
                game: name,
                score: score.current,
                id: gameId(name, gameName),
                collection: name,
              })
            } else {
              handleUpdateProgress();
              updateGameStorage(name, gameName, point[level], true, level);
            }
            setTimeout(()=>handleOpenScore(), animationDurationToShowscoresPopup*1000);
            localStorage.setItem(`${name+'_'+gameName+'_'+level+'_score'}`, score.current);
            localStorage.setItem('totalScore', ++score.current);
          } else {
            if (!subscription) {
              handleUpdateProgress();
            }
            setTimeout(()=>{
                const answers = getAnswers(level);
                setAnswers(answers);
                copyAnswers.current = mixArrayNumbers(answers);
                setResult([null, null, null, null, null, null]);
                selectedItems.current = [];
                gameCount.current+=1;
                const items = document.querySelectorAll('.item');
                items.forEach((item)=>item.style.opacity=1);
              }, animationDurationToMoveCorrectAnswer*1000);
          }

    };

    const handleRefresh = (selected, sound) => {
        if (answerSound+""=="true" && sound === 'wrong') {
            soundWrongRef.current.play();
            return;
        }
        const index = answers.findIndex((item)=>item.answer===selected.answer);
        
        if (result[index] === null && answers[index].answer == selected.answer && !sound) {
          if (answerSound+""=="true") {
            soundRef.current.play();
          }
          const arr = [...result];
          arr[index] = selected;
          selectedItems.current.push(selected.answer);
          setResult(arr);
        }
    };

    useEffect(()=>{
        if (result.length && !result.includes(null)) {
          handleScore();
        }
    }, [result]);

    return (
        <div className="w-full h-full flex flex-col justify-between">
            <TopPart 
               state={state}
               result={result}
               answers={answers} 
               level={level}
            />
            <BottomPart
               hint={hint}
               state={state}
               result={result}
               answers={answers}
               level={level}
               handleRefresh = {handleRefresh}
               selected = {selectedItems.current}
               gameCount = {gameCount.current}
               copyAnswers={copyAnswers.current}
            />
            {answerSound+""=="true" && <Sound ref={soundRef} url={"/backGrounds/right_answer.wav"} loop={false}/>}
            {answerSound+""=="true" && <Sound ref={soundWrongRef} url={"/backGrounds/wrong_answer.wav"} loop={false}/>}
        </div>
        
    )
}