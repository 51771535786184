
export const calcMargin = (state, count) => {
  if (window.innerWidth<1024) {
      return (167-35.21*count) / (count+1);
  }
  if (window.innerWidth>=1024 && window.innerWidth<1440) {
     if (state) {
       return (195-38*count) / (count+1);
     } else {
       return (221-43*count) / (count+1);
     }
  }
  if (window.innerWidth>=1440 && window.innerWidth<1920) {
      if (state) {
          return (230-50*count) / (count+1);
      } else {
          return (263-51*count) / (count+1);
      }
  }
  if (window.innerWidth>=1920) {
      if (state) {
          return (264-61*count) / (count+1);
      } else {
          return (326-77*count) / (count+1);
      }
  }
}

export const calcGapY = (state) => {
  if (window.innerWidth<1024) {
      return (260-39*3) / 4;
  }
  if (window.innerWidth>=1024 && window.innerWidth<1440) {
     if (state) {
        return (286-39*3) / 4;
     } else {
        return (354-49*3) / 4;
     }
  }
  if (window.innerWidth>=1440 && window.innerWidth<1920) {
      if (state) {
          return (364-50*3) / 4;
      } else {
          return (446-69*3) / 4;
      }
  }
  if (window.innerWidth>=1920) {
      if (state) {
          return (448-70*3) / 4;
      } else {
          return (538-89*3) / 4;
      }
  }
}

export const generateAnswers = () => {
    let uniqueAnswers = new Set();
    while (uniqueAnswers.size < 3) {
      const num = Math.floor(Math.random() * 8) + 2;
      uniqueAnswers.add(num);
    }
    return Array.from(uniqueAnswers);
}

export const generateImages = () => {
    let uniqueAnswers = new Set();
    while (uniqueAnswers.size < 2) {
      const num = Math.floor(Math.random() * 15) + 1;
      uniqueAnswers.add(num);
    }
    return Array.from(uniqueAnswers);
}

 export const getCoordinates = () => {
    const elem = document.querySelector('.adding');
    const answer = document.querySelector('.answer');
    const x1 = elem.getBoundingClientRect().x;
    const y1 = elem.getBoundingClientRect().y;
    const x2 = answer.getBoundingClientRect().x;
    const y2 = answer.getBoundingClientRect().y;
    const xDistance = x1 - x2;
    const yDistance = y1 - y2;
    return [
              xDistance-(answer.getBoundingClientRect().width-elem.getBoundingClientRect().width)/2, 
              yDistance-(answer.getBoundingClientRect().height-elem.getBoundingClientRect().height)/2
           ];
}