import { motion } from "framer-motion";
import { animationDurationToShowQuestion } from "../../../../config";
import React, { useState, useEffect, useRef } from 'react';
import { DragAndDrop } from "../../../../components/global/DragAndDrop";

const colors = ["#34A5CF", "#DB3E50", "#7F48C3"];
let showHint;

export const BottomPart = ({state, answers, refresh, selected, hint, result, numbers}) => {
    const [index, setIndex] = useState('');
    const [animate, setAnimate] = useState(false);

    const isDragging =  useRef();

    const [start, setStart] = useState({x:0, y:0});   
    const [end, setEnd] = useState({x:0, y:0});
    const [time, setTime] = useState(0);

    useEffect(()=>{
        if (!animate) return;
        setAnimate(false);
        setIndex('');
        setTime(0);
        isDragging.current = false;
    }, [answers]);

    useEffect(()=>{
        showHint = true;
        if(result.indexOf(null)===-1) return;
        const start = document.querySelector('.answer');
        const end = document.querySelector('.drop'+(result.indexOf(null)));
       if (!start || !end) return;
        const startCoord = start.getBoundingClientRect();
        const endCoord = end.getBoundingClientRect();
        setStart({x:startCoord.x, y:startCoord.y});
        setEnd({x:endCoord.x, y:endCoord.y});
        setTime(0);
   }, [result]);

//    useEffect(()=>{
//         if (!hint) {
//             setTime(0);
//             return;
//         }
//         if (time===10) return;
//         const id = setInterval(()=>setTime(prev=>prev+1), 1000);
//         return ()=>clearInterval(id);
//    }, [time]);
    
    return (
        <div
            className={`dropAnswers flex text-[30px] lg:text-[42px] items-center justify-around ${state ? "xl:text-[48px] md:text-[32px] h-[67px] md:h-[73px] lg:h-[93px] xl:h-[114px]" : "xl:text-[60px] md:text-[42px] h-[67px] md:h-[90px] lg:h-[113px] xl:h-[136px]"} bg-white border-[2px] border-[#D2461B] md:border-[3px] xl:border-[4px] rounded-[14px] md:rounded-[16px] lg:rounded-[20px]`}>
            {
                answers.map((answer, i)=>{
                    if ( i === index) {
                        return (
                            <DragAndDrop
                                key={i} 
                                enableClick = {false}
                                item = {answer}
                                cmp={'sorting'}
                                drop={'drop0'}
                                dragging = {(state)=>{
                                    if (!state) return;
                                    isDragging.current = state;
                                }}
                                refresh={(sound, event) => {
                                    showHint = false;
                                    setIndex('');
                                    refresh(event, i, answer, '', isDragging.current)
                                }}
                            >
                                <div 
                                    style={{backgroundColor: colors[i]}} 
                                    className={`z-[50] ${selected.includes(answer) ? "opacity-0" : "opacity-100"} w-[120px] cursor-pointer h-[43px] ${state ? "md:w-[125px] md:h-[46px] lg:w-[173px] lg:h-[61px] xl:w-[229px] xl:h-[82px]" : "md:w-[172px] md:h-[58px] lg:w-[209px] lg:h-[73px] xl:w-[271px] xl:h-[96px]"} rounded-[7px] lg:rounded-[10px] font-bigfatScript flex items-center justify-center text-[#FFF]`}
                                >
                                    { answer }
                                </div>
                            </DragAndDrop>
    
                        )
                    } else {
                        return (
                            <motion.div
                                animate={{y: animate ? [0, 0] : [(i+1)*500, 0]}}
                                transition={{duration: animationDurationToShowQuestion, type: "tween"}}
                                key={i}
                                onMouseEnter={(e)=>{
                                   setIndex(i);
                                   setAnimate(true);
                                }}
                                onTouchStart={(e)=>{
                                    setIndex(i);
                                    setAnimate(true);
                                }}
                                style={{backgroundColor: colors[i]}} 
                                className={`relative z-[50] ${answers.indexOf(numbers[result.indexOf(null)]) && 'answer'} ${selected.includes(answer) ? "opacity-0" : "opacity-100"} w-[120px] cursor-pointer h-[43px] ${state ? "md:w-[125px] md:h-[46px] lg:w-[173px] lg:h-[61px] xl:w-[229px] xl:h-[82px]" : "md:w-[172px] md:h-[58px] lg:w-[209px] lg:h-[73px] xl:w-[271px] xl:h-[96px]"} rounded-[7px] lg:rounded-[10px] font-bigfatScript flex items-center justify-center text-[#FFF]`}
                           >
                             { answer }
                             {hint && showHint && time==10 && i === answers.indexOf(numbers[result.indexOf(null)]) &&  <motion.div
                                        animate={{y: [0, end.y-start.y], x: [0, end.x-start.x]}}
                                        transition={{duration: 2 , type: "tween", ease: "linear", repeat: Infinity}}
                                        className="bg-[url('/backGrounds/hint.svg')] bg-[position:50%_50%] bg-contain bg-no-repeat absolute w-[50px] h-[50px] top-[50%] z-[1000]"></motion.div>}
                           </motion.div>
                        )

                    }

                })
            }
        </div>
    );
}