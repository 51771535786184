
import { shapes } from '../Shape5.data';
import { motion } from 'framer-motion';
import { 
   animationDurationToShowQuestion,
} from "../../../../config";
import { memo } from 'react';


export default memo(function LeftPart ({state, animate, data, index, update}) {
    const [x, y] = index;
    return (
        <div className={`
           border-[3px] rounded-[16px] lg:rounded-[20px] border-[#2F9EBA] bg-[#FFF] flex items-center justify-center font-bigfatScript text-[#FFF]
           ${state ? "w-[344px] md:w-[350px] lg:w-[477px] xl:w-[630px] lg:text-[36px] xl:text-[46px]" : "w-[344px] md:w-[467px] lg:w-[566px] xl:w-[745px] md:text-[32px] lg:text-[46px] xl:text-[60px]"} h-full text-[24px]
        `}>
            <div className={`w-[209px] h-[178.26px] flex flex-col justify-between
               ${state ? 'md:w-[235px] md:h-[200.44px] lg:w-[336px] lg:h-[286.59px] xl:w-[408px] xl:h-[348px]' : 'md:w-[299px] md:h-[255.03px] lg:w-[433px] lg:h-[369.32px] xl:w-[497.03px] xl:h-[424px]'}`}>
               {
                  data.map((item, index)=>{
                    return (
                        <div key={Math.random()} className={`flex justify-between w-full h-[42.65px] ${state ? 'md:h-[47.96px] lg:h-[68.56px] xl:h-[83.27px]' : 'md:h-[61.02px] lg:h-[88.35px] xl:h-[101.44px]'}`}>
                            {
                               [0, 1, 2].map((number, i)=>{
                                  return (
                                    <motion.div
                                       animate={{scale: !update && [animate, 1]}}
                                       transition={{duration: animationDurationToShowQuestion, type: "tween", ease: "linear"}} 
                                       key = {Math.random()}
                                       style={{
                                            opacity: !update && x===index && y===i && 0,
                                            backgroundColor: shapes[item[number]].color,
                                            clipPath: item[number]!=="circle" && shapes[item[number]].path,
                                            borderRadius: item[number] === "circle" && "50%",
                                       }}
                                       className={`${x===index && y===i && ('drop'+x+y)} h-full w-[42.65px] ${state ? 'md:w-[47.96px] lg:w-[68.56px] xl:w-[83.27px]' : 'md:w-[61.02px] lg:w-[88.35px] xl:w-[101.44px]'}`}>
                                    </motion.div>
                                  )
                               })
                            }
                        </div>
                    )
                  })
               }
            </div>
        </div>
    )
})