import { TopPart } from "./components/TopPart";
import { BottomPart } from "./components/BottomPart";
import { useState, useRef, useEffect } from "react";
import { getNumber, getAnswers, getAnimationValues } from "./RapidMath.data";
import { useParams } from 'react-router-dom';
import { updateStorage } from "../../../hooks/useStorage";
import { updateGameStorage } from "../../../hooks/useGameStorage";
import { 
    animationDurationToMoveCorrectAnswer
} from "../../../config";
import * as amplitude from '@amplitude/analytics-browser';


export const RapidMathMult = ({state, level, handleTimerResult, handleUpdateProgress, answerSound, point, update, subscription}) => {
    const [firstNumber, setFirstNumber] = useState(getNumber(level, "first"));
    const [secondNumber, setSecondNumber] = useState(getNumber(level, "second"));
    const answer = useRef(firstNumber*secondNumber);
    const answers = useRef(getAnswers(answer.current, level));
    const [result, setResult] = useState('?');
    const score = useRef(0);
    const animate = useRef(getAnimationValues());
    const {name, gameName} = useParams();
    const time = useRef(0);
    const [refresh, setRefresh] = useState(false);

    useEffect(()=>{
        amplitude.track(`${name+"_"+gameName.replace("_", " ")+"_"+level+"_start"}`)
    }, []);

    useEffect(()=>{
      if(!update) {
          var id = setInterval(()=>{
            time.current +=1;
           
            if (time.current && time.current%5===0) {
                handleTimerResult(false);
                const firstNumber = getNumber(level);
                const secondNumber = getNumber(level);
                setFirstNumber(firstNumber);
                setSecondNumber(secondNumber);
                animate.current = getAnimationValues();
                answer.current = firstNumber*secondNumber;
                answers.current = getAnswers(answer.current, level);
                setResult('?');
                setRefresh(!refresh);
                time.current = 0;
                localStorage.setItem(`${name+'_'+gameName+'_'+level+'_score'}`, score.current);
            }
          }, 1000);
      }
      return ()=> clearInterval(id);
    });

    const checkAnswer = (state, event, type, val) => {
        if (event==='mouseup') return;
        if (state) {
            handleTimerResult(true);
            score.current += point[level];
            if (!subscription) {
                updateStorage(point[level]);
                updateGameStorage(name, gameName, point[level], false, level);
            }
            localStorage.setItem(`${name+'_'+gameName+'_'+level+'_score'}`, score.current);
        } else {
            handleTimerResult(false);
            localStorage.setItem(`${name+'_'+gameName+'_'+level+'_score'}`, score.current);
        }
        !type && setResult(val);
        if (!subscription) {
            handleUpdateProgress();
        }
        
        setTimeout(()=>{ 
            const firstNumber = getNumber(level);
            const secondNumber = getNumber(level);
            setFirstNumber(firstNumber);
            setSecondNumber(secondNumber);
            animate.current = getAnimationValues();
            answer.current = firstNumber*secondNumber;
            answers.current = getAnswers(answer.current, level);
            setResult('?');
            setRefresh(!refresh);
            time.current = 0;
        }, animationDurationToMoveCorrectAnswer*1000);
    }

    return (
        <div
           className="w-full h-full flex flex-col justify-between"
        >
            <TopPart
              state = {state}
              firstNumber = {firstNumber}
              secondNumber = {secondNumber}
              result = {result}
              animate = {animate.current}
            />
            <BottomPart
              state = {state}
              answers = {answers.current}
              answer = {answer.current}
              checkAnswer = {checkAnswer}
              answerSound = {answerSound}
            />
        </div>
    )
}