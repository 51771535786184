import React, { useState, useRef, useEffect } from 'react';
import { generateAnswers, getFirstNumber, getAnswers, getAnimationValues } from './Subtracting.data';
import { 
    animationDurationToShowscoresPopup,
    animationDurationToMoveCorrectAnswer
} from "../../../config";
import { useParams } from 'react-router-dom';
import { updateStorage } from "../../../hooks/useStorage";
import { updateGameStorage } from "../../../hooks/useGameStorage";
import RightPart from './components/RightPart';
import LeftPart from './components/LeftPart';
import * as amplitude from '@amplitude/analytics-browser';
import { useUpdateReduxState } from "../../../hooks/useUpdateReduxState";
import { gameId } from "../../../hooks/getGameId";


export const Subtracting2 = ({state, handleOpenScore, count, handleUpdateProgress, answerSound, point, hint, subscription}) => {
    const {name, gameName} = useParams();
    const allAnswer = useRef(generateAnswers());
    const gameCount = useRef(0);
    const [result, setResult] = useState('?');
    const [answer, setAnswer] = useState(allAnswer.current[gameCount.current]);
    const [animation, setAnimation] = useState(getAnimationValues());
    const copyAnimation = [...animation];
    const firstNumber = useRef(getFirstNumber(answer));
    const secondNumber = useRef(firstNumber.current-answer);
    const answersArray = useRef(getAnswers(answer));
    const score = useRef(0);

    const updateState = useUpdateReduxState();

    useEffect(()=>{
        amplitude.track(`${"subtract"+"_"+gameName.replace("_", " ")+"_start"}`)
      }, []);

    const checkAnswer = (event, type, val) => {
        if (event==='mouseup') return;
        score.current += point;
        if (!subscription) {
          updateStorage(point);
        }
        !type && setResult(val);
        setAnimation(copyAnimation);
        if (gameCount.current === count-1) {
            if (subscription) {
              updateState({
                userId: localStorage.getItem('user'),
                profileId: JSON.parse(localStorage.getItem('profile'))?._id,
                game: name,
                score: score.current,
                id: gameId(name, gameName),
                collection: name,
              })
            } else {
              handleUpdateProgress();
              updateGameStorage(name, gameName, point, true);
            }
            setTimeout(()=>handleOpenScore(), animationDurationToShowscoresPopup*1000);
            localStorage.setItem(`${name+'_'+gameName+'_'+'_score'}`, score.current);
          } else {
            if (!subscription) {
              handleUpdateProgress();
              updateGameStorage(name, gameName, point, false);
            }
            
            setTimeout(()=>{ 
              gameCount.current +=1
              const answer = allAnswer.current[gameCount.current];
              setAnswer(answer);
              setResult('?');
              setAnimation(getAnimationValues());
              firstNumber.current = getFirstNumber(answer);
              secondNumber.current = firstNumber.current-answer;
              answersArray.current = getAnswers(answer);
            }, animationDurationToMoveCorrectAnswer*1000);
          }
    }

    return (
        <div className="w-full h-full flex justify-between relative z-30">
            <LeftPart
               state={state}
               answer={answer}
               result={result}
               animation={animation}
               firstNumber={firstNumber.current}
               secondNumber={secondNumber.current}
            />
           <RightPart
               hint={hint}
               state={state}
               answer={answer}
               checkAnswer={checkAnswer}
               answerSound={answerSound}
               answers={answersArray.current}
            />
        </div>
    )
}