import { CircleButton } from "../../../../components/elements/CircleButton";
import { animationDurationToShowQuestion } from "../../../../config";
import { motion } from "framer-motion";
import { DashedCircle } from "../../../../components/elements/DashedCircle";

export const TopPart = ({state, numbers, result}) => {
    return(
        <div
            className={`addingMix flex overflow-hidden justify-between items-center w-full border-[3px] rounded-[16px] border-[#7648B5] bg-[#CCD156] lg:rounded-[20px] 
                       ${state ? "md:text-[24px] lg:text-[36px] xl:text-[46px] h-[185px] md:h-[203px] lg:h-[257px] xl:h-[316px]" : "xl:text-[60px] lg:text-[46px] md:text-[32px] h-[185px] md:h-[252px] lg:h-[315px] xl:h-[380px]"}
                       px-[79px] py-[22px] flex-col text-[24px] text-[#FFF] font-bigfatScript ${state ? "md:px-[110px] lg:px-[135px] xl:px-[169px] xl:py-[46px]" : "md:px-[142px] lg:px-[153px] md:py-[38px] lg:py-[46px] xl:px-[188px] xl:py-[63px]"}
            `}
        >
              <div className={`drop1 flex items-center justify-between ${state ? "md:h-[39px] lg:h-[50px] xl:h-[70px]" : "md:h-[49px] lg:h-[69px] xl:h-[89px]"} h-[39px] w-full`}>
                  <div className={`${result[0] ? 'opacity-50' : 'opacity-100'}`}>{numbers.number1}</div>
                  <div className={`${result[0] ? 'opacity-50' : 'opacity-100'}`}>+</div>
                  <div className={`${result[0] ? 'opacity-50' : 'opacity-100'}`}>{numbers.number2}</div>
                  <div className={`${result[0] ? 'opacity-50' : 'opacity-100'}`}>+</div>
                  <div className={`${result[0] ? 'opacity-50' : 'opacity-100'}`}>{numbers.number3}</div>
                  <div className={`${result[0] ? 'opacity-50' : 'opacity-100'}`}>=</div>
                  {!result[0] && <DashedCircle state={state} />}
                  {result[0]  && <CircleButton isActive={numbers.answer1}>
                             {numbers.answer1}
                   </CircleButton>}
              </div>
              <div className={`drop2 flex items-center justify-between ${state ? "md:h-[39px] lg:h-[50px] xl:h-[70px]" : "md:h-[49px] lg:h-[69px] xl:h-[89px]"} h-[39px] w-full`}>
                  <div className={`${result[1] ? 'opacity-50' : 'opacity-100'}`}>{numbers.number4}</div>
                  <div className={`${result[1] ? 'opacity-50' : 'opacity-100'}`}>+</div>
                  <div className={`${result[1] ? 'opacity-50' : 'opacity-100'}`}>{numbers.number5}</div>
                  <div className={`${result[1] ? 'opacity-50' : 'opacity-100'}`}>+</div>
                  <div className={`${result[1] ? 'opacity-50' : 'opacity-100'}`}>{numbers.number6}</div>
                  <div className={`${result[1] ? 'opacity-50' : 'opacity-100'}`}>=</div>
                  {!result[1] && <DashedCircle state={state} />}
                  {result[1]  && <CircleButton isActive={numbers.answer2}>
                             {numbers.answer2}
                   </CircleButton>}
              </div>
              <div className={`drop3 flex items-center justify-between ${state ? "md:h-[39px] lg:h-[50px] xl:h-[70px]" : "md:h-[49px] lg:h-[69px] xl:h-[89px]"} h-[39px] w-full`}>
                  <div className={`${result[2] ? 'opacity-50' : 'opacity-100'}`}>{numbers.number7}</div>
                  <div className={`${result[2] ? 'opacity-50' : 'opacity-100'}`}>+</div>
                  <div className={`${result[2] ? 'opacity-50' : 'opacity-100'}`}>{numbers.number8}</div>
                  <div className={`${result[2] ? 'opacity-50' : 'opacity-100'}`}>+</div>
                  <div className={`${result[2] ? 'opacity-50' : 'opacity-100'}`}>{numbers.number9}</div>
                  <div className={`${result[2] ? 'opacity-50' : 'opacity-100'}`}>=</div>
                  {!result[2] && <DashedCircle state={state} />}
                  {result[2]  && <CircleButton isActive={numbers.answer3}>
                             {numbers.answer3}
                   </CircleButton>}
              </div>
        </div>
    )
}