export const getBottomGapX = (state) => {
    if (window.innerWidth <  1024) {
      return (425-5*39)/6;
    }
    if (window.innerWidth>=1024 && window.innerWidth<1440) {
      if (state) {
        return (439-5*39)/6;
      } else {
        return (576-5*49)/6;
      } 
    }
    if (window.innerWidth>=1440 && window.innerWidth<1920) {
      if (state) {
        return (590-5*50)/6;
      } else {
        return (723-5*69)/6;
      } 
    }
    if (window.innerWidth>=1920) {
      if (state) {
        return (768-5*70)/6;
      } else {
        return (909-5*89)/6;
      } 
    }
}

export const getCoordinates = () => {
    const elem = document.querySelector('.adding');
    const answer = document.querySelector('.answer');
    const x1 = elem.getBoundingClientRect().x;
    const y1 = elem.getBoundingClientRect().y;
    const x2 = answer.getBoundingClientRect().x;
    const y2 = answer.getBoundingClientRect().y;
    const xDistance = x1 - x2;
    const yDistance = y1 - y2;
    return [
              xDistance-(answer.getBoundingClientRect().width-elem.getBoundingClientRect().width)/2, 
              yDistance-(answer.getBoundingClientRect().height-elem.getBoundingClientRect().height)/2
           ];
}

export const getResult = () => {
    return [">", "<", "="].sort(()=>Math.random()-0.5);
}

export const getAction = () => {
    const arr = ["+", "-"];
    return arr[Math.floor(Math.random()*arr.length)];
}

const getNumbersArray = (number, action, level) => {
    if (action === "+") {
         const max = number-1;
         const min = 1;
         const num = Math.floor(Math.random() * (max - min + 1) + min);
         return [num, number-num]
    } else {
         const max = level === "beginner" ? 20 : level==="advanced" ? 60 : 50;;
         const min = number+1;
         const num = Math.floor(Math.random() * (max - min + 1) + min);
         return [num, num-number]
    }
 
}

export const getNumbers = (result, action, level) => {
    if (result === "=") {
        const max = level === "beginner" ? 10 : level==="advanced" ? 50 : 30;
        const min = level === "beginner" ? 5 : level==="advanced" ? 30 : 20;;
        const number = Math.floor(Math.random() * (max - min + 1) + min);
        const leftArray = getNumbersArray(number, action, level);
        const rightArray = getNumbersArray(number, action, level);
        return [...leftArray, ...rightArray]; 
    } else {
          const max1 = 10;
          const min1 = 5;
          const firstNumber = Math.floor(Math.random() * (max1 - min1 + 1) + min1);
          const max2 = firstNumber-1;
          const min2 = 2;
          const secondNumber = Math.floor(Math.random() * (max2 - min2 + 1) + min2);
    	
          if (result === ">") {
            const leftArray = getNumbersArray(firstNumber, action);
            const rightArray = getNumbersArray(secondNumber, action);
            return [...leftArray, ...rightArray]; 
          }
    
          if (result === "<") {
            const leftArray = getNumbersArray(secondNumber, action);
            const rightArray = getNumbersArray(firstNumber, action);
            return [...leftArray, ...rightArray];
          }
    }
}
    


export const getAnswer = () => {
    const max = 0;
    const min = 3;
    return Math.floor(Math.random() * (max - min + 1) + min);
}

export const getAnswers = (number, level) => {
    const max = level === "beginner" ? 10 : level==="advanced" ? 50 : 30;;
    const min = level === "beginner" ? 5 : level==="advanced" ? 30 : 20;;
    const array = new Set();
    array.add(number);

    while(array.size<5) {
      array.add(Math.floor(Math.random() * (max - min + 1) + min));
    }
    return Array.from(array).sort(()=>Math.random()-0.5);
}

export const getRightAnswerArray = (numbers, answers, index, operator, action) => {
  if (operator === "=") {
      return [numbers[index]];
  }

  if (operator === ">") {
     const arr = [];
     answers.forEach((answer)=>{
        const copyNumbers = [...numbers];
        copyNumbers[index] = answer;
        if (action === "+") {
          if (copyNumbers[0]+copyNumbers[1] > copyNumbers[2]+copyNumbers[3]) {
            arr.push(answer);
          }
        }
        if (action === "-") {
          if (copyNumbers[0]-copyNumbers[1] > copyNumbers[2]-copyNumbers[3]) {
            arr.push(answer);
          }
        }
     });
     return arr;
  }

  if (operator === "<") {
    const arr = [];
     answers.forEach((answer)=>{
        const copyNumbers = [...numbers];
        copyNumbers[index] = answer;
        if (action === "+") {
          if (copyNumbers[0]+copyNumbers[1] < copyNumbers[2]+copyNumbers[3]) {
            arr.push(answer);
          }
        }
        if (action === "-") {
          if (copyNumbers[0]-copyNumbers[1] < copyNumbers[2]-copyNumbers[3]) {
            arr.push(answer);
          }
        }
     });
     return arr;
  }

}
