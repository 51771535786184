export const calcGapX = (state, count) => {
    if (window.innerWidth <  1024) {
      return (425-count*39)/(count+1);
    }
    if (window.innerWidth>=1024 && window.innerWidth<1440) {
      if (state) {
        return (439-count*39)/(count+1);
      } else {
        return (576-count*49)/(count+1);
      } 
    }
    if (window.innerWidth>=1440 && window.innerWidth<1920) {
      if (state) {
        return (590-count*50)/(count+1);
      } else {
        return (723-count*69)/(count+1);
      } 
    }
    if (window.innerWidth>=1920) {
      if (state) {
        return (768-count*70)/(count+1);
      } else {
        return (909-count*89)/(count+1);
      } 
    }
}

const colors = ["#78A941", "#DB3E50", "#34A5CF", "#FFB43B", "#7F48C3", "#F87E9B"];

export const getColors = (size, time) => {
   if(time<5) return;
    const min = 0;
    const max = colors.length-1;
    const arr = new Set();
    while(arr.size<size) {
       arr.add(colors[Math.floor(Math.random() * (max - min + 1) + min)]);
    }
 
    return Array.from(arr);
}

export const shuffleArray = (arr, level, time) => {
    if(time<5) return;
    if (level !== "beginner") {
        return [...colors].sort(() => 0.5 - Math.random());
    } else {
        return [...arr].sort(() => Math.random()-0.5 );
    }
}