

import React , {useEffect, useState, useRef} from "react";
import { useParams } from "react-router-dom";
import { updateStorage } from "../../../hooks/useStorage.jsx";
import { updateGameStorage } from "../../../hooks/useGameStorage";
import { 
    animationDurationToShowscoresPopup,
    animationDurationToMoveCorrectAnswer
} from "../../../config";
import { TopRowOfStepByStep } from "./components/TopPart.jsx";
import { BottomRowsOfStepByStep } from "./components/BottomPart.jsx";
import { generateSequence, generateUniqueNumbers, generateArray } from "./components/assets/data";
import Sound from '../../../components/global/Sound';
import * as amplitude from '@amplitude/analytics-browser';
import { useUpdateReduxState } from "../../../hooks/useUpdateReduxState";
import { gameId } from "../../../hooks/getGameId";


export function StepByStep({state, handleUpdateProgress, level, start, count, answerSound, handleTimer, point, hint, screenSize, subscription}) {
    const [gameCount, setGameCount] = useState(0);
    const {name, gameName} = useParams();
    const [sequence, setSequence] = useState(generateSequence()); // toppart numbers
    const [numbers, setNumbers] = useState([]); // bottompart numbers
    const [copySequence, setCopysequence] = useState(generateArray(sequence, level)); // displaying top numbers
    const soundWrongRef = useRef();
    const soundRef = useRef();
    const selectedItems = useRef([]);

    const updateState = useUpdateReduxState();

    useEffect(()=>{
        amplitude.track(`${name+"_"+gameName.replaceAll("_", " ")+"_"+level+"_start"}`);
    }, []);

    const score = useRef(0);
    const handleScore=()=>{
        score.current+=point[level];
        if (!subscription) {
            updateStorage(point[level]);
        }
        if (gameCount === count-1) {
            if (subscription) {
                updateState({
                    userId: localStorage.getItem('user'),
                    profileId: JSON.parse(localStorage.getItem('profile'))?._id,
                    game: name,
                    score: score.current,
                    id: gameId(name, gameName),
                    collection: name,
                })
            } else {
                handleUpdateProgress();
                updateGameStorage(name, gameName, point[level], true, level);
            }
            setTimeout(()=>{
                handleTimer();
                setGameCount(0);
                const sequence = generateSequence();
                selectedItems.current = [];
                setSequence(sequence);
                setCopysequence(generateArray(sequence, level));
                const items = document.querySelectorAll('.item');
                items.forEach((item)=>item.style.opacity=1);
            }, animationDurationToShowscoresPopup*1000)
            localStorage.setItem(`${name+'_'+gameName+'_'+level+'_score'}`, score.current);
            return;
          }

          if (!subscription) {
            handleUpdateProgress();
            updateGameStorage(name, gameName, point[level], false, level);
          }
          setTimeout(()=>{
            setGameCount(gameCount+1);
            const sequence = generateSequence();
            setSequence(sequence);
            setCopysequence(generateArray(sequence, level));
            selectedItems.current = [];
          }, animationDurationToMoveCorrectAnswer*1000)

    }

    useEffect(() => {
        setNumbers(() => {
            let uniqueNumbersArr = generateUniqueNumbers(sequence);
            let finalArray = [];
            while (finalArray.length < 15) {
                const randomNumber = Math.floor(Math.random() * 15); // Generates a random number between 0 and 20 (inclusive)
                if (!finalArray.includes(uniqueNumbersArr[randomNumber])) {
                    finalArray.push(uniqueNumbersArr[randomNumber]);
                }
            }
            return finalArray;
        });
    }, [start, gameCount, sequence]);

    const refresh = (selected, sound) => {
        if (answerSound+""=="true" && sound === 'wrong') {
            soundWrongRef.current.play();
            return;
        }
       const index = sequence.indexOf(selected);
       if (index!==-1 && copySequence[index] === null && !sound) {
          answerSound+""=="true" && soundRef.current.play();
          const newArr = [...copySequence];
          newArr[index] = selected;
          setCopysequence(newArr);
          selectedItems.current.push(selected);
       }
    }

    useEffect(()=>{

        if (!copySequence.includes(null)) handleScore();

    }, [copySequence]);

    return (
        <div className={"w-full h-full flex flex-col justify-between "}>
            <TopRowOfStepByStep
               gameCount={gameCount}
               copySequence = {copySequence}
               state = {state}
            />
            <BottomRowsOfStepByStep
               hint={hint}
               screenSize={screenSize}
               numbers = {numbers} 
               gameCount = {gameCount}
               state = {state}
               sequence = {sequence}
               refresh = {refresh}
               copySequence = {copySequence}
               selected = {selectedItems.current}
            />
          {answerSound+""=="true" && <Sound ref={soundRef} url={"/backGrounds/right_answer.wav"} loop={false}/>}
          {answerSound+""=="true" && <Sound ref={soundWrongRef} url={"/backGrounds/wrong_answer.wav"} loop={false}/>}
        </div>
    );
}


