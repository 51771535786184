
import { TopPart } from "./components/TopPart";
import { BottomPart } from "./components/BottomPart";
import { useState, useRef, useEffect } from "react";
import { getAction, getNumbersArray } from "./Sorting1.data";
import * as amplitude from '@amplitude/analytics-browser';
import { useParams } from 'react-router-dom';
import { updateStorage } from "../../../hooks/useStorage";
import { updateGameStorage } from "../../../hooks/useGameStorage";
import { 
    animationDurationToMoveCorrectAnswer,
    animationDurationToShowscoresPopup
} from "../../../config";
import Sound from '../../../components/global/Sound';
import { useUpdateReduxState } from "../../../hooks/useUpdateReduxState";
import { gameId } from "../../../hooks/getGameId";

const getAnswers = (numbers) => {
    return [...numbers].sort(()=>0.5-Math.random())
}
export const Sorting1 = ({state, level, handleUpdateProgress, answerSound, point, count, handleOpenScore, hint, subscription}) => {
    const {name, gameName} = useParams();
    const action = useRef(getAction());
    const numbers = useRef(getNumbersArray(action.current, level));
    const selected = useRef([]);
    const [result, setResult] = useState([null, null, null]);
    const [answers, setAnswers] = useState(getAnswers(numbers.current));

    const updateState = useUpdateReduxState();

    const score = useRef(0);
    const gameCount = useRef(0);

    const soundRef = useRef();
    const soundWrongRef = useRef();

    useEffect(()=>{
        amplitude.track(`${name+"_"+gameName.replace("_", " ")+"_"+level+"_start"}`)
    }, []);

    const refresh = (event, i, answer, type, isDragging) => {
        if (type) {
            if (!isDragging) return;
            const elem = document.querySelector('.dropAnswers');
            const elemCoord = elem.getBoundingClientRect();
            if (
                event.clientX<elemCoord.x+elemCoord.width && event.clientX>=elemCoord.x && 
                event.clientY<elemCoord.y+elemCoord.height && event.clientY>=elemCoord.y
            ) {
                const newResult = [...result].map((val)=>{
                    if (val === answer) {
                        return undefined
                    } else {
                        return val
                    }
                });
                selected.current = selected.current.filter((val)=>val!==answer)
                setResult(newResult);
                return;
           }

        } else {
            for (let i=0; i<3; i++) {
                const elem = document.querySelector('.drop'+i);
                const elemCoord = elem.getBoundingClientRect()
                if (
                     event.clientX<elemCoord.x+elemCoord.width && event.clientX>=elemCoord.x && 
                     event.clientY<elemCoord.y+elemCoord.height && event.clientY>=elemCoord.y && answer === numbers.current[i]
                 ) {
                  const newRes = [...result];
                  newRes[i] = answer;
                  selected.current.push(answer);
                  setResult(newRes);
                  return;
                } else {
                    if (soundWrongRef.current  && answerSound) {
                        soundWrongRef.current.currentTime = 0;
                        soundWrongRef.current.play();
                    }
                }
            }
        }
    }

    useEffect(()=>{
        if (!result.includes(null)) {
            if (soundRef.current && answerSound) {
                soundRef.current.currentTime = 0;
                soundRef.current.play();
            }
            if (!subscription) {
                updateStorage(point[level]);
            }
            if (gameCount.current === count-1) {
                score.current+=point[level];
                if (subscription) {
                    updateState({
                        userId: localStorage.getItem('user'),
                        profileId: JSON.parse(localStorage.getItem('profile'))?._id,
                        game: name,
                        score: score.current,
                        id: gameId(name, gameName),
                        collection: name,
                    })
                } else {
                    handleUpdateProgress();
                    updateGameStorage(name, gameName, point[level], true, level);
                }
                setTimeout(()=>handleOpenScore(), animationDurationToShowscoresPopup*1000);
                localStorage.setItem(`${name+'_'+gameName+'_'+level+'_score'}`, score.current);
                localStorage.setItem('totalScore', ++score.current);
            } else {
               score.current+=point[level];
               if (!subscription) {
                  updateGameStorage(name, gameName, point[level], false, level);
                  handleUpdateProgress();
               }
                setTimeout(()=>{
                    gameCount.current+=1;
                    action.current = getAction();
                    numbers.current = getNumbersArray(action.current, level);
                    setResult([null, null, null]);
                    setAnswers(getAnswers(numbers.current));
                    selected.current = [];
                }, animationDurationToMoveCorrectAnswer*2000);
                
            }
        }
      }, [result]);

    return (
        <div className="w-full h-full flex flex-col justify-between absolute">
            <TopPart
                state = {state}
                refresh = { refresh }
                result = {result}
            />
            <BottomPart
                state = {state}
                selected = {selected.current}
                refresh = { refresh }
                answers = { answers }
                result = { result }
                hint = { hint }
                numbers = { numbers.current }
            />
            <Sound ref={soundRef} url={"/backGrounds/right_answer.wav"} loop={false}/>
            <Sound ref={soundWrongRef} url={"/backGrounds/wrong_answer.wav"} loop={false}/>
        </div>
    )
}