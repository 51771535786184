import React, { useState, useRef, useEffect } from 'react';
import { 
    animationDurationToShowscoresPopup,
    animationDurationToMoveCorrectAnswer
} from "../../../config";
import { useParams } from 'react-router-dom';
import { updateStorage } from "../../../hooks/useStorage";
import { updateGameStorage } from "../../../hooks/useGameStorage";
import {TopPart} from './components/TopPart';
import {BottomPart} from './components/BottomPart';
import * as amplitude from '@amplitude/analytics-browser';
import { getNumbers, getResult, getAction, getAnswer, getAnswers, getRightAnswerArray } from "./Compare1.data";
import { useUpdateReduxState } from "../../../hooks/useUpdateReduxState";
import { gameId } from "../../../hooks/getGameId";

export const Compare1 = ({state, handleOpenScore, count, handleUpdateProgress, answerSound, point, level, hint, subscription}) => {
    const operatorsArray = useRef(getResult());
    const gameCount = useRef(0);
    const {name, gameName} = useParams();
    const action = useRef(getAction());
    const mathOperator = useRef(operatorsArray.current[gameCount.current]);
    const [result, setResult] = useState("?");
    const numbersArray = useRef(getNumbers(mathOperator.current, action.current, level));
    const answerIndex = useRef(getAnswer());
    const answers = useRef(getAnswers(numbersArray.current[answerIndex.current], level));
    
    const updateState = useUpdateReduxState();

    const score = useRef(0);
    const [refresh, setRefresh] = useState(false);

    useEffect(()=>{
        amplitude.track(`${name+"_"+gameName.replace("_", " ")+"_"+level+"_start"}`);
    }, []);

    const checkAnswer = (event, type, val) => {
        if (event==='mouseup') return;
        score.current += point[level];
        if (!subscription) {
          updateStorage(point[level]);
        }
        !type && setResult(val);
        if (gameCount.current === count-1) {
            if (subscription) {
              updateState({
                userId: localStorage.getItem('user'),
                profileId: JSON.parse(localStorage.getItem('profile'))?._id,
                game: name,
                score: score.current,
                id: gameId(name, gameName),
                collection: name,
              })
            } else {
              handleUpdateProgress();
              updateGameStorage(name, gameName, point[level], true, level);
            }
            setTimeout(()=>handleOpenScore(), animationDurationToShowscoresPopup*1000);
            localStorage.setItem(`${name+'_'+gameName+'_'+level+'_score'}`, score.current);
          } else {
            if (!subscription) {
              handleUpdateProgress();
              updateGameStorage(name, gameName, point[level], false, level);
            }
           
            setTimeout(()=>{ 
              gameCount.current +=1;
              action.current = getAction();
              mathOperator.current = operatorsArray.current[gameCount.current];
              numbersArray.current = getNumbers(mathOperator.current, action.current, level);
              answerIndex.current = getAnswer();
              answers.current = getAnswers(numbersArray.current[answerIndex.current], level);
              setRefresh(!refresh);
            }, animationDurationToMoveCorrectAnswer*1000);
          }
    }

    return (
        <div className="w-full h-full flex justify-between flex-col relative z-30">
           <TopPart
               numbers={numbersArray.current}
               state = {state}
               result = {result}
               operator = {mathOperator.current}
               action={action.current}
               answerIndex = {answerIndex.current}
               level={level}
            />
           <BottomPart 
              state={state}
              hint={hint}
              answers={answers.current}
              answer={getRightAnswerArray(numbersArray.current, answers.current, answerIndex.current, mathOperator.current, action.current)}
              checkAnswer={checkAnswer}
              answerSound={answerSound}
            />
        </div>
    )
}