import {useTranslation} from 'react-i18next';
import { motion } from "framer-motion";
import { getFontSize } from '../OddOrEven.styles';
import { CircleButton } from '../../../../components/elements/CircleButton';

export default function LeftControl({state, odds, evens}) {
    const {t} = useTranslation();

    return (
        <div
            className={`${state ? "w-[344px] md:w-[350px] lg:w-[477px] xl:w-[630px]" : "w-[344px] md:w-[467px] lg:w-[566px] xl:w-[745px]"} ${state ? 'md:p-[16px] lg:p-[27px]' : 'md:p-[27px] lg:p-[37px]'} p-[20px] xl:p-[37px] bg-white flex flex-row justify-between rounded-[20px] border-4 border-[#2F9EBA] h-full`}>
            <div className={'flex flex-col items-center justify-center'}>
                <div
                    className={`bg-[#f67300] flex items-center justify-center h-[52px] ${state ? 'md:h-[60px] lg:h-[71px] xl:h-[86px] w-[133px] md:w-[141px] lg:w-[181px] xl:w-[241px]' : 'md:h-[69px] lg:h-[86px] xl:h-[106px] w-[133px] md:w-[173px] lg:w-[215px] xl:w-[293px]'} font-bigfatScript text-white text-center rounded-t-[20px]`}>
                    <div className="w-full" style={{fontSize: getFontSize(t("odd"))+'px', state}}>
                        {t("odd")}
                    </div>
                </div>
                <div
                    className={`dropOdd bg-[#ffb43b] justify-items-center grid grid-cols-2 gap-x-[25px] md:gap-x-[30px] gap-y-[32px] py-[32px] lg:py-[46px] xl:gap-x-[50px] h-[173px] ${state ? 'md:gap-y-[40px]  xl:py-[50px] lg:gap-y-[46px] xl:gap-y-[50px] md:h-[196px] lg:h-[239px] xl:h-[288px] md:py-[40px] w-[133px] md:w-[141px] lg:w-[181px] xl:w-[241px] px-[16px] md:px-[16px] lg:px-[25px] xl:px-[30px] xl:text-[46px]' : 'lg:py-[50px] md:gap-y-[44px] xl:text-[60px] lg:gap-y-[50px] lg:gap-x-[36px] xl:gap-y-[55px] md:py-[44px] w-[133px] md:h-[230px] lg:h-[286px] xl:h-[352px] md:w-[173px] lg:w-[215px] xl:w-[293px] px-[16px] md:px-[24px] lg:px-[20px] xl:px-[34px] xl:py-[55px]'} rounded-b-[20px]`}>
                        {[...odds, ...new Array(4 - odds.length)].map((odd, i) => {
                            return (
                                <motion.div
                                    key={Math.random()}
                                    className="relative flex justify-center items-center text-white"
                                >
                                    <CircleButton key={i} isActive={odd || false}>
                                        {odd}
                                    </CircleButton>
                                </motion.div>
                            )
                        })}
                </div>
            </div>
            <div className={'flex flex-col items-center justify-center'}>
                <div
                    className={`bg-[#f67300] flex items-center justify-center h-[52px] text-[35px] md:text-[40px] ${state ? 'md:h-[60px] lg:h-[71px] xl:h-[86px] w-[133px] md:w-[141px] lg:w-[181px] xl:w-[241px]' : 'md:h-[69px] lg:h-[86px] xl:h-[106px] w-[133px] md:w-[173px] lg:w-[215px] xl:w-[293px]'} font-bigfatScript text-white lg:text-[45px] xl:text-[50px] text-center rounded-t-[20px]`}>
                    <div style={{fontSize: getFontSize(t("odd"))+'px', state}} className="w-full">
                        {t("even")}
                    </div>
                </div>
                <div
                    className={`dropEven bg-[#ffb43b] justify-items-center grid grid-cols-2 gap-x-[25px] md:gap-x-[30px] gap-y-[32px] py-[32px] lg:py-[46px] xl:gap-x-[50px] h-[173px] ${state ? 'md:gap-y-[40px]  xl:py-[50px] lg:gap-y-[46px] xl:gap-y-[50px] md:h-[196px] lg:h-[239px] xl:h-[288px] md:py-[40px] w-[133px] md:w-[141px] lg:w-[181px] xl:w-[241px] px-[16px] md:px-[16px] lg:px-[25px] xl:px-[30px] xl:text-[46px]' : 'lg:py-[50px] md:gap-y-[44px] xl:text-[60px] lg:gap-y-[50px] lg:gap-x-[36px] xl:gap-y-[55px] md:py-[44px] w-[133px] md:h-[230px] lg:h-[286px] xl:h-[352px] md:w-[173px] lg:w-[215px] xl:w-[293px] px-[16px] md:px-[24px] lg:px-[20px] xl:px-[34px] xl:py-[55px]'} rounded-b-[20px]`}>
                        {[...evens, ...new Array(4 - evens.length)].map((even, i) => {
                            return (
                                <div
                                    key={Math.random()}
                                    className="relative flex justify-center items-center text-white"
                                >
                                    <CircleButton key={i} isActive={even || false}>
                                        {even}
                                    </CircleButton>
                                </div>
                            )
                        })}
                </div>
            </div>
        </div>
    )
}