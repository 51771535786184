import {
    IconHome,
    IconSettings,
    IconLanguage,
    IconVolume,
    IconMusic,
    IconAbout,
    MenuIconForSmallSize,
    IconProgress
} from "../../../elements/Icon";

export const styles = {
    button: "group relative hover:scale-110 hover:brightness-110 active:scale-100",
    buttonBg: "aspect-square w-[27px] md:w-[35px] lg:w-[45px] xl:w-[52px] xl:h-[52px] text-black",
    animation: "w-full h-[15px] md:h-[20px] lg:h-[25px] xl:h-[32px] duration-[1500ms] ease-in-out group-hover:rotate-[360deg] uppercase text-[11px] md:text-[13.5px] lg:text-[16px] xl:text-[20px] text-[#FFD670] font-fredokaOne items-end",
    wrapper: "absolute z-10 -translate-x-1/2 -translate-y-1/2",
};

export const cmpStyles = {
    MenuIconForSmallSize: `${styles.wrapper} md:hidden left-[49%] top-[47%] w-[13px] md:w-[17px] lg:w-[22px]`,
    IconHome: `${`${styles.wrapper} left-[49%] top-[47%] w-[12.79px] md:w-[17px] lg:w-[22px] xl:w-[26.51px]`}`,
    IconLanguage: `${`${styles.wrapper} left-[55%] top-[55%] w-[15px] md:w-[20px] lg:w-[33px] xl:w-[33px]`}`,
    IconSettings: `${`${styles.wrapper} left-[49%] top-[47%] w-[13px] md:w-[17px] lg:w-[22px] xl:w-[24px]`}`,
    IconVolume: `${`${styles.wrapper} left-[45%] top-[47%] w-[13px] md:w-[17px] lg:w-[21px]`}`,
    IconMusic: `${`${styles.wrapper} left-[45%] top-[47%] w-[13px] md:w-[17px] lg:w-[22px]`}`,
    IconAbout: `${`${styles.wrapper} left-[50%] top-[47%] w-[13px] md:w-[17px] lg:w-[22px]`}`,
    IconProgress: `${`${styles.wrapper} left-[45%] top-[47%] w-[13px] md:w-[17px] lg:w-[22px]`}`,
    IconUser: `${`${styles.wrapper} left-[48%] top-[47%] w-[13px] md:w-[14px] lg:w-[16px] xl:w-[22px]`}`,
};
export const cmps = {
    MenuIconForSmallSize,
    IconHome,
    IconLanguage,
    IconSettings,
    IconMusic,
    IconVolume,
    IconProgress,
    IconAbout   
};
export const langs = Object.fromEntries(
    Object.entries({
        "English": "en",
        "Korean": "kr",
        "Russian": "ru",
        "Japanese": "ja",
        "Spanish": "sp",
        "Arabic": "ar",
        "Thai": "th",
        "French": "fr",
        "Italian": "it",
        "German": "de",
        "Chinese (Simplified)": "chs",
        "Chinese (Traditional)": "cht",
        "Portugal": "pt",
        "Polish": "pl",
        "Armenian": "am",
        "Malay": "my",
        "Dutch": "nl",
        "Ukrainian": "uk"
    }).sort(function(a, b) {
      return a[0].localeCompare(b[0], {sensitivity:'base'});
    })
);

export const colors = {
    counting: "rgb(var(--color-blue))",
    adding: "rgb(var(--color-violet))",
    multiplication: "rgb(var(--color-green))",
    sorting: "rgb(var(--color-red))",
    memory: "rgb(var(--color-teal))",
    shapes: "rgb(var(--color-teal))",
    subtracting: "rgb(var(--color-red))",
    division: "rgb(var(--color-ocher))",
    compare: "rgb(var(--color-pink))",
    schulte: "rgb(var(--color-purpule))",
    about: "rgb(var(--color-blue))"
};

export const elems = ['MenuIconForSmallSize', 'IconHome', 'IconLanguage', 'IconSettings', 'IconVolume', 'IconMusic', 'IconAbout'];
