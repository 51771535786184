import { animationDurationToShowQuestion } from "../../../../config";
import { motion } from "framer-motion";
import { shapes } from "../RapidShapes.data";
import { DragAndDrop } from "../../../../components/global/DragAndDrop";
import { useState, useEffect, useRef } from "react";


export const TopPart = ({state, data, level, time, result, checkingState, animate, refresh, update, handleNext, handleTime}) => {
    const items = time && !update ? data : result;

    const [i, setI] = useState('');

    const isDragging = useRef();

    useEffect(()=>{
        setI('');
        isDragging.current = false;
    }, [items]);

    return (
        <div className={`flex justify-center items-center relative bg-[url('/backGrounds/rapid_color_bg.svg')] bg-cover w-full border-[3px] border-[#2F9EBA] bg-[#FFF] rounded-[16px] ${state ? time ? "h-full" : "h-[185px] md:h-[203px] lg:h-[257px] xl:h-[316px]" : time ? "h-full" : "h-[185px] md:h-[252px] lg:h-[315px] xl:h-[380px]"}`}>
            {time ? <div className={`font-righteous text-[#DB3E50] text-[20px] md:text-[24px] lg:text-[32px] xl:text-[36px] text-center w-[28.54px] h-[58.5px] md:w-[38px] md:h-[78px] lg:w-[48px] lg:h-[97px] xl:w-[50px] xl:h-[103px] absolute bg-[url('/backGrounds/rapid_ballon.svg')] bg-cover top-[25px] right-[30px]`}>
                {time}
            </div> : ""}
            {checkingState.includes(undefined) && <div className={`z-[50] cursor-pointer absolute ${state ? 'md:w-[39px] md:h-[39px] lg:w-[50px] lg:h-[50px] xl:w-[70px] xl:h-[70px] ' : ' md:w-[49px] md:h-[49px]  lg:w-[69px] lg:h-[69px] xl:w-[89px] xl:h-[89px] '} w-[39px] h-[39px] bg-contain bottom-[2%] right-[2%] ${time ? "bg-[url('/backGrounds/reset.svg')]" : "bg-[url('/backGrounds/color_next.svg')]"} `} onClick={()=>time ? handleTime() : handleNext()} />}
            {!checkingState.includes(undefined) && !checkingState.includes(false) && <div className="absolute w-[30px] h-[30px] lg:w-[50px] lg:h-[50px] bg-[url('/backGrounds/rapid_color_check.svg')] bottom-[13px] right-[14px] bg-contain"/>}
            {(!checkingState.includes(undefined) && checkingState.includes(false)) &&
                <div
                   className={`flex absolute w-[85px] md:w-[100px] lg:w-[135px] xl:w-[160px] bottom-3 right-2 justify-between`}
                >
                  {data.map((item, i)=>{
                      return(
                        <div
                           key={i}
                           style={{
                                backgroundColor: shapes[item].color,
                                clipPath: item && item!=="circle" && shapes[item].path,
                                borderRadius: item && item=="circle" && '50%',
                           }}
                           className={`w-[25px] h-[25px] md:w-[30px] md:h-[30px] lg:w-[40px] lg:h-[40px] xl:w-[50px] xl:h-[50px]`}
                        >
                        </div>
                      )
                  })}
                </div>
            }
            <div
                className={`z-[50] dropColor w-[240px] h-[50px] ${state ? "md:w-[264px] md:h-[55px] lg:w-[336px] lg:h-[72px] xl:w-[421px] xl:h-[89px]" : "md:w-[317.86px] md:h-[68px] lg:w-[417px] lg:h-[85px] xl:w-[538px] xl:h-[114px]"} flex justify-between`}
            >
                {items.map((item, index)=>{
                    if (item && i===index) {
                        return(
                            <DragAndDrop
                                key={index}
                                enableClick = {false}
                                cmp={"colors"}
                                drop={"dropAnswers"}
                                item = {item}
                                dragging = {(state)=>{
                                    if (!state) return
                                    isDragging.current = state;
                                }}
                                refresh={(sound, event) => {
                                    setI('');
                                    refresh(event, i, item, 'top', isDragging.current);
                                }}
                            >
                                <motion.div
                                    key={index}
                                    animate={{y: !animate ? [0, 0] : [(index+1)*500, 0]}}
                                    transition={{duration: animationDurationToShowQuestion, type: "tween"}} 
                                    style={{
                                        backgroundColor: item ? level === "beginner" ? '#FBB23B' :  shapes[item].color : "#E4E4E4",
                                        clipPath: item && item!=="circle" && shapes[item].path,
                                        borderRadius: item && item=="circle" && '50%',
                                    }}
                                    className={`cursor-pointer z-[50] font-bigfatScript flex justify-center items-center text-[#FFFFFF] h-full w-[50px] ${"drop"+index} ${state ? " md:w-[55px] lg:w-[72px] xl:w-[89px]" : "md:w-[68px] lg:w-[85px] xl:w-[114px]"} bg-[#E4E4E4]
                                    text-[28px] lg:text-[40px] xl:text-[60px]
                                    `}
                                >
                                    <div className="font-fredokaOne">
                                    {!checkingState.includes(undefined) ? checkingState[index] ? "" : "x" : ""}
                                    </div>
                                    {!item && '?'}
                                </motion.div>
                            </DragAndDrop>
                        )
                    } else {
                        return(
                            <motion.div
                                onMouseEnter={(e)=>{
                                    if(time) return;
                                    setI(index);  
                                }}
                                onTouchStart={(e)=>{
                                    if(time) return;
                                    setI(index);  
                                }}
                                key={index}
                                animate={{y: !animate ? [0, 0] : [(index+1)*500, 0]}}
                                transition={{duration: animationDurationToShowQuestion, type: "tween"}} 
                                style={{
                                    backgroundColor: item ? level === "beginner" ? '#FBB23B' :  shapes[item].color : "#E4E4E4",
                                    clipPath: item && item!=="circle" && shapes[item].path,
                                    borderRadius: item && item=="circle" && '50%',
                                }}
                                className={`z-[50] font-bigfatScript flex justify-center items-center text-[#FFFFFF] h-full w-[50px] ${"drop"+index} ${state ? " md:w-[55px] lg:w-[72px] xl:w-[89px]" : "md:w-[68px] lg:w-[85px] xl:w-[114px]"} bg-[#E4E4E4]
                                   text-[28px] lg:text-[40px] xl:text-[60px]
                                `}
                            >
                                <div className="font-fredokaOne">
                                  {!checkingState.includes(undefined) ? checkingState[index] ? "" : "x" : ""}
                                </div>
                                {!item && '?'}
                            </motion.div>
                        )
                    }

                })}
            </div>
        </div>
    )
}