/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import clsx from "clsx";
import {
    BlueSvg,
    VioletSvg,
    GreenSvg,
    RedSvg,
    TealSvg,
    PinkSvg,
    OcherSvg,
} from "./Icon";

import useFitText from "use-fit-text";
import {useEffect} from "react";

export function Button({
                           as = "button",
                           className = "",
                           color = "blue",
                           variant = "primary",
                           // width = "auto",
                           children,
                           ...props
                       }) {
    // const Component = props?.to ? Link : as;
    color;
    const Component = as;
    const svgComponents = {
        blue: BlueSvg,
        violet: VioletSvg,
        green: GreenSvg,
        red: RedSvg,
        teal: TealSvg,
        pink: PinkSvg,
        ocher: OcherSvg,
    };
    const {fontSize, ref} = useFitText({
        onStart: () => {},
    });
    // useEffect(() => console.log(fontSize), [fontSize]);
    const ColorSvg = svgComponents[color];
    const baseButtonClasses = `inline-block ${
        variant === "primaryActive" ? "active:scale-125" : "active:scale-110"
    } hover:brightness-110 transform transition-all duration-150 text-cente`;

    const variants = {
        primary: `rounded-[8px] md:rounded-[10px] lg:rounded-[12px] w-[130px] md:w-[165px] lg:w-[174x] xl:w-[250px] flex-shrink-0 px-2 scalable-button py-1.5 xl:py-[14px] leading-6 uppercase font-fredokaOne`,
        primaryActive: `rounded-[15px] scalable-button py-1.5 md:py-2 text-amber-300 scale-110 xl:py-[14px] leading-6 uppercase text-white font-fredokaOne`,
    };

    const colors = {
        blue: "bg-[rgb(var(--color-blue))]",
        violet: "bg-[rgb(var(--color-violet))]",
        green: "bg-[rgb(var(--color-green))]",
        red: "bg-[rgb(var(--color-red))]",

        teal: "bg-[rgb(var(--color-teal))]",
        pink: "bg-[rgb(var(--color-pink))]",
        ocher: "bg-[rgb(var(--color-ocher))]",
        purpule: "bg-[rgb(var(--color-purpule))]",
    };

    const styles = clsx(
        baseButtonClasses,
        variants[variant],
        colors[color],
        className,
    );
    if (variant === "primary" || variant === "primaryActive") {
        return (
            <button className={`${styles} flex`}>
                <div className={`absolute left-[5px] top-[5px] max-h-[70%] w-[88%]`}>
                    <ColorSvg/>
                    {/* <svg className="h-full w-full" viewBox="0 0 219 31">
            <path
              d="M159.484 3.77758C106.828 3.77758 45.7994 4.01595 26.004 4.67479C6.20989 5.33364 2.35028 13.9549 2.35028 23.235C2.35028 26.2677 1.6464 28.1813 1.31458 30.9425C1.31458 30.9425 1.05428 31.2372 0.995873 30.4923C0.598018 25.4036 1.235 30.4194 0.518012 15.8255C-0.198551 1.2316 5.15006 0.857482 29.9064 0.185393C54.6623 -0.490006 85.7288 0.900491 121.498 0.857451C155.806 0.817722 209.343 0.03307 209.343 0.03307C222.87 0.03307 221.279 7.14465 209.343 6.09513C209.343 6.09513 178.979 3.77758 159.484 3.77758Z"
              fill="url(#paint0_linear_762_5916)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_762_5916"
                x1="0.457064"
                y1="15.501"
                x2="218.9"
                y2="15.501"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#FC5106" />
                <stop offset="0.460218" stopColor="#FF552A" />
                <stop offset="0.9999" stopColor="#FF6C32" />
              </linearGradient>
            </defs>
          </svg> */}
                </div>
                <span
                    ref={ref}
                    className="btn-text mx-auto flex justify-center truncate text-center"
                    style={{fontSize}}
                >
          {children}
        </span>
            </button>
        );
    }
    return <Component className={styles} {...props} />;
}
