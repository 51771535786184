import {useRef, useState, useEffect} from "react";
import { useParams } from "react-router-dom";
import { updateStorage } from "../../../hooks/useStorage.jsx";
import { updateGameStorage } from "../../../hooks/useGameStorage";
import { 
    animationDurationToShowscoresPopup,
    animationDurationToMoveCorrectAnswer} from "../../../config";
import RightControl from './components/RightControl.jsx';
import LeftControl from "./components/LeftControl.jsx";
import { generateNumbers } from './OddOrEven.styles';
import Sound from '../../../components/global/Sound';
import * as amplitude from '@amplitude/analytics-browser';
import { useUpdateReduxState } from "../../../hooks/useUpdateReduxState";
import { gameId } from "../../../hooks/getGameId";


export function OddOrEven({state, handleOpenScore, count, handleUpdateProgress, answerSound, point, hint, subscription}) {
    const {name, gameName} = useParams();
    const [gameCount, setGameCount] = useState(0);
    const score = useRef(0);
    const [answers, setAnswers] = useState(generateNumbers([]));
    const [odds, setOdds] = useState([]);
    const [evens, setEvens] = useState([]);
    const soundWrongRef = useRef();
    const soundRef = useRef();

    const updateState = useUpdateReduxState();

    useEffect(()=>{
        amplitude.track(`${name+"_"+gameName.replaceAll("_", " ")+"_start"}`);
    }, []);

    const refresh = (val, sound) => {
        if (answerSound+""=="true" && sound === 'wrong') {
            soundWrongRef.current.play();
            return;
        }
        if (sound) return;
        if (val%2) {
          if (odds.length === 4) return;
          answerSound+""=="true" && soundRef.current.play();
          setOdds([ ...odds, val]);
          setAnswers(generateNumbers(answers.filter(num=>num!==val)));
        } else {
          if (evens.length === 4) return;
          answerSound+""=="true" && soundRef.current.play();
          setEvens([ ...evens, val]);
          setAnswers(generateNumbers(answers.filter(num=>num!==val)));
        }
    }

    useEffect(()=>{
       if (odds.length+evens.length === 8) {
            score.current += point;
            if (!subscription) {
                updateStorage(point);
            }
            if(gameCount === count-1) {
                if (subscription) {
                    updateState({
                        userId: localStorage.getItem('user'),
                        profileId: JSON.parse(localStorage.getItem('profile'))?._id,
                        game: name,
                        score: score.current,
                        id: gameId(name, gameName),
                        collection: name,
                    })
                } else {
                    updateGameStorage(name, gameName, point, true);
                    handleUpdateProgress();
                }
                setTimeout(()=>handleOpenScore(), animationDurationToShowscoresPopup*1000);
                localStorage.setItem(`${name+'_'+gameName+'_'+'_score'}`, score.current);
                return;
            } else {
                if (!subscription) {
                    handleUpdateProgress();
                }
                setEvens([]);
                setOdds([]);
                setTimeout(()=>{
                    setGameCount(gameCount+1);
                    setAnswers(generateNumbers([]));
                }, animationDurationToMoveCorrectAnswer*1000);
            }
       }
    }, [odds, evens]);

    return (
        <div className={'w-full h-full flex flex-row justify-between z-[10] font-bigfatScript text-[30px]'}>
            <LeftControl state={state} odds={odds} evens={evens}/>
            <RightControl hint={hint} state={state} answers={answers} refresh={refresh} odds={odds} evens={evens}/>
            {answerSound+""=="true" && <Sound ref={soundRef} url={"/backGrounds/right_answer.wav"} loop={false}/>}
            {answerSound+""=="true" && <Sound ref={soundWrongRef} url={"/backGrounds/wrong_answer.wav"} loop={false}/>}
        </div>
    )
}