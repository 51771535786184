export const calcGapX = (state, count) => {
    if (window.innerWidth <  1024) {
      return (425-count*39)/(count+1);
    }
    if (window.innerWidth>=1024 && window.innerWidth<1440) {
      if (state) {
        return (439-count*39)/(count+1);
      } else {
        return (576-count*49)/(count+1);
      } 
    }
    if (window.innerWidth>=1440 && window.innerWidth<1920) {
      if (state) {
        return (590-count*50)/(count+1);
      } else {
        return (723-count*69)/(count+1);
      } 
    }
    if (window.innerWidth>=1920) {
      if (state) {
        return (768-count*70)/(count+1);
      } else {
        return (909-count*89)/(count+1);
      } 
    }
}

export const getNumbers = (size, time, level) => {
    if(time<5) return;
     const min = 1;
     const max = level === "beginner" ? 10 : level === "advanced" ? 99 : 50;
     const arr = new Set();
     while(arr.size<size) {
        arr.add(Math.floor(Math.random() * (max - min + 1) + min));
     }
  
     return Array.from(arr);
}

export const getAnswers = (data, time, level) => {
    if(time<5) return;
    const arr = new Set();
    data.forEach((num)=>arr.add(num));;
    const max = level === "beginner" ? 10 : level === "advanced" ? 99 : 50;
    const min = 1;
    while(arr.size<6) {
        arr.add(Math.floor(Math.random() * (max - min + 1) + min));
    }
    return Array.from(arr).sort(()=>Math.random()-0.5);
}

