import { getData, mixAnswers, shapes } from "./Shape2.data";
import { useState, useRef, useEffect } from "react";
import { AnswersPart } from "./components/AnswersPart";
import { 
    animationDurationToShowscoresPopup,
    animationDurationToMoveCorrectAnswer,
    animationDurationToShowQuestion,
} from "../../../config";
import { useParams } from 'react-router-dom';
import { updateStorage } from "../../../hooks/useStorage";
import { updateGameStorage } from "../../../hooks/useGameStorage";
import { motion } from 'framer-motion';
import Sound from "../../../components/global/Sound";
import * as amplitude from '@amplitude/analytics-browser';
import { gameId } from "../../../hooks/getGameId";
import { useUpdateReduxState } from "../../../hooks/useUpdateReduxState";


export const Shape2 = ({state, handleOpenScore, count, handleUpdateProgress, answerSound, point, hint, subscription}) => {
    const gameStore = JSON.parse(localStorage.getItem('playedGames'));
    const {name, gameName} = useParams();
    const [data, setData] = useState(getData());
    const answers = useRef(mixAnswers(data.answers));
    const [result, setResult] = useState([null, null, null, null]);
    const score = useRef(0);
    const gameCount = useRef(0);
    const animateArr = [0.1, 0.2, 0.3];
    const soundRef = useRef();
    const soundWrongRef = useRef();
    const updateState = useUpdateReduxState();

    useEffect(()=>{
        amplitude.track(`${name+"_"+gameName.replace("_", " ")+"_start"}`)
    }, []);

    const refresh = (event, i, answer) => {
       const elem = document.querySelector(".drop"+data.answers.indexOf(answer));
       if (
          event.clientX>=elem.getBoundingClientRect().x-elem.getBoundingClientRect().width && 
          event.clientX<=elem.getBoundingClientRect().x+elem.getBoundingClientRect().width &&
          event.clientY>=elem.getBoundingClientRect().y-elem.getBoundingClientRect().height && 
          event.clientY<=elem.getBoundingClientRect().y+elem.getBoundingClientRect().height
        ) {
           soundRef.current.currentTime = 0;
           answerSound && soundRef.current.play();
           const arr = [...result];
           arr[data.answers.indexOf(answer)] = answer;
           setResult(arr);
        } else {
            soundWrongRef.current.currentTime = 0;
            answerSound && soundWrongRef.current.play();
        }
    }

    useEffect(()=>{
       if(!result.includes(null)) {
            score.current += point;
            if(!subscription) {
                updateStorage(point);
            }
            if (gameCount.current === count-1 || (gameStore[name] && gameStore[name].find(item=>item.name===gameName)?.all === count-1)) {
                if (subscription) {
                    updateState({
                        userId: localStorage.getItem('user'),
                        profileId: JSON.parse(localStorage.getItem('profile'))?._id,
                        game: name,
                        score: score.current,
                        id: gameId(name, gameName),
                        collection: name,
                    })
                } else {
                    handleUpdateProgress();
                    updateGameStorage(name, gameName, point, true);
                }
                setTimeout(()=>handleOpenScore(), animationDurationToShowscoresPopup*1000);
                localStorage.setItem(`${name+'_'+gameName+'_'+'_score'}`, score.current);
            } else {
                if (!subscription) {
                    handleUpdateProgress();
                    updateGameStorage(name, gameName, point, false);
                }
                setTimeout(()=>{ 
                    gameCount.current +=1;
                    const data = getData();
                    answers.current = mixAnswers(data.answers);
                    setData(data);
                    setResult([null, null, null, null]);
                }, animationDurationToMoveCorrectAnswer*1000);
            }
       }
    }, [result]);

    return (
        <div className={`px-[47px] py-[48px] ${state ? "md:px-[30px] md:py-[49px] lg:px-[47px] lg:py-[58px] xl:px-[86px] xl:py-[74px]" : "md:px-[40px] md:py-[53px] lg:px-[64px] lg:py-[73px] xl:px-[86px] xl:py-[57px]"} flex flex-col justify-between w-full h-full border-[3px] border-[#2F9EBA] rounded-[16px] lg:rounded-[20px] xl:border-[4px] bg-[#FFF]`}>
            <div className={`w-full flex justify-between items-center h-[47px] ${state ? "md:h-[54px] lg:h-[70px] xl:h-[85px]" : "md:h-[70px] lg:h-[85px] xl:h-[100px]"}`}>
                {
                    data.images.map((image, i)=>{
                        return (
                            <motion.div 
                                animate={{opacity: [animateArr[gameCount.current], 1], scale: [animateArr[gameCount.current], 1]}}
                                transition={{duration: animationDurationToShowQuestion, type: "tween", ease: "linear"}} 
                                key={i}
                                style={{
                                    backgroundImage: !result[i] && `url(/backGrounds/shapes/${data.answers[i]}/${image}.svg)`,
                                    clipPath: result[i] && shapes[result[i]].path,
                                    backgroundColor: result[i] && "#FBB23B",
                                }}
                                className={`${"drop"+i} bg-[length:100%_100%] h-full w-[47px] 
                                            ${state ? "md:w-[54px] lg:w-[70px] xl:w-[85px]" : "md:w-[70px] lg:w-[85px] xl:w-[100px]"}
                                            ${result[i]==="rectangle" ? "h-[23.5px] md:h-[35px] lg:h-[42.5px] xl:h-[50px]": "h-full"}`
                                   }>
                            </motion.div>
                        )
                    })
                }
            </div>
            <AnswersPart
              hint={hint}
              state = {state}
              data = {data}
              refresh = {refresh}
              result = {result}
              animate = {animateArr[gameCount.current]}
              answers = {answers.current}
            />
            <Sound ref={soundRef} url={"/backGrounds/right_answer.wav"} loop={false}/>
            <Sound ref={soundWrongRef} url={"/backGrounds/wrong_answer.wav"} loop={false}/>
        </div>
    )
}