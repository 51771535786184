
import React, {useState, useEffect} from "react";
import { AddProfile } from "./components/AddProfile";
import { EditProfile } from "./components/EditProfile";
import { Delete } from "./components/Delete";
import { useSelector, useDispatch } from 'react-redux';
import { 
    getCountriesDataThunk, 
    updateProfilesDataThunk, 
    updateSelectedProfile, 
    updateProfiles, resetUser, deleteProfileDataThunk } from '../../../redux/features/userSlice';

export const Avatar = ({
    handleAvatar, 
    handleSubscription,
    subscription
}) => {
    const dispatch = useDispatch();
  
    const [state, setState] = useState("add");
    const [selectedAvatars, setSelectedAvatars] = useState(useSelector((state) => state.user.user.profiles?.filter((profile=>profile.avatar!=="null"))) || [JSON.parse(localStorage.getItem('profile'))]);
    const [country, setCountry] = useState(useSelector((state) => state.user.user.country) || "");
    const countries = useSelector((state) => state.user.countries);
    const selectedProfile = useSelector((state) => state.user.selectedProfile);
    const profiles = useSelector((state) => state.user.user.profiles);
    const [index, setIndex] = useState('');
    const [openCountry, setOpenCountry] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);

    const handleSave = (avatar, nickName) => {
        const arr = [...selectedAvatars];
        arr[index] = {...arr[index], avatar, nickName};
        setSelectedAvatars(arr);
        setState("add");
        setIndex("");
        if (subscription) {
            dispatch(updateProfilesDataThunk({userId: localStorage.getItem('user'), profiles: [profiles[0], ...arr], country})).
            then((res)=>{
                setSelectedAvatars(res.payload.user.profiles.filter((profile=>profile.avatar!=="null")));
            });
        } else {
            dispatch(updateProfiles([{avatar, nickName}]));
            dispatch(updateSelectedProfile({avatar, nickName}))
            localStorage.setItem('profile', JSON.stringify({avatar, nickName}));
        }
    }

    const confirmDelete = () => {
        if (subscription) {
            dispatch(deleteProfileDataThunk({userId: localStorage.getItem('user'), profileId: selectedAvatars[index]._id})).
            then((res)=>{
                setOpenDelete(false);
                setState("add");
                setIndex('');
                if (res.payload.user.profiles.length===1 || (selectedAvatars[index]._id === selectedProfile?._id)) {
                    dispatch(updateSelectedProfile(null));
                }
                setSelectedAvatars(res.payload.user.profiles.filter((profile=>profile.avatar!=="null")));
            });
        } else {
            //dispatch(resetUser());
            localStorage.removeItem('profile');
            setOpenDelete(false);
            setState("add");
            setIndex('');
            setSelectedAvatars([null]);
        }
    }

    const handleCountry = (val) => {
        setCountry(val);
        setOpenCountry(false);
    }

    const handleState = (i) => {
        if (typeof(i) === "object") {
            setState("add");
            setIndex('');
        } else {
            setState("edit");
            setIndex(i);
        }
    }

    useEffect(()=>{
        if (!countries.length) {
            dispatch(getCountriesDataThunk());
        }
    }, []);

    return (
        <div onClick={()=>handleAvatar()} className="bg-bgBlack/49 h-screen flex justify-center items-center w-full absolute z-[50]">
           <div onClick={(e)=>e.stopPropagation()} className="relative flex justify-center items-center w-[640px] h-[302px] md:w-[652px] md:h-[428px] lg:w-[928px] lg:h-[452px] lg:rounded-[16px] rounded-[13px] xl:rounded-[30px] xl:w-[1142px] xl:h-[576px] shadow-[0px_6px_36px_0px_rgba(109, 109, 109, 0.25)] bg-[url('/backGrounds/secondarybackground.svg')] bg-cover">
                {openDelete && <Delete handleDelete={()=>setOpenDelete(!openDelete)} confirmDelete={confirmDelete}/>}
                {state === "add" && !country && <img
                    onClick={()=>setOpenCountry(!openCountry)}
                    className="cursor-pointer absolute w-[30px] h-[30px] top-[18px] right-[18px] md:w-[39px] md:h-[39px] md:right-[20px] md:top-[20px] lg:w-[46px] lg:h-[46px] xl:right-[32px] xl:top-[32px]"
                    src="/backGrounds/profile/country.svg"
                />}
                {
                    state === "add" && country && <div
                       onClick={()=>setOpenCountry(!openCountry)}
                       className="cursor-pointer text-[14px] flex items-center justify-center rounded-[10px] top-[18px] right-[18px] min-w-[101px] px-[10px] h-[34px] absolute md:min-w-[111px] md:h-[39px] md:text-[16px]  md:right-[20px] md:top-[20px] lg:right-[32px] lg:text-[20px] lg:top-[32px] font-fredokaOne lg:min-w-[146px] lg:h-[46px] md:rounded-[13px] text-[#fff] bg-[#905ED0]"
                    >
                        <div
                            className="bg-cover bg-no-repeat bg-[position:50%_50%] min-w-[20px] h-[20px] md:min-w-[24px] md:h-[24px] lg:min-w-[26px] lg:h-[26px] rounded-[50%]"
                            style={{backgroundImage: countries.length && `url(${countries.find((item)=>item.country===country).flag})`}}
                        />

                        <p className="ml-[7px] lg:ml-[10px]">{country}</p>
                    </div>
                }
                {
                    state === "add" && openCountry && <div 
                       className="z-[15] lang overflow-y-scroll p-[12px] text-[14px] md:p-[14px] md:text-[16px] lg:p-[16px] lg:text-[20px] text-[#2F9EBA] font-fredokaOne  w-[160px] h-[164px] rounded-[13px] absolute right-[16px] top-[64px] md:w-[166px] md:h-[204px] md:right-[20px] md:top-[68px] lg:w-[218px] lg:h-[226px] lg:top-[87px] xl:h-[310px] xl:top-[87px] xl:right-[32px] bg-[#FFFBED] shadow-[0px_6px_36px_0px_#DCDCDD]">
                          {
                            countries.map((item)=><div key={item.country} onClick={()=>handleCountry(item.country)} className="flex items-center h-[20px] md:h-[24px] lg:h-[26px] lg:mb-[16px] mb-[10px] md:mb-[14px] cursor-pointer">
                                
                                <div
                                   className="bg-cover bg-no-repeat bg-[position:50%_50%] min-w-[20px] h-[20px] md:min-w-[24px] md:h-[24px] lg:min-w-[26px] lg:h-[26px] rounded-[50%]"
                                   style={{backgroundImage: `url(${item.flag})`}}
                                />
  

                                <p title={item.country} className="ml-[7px] lg:ml-[12px] truncate">{item.country}</p>
                            </div>)
                          }
                       </div>
                }
                {state === "add" && <AddProfile
                   subscription={subscription}
                   selectedProfile={selectedProfile}
                   selectedAvatars={selectedAvatars.length<3 && selectedAvatars[selectedAvatars.length-1] ? [...selectedAvatars, null] : selectedAvatars.length? selectedAvatars : [null]} 
                   handleState={handleState}
                   handleSubscription={handleSubscription}
                />}
                {state === "edit" && <EditProfile 
                   handleSave={handleSave} 
                   handleState={handleState}
                   handleDelete={()=>setOpenDelete(!openDelete)}
                   profile={selectedAvatars[index]}
                />}
            </div>

        </div>
    )
}