import { TopPart } from "./components/TopPart";
import { BottomPart } from "./components/BottomPart";
import { getOptions, getAnswers } from "./Shape4.data";
import { useState, useEffect, useRef } from "react";
import { 
   animationDurationToShowscoresPopup,
   animationDurationToMoveCorrectAnswer,
} from "../../../config";
import { useParams } from 'react-router-dom';
import { updateStorage } from "../../../hooks/useStorage";
import { updateGameStorage } from "../../../hooks/useGameStorage";
import Sound from "../../../components/global/Sound";
import * as amplitude from '@amplitude/analytics-browser';
import { gameId } from "../../../hooks/getGameId";
import { useUpdateReduxState } from "../../../hooks/useUpdateReduxState";

export const Shape4 = ({state, level, handleOpenScore, count, handleUpdateProgress, answerSound, point, rotate, hint, subscription}) => {
   const options = useRef(getOptions(level));
   const answers =  useRef(getAnswers(options.current, level));
   const [result, setResult] = useState([null, null, null]);
   const gameCount = useRef(0);
   const selected = useRef([]);
   const score = useRef(0);
   const rotateDeg = useRef([90, 180, 270].sort(() => 0.5 - Math.random()));
   const soundRef = useRef();
   const soundWrongRef = useRef();
   const updateState = useUpdateReduxState();

   const {name, gameName} = useParams();

   const animateArr = [0.1, 0.2, 0.3];

   useEffect(()=>{
      amplitude.track(`${name+"_"+gameName.replace("_", " ")+"_"+level+"_start"}`)
    }, []);

   const refresh = (event, i, answer) => {
      const elem = document.querySelector(".drop"+options.current.indexOf(answer));
      if (
         elem &&
         event.clientX>=elem.getBoundingClientRect().x-elem.getBoundingClientRect().width && 
         event.clientX<=elem.getBoundingClientRect().x+elem.getBoundingClientRect().width
       ) {
            if (soundRef.current && answerSound ) {
               soundRef.current.currentTime = 0;
               soundRef.current.play();
            }
           const newResult = [...result];
           newResult[options.current.indexOf(answer)] = true;
           setResult(newResult);
           selected.current.push(answer);
       } else {
         if (soundWrongRef.current && answerSound ) {
            soundWrongRef.current.currentTime = 0;
            soundWrongRef.current.play();
         }
       }
   }

   useEffect(()=>{
      if (!result.includes(null)) {
         score.current += point[level];
         if (!subscription) {
            updateStorage(point[level]);
         }
         if (gameCount.current === count-1) {
            if (subscription) {
               updateState({
                  userId: localStorage.getItem('user'),
                  profileId: JSON.parse(localStorage.getItem('profile'))?._id,
                  game: name,
                  score: score.current,
                  id: gameId(name, gameName),
                  collection: name,
               })
            } else {
               handleUpdateProgress();
               updateGameStorage(name, gameName, point[level], true, level);
            }
            setTimeout(()=>handleOpenScore(), animationDurationToShowscoresPopup*1000);
            localStorage.setItem(`${name+'_'+gameName+'_'+level+'_score'}`, score.current);
         } else {
             if (!subscription) {
               handleUpdateProgress();
               updateGameStorage(name, gameName, point[level], false, level);
             }
             
             setTimeout(()=>{ 
                 gameCount.current +=1;
                 const newOptions = getOptions(level);
                 options.current = newOptions;
                 answers.current = getAnswers(options.current, level);
                 setResult([null, null, null]);
                 selected.current = [];
             }, animationDurationToMoveCorrectAnswer*1000);
          } 
      }

   }, [result]);


   return (
      <div className={`md:py-[40px] ${state ? "lg:py-[50px] xl:py-[50px]" : " lg:py-[60px] xl:py-[62px]"} w-full h-full bg-[#FFF] flex flex-col justify-between border-[3px] border-[#2F9EBA] rounded-[16px] lg:rounded-[20px] xl:border-[4px] py-[33px]`}>
            <TopPart 
               state={state} 
               options={options.current}
               level={level}
               result = {result}
               animate = {animateArr[gameCount.current]}
            />
            <BottomPart 
               hint={hint}
               state={state} 
               answers={answers.current}
               result={result}
               options={options.current}
               level={level}
               rotate={rotate}
               rotateDeg = {rotateDeg.current[gameCount.current]}
               refresh={refresh}
               selected = {selected.current}
            />
            <Sound ref={soundRef} url={"/backGrounds/right_answer.wav"} loop={false}/>
            <Sound ref={soundWrongRef} url={"/backGrounds/wrong_answer.wav"} loop={false}/>
      </div>
   )
}