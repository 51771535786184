
import { calcMargin } from '../Adding.data';
import { CircleButton } from '../../../../components/elements/CircleButton';
import { 
    animationDurationToShowQuestion,
} from "../../../../config";
import { motion } from 'framer-motion';


export default function LeftPart ({state, number1, number2, timeduration, result, images}) {
    return (
        <div className={`flex items-center justify-center h-full border-[3px] rounded-[16px] lg:rounded-[20px] border-[#7648B5] bg-[#FFF] ${state ? "w-[344px] md:w-[350px] lg:w-[477px] xl:w-[630px]" : "w-[344px] md:w-[467px] lg:w-[566px] xl:w-[745px]"}`}>
           <div className={`w-[286px] h-[226px] ${state ? 'md:w-[302px] md:h-[252px] lg:w-[392px] lg:h-[310px] xl:w-[522px] xl:h-[374px]' : 'md:w-[376px] md:h-[300px] lg:w-[470px] lg:h-[372px] xl:w-[626px] xl:h-[458px]'} flex flex-col justify-between`}>
              <div className={`w-full h-[39px] ${state ? 'lg:h-[50px] lg:text-[36px] xl:h-[70px] xl:text-[46px]' : 'md:h-[49px] md:text-[32px] lg:h-[69px] xl:h-[89px] lg:text-[46px] xl:text-[60px]'} flex justify-between items-center text-[24px] font-bigfatScript`}>
                 <div className={`w-[45px]  h-[37px] ${state ? 'lg:h-[44px]' : 'md:h-[44px] lg:h-[44px]'} flex items-center justify-center text-[#85B054]`}>{number1}</div>
                 <div className={`w-[45px]  h-[37px] ${state ? 'lg:h-[44px]' : 'md:h-[44px] lg:h-[44px]'} flex items-center justify-center text-[#FFB43B]`}>+</div>
                 <div className={`w-[45px]  h-[37px] ${state ? 'lg:h-[44px]' : 'md:h-[44px] lg:h-[44px]'} flex items-center justify-center text-[#85B054]`}>{number2}</div>
                 <div className={`w-[45px]  h-[37px] ${state ? 'lg:h-[44px]' : 'md:h-[44px] lg:h-[44px]'} flex items-center justify-center text-[#FFB43B]`}>=</div>
                 <CircleButton className={"addingresult"} isActive={isNaN(result) ? false : result}>
                     <div className="adding">
                        {result}
                    </div>
                 </CircleButton>
              </div>
              <div className={`w-full h-[167px] ${state ? 'md:h-[195px] lg:h-[230px] xl:h-[264px]': 'md:h-[221px] lg:h-[263px] xl:h-[326px]'} flex justify-between`}>
                <div className={`w-[133px] flex justify-center items-center h-full ${state ? 'md:w-[141px] lg:w-[181px] xl:w-[241px]': 'md:w-[173px] lg:w-[215px] xl:w-[293px]'} rounded-[20px] bg-[#C4A8E3]`}>
                    <div className={`h-full flex ${number1>1 ? 'justify-between': 'justify-center'} w-[87px] ${state ? 'md:w-[97px] lg:w-[135px] xl:w-[163px]':'md:w-[117px] lg:w-[139px]  xl:w-[207px]'}`}>
                        {number1>1 && <div className={`overflow-hidden w-[30.82px] h-full ${state? 'md:w-[33px] lg:w-[43.36px] xl:w-[53px]' : 'md:w-[37.5px] lg:w-[45px] xl:w-[66px]'}`}>
                            {
                                [...new Array(Math.floor(number1/2))].map((item, i)=>{
                                    return (
                                    <motion.div
                                        animate={{y: [timeduration.shift() || 0, 0]}}
                                        transition={{duration: animationDurationToShowQuestion, type: "tween", ease: "linear"}} 
                                        key={i}
                                        style={{marginTop: calcMargin(state, Math.floor(number1/2)), backgroundImage: `url(/backGrounds/counting/${images.current[0]}.svg)`, backgroundSize: "100% 100%", backgroundRepeat: "no-repeat"}}
                                        className={`${state ? 'md:h-[38px] lg:h-[50px] xl:h-[61px]' : 'h-[38px] md:h-[43px] lg:h-[51px] xl:h-[77px]'} w-full h-[35.21px]`}
                                    />
                                    )
                                })
                            }
                        </div>}
                        <div className={`overflow-hidden w-[30.82px] h-full ${state? 'md:w-[33px] lg:w-[43.36px] xl:w-[53px]' : 'md:w-[37.5px] lg:w-[45px] xl:w-[66px]'}`}>
                            {
                                [...new Array(Math.ceil(number1>2 ? number1/2 : number1))].map((item, i)=>{
                                    return (
                                    <motion.div
                                        animate={{y: [timeduration.shift() || 0, 0]}}
                                        transition={{duration: animationDurationToShowQuestion, type: "tween", ease: "linear"}} 
                                        key={i}
                                        style={{marginTop: calcMargin(state, Math.ceil(number1/2)), backgroundImage: `url(/backGrounds/counting/${images.current[0]}.svg)`, backgroundSize: "100% 100%", backgroundRepeat: "no-repeat"}}
                                        className={`${state ? 'md:h-[38px] lg:h-[50px] xl:h-[61px]' : 'h-[38px] md:h-[43px] lg:h-[51px] xl:h-[77px]'} w-full h-[35.21px]`}
                                    />
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
                <div className={`w-[133px] flex justify-center items-center h-full ${state ? 'md:w-[141px] lg:w-[181px] xl:w-[241px]': 'md:w-[173px] lg:w-[215px] xl:w-[293px]'} rounded-[20px] bg-[#C4A8E3]`}>
                    <div className={`h-full flex ${number2>1 ? 'justify-between': 'justify-center'} w-[87px] ${state ? 'md:w-[97px] lg:w-[135px] xl:w-[163px]':'md:w-[117px] lg:w-[139px]  xl:w-[207px]'}`}>
                        {number2>1 && <div className={`overflow-hidden w-[30.82px] h-full ${state? 'md:w-[33px] lg:w-[43.36px] xl:w-[53px]' : 'md:w-[37.5px] lg:w-[45px] xl:w-[66px]'}`}>
                            {
                                [...new Array(Math.floor(number2/2))].map((item, i)=>{
                                    return (
                                    <motion.div
                                        animate={{y: [timeduration.shift(), 0]}}
                                        transition={{duration: animationDurationToShowQuestion, type: "tween", ease: "linear"}} 
                                        key={i}
                                        style={{marginTop: calcMargin(state, Math.floor(number2/2)), backgroundImage: `url(/backGrounds/counting/${images.current[1]}.svg)`, backgroundSize: "100% 100%", backgroundRepeat: "no-repeat"}}
                                        className={`${state ? 'md:h-[38px] lg:h-[50px] xl:h-[61px]' : 'h-[38px] md:h-[43px] lg:h-[51px] xl:h-[77px]'} w-full h-[35.21px]`}
                                    />
                                    )
                                })
                            }
                        </div>}
                        <div className={`overflow-hidden w-[30.82px] h-full ${state? 'md:w-[33px] lg:w-[43.36px] xl:w-[53px]' : 'md:w-[37.5px] lg:w-[45px] xl:w-[66px]'}`}>
                            {
                                [...new Array(Math.ceil(Math.ceil(number2>2 ? number2/2 : number2)))].map((item, i)=>{
                                    return (
                                    <motion.div
                                        animate={{y: [timeduration.shift(), 0]}}
                                        transition={{duration: animationDurationToShowQuestion, type: "tween", ease: "linear"}} 
                                        key={i}
                                        style={{marginTop: calcMargin(state, Math.ceil(number2/2)), backgroundImage: `url(/backGrounds/counting/${images.current[1]}.svg)`, backgroundSize: "100% 100%", backgroundRepeat: "no-repeat"}}
                                        className={`${state ? 'md:h-[38px] lg:h-[50px] xl:h-[61px]' : 'h-[38px] md:h-[43px] lg:h-[51px] xl:h-[77px]'} w-full h-[35.21px]`}
                                    />
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
              </div>
           </div>
        </div>
    )
}