import { DragAndDrop } from "../../../../components/global/DragAndDrop";
import { CircleButton } from "../../../../components/elements/CircleButton";
import { animationDurationToShowQuestion } from "../../../../config";
import { motion } from "framer-motion";
import React, {useState, useEffect, useRef} from "react";
import { getCircleComponent } from "../../../../hooks/useCircleButtonColor";

export const AnswersPart = ({state, refresh, selected, answers, gameCount, level}) => {
    const [animate, setAnimate] = useState(false);
    const colors = useRef(getCircleComponent(answers));

    const [index, setIndex] = useState('');
    const [isDragging, setIsDragging] = useState(false);

    useEffect(()=>{
        if (!animate) return;
        setAnimate(false);
        setIndex('');
        colors.current = getCircleComponent(answers);
    }, [answers]);

    const animNumbers = [100, 101, 103, 104, 105, 106, 107, 108, 109, 110, 111];

    return (
        <div className={`scale w-[379px] flex justify-between mx-auto ${state ? "md:w-[393px] lg:w-[544px] xl:w-[722px]": "md:w-[530px] lg:w-[677px] xl:w-[863px]"}`}>
            {answers.map((item, i) => {
                 if (i===index) {
                     return (
                         <DragAndDrop 
                           key={i}
                           enableClick = {false}
                           item = {item}
                           cmp={'scale'}
                           dragging = {(state)=>setIsDragging(state)}
                           refresh={(type) => refresh(item, type, index)}
                         >
                             <motion.div
                                 key={i}
                                 className={`item relative flex justify-center items-center text-white cursor-pointer ${selected.current.includes(level==="beginner" ? i : item) ? "opacity-0" : "opacity-100"}`}
                             >
                                 <CircleButton key={i} isActive={item ? item : true} color={colors.current[i]}>
                                 {item}
                                 </CircleButton>
                             </motion.div>
                         </DragAndDrop>
                     )
                 } else {
                     return (
                         <motion.div
                             animate={{y: animate ? [0, 0] : [animNumbers[gameCount], 0]}}
                             transition={{duration: animationDurationToShowQuestion*(i+1)/7, type: "tween", ease: "linear"}}
                             key={i}
                             className={`item relative flex justify-center items-center text-white cursor-pointer ${selected.current.includes(level==="beginner" ? i : item) ? "opacity-0" : "opacity-100"}`}
                             onMouseEnter={(e)=>{
                                if (!isDragging) {
                                    setIndex(i);
                                    setAnimate(true);
                                }
                             }}
                             onTouchStart={(e)=>{
                                if (!isDragging) {
                                    setIndex(i);
                                    setAnimate(true);
                                }
                            }}
                         >
                             <CircleButton key={i} isActive={item ? item : true} color={colors.current[i]}>
                                {item}
                             </CircleButton>
                        </motion.div>
                     )
                 }

             })}
        </div>
    )
}