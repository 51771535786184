import { TopPart } from "./components/TopPart";
import { BottomPart } from "./components/BottomPart";
import { useEffect, useState, useRef } from "react";
import { getShapes, getAnswers } from "./RapidShapes.data";
import { useParams } from 'react-router-dom';
import { updateStorage } from "../../../hooks/useStorage";
import { updateGameStorage } from "../../../hooks/useGameStorage";
import { 
    animationDurationToMoveCorrectAnswer,
} from "../../../config";
import Sound from '../../../components/global/Sound';
import * as amplitude from '@amplitude/analytics-browser';

export const RapidShapes = ({state, level, handleTimerResult, handleUpdateProgress, answerSound, point, update, subscription}) => {
    const {name, gameName} = useParams();
    const [time, setTime] = useState(5);
    const size = 3;
    const answersSize = level === "beginner" ? 4 : level === "advanced" ? 6 : 5; 
    const shapes = useRef(getShapes(size, time));
    const answers = useRef(getAnswers(shapes.current, time, answersSize));
    const [result, setResult] = useState([...new Array(size)]);
    const [checkingState, setCheckingState] = useState([...new Array(size)]);
    const [animate, setAnimate] = useState(true);

    const selected = useRef([]);
    const score = useRef(0);

    const soundWrongRef = useRef();
    const soundRef = useRef();

    useEffect(()=>{
       if (!time) return;
       const id = !update && setInterval(()=>setTime(time-1), 1000);
       return ()=>clearInterval(id);
    }, [time, update]);

    useEffect(()=>{
      localStorage.setItem(`${name+'_'+gameName+'_'+level+'_score'}`, 0);
      amplitude.track(`${name+"_"+gameName.replace("_", " ")+"_"+level+"_start"}`)
   }, []);

   const refresh = (event, i, answer, type, isDragging) => {
    if (type) {
        if(!isDragging) return;
        const elem = document.querySelector('.dropAnswers');
        const elemCoord = elem.getBoundingClientRect();
        if (
            event.clientX<elemCoord.x+elemCoord.width && event.clientX>=elemCoord.x && 
            event.clientY<elemCoord.y+elemCoord.height && event.clientY>=elemCoord.y
        ) {
            const newResult = [...result].map((val)=>{
                if (val === answer) {
                    return undefined
                } else {
                    return val
                }
            });
            selected.current = selected.current.filter((val)=>val!==answer)
            setResult(newResult);
            setAnimate(false);
            return;
       }

    } else {
        for (let i=0; i<size; i++) {
            const elem = document.querySelector('.drop'+i);
            const elemCoord = elem.getBoundingClientRect()
            if (
                 event.clientX<elemCoord.x+elemCoord.width && event.clientX>=elemCoord.x && 
                 event.clientY<elemCoord.y+elemCoord.height && event.clientY>=elemCoord.y
             ) {
              const newRes = [...result];
              newRes[i] = answer;
              selected.current.push(answer);
              setResult(newRes);
              setAnimate(false);
              return;
            }
         }
    }

}

    useEffect(()=>{
      if (!result.includes(undefined)) {
         const state = [];
         shapes.current.forEach((item, i) => {
            if (item === result[i]) {
               state[i] = true;
            } else {
               state[i] = false;
            }
         });
         setCheckingState(state);
         if (!state.includes(false)) {
            handleTimerResult(true);
            if (soundRef.current) {
               soundRef.current.currentTime = 0;
               soundRef.current.play();
            }
            score.current += point[level];
            updateGameStorage(name, gameName, point[level], false, level);
            if (!subscription) {
                updateStorage(point[level]);
            }
            localStorage.setItem(`${name+'_'+gameName+'_'+level+'_score'}`, score.current);
        } else {
            handleTimerResult(false);
            if (soundWrongRef.current) {
               soundWrongRef.current.currentTime = 0;
               soundWrongRef.current.play();
            }
            localStorage.setItem(`${name+'_'+gameName+'_'+level+'_score'}`, score.current);
        }
        if (!subscription) {
            handleUpdateProgress();
        }
        setTimeout(()=>{ 
         setTime(5);
         shapes.current = getShapes(size, 5);
         answers.current = getAnswers(shapes.current, 5, answersSize);
         setResult([...new Array(size)]);
         setCheckingState([...new Array(size)]);
         setAnimate(true);
         selected.current = [];
         }, animationDurationToMoveCorrectAnswer*2000);
      }
    }, [result]);

    const handleNext = () => {
      handleTimerResult(false);
      //handleUpdateProgress();
      setTimeout(()=>{ 
       setTime(5);
       shapes.current = getColors(size);
       answers.current = shuffleArray(shapes.current);
       setResult([...new Array(size)]);
       setAnimate(true);
       setCheckingState([...new Array(size)]);
       selected.current = [];
       }, animationDurationToMoveCorrectAnswer*2000);
    }

    return (
        <div className = "w-full h-full flex flex-col justify-between">
            <TopPart
                state={state} 
                level={level} 
                time={time}
                update={update}
                data={shapes.current}
                result={result}
                animate={animate}
                refresh={refresh}
                checkingState={checkingState}
                handleNext={handleNext}
                handleTime={()=>setTime(0)}
            />
            {!time && <BottomPart
                selected={selected.current}
                state={state}
                level={level}
                answers={answers.current} 
                refresh={refresh}/>
            }
            <Sound ref={soundRef} url={"/backGrounds/right_answer.wav"} loop={false}/>
            <Sound ref={soundWrongRef} url={"/backGrounds/wrong_answer.wav"} loop={false}/>
        </div>
    )
}