import {NavLink} from "react-router-dom";
import useFitText from "use-fit-text";
export default function HomePage() {
    const {fontSize, ref} = useFitText();

    return (
        <div className="flex h-full w-full items-center justify-center bg-[url('/backGrounds/home.svg')] bg-contain">
            <div className="relative">
                <div className="absolute w-full h-[263px] flex justify-center z-0 lg:mt-[40px] xl:mt-[90px]">
                    <img
                        className="w-[426px] h-[120px] md:w-[603px] md:h-[160px] xl:w-[1074px] xl:h-[263px] lg:w-[884px] lg:h-[203px]"
                        src="./backGrounds/welcome_message.png"
                    />
                </div>
                <div className="absolute top-[65%] flex w-full">
                    <NavLink

                        to="/games/schulte/classic_asc"
                        className="relative mx-auto flex w-[16%] items-center justify-center px-4 text-center font-fredokaOne text-[20px] duration-100 hover:brightness-110 active:brightness-125 md:text-[30px] lg:text-[44px] xl:text-[60px]"
                        style={({isActive, isPending}) => {
                            return {
                                color: isPending ? "red" : "inherit",
                            };
                        }}
                    >
                        <img
                            className="absolute object-contain"
                            src="./backGrounds/PlayButtonBg.svg"
                        />
                        <span
                            style={{fontSize}}
                            ref={ref}
                            className="z-10 w-full truncate text-white"
                        >
                          PLAY
                       </span>
                    </NavLink>
                </div>
                <img
                    className="max-h-screen object-contain"
                    src="./backGrounds/homeSvgs.svg"
                />
            </div>
        </div>
    );
}
