import { animationDurationToShowQuestion } from "../../../../config";
import { motion } from "framer-motion";
import { DragAndDrop } from "../../../../components/global/DragAndDrop";
import { useState, useEffect, useRef } from "react";


export const TopPart = ({state, data, level, time, result, checkingState, animate, refresh, handleTime}) => {
    const items = time ? data : result;

    const [i, setI] = useState('');
    const isDragging = useRef();
    useEffect(()=>{
        setI('');
        isDragging.current = false;
    }, [items]);

    return (
        <div className={`flex justify-center items-center relative bg-[url('/backGrounds/numbers_bg.svg')] bg-cover w-full border-[3px] border-[#2F9EBA] bg-[#FEC951] rounded-[16px] ${state ? time ? "h-full" : "h-[185px] md:h-[203px] lg:h-[257px] xl:h-[316px]" : time ? "h-full" : "h-[185px] md:h-[252px] lg:h-[315px] xl:h-[380px]"}`}>
            {time ? <div className={`font-righteous text-[#FFFFFF] text-[20px] md:text-[24px] lg:text-[32px] xl:text-[36px] text-center w-[28.54px] h-[58.5px] md:w-[38px] md:h-[78px] lg:w-[48px] lg:h-[97px] xl:w-[50px] xl:h-[103px] absolute bg-[url('/backGrounds/number_ballon.svg')] bg-cover top-[25px] right-[30px]`}>
                {time}
            </div> : ""}
            {time ? <div className={`z-[50] cursor-pointer absolute ${state ? 'md:w-[39px] md:h-[39px] lg:w-[50px] lg:h-[50px] xl:w-[70px] xl:h-[70px] ' : ' md:w-[49px] md:h-[49px]  lg:w-[69px] lg:h-[69px] xl:w-[89px] xl:h-[89px] '} w-[39px] h-[39px] bg-contain bottom-[2%] right-[2%] bg-[url('/backGrounds/reset.svg')]`} onClick={handleTime} /> : ""}
            {!checkingState.includes(undefined) && !checkingState.includes(false) && <div className="absolute w-[30px] h-[30px] lg:w-[50px] lg:h-[50px] bg-[url('/backGrounds/rapid_color_check.svg')] bottom-[13px] right-[14px] bg-contain bg-no-repeat"/>}
            {(!checkingState.includes(undefined) && checkingState.includes(false)) &&
                <div
                   className={`flex absolute space-x-[17px] bottom-3 right-2 justify-between font-bigfatScript`}
                >
                  {data.map((item, i)=>{
                      return(
                        <div
                           key={i}
                           className={`text-[#87B43F] bg-[#FFF] border-[3px] border-[#FFB43B] rounded-[4px] xl:rounded-[9px] flex justify-center items-center text-[16px] md:text-[20px] lg:text-[25px] xl:text-[32px] w-[25px] h-[25px] md:w-[30px] md:h-[30px] lg:w-[40px] lg:h-[40px] xl:w-[50px] xl:h-[50px]`}
                        >
                           {item}
                        </div>
                      )
                  })}
                </div>
            }
            <div
                className={`z-[50] dropColor space-x-[35px] lg:space-x-[40px] h-[50px] ${state ? "md:h-[55px] lg:h-[72px] xl:h-[89px]" : "md:h-[68px] lg:h-[85px] xl:h-[114px]"} flex justify-between`}
            >
                {items.map((item, index)=>{
                    if (item && i===index && checkingState.includes(undefined)) {
                        return(
                            <motion.div
                                key={index}
                                animate={{y: !animate ? [0, 0] : [(index+1)*500, 0]}}
                                transition={{duration: animationDurationToShowQuestion, type: "tween"}} 
                                className={`rounded-[4px] xl:rounded-[9px] border-[1px] md:border-[2px] xl:border-[4px] border-[#FFB43B] z-[50] font-bigfatScript flex justify-center items-center ${item ? "text-[#87B43F]" : "text-[#E4E4E4]"} h-full w-[50px] ${"drop"+index} ${state ? " md:w-[55px] lg:w-[72px] xl:w-[89px]" : "md:w-[68px] lg:w-[85px] xl:w-[114px]"} bg-[#FFFFFF]
                                    text-[36px] ${state ? "md:text-[40px] lg:text-[48px] xl:text-[64px]": "md:text-[48px] lg:text-[60px] xl:text-[68px]"}
                                `}
                            >
                                <DragAndDrop
                                    key={index}
                                    enableClick = {false}
                                    cmp={"colors"}
                                    drop={"dropAnswers"}
                                    item = {item}
                                    dragging = {(state)=>{
                                        if (!state) return
                                        isDragging.current = state;
                                    }}
                                    refresh={(sound, event) => {
                                        setI('');
                                        refresh(event, i, item, 'top', isDragging.current);
                                    }}
                               > 
                                   <div className="cursor-pointer">
                                       {item || "?"}
                                   </div>
                                </DragAndDrop>      
                            </motion.div>
                           
                        )
                    } else {
                        return(
                            <motion.div
                                onMouseEnter={(e)=>{
                                    if(time) return;
                                    setI(index);  
                                }}
                                onTouchStart={(e)=>{
                                    if(time) return;
                                    setI(index);  
                                }}
                                key={index}
                                animate={{y: !animate ? [0, 0] : [(index+1)*500, 0]}}
                                transition={{duration: animationDurationToShowQuestion, type: "tween"}} 
                                className={`rounded-[4px] xl:rounded-[9px] border-[1px] md:border-[2px] xl:border-[4px] border-[#FFB43B] z-[50] font-bigfatScript flex justify-center items-center ${item ? "text-[#87B43F]" : "text-[#E4E4E4]"} h-full w-[50px] ${"drop"+index} ${state ? " md:w-[55px] lg:w-[72px] xl:w-[89px]" : "md:w-[68px] lg:w-[85px] xl:w-[114px]"} bg-[#FFFFFF]
                                   text-[36px] ${state ? "md:text-[40px] lg:text-[48px] xl:text-[64px]": "md:text-[48px] lg:text-[60px] xl:text-[68px]"}
                                `}
                            >
                                <div className={`absolute text-[#DB3E50] font-fredokaOne text-[36px] ${state ? "md:text-[40px] lg:text-[48px] xl:text-[64px]": "md:text-[48px] lg:text-[60px] xl:text-[68px]"}`}>
                                  {!checkingState.includes(undefined) ? checkingState[index] ? "" : "x" : ""}
                                </div>
                                {item || "?"}
                            </motion.div>
                        )
                    }

                })}
            </div>
        </div>
    )
}