import { getGapX } from "../Shape4.data";
import { motion } from 'framer-motion';
import { 
    animationDurationToShowQuestion,
} from "../../../../config";

export const TopPart = ({state, options, level, animate, result}) => {
    return (
        <div className="w-full flex drop">
            {
                options.map((item, index)=>{
                    return (
                        <motion.div
                           animate={{opacity: [animate, 1], scale: [animate, 1]}}
                           transition={{duration: animationDurationToShowQuestion, type: "tween", ease: "linear"}} 
                           key={item}
                           style = {{
                              marginLeft: getGapX(3, state)+'px',
                              backgroundImage: result[index] ?  `url(/backGrounds/shapes/shapes_4/${level}/fill.svg)` : `url(/backGrounds/shapes/shapes_4/${level}/options/${item}.svg)`
                           }}
                           className={`${"drop"+index} w-[76px] h-[76px] bg-[length:100%_100%] ${state ? "md:w-[76px] lg:w-[95px] xl:w-[125px] lg:h-[95px] xl:h-[125px]" : "md:w-[95px] md:h-[95px] lg:w-[114px] xl:w-[155px] lg:h-[114px] xl:h-[155px]"}`}
                        >
                        </motion.div>
                    )
                })
            }
        </div>
    )
}