const intermediateNumbers = {
  5: [[2,3], [1,4]],
  6: [[5,1], [4,2]],
  7: [[5,2], [4,3], [6,1]],
  8: [[1,7], [2,6], [3,5]],
  9: [[1,8], [2,7], [3,6],[4,5]],
  10: [[1,9], [2,8], [3,7],[4,6]],
}

const advancedNumbers = {
  8: [[3,4,1,6,2,0]],
  9: [[0,1,8,3,4,2], [2,7,0,3,5,1], [2,5,1,6,3,0]],
  10: [[0,1,9,2,3,5],[1,2,7,4,6,0], [3,7,0,4,5,1], [4,5,1,8,2,0], [6,3,1,8,2,0]],
}

export const getAnswers = (answer, level, update) => {
  if (!update) return;
    const arr = new Set();
    const max = 10;
    const min = 1;

    while(arr.size<7) {
        arr.add(Math.floor(Math.random() * (max - min + 1) + min));
    }

    if (answer === "=") {
        if(level === "beginner") {
          const numbers = Array.from(arr);
          const index = new Set();
          while(index.size<2) {
            const min = 0;
            const max = 6;
            index.add(Math.floor(Math.random() * (max - min + 1) + min));
          }
          numbers[Array.from(index)[0]] = numbers[Array.from(index)[1]];
          return [...numbers];
        }
        if (level === "intermediate") {
          const answerMin = 5;
          const answerMax = 10;
          const result = Math.floor(Math.random() * (answerMax - answerMin + 1) + answerMin);
          const indexes = new Set();
          const min = 0;
          const max = intermediateNumbers[result].length-1;
          while(indexes.size < 2) {
            indexes.add(Math.floor(Math.random() * (max - min + 1) + min))
          }
          const arr = [...intermediateNumbers[result][Array.from(indexes)[0]], ...intermediateNumbers[result][Array.from(indexes)[1]]];
          const answersArray = new Set();
          arr.forEach(number=>answersArray.add(number));

          while(answersArray.size < 7) {
            answersArray.add(Math.floor(Math.random() * 11));
          }
          return Array.from(answersArray).sort(()=>Math.random()-0.5);
        }

        if (level === "advanced") {
          const answerMin = 8;
          const answerMax = 10;
          const result = Math.floor(Math.random() * (answerMax - answerMin + 1) + answerMin);
          const min = 0;
          const max = advancedNumbers[result].length-1;
          const index = Math.floor(Math.random() * (max - min + 1) + min);
          const answersArray = new Set();
          advancedNumbers[result][index].forEach(number=>answersArray.add(number));
          while(answersArray.size < 7) {
            answersArray.add(Math.floor(Math.random() * 11))
          }
          return Array.from(answersArray).sort(()=>Math.random()-0.5);
        }

    } else {
        return [...Array.from(arr)];
    }
   
}

export const getAnswer = () => {
    const arr = [">", "<", "="];
    const max = 2;
    const min = 0;
    const index = new Set();

    while(index.size<3) {
      index.add(Math.floor(Math.random() * (max - min + 1) + min));
    }

    arr.push([Array.from(index)[0]]);

    return [arr[Array.from(index)[0]], arr[Array.from(index)[1]], arr[Array.from(index)[2]]];
}

export const getWidth = (state, level) => {
    if (innerWidth < 1024) {
      if (level === "advanced") {
         return "w-[78px]";
      }
    }
    if (innerWidth >= 1024 && innerWidth<1440) {
      if (level === "advanced") {
         if (state) {
          return "md:w-[78px]";
         } else {
          return "md:w-[98px]";
         }
         
      }
    }
    if (innerWidth >= 1440 && innerWidth<1920) {
      if (level === "advanced") {
         if (state) {
          return "lg:w-[100px]";
         } else {
          return "lg:w-[138px]";
         }
         
      }
    }
    if (innerWidth >= 1920) {
      if (level === "advanced") {
         if (state) {
          return "xl:w-[140px]";
         } else {
          return "xl:w-[178px]";
         }
         
      }
    }
}

export const getPosition = (state, level, pos) => {
    if (innerWidth < 1024) {
       if (level === "beginner") {
          return pos === "left" ? "left-[9%]" : "right-[9%]";
       } else {
          return  pos === "left" ? "left-[3%]" : "right-[3%]";
       }
    }

    if (innerWidth >= 1024 && innerWidth < 1440) {
      if (level === "beginner") {
         return pos === "left" ? "left-[9%]" : "right-[9%]";;
      } else {
         return pos === "left" ? "left-[5%]" : "right-[5%]";;
      }
   }

  if (innerWidth >= 1440 && innerWidth < 1920) {
    if (level === "beginner") {
      if (state) {
        return pos === "left" ? "left-[9%]" : "right-[9%]";
      } else {
        return pos === "left" ? "left-[8%]" : "right-[8%]";
      }   
    } else {
       if (state) {
        return pos === "left" ? "left-[4%]" : "right-[4%]";
      } else {
        return pos === "left" ? "left-[1%]" : "right-[1%]";
      }
    }
  }
  if (innerWidth >= 1920) {
    if (level === "beginner") {
      if (state) {
        return pos === "left" ? "left-[7%]" : "right-[7%]";
      } else {
        return pos === "left" ? "left-[8%]" : "right-[8%]";
      }   
    } else {
       if (state) {
        return pos === "left" ? "left-[1%]" : "right-[1%]";
      } else {
        return pos === "left" ? "left-[2%]" : "right-[2%]";
      }
    }
  }
}

export const calcSum = (arr) => {
   return arr.reduce((aggr, number)=>{
      return aggr+=number;
   }, 0);
}

