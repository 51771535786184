import { CircleButton } from '../../../../components/elements/CircleButton';
import { DragAndDrop } from "../../../../components/global/DragAndDrop";
import { motion } from "framer-motion";
import React, { useState, useEffect, useRef } from "react";
import { 
    animationDurationToShowQuestion,
} from "../../../../config";
import { getCircleComponent } from "../../../../hooks/useCircleButtonColor";

let showHint;

export const BottomPart = ({state, answers, handleRefresh, numbers, selected, result, hint}) => {  
    const colors = useRef(getCircleComponent(answers));
    const [i, setI] = useState('');
    const [animate, setAnimate] = useState(false);
    const [start, setStart] = useState({x:0, y:0});   
    const [end, setEnd] = useState({x:0, y:0});
    const [time, setTime] = useState(0);

    useEffect(()=>{
        if (!animate) return;
        setAnimate(false);
        setI('');
        setTime(0);
        colors.current = getCircleComponent(answers);
    }, [answers]);

    useEffect(()=>{
        showHint = true;
        if(result.indexOf(null)===-1) return;
        setTime(0);
        const start = document.querySelector('.answer');
        const end = document.querySelector('.drop'+(result.indexOf(null)));
        if(!start || !end) return; 
        const startCoord = start.getBoundingClientRect();
        const endCoord = end.getBoundingClientRect();
        setStart({x:startCoord.x, y:startCoord.y});
        setEnd({x:endCoord.x+endCoord.width-endCoord.height, y:endCoord.y});
    }, [result]);

    useEffect(()=>{
        if(!hint) {
            setTime(0);
            return;
        }
        if (time==10) return;
        const id = setInterval(()=>setTime(prev=>prev+1), 1000);
        return ()=>clearInterval(id);
    }, [time, hint]);

    return (
        <div className={`w-full h-[39px] flex justify-between absolute bottom-[48px] 
            ${state ? 'md:h-[39px] md:bottom-[49px] lg:h-[50px] lg:bottom-[58px] xl:h-[70px] xl:bottom-[74px]' : 'md:h-[49px] md:bottom-[53px] lg:h-[69px] lg:bottom-[73px] xl:h-[89px] xl:bottom-[85px]'}`}>
                {answers.map((number, index) => {
                    if (i === index) {
                        return (
                            <DragAndDrop
                                key={index} 
                                enableClick = {false}
                                item = {number}
                                cmp={'sorting'}
                                drop={numbers.indexOf(number)===-1 ? 'ascending0' : 'ascending'+numbers.indexOf(number)}
                                refresh={(sound) => {
                                    showHint = false;
                                    handleRefresh(number, sound)
                                }}

                            >
                                <motion.div
                                    className={`${selected.includes(number)? 'opacity-0': 'opacity-100'} item z-[40] cursor-pointer`}
                                    key={index}
                                >
                                    <CircleButton isActive={number} color={colors.current[index]}>
                                        {number}
                                    </CircleButton>
                                </motion.div>
                            </DragAndDrop>
                        )
                    } else {
                        return (
                            <motion.div
                               animate={{y: animate ? [0, 0] : [(index+1)*100, 0]}}
                               transition={{duration: animationDurationToShowQuestion , type: "tween", ease: "linear"}}
                               className={`relative ${answers[answers.indexOf(numbers[result.indexOf(null)])]===number && "answer"} ${selected.includes(number)? 'opacity-0': 'opacity-100'} item z-[40] cursor-pointer`}
                               key={index}
                               onMouseEnter={(e)=>{
                                setI(index);
                                setAnimate(true);
                               }}
                               onTouchStart={(e)=>{
                                setI(index);
                                setAnimate(true);
                               }}
                            >
                                <CircleButton isActive={number} color={colors.current[index]}>
                                    {number}
                                </CircleButton>
                                {hint && showHint && time==10 && answers[answers.indexOf(numbers[result.indexOf(null)])]===number && <motion.div
                                        animate={{y: [0, end.y-start.y], x: [0, end.x-start.x]}}
                                        transition={{delay: 1, duration: 2, type: "tween", ease: "linear", repeat: Infinity}}
                                        className="bg-[url('/backGrounds/hint.svg')] bg-[position:50%_50%] bg-contain bg-no-repeat absolute w-[50px] h-[50px] top-[50%] z-[500]"></motion.div>}
                            </motion.div>
                          )
                    }
                })
              }
        </div>
    )
}