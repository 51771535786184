export const getSizes = (state) => {
    if (state) {
        return 'md:w-[292px] md:h-[292px] lg:w-[370px] lg:h-[370px] xl:w-[454px] xl:h-[454px] w-[266px] h-[266px]';
    } else {
        return 'md:w-[360px] md:h-[360px] lg:w-[452px] lg:h-[452px] xl:w-[543px] xl:h-[543px] w-[266px] h-[266px]';
    }
}

export const getDivSize = (state) => {
    if (state) {
        return 'md:w-[56.4px] md:h-[56.4px] lg:w-[72px] lg:h-[72px] xl:w-[88.8px] xl:h-[88.8px] w-[51.2px] h-[51.2px]';
    } else {
        return 'md:w-[70px] md:h-[70px] lg:w-[88.4px] lg:h-[88.4px] xl:w-[106.6px] xl:h-[106.6px] w-[51.2px] h-[51.2px]';
    }  
}

export const generateArray = (level, sort) => {
    const max = 25;
    const arr = [sort===1 ? level : level*25];

    while(arr.length<max) {
        arr.push(arr[arr.length-1]+level*sort);
       
    }
    
    return arr;
}

export const mixArray = (arr, level) => {
    const max = 24;
    const newArr = arr.reduce((aggr, item)=>{
        while(true) {
          const index = Math.floor(Math.random() * (max - 0 + 1) + 0);
          if (!aggr[index]) {
            aggr[index] = item;
            break;
          }
        }
        return aggr;
    }, [...new Array(max)]);
    return newArr;
}