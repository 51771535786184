import React, { useState, useRef, useEffect } from 'react';
import { 
    animationDurationToShowscoresPopup,
    animationDurationToMoveCorrectAnswer
} from "../../../config";
import { useParams } from 'react-router-dom';
import { updateStorage } from "../../../hooks/useStorage";
import { updateGameStorage } from "../../../hooks/useGameStorage";
import {TopPart} from './components/TopPart';
import {BottomPart} from './components/BottomPart';
import * as amplitude from '@amplitude/analytics-browser';
import { TopPartNumbers, BottomPartNumbers, getAnswer, getAnswers, getAction } from "./Expression.data";
import { useUpdateReduxState } from "../../../hooks/useUpdateReduxState";
import { gameId } from "../../../hooks/getGameId";


export const Expression = ({state, handleOpenScore, count, handleUpdateProgress, answerSound, point, level, hint, subscription}) => {
    const {name, gameName} = useParams();
    const updateState = useUpdateReduxState();
    const topNumbers = useRef(TopPartNumbers(level));
    const bottomNumbers = useRef(BottomPartNumbers(level));
    const action = useRef(getAction());
    const answer = useRef(getAnswer(topNumbers.current, bottomNumbers.current, action.current));
    const answers = useRef(getAnswers());
   

    const [result, setResult] = useState('?');
    const score = useRef(0);
    const gameCount = useRef(0);
    const [refresh, setRefresh] = useState(false);

    useEffect(()=>{
        amplitude.track(`${name+"_"+gameName.replace("_", " ")+"_"+level+"_start"}`);
    }, []);

    const checkAnswer = (event, type, val) => {
        if (event==='mouseup') return;
        score.current += point[level];
        if (!subscription) {
            updateStorage(point[level]);
        }
        !type && setResult(val);
        if (gameCount.current === count-1) {
            if (subscription) {
              updateState({
                userId: localStorage.getItem('user'),
                profileId: JSON.parse(localStorage.getItem('profile'))?._id,
                game: name,
                score: score.current,
                id: gameId(name, gameName),
                collection: name,
              })
            } else {
              handleUpdateProgress();
              updateGameStorage(name, gameName, point[level], true, level);
            }
            setTimeout(()=>handleOpenScore(), animationDurationToShowscoresPopup*1000);
            localStorage.setItem(`${name+'_'+gameName+'_'+level+'_score'}`, score.current);
          } else {
            if (!subscription) {
              handleUpdateProgress();
              updateGameStorage(name, gameName, point[level], false, level);
            }
            
            setTimeout(()=>{ 
              gameCount.current +=1;
              action.current = getAction();
              topNumbers.current = TopPartNumbers(level);
              bottomNumbers.current = BottomPartNumbers(level);
              answer.current = getAnswer(topNumbers.current, bottomNumbers.current, action.current);
              answers.current = getAnswers();
              setResult('?');
              setRefresh(!refresh);
            }, animationDurationToMoveCorrectAnswer*1000);
          }
    }

    return (
        <div className="w-full h-full flex justify-between flex-col relative z-30">
           <TopPart
               topNumbers = {topNumbers.current}
               bottomNumbers = {bottomNumbers.current}
               state = {state}
               result = {result}
               action = {action.current}
               level={level}
            />
           <BottomPart 
              state={state}
              hint={hint}
              answer={answer.current}
              answers={answers.current}
              checkAnswer={checkAnswer}
              answerSound={answerSound}
            />
        </div>
    )
}