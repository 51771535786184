
 export const imageMarginLeft = (state, count) => {
    if (window.innerWidth<1024) {
        return (286-49.53*count) / (count+1);
    }
    if (window.innerWidth>=1024 && window.innerWidth<1440) {
       if (state) {
          return (302-57.97*count) / (count+1);
       } else {
          return (376-67*count) / (count+1);
       }
    }
    if (window.innerWidth>=1440 && window.innerWidth<1920) {
        if (state) {
            return (392-72*count) / (count+1);
        } else {
            return (470-80*count) / (count+1);
        }
    }
    if (window.innerWidth>=1920) {
        if (state) {
            return (522-85*count) / (count+1);
        } else {
            return (626-102*count) / (count+1);
        }
    }
 }

 export const imageMarginTop = (state, answer) => {
    const count = answer>2 ? 2 : 1;
    if (window.innerWidth<1024) {
        return (167-34.31*count) / (count+1);
    }
    if (window.innerWidth>=1024 && window.innerWidth<1440) {
       if (state) {
          return (195-40.16*count) / (count+1);
       } else {
          return (221-46*count) / (count+1);
       }
    }
    if (window.innerWidth>=1440 && window.innerWidth<1920) {
        if (state) {
            return (230-50*count) / (count+1);
        } else {
            return (263-55*count) / (count+1);
        }
    }
    if (window.innerWidth>=1920) {
        if (state) {
            return (264-59*count) / (count+1);
        } else {
            return (326-70*count) / (count+1);
        }
    }
 }


export const generateImages = () => {
    const arr = new Set();
    arr.add(20);
    while(arr.size<3) {
        arr.add(Math.floor(Math.random() * 23) + 1);
    }
    return Array.from(arr);
}

export const getAnswers = () => {
    const arr = new Set();
    while(arr.size<3) {
        arr.add(Math.floor(Math.random() * (8 - 3 + 1) + 3));
    }
    return Array.from(arr)
}

export const getFisrtNumber = (answer) => {
    let firstNumber = 0;
    while(true) {
      const number = Math.floor(Math.random() * (9 - answer + 1) + answer);
      if (number>answer) {
        firstNumber = number;
        break;
      }
    }
    return firstNumber;
}

export const getAnswersArray = (answer) => {
    const arr = new Set();
    while(arr.size<3) {
        arr.add(Math.floor(Math.random() * 9) + 1);
    }
    const newArr = Array.from(arr);
     if (!newArr.includes(answer)) {
        newArr[Math.floor(Math.random() * 2) + 0] = answer;
     }
   
    return newArr;
}

export const getNumbers = (number) => {
    const result = [];
    for (let i=0; i<number; i++) {
      result.push(i);
    }
    return result;
}
  

export const getAnimationValues = (answer) => {
    const topValues = [Math.random()*100];
    while(topValues.length < answer) {
        topValues.push(Math.random()*100+topValues[topValues.length-1])
    }
    return topValues;
}

export const getCoordinates = () => {
    const elem = document.querySelector('.subtracting');
    const answer = document.querySelector('.answer');
    const x1 = elem.getBoundingClientRect().x;
    const y1 = elem.getBoundingClientRect().y;
    const x2 = answer.getBoundingClientRect().x;
    const y2 = answer.getBoundingClientRect().y;
    const xDistance = x1 - x2;
    const yDistance = y1 - y2;
    return [
              xDistance-(answer.getBoundingClientRect().width-elem.getBoundingClientRect().width)/2, 
              yDistance-(answer.getBoundingClientRect().height-elem.getBoundingClientRect().height)/2
           ];
}

export const calcGapY = (state) => {
    if (window.innerWidth<1024) {
        return (260-39*3) / 4;
    }
    if (window.innerWidth>=1024 && window.innerWidth<1440) {
       if (state) {
          return (286-39*3) / 4;
       } else {
          return (354-49*3) / 4;
       }
    }
    if (window.innerWidth>=1440 && window.innerWidth<1920) {
        if (state) {
            return (364-50*3) / 4;
        } else {
            return (446-69*3) / 4;
        }
    }
    if (window.innerWidth>=1920) {
        if (state) {
            return (448-70*3) / 4;
        } else {
            return (538-89*3) / 4;
        }
    }
  }