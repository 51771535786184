

export const Refund = () =>{
    return (
        <div
           className="font-concert text-[14px] md:text-[20px] lg:text-[24px] xl:text-[36px] text-[#5C5C5C] select-none flex w-full h-screen md:w-auto overflow-x-scroll no-scrollbar flex-col items-center bg-cover bg-[url('/backGrounds/home.svg')] z-0 relative"
        >
            <div className="w-[90%] text-left mt-[50px] text-[#8048C2]">Refund Policy</div>
            <div className="w-[90%] mt-[20px]">
                Thank you for choosing our application subscription service! We strive to provide the best user experience possible. However, we understand that circumstances may arise where a refund is necessary. Please read our refund policy carefully to understand your rights and responsibilities regarding refunds:
            </div>
            <div className="w-[90%] text-left mt-[5px]">
                <div className="text-[#2F9EBA]">1. Refund Eligibility:</div>
                <ul className="list-disc ml-[5%]">
                    <li>Refunds may be granted under the following conditions:</li>
                    <li>
                       Technical issues or errors that prevent you from accessing or using the application as intended, and our support team is unable to resolve the issue within a reasonable timeframe.
                    </li>
                    <li>
                       Unauthorized charges or billing errors.
                    </li>
                    <li>Any other valid reason as determined by our customer support team.</li>
                </ul>
            </div>

            <div className="w-[90%] text-left mt-[5px]">
                <div className="text-[#2F9EBA]">2. Refund Process:</div>
                <ul className="list-disc ml-[5%]">
                    <li>To request a refund, please contact our customer support team at <a className="text-[#2F9EBA]" href="mailto:mathgame@witplex.com">mathgame@witplex.com</a>  within 7 days of the purchase date.</li>
                    <li>
                       In your refund request, please provide detailed information regarding the reason for the refund request, along with any relevant evidence or documentation.
                    </li>
                    <li>
                       Our customer support team will review your request and respond to you promptly regarding the status of your refund.
                    </li>
                    <li>If your refund request is approved, the refund will be processed using the original payment method used for the purchase.</li>
                </ul>
            </div>


            <div className="w-[90%] text-left mt-[5px]">
                <div className="text-[#2F9EBA]">3. Subscription Cancellation:</div>
                <ul className="list-disc ml-[5%]">
                    <li>
                       If you no longer wish to use our application subscription service, you can cancel your subscription at any time. Please refer to the instructions provided by the platform through which you made the purchase (e.g., Apple Store, Google Play Store) for guidance on how to cancel your subscription.
                    </li>
                    <li>
                       Upon cancellation, your subscription will remain active until the end of the current billing cycle, and you will not be charged for subsequent billing periods.
                    </li>
                </ul>
            </div>

            <div className="w-[90%] text-left mt-[5px]">
                <div className="text-[#2F9EBA]">4. Non-Refundable Items:</div>
                <ul className="list-disc ml-[5%]">
                    <li>Please note that certain items are non-refundable, including but not limited to:</li>
                    <li>
                       Subscription fees for periods during which the application was accessible and functional.
                    </li>
                    <li>
                       Any promotional or discounted purchases.
                    </li>
                    <li>In-app purchases or transactions made within the application.</li>
                </ul>
            </div>

            <div className="w-[90%] text-left mt-[5px]">
                <div className="text-[#2F9EBA]">5. Changes to Refund Policy:</div>
                <ul className="list-disc ml-[5%]">
                    <li>
                       We reserve the right to modify or update this refund policy at any time without prior notice. Any changes will be effective immediately upon posting on our website.
                    </li>
                    <li>
                       It is your responsibility to review this refund policy periodically for any updates or changes.
                    </li>
                </ul>
            </div>
            <div className="w-[90%] mt-[20px]">
               By continuing to use our application subscription service, you acknowledge that you have read, understood, and agreed to the terms of this refund policy. If you have any questions or concerns regarding this policy, please contact us at <a className="text-[#2F9EBA]" href="mailto:mathgame@witplex.com">mathgame@witplex.com</a>.
            </div>
            <div className="w-[90%] mt-[10px] text-left">Thank you for your understanding and cooperation.</div>
            <div className="w-[90%] mt-[10px] text-left">WitPlex LLC</div>
        </div>
    )
}