import {IconGray} from "../../../components/index.js";
import React, {useState, useEffect, useRef} from 'react';
import {motion} from "framer-motion"
import { useParams } from "react-router-dom";
import { updateStorage } from "../../../hooks/useStorage.jsx";
import { updateGameStorage } from "../../../hooks/useGameStorage";
import { 
   animationDurationToShowscoresPopup,
   animationDurationToShowQuestion,
   animationDurationToMoveCorrectAnswer} from "../../../config";
import Sound from "../../../components/global/Sound";
import * as amplitude from '@amplitude/analytics-browser';
import { useUpdateReduxState } from "../../../hooks/useUpdateReduxState";
import { gameId } from "../../../hooks/getGameId";

const colors = [
    ['#DB3E50', '#F1475B'],
    ['#34A5CF', '#48B9E3'],
    ['#78A941', '#87B43F'],
    ['#7F48C3', '#985FDF'],
 ];
const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

const generateAnswers=(number)=>{
  const answers = [];
  while(true) {
    if (answers.length === 4) break;
    const val = numbers[Math.floor(Math.random()*numbers.length)];
    if (answers.length === 3) {
      if (answers.includes(number)) {
        if (answers.includes(val)) continue;
        answers.push(val);
      } else {
        answers.push(number);
      }
    } else {
        if (answers.includes(val)) continue;
        answers.push(val);
    }
  }
  return answers;
}
const deltaY = (state) => {
  if (state === true) {
      if(window.innerWidth>=1920) {
          return 20;
       }
       if(window.innerWidth>=1440) {
          return 20;
       }
       if(window.innerWidth<1440) {
          return 15;
       }
  } else {
       if(window.innerWidth>=1920) {
          return 25;
       }
       if(window.innerWidth>=1024) {
          return 20;
       }
       if(window.innerWidth<1024) {
          return 15;
       }
  }

}
const getCoordinates = (i) => {
  const elem = document.querySelector('.result');
  const answer = document.querySelectorAll('.answer');
  const x1 = elem.offsetTop;
  const y1 = elem.offsetLeft;
  const x2 = answer[i].offsetTop;
  const y2 = answer[i].offsetLeft;
  const xDistance = x1 - x2;
  const yDistance = y1 - y2;
  return [xDistance, yDistance, answer[i]];
}

const getGap = (state, elem) => {
   if (window.innerWidth<1024) {
     return (216-elem*36)/(elem+1);
   }
   if (window.innerWidth>=1024 && window.innerWidth<1440) {
    if (state) {
      return (247-elem*40)/(elem+1);
    } else {
      return (324-elem*50)/(elem+1);
    }
    
  }
  if (window.innerWidth>=1440 && window.innerWidth<1920) {
    if (state) {
      return (315-elem*50)/(elem+1);
    } else {
      return (428-elem*60)/(elem+1);
    } 
  }
  if (window.innerWidth>=1920) {
    if (state) {
      return (461-elem*75)/(elem+1);
    } else {
      return (510-elem*83)/(elem+1);
    } 
  }
}

const generateNumbers = () => {
   const arr = new Set();
   while(arr.size<3) {
     arr.add(numbers[Math.floor(Math.random()*numbers.length)]);
   }
   return Array.from(arr);
}

const getImages = () => {
  const arr = new Set();
  while(arr.size<3) {
    arr.add([...numbers, 11, 12, 13][Math.floor(Math.random()*[...numbers, 11, 12, 13].length)]);
  }
  return Array.from(arr);
}
let images = getImages();

export function Counting({state, handleOpenScore, count, handleUpdateProgress, answerSound, point, hint, subscription}) {
    const gameStore = JSON.parse(localStorage.getItem('playedGames'));
    const updateState = useUpdateReduxState();
    const soundWrongRef = useRef();
    const soundRef = useRef();
    const numbers = generateNumbers();
    const gameCount = useRef(0);
    const [number, setNumber] = useState(numbers[gameCount.current]);
    const timeduration = [animationDurationToShowQuestion/number]
    while(timeduration.length <= number) {
      timeduration.push(animationDurationToShowQuestion/number+timeduration[timeduration.length-1])
    }
    const [answers, setAnswers] = useState(generateAnswers(number));
    const [coord, setCoord] = useState({x:0, y:0});
    const {name, gameName} = useParams();
    const [index, setIndex] = useState("");
    const [update, setUpdate] = useState(false);
    const score = useRef(0);

    const [time, setTime] = useState(0);

    useEffect(()=>{
      amplitude.track(`${name+"_"+gameName.replace("_", " ")+"_start"}`)
    }, []);

    useEffect(()=>{
      if (!hint) return;
      const id = setInterval(()=>setTime(prev=>prev+1), 1000);
      return ()=>clearInterval(id);
    }, [time, hint]);

    const handleClick = (value, i) => {
      if (value === number) {
        setIndex(i);
        setTime(0);
        if (soundRef.current) {
          soundRef.current.currentTime = 0;
          answerSound+""=="true" && soundRef.current.play();
        }
        const [xDistance, yDistance] = getCoordinates(i);
        setCoord({x: [0, yDistance], y: [0, xDistance-deltaY(state)]});
        const elem = document.querySelector('.result');
        elem.style.opacity = 0;
        score.current += point;
        if (!subscription) {
          updateStorage(point);
        }
        if ((gameCount.current === count-1) || (gameStore[name] && gameStore[name].find(item=>item.name===gameName)?.all === count-1)) {
          if (subscription) {
            updateState({
              userId: localStorage.getItem('user'),
              profileId: JSON.parse(localStorage.getItem('profile'))?._id,
              game: name,
              score: score.current,
              id: gameId(name, gameName),
              collection: name,
            })
          } else {
            updateGameStorage(name, gameName, point, true);
            handleUpdateProgress();
          }
          setTimeout(()=>handleOpenScore(), animationDurationToShowscoresPopup*1000);
          localStorage.setItem(`${name+'_'+gameName+'_'+'_score'}`, score.current);
        } else {
          if (!subscription) {
            handleUpdateProgress();
            updateGameStorage(name, gameName, point, false);
          }
          
          setTimeout(()=>{
            gameCount.current +=1;
            setNumber(numbers[gameCount.current]);
            setAnswers(generateAnswers(numbers[gameCount.current]));
            setIndex("");
            setCoord({x: [0, 0], y: [0, 0]})
            setUpdate(true)
            images = getImages();
            const elem = document.querySelector('.result');
            elem.style.opacity = 1;
          }, animationDurationToMoveCorrectAnswer*1000);
        }
      } else {
        if(soundWrongRef.current) {
          soundWrongRef.current.currentTime = 0;
          answerSound+""=="true" && soundWrongRef.current.play();
        }
        setIndex(i);
        setUpdate(!update);
      }
    }

    useEffect(()=>{
      if (!index) return;
      const [xDistance, yDistance, answer] = getCoordinates(index);
      if(!state) {
        setCoord({x: [coord.x[1], yDistance], y: [coord.y[1], xDistance-deltaY(state)]})
      } else {
        setCoord({x: [coord.x[1], yDistance], y: [coord.y[1], xDistance+deltaY(state)-answer.offsetWidth]})
      }
      
    }, [state]);

    useEffect(()=>{
       setTime(0);
    }, [answers]);

    const amimNumbers = [-100, -101, -103, -104, -105, -106, -107, -108, -109, -110, -111];

    return(
        <div className={'w-full h-full flex flex-col justify-between z-[10] select-none'}>
           {/* game content */}
           <div className={`${state ? 'md:px-[31.5px] lg:px-[45px] xl:px-[65px]': 'lg:px-[48px] xl:px-[76px] md:px-[44px]'} w-full justify-between flex items-center px-[35px] rounded-[16px] lg:rounded-[20px] border-[#2F9EBA] border-[3px] bg-[#FFF]
              ${state ? "h-[185px] md:h-[203px] lg:h-[257px] xl:h-[316px]" : "h-[185px] md:h-[252px] lg:h-[315px] xl:h-[380px]"}`}>
             {/* balls */}
             <motion.div  
               className={`overflow-hidden ${state ? 'md:h-[118px] md:w-[247px] lg:w-[315px] lg:h-[144px] xl:h-[196px] xl:w-[461px]' : 'md:h-[140px] md:w-[324px] lg:w-[428px] lg:h-[173px] xl:h-[224px] xl:w-[510px]'} flex-col flex  justify-between w-[216px] h-[102px]`}>
               {number>4 && <motion.div
                  style={{gap: getGap(state, Math.floor(number/2))+"px"}} className={`${state? 'md:h-[40px] lg:h-[50px] xl:h-[75px]' : 'md:h-[50px] lg:h-[60px] xl:h-[83px]'} w-full h-[35px] flex justify-center items-center`}>
                    {
                      [...new Array(Math.floor(number/2))].map((item, i)=>{
                        return (
                          <motion.div
                            animate={{y: [-amimNumbers[number], 0]}}
                            transition={{duration:  timeduration.shift(), type: "tween", ease: "linear"}} 
                            key={i}
                            style={{backgroundImage: `url(/backGrounds/counting/${images[gameCount.current]}.svg)`, backgroundSize: "100% 100%", backgroundRepeat: "no-repeat"}}
                            className={`image ${state ? 'w-[36px] h-full md:w-[40px] lg:w-[51px] xl:w-[75px]' : 'h-full w-[36px] md:w-[50px] lg:w-[60px] xl:w-[83px]'}`}
                          />
                        )
                      })
                    }
               </motion.div>}
               <motion.div  
                    style={{gap: getGap(state, Math.floor(number>4 ? number/2 : number))+"px", margin:number<=4 ?  "auto" : "unset"}} 
                    className={`justify-start ${state? 'md:h-[40px] lg:h-[50px] xl:h-[75px]' : 'md:h-[50px] lg:h-[60px] xl:h-[83px]'} w-full h-[35px] flex justify-center items-center`}>
                    {
                      [...new Array(Math.ceil(number>4 ? number/2 : number))].map((item, i)=>{
                        return (
                          <motion.div
                           animate={{y: [-amimNumbers[number], 0]}}
                           transition={{duration: timeduration.shift(), type: "tween", ease: "linear"}}  
                            key={i}
                            style={{backgroundImage: `url(/backGrounds/counting/${images[gameCount.current]}.svg)`, backgroundSize: "100% 100%", backgroundRepeat: "no-repeat"}}
                            className={`image ${state ? 'w-[36px] md:w-[40px] h-full  lg:w-[51px] xl:w-[75px]' : 'w-[36px] h-full md:w-[50px] lg:w-[60px] xl:w-[83px]'}`}
                          />
                        )
                      })
                    }
               </motion.div>
             </motion.div>
             {/* equal */}
             <div className="text-[50px] lg:text-[70px] font-bigfatScript text-[#FDB349]">
               =
             </div>
             {/* symbol */}
             <div
                className={`relative result ${state? 'md:text-[28px] lg:text-[36px] xl:text-[46px]': 'md:text-[32px] lg:text-[46px] xl:text-[60px]'} relative flex justify-center items-center text-white text-[28px] font-bigfatScript`}>
                    <div
                        className="absolute">
                      ?
                    </div>
                        <IconGray  className={`${state? 'w-[39px] h-[39px] lg:w-[50px] lg:h-[50px] xl:w-[70px] xl:h-[70px]': 'w-[39px] h-[39px] md:w-[49px] md:h-[49px] lg:w-[69px] lg:h-[69px] xl:w-[89px] xl:h-[89px]'}`}/>
                    </div>
            </div>
           {/* answers */}
           <div className={`${state ? "h-[67px] md:h-[73px] lg:h-[93px] xl:h-[114px]" : "h-[67px] md:h-[90px] lg:h-[113px] xl:h-[136px]"} ${state ? 'md:px-[56px] md:text-[28px] lg:px-[78px] lg:text-[36px] xl:px-[97px] xl:text-[46px]': 'md:text-[32px] md:px-[76px] lg:px-[90px] lg:text-[46px] xl:px-[110px] xl:text-[60px]'} font-bigfatScript text-[28px] px-[53px] flex w-full justify-between  rounded-[16px] lg:rounded-[20px] border-[#2F9EBA] border-[3px] bg-[#FFF]`}>
              <motion.div
                className={`flex w-full justify-between h-full`}
                animate={{y: [-amimNumbers[number], 0]}}
                transition={{duration: animationDurationToShowQuestion, type: "spring"}}    
              >
                {answers.map((item, i)=>{
                          return(
                          <motion.div
                              key={i}
                              animate={{ x: item === number ? coord.x : i === index ? [-5, 0, 5, 0]: 0 , y: item === number ? coord.y:  [-amimNumbers[number], 0]}}
                              transition={{
                                duration: item === number ? 0.5 : animationDurationToShowQuestion*(i+1)/4,
                                type: item === number ? "tween" : i === index  ? "spring" : "tween",
                                damping: 3,
                                bounce: 0.25,
                                stiffness: 100,
                                restDelta: 0.001
                              }}
                              onClick = {()=>handleClick(item, i, state, function(){
                                
                              })}
                              className={`relative scroll-smooth answer flex justify-center items-center text-white cursor-pointer overflow-hidden`}>
                              <motion.div
                                  className="absolute">
                                      {item}
                              </motion.div>
                              <IconGray color={colors[i]} className={`${state? 'w-[39px] h-[39px] lg:w-[50px] lg:h-[50px] xl:w-[70px] xl:h-[70px]': 'w-[39px] h-[39px] md:w-[49px] md:h-[49px] lg:w-[69px] lg:h-[69px] xl:w-[89px] xl:h-[89px]'} cursor-pointer`}/>
                              {hint && item === number && time>=10 && time%2===0 && <div className="bg-[url('/backGrounds/hint.svg')] bg-[position:50%_50%] bg-contain bg-no-repeat absolute w-[50px] h-[50px] top-[50%] z-[100]"></div>}
                          </motion.div>
                          )
                      })}
              </motion.div>
           </div>
           {answerSound+""=="true" && <Sound ref={soundRef} url={"/backGrounds/right_answer.wav"} loop={false}/>}
           {answerSound+""=="true" && <Sound ref={soundWrongRef} url={"/backGrounds/wrong_answer.wav"} loop={false}/>}
            
        </div>
    )
}