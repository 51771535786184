export const getGapX = (count, state) => {
    if (window.innerWidth <  1024) {
      return (425-count*76)/(count+1);
    }
    if (window.innerWidth>=1024 && window.innerWidth<1440) {
      if (state) {
        return (439-count*76)/(count+1);
      } else {
        return (576-count*95)/(count+1);
      } 
    }
    if (window.innerWidth>=1440 && window.innerWidth<1920) {
      if (state) {
        return (590-count*95)/(count+1);
      } else {
        return (723-count*114)/(count+1);
      } 
    }
    if (window.innerWidth>=1920) {
      if (state) {
        return (768-count*125)/(count+1);
      } else {
        return (909-count*155)/(count+1);
      } 
    }
}

export const getOptions = (level) => {
    const arr = new Set();
    const min = 1;
    const max = level === "beginner" ? 4 : level === "intermediate" ? 6 : 28;
    while(arr.size<3) {
        arr.add(Math.floor(Math.random() * (max - min + 1) + min));
    }

    return Array.from(arr);
}

export const getAnswers = (arr,level) => {
   if (level === "beginner") {
      return [1, 2, 3, 4].sort(() => 0.5 - Math.random());
   }

   if (level === "intermediate") {
      const newArr = [...arr];
      newArr.push([1, 2, 3, 4, 5, 6].filter(number=> number!==arr[0] && number!==arr[1] && number!==arr[2])[Math.floor(Math.random() * 3)]);
      return newArr.sort(() => 0.5 - Math.random());
   }

   if (level === "advanced") {
      const numbers = [...new Array(28)].map((num, i)=>i+1);
      const newArr = [...arr];
      newArr.push(numbers.filter(number=> number!==arr[0] && number!==arr[1] && number!==arr[2])[Math.floor(Math.random() * 25)]);
      return newArr.sort(() => 0.5 - Math.random());
   }
}
