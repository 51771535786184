import {
    IconNavbarButtonBg,
    IconVolume,
    IconMusic,
} from "../../elements/Icon";
import {useTranslation} from 'react-i18next';
import {useState} from 'react';
import {styles} from "./assets/constants";
import { motion } from "framer-motion";
import { useDispatch } from "react-redux";


export const Settings = ({handleOpen, hint, handleSubscription, handleBackgroundMusic, handleSound, sound, backgroundMusic}) => {
    const {t} = useTranslation();

    const [hintOn, setHintOn] = useState(hint);

    return (
        <div 
             onClick={() => handleOpen(hintOn)}
             className="flex justify-center items-center h-screen w-full bg-bgBlack/49 absolute z-[15] text-[36px] text-[#FFF] font-normal font-fredokaOne">
            <div
                className="w-[562px] h-[495px] overflow-hidden lg:w-[726px] lg:h-[698px] bg-[#FFF] rounded-[44px] border-[8px] border-[#4F9686]">
                <div
                    className="h-[88px] flex items-center justify-center pt-[10px] bg-[linear-gradient(180deg,_#4F9686_0%,_#77B0A4_100%)] rounded-t-[34px] text-center">
                        <div className="px-[48px]">{t("settings")}</div>
                        <div
                            className="mt-[25px] w-[40px] h-[30px] bg-[url('/backGrounds/star.svg'),_url('/backGrounds/star.svg')] bg-no-repeat bg-[length:19px_16px,13px_10px] bg-[position:top_left,right_bottom]">
                        </div>
                </div>
                <div className="w-[504px] text-[24px] h-[80%] lg:h-[90%] lg:text-[36px] lg:w-[606px] mx-auto text-[#4F9686] text-left">
                    <div
                        onClick={()=>{
                            handleSubscription("info");
                        }}
                        className="bg-[url('/backGrounds/subscriptionInfo/star.svg')] bg-[length:40px_40px] bg-[position:77%_50%] lg:bg-[length:62px_62px] lg:bg-[position:85%_50%] bg-no-repeat bg-[#FFBD1C] text-[#FFFFFF] h-[50px] text-[25px] lg:text-[36px] flex items-center justify-center text-center rounded-[17px] mt-[23px] lg:h-[89px] w-full cursor-pointer"
                        >
                            {t("Subscription Info")}
                    </div>
                    <div className="lang w-full my-[23px] overflow-y-auto h-[70%]">
                        <div
                            className="mt-[20px] lg:mt-[32px] h-[29px] lg:h-[44px] w-full flex justify-between items-center">
                            <div>{t("background_music")}</div>
                            <button 
                                onClick={(e)=>{
                                    e.stopPropagation();
                                    handleBackgroundMusic();
                                }}
                                className="group relative"
                            >
                            {!backgroundMusic && <img className={`${styles.buttonBg} w-full h-full lg:h-auto hover:scale-110 hover:brightness-110 absolute z-20`} src="/backGrounds/disableSound.svg" />}
                                <div
                                    className=" absolute left-[45%] top-[47%] z-10 w-[15px] lg:w-[22px] -translate-x-1/2 -translate-y-1/2 ">
                                    <IconVolume className="w-full"/>
                                </div>
                                <IconNavbarButtonBg className="aspect-square w-[29px] lg:w-[49px] text-black "/>
                            </button>
                        </div>
                        <div
                        className="mt-[20px] lg:mt-[32px] lg:h-[44px] h-[29px] w-full flex justify-between items-center">
                        <div>{t("sound")}</div>
                        <button
                           className="group relative"
                           onClick={(e)=>{
                                e.stopPropagation();
                                handleSound();

                           }}
                        >
                        {!sound && <img className={`${styles.buttonBg} w-full h-full hover:scale-110 hover:brightness-110 absolute z-20`} src="/backGrounds/disableSound.svg" />}
                            <div
                                className=" absolute left-[45%] top-[47%] z-10 w-[13px] lg:w-[22px] -translate-x-1/2 -translate-y-1/2 ">
                                <IconMusic className="w-full"/>
                            </div>
                            <IconNavbarButtonBg className="aspect-square w-[29px] lg:w-[49px] text-black "/>
                        </button>
                       </div>
                       <div className="mt-[20px] lg:mt-[32px] h-[29px] lg:h-[45px] w-full flex justify-between items-center">
                        <div className="h-[45px] translate-y-[15%]">{t("show_hint")}</div>
                        <div onClick={(e)=> e.stopPropagation()} className={`flex ${hintOn ? 'justify-end bg-[#418777] p-[4px]' : 'p-[2px] justify-start border-[2px] border-[#418777] bg-[#FFFFFF]'} w-[85px] h-[45px] rounded-[26.5px] translate-y-[20%]`}>
                            <motion.div
                               layout 
                               transition={{
                                   type: "spring",
                                   stiffness: 700,
                                   damping: 30
                               }}
                               onClick={(e)=>{
                                   e.stopPropagation();
                                   setHintOn(!hintOn);
                               }}
                               className={`w-[37px] h-[37px] ${hintOn ? 'bg-[#FFD54D] shadow-[-1px_1px_4.800000190734863px_1px_#245348] ' : 'bg-[#418777]'}  rounded-[50%] cursor-pointer`}>
                            </motion.div>
                        </div>
                    </div>
                    <div className="mt-[20px] lg:mt-[32px] h-[29px] lg:h-[44px] w-full">{t("rate_Us")}</div>
                    <div className="mt-[20px] lg:mt-[32px] h-[29px] lg:h-[44px] w-full">{t("share")}</div>
                    <div className="mt-[20px] lg:mt-[32px] h-[29px] lg:h-[44px] w-full">{t("feedback")}</div>
                </div>
                </div>
            </div>
        </div>
    );
}