import { animationDurationToShowQuestion } from "../../../../config";
import { motion } from "framer-motion";
import { getWidth } from "../Multiplication4.data";

export const TopPart = ({state, number1, number2, result}) => {
    return(
        <div
            className={`multiplication flex overflow-hidden justify-between items-center w-full border-[3px] rounded-[16px] border-[#60963A] bg-[#FEC951] lg:rounded-[20px] 
                       ${state ? "md:text-[24px] lg:text-[36px] xl:text-[46px] h-[185px] md:h-[203px] lg:h-[257px] xl:h-[316px]" : "xl:text-[60px] lg:text-[46px] md:text-[32px] h-[185px] md:h-[252px] lg:h-[315px] xl:h-[380px]"}
                       px-[79px] py-[22px] flex-col text-[24px] text-[#FFF] font-bigfatScript ${state ? "md:px-[110px] lg:px-[135px] xl:px-[169px] xl:py-[25px]" : "md:px-[142px] lg:px-[153px] md:py-[38px] lg:py-[46px] xl:px-[188px] xl:py-[25px]"}
            `}
        >
            <div
                style={{width: getWidth(state, String(number1*number2))+"px"}}
                className={`flex justify-between h-[34px] ${state ? "md:h-[40px] lg:h-[50px]" : "md:h-[45px] lg:h-[60px]"}`}
            >
                {
                    [...new Array(String(number1*number2).length-String(number1).length),...String(number1).split("")].map((num)=>{
                        return (
                            <div key={Math.random()} className={`w-[34px] ${state ? "md:w-[40px] lg:w-[50px] xl:w-[70px]" : "md:w-[45px] lg:w-[60px] xl:w-[80px]"} text-center`}>
                                {num}
                            </div>
                        )
                    })
                }
            </div>
            <div
               style={{width: getWidth(state, String(number1*number2), String(number1*number2).length-String(number1).length ? null : 1)+"px"}}
               className={`h-[20px] flex items-center justify-start`}>x</div>
            <div
                style={{width: getWidth(state, String(number1*number2))+"px"}}
                className={`flex justify-between h-[34px] ${state ? "md:h-[40px] lg:h-[50px]" : "md:h-[45px] lg:h-[60px]"}`}
            >
                {
                    [...new Array(String(number1*number2).length-String(number2).length),...String(number2).split("")].map((num)=>{
                        return (
                            <div key={Math.random()} className={`w-[34px] ${state ? "md:w-[40px] lg:w-[50px] xl:w-[70px]" : "md:w-[45px] lg:w-[60px] xl:w-[80px]"} text-center`}>
                                {num}
                            </div>
                        )
                    })
                }
            </div>
            <div style={{width: getWidth(state, String(number1*number2))+"px"}} className={`h-[1px] bg-[#FFF]`} />
            <div
                style={{width: getWidth(state, String(number1*number2))+"px"}}
                className={`mt-[8px] flex justify-between h-[34px] ${state ? "md:h-[40px] lg:h-[50px] xl:h-[70px]" : "md:h-[45px] lg:h-[60px] xl:h-[80px]"}`}
            >
                {
                    String(number1*number2).split("").map((num, i)=>{
                        return (
                            <div key={Math.random()} className={`${"drop"+i} text-[#87B43F] h-full w-[34px] ${state ? "md:w-[40px] lg:w-[50px] xl:w-[70px]" : "md:w-[45px] lg:w-[60px] xl:w-[80px]"} rounded-[8px] text-center bg-[#fff] border-[2px] border-[#FBAB3B]`}>
                                {result[i] !== undefined ? num : ""}
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}