import React, {useState, useRef, memo, useEffect} from "react";
import { generateNumbers, calcGapY, calcGapX, generateAnswers } from "./Classic.styles";
import { animationDurationToShowscoresPopup, animationDurationToMoveCorrectAnswer } from "../../../config";
import { MouseDraw } from "./components/MouseDraw2";
import { useParams } from "react-router-dom";
import { updateStorage } from "../../../hooks/useStorage";
import { updateGameStorage } from "../../../hooks/useGameStorage";
import * as amplitude from '@amplitude/analytics-browser';
import { useUpdateReduxState } from "../../../hooks/useUpdateReduxState";
import { gameId } from "../../../hooks/getGameId";


export default memo(function Classic ({state, count, handleOpenScore, handleUpdateProgress, screenSize, answerSound, point, hint, subscription}) {
    const gameStore = JSON.parse(localStorage.getItem('playedGames'));
    const {name, gameName} = useParams();
    const [numbers, setNumbers] = useState(generateNumbers(9, 1));
    const [images, setImages] = useState(generateNumbers(13, 1));
    const [answers, setAnswers] = useState(generateAnswers(numbers));
    const score = useRef(0);
    const gameCount = useRef(0);

    const updateState = useUpdateReduxState();

    useEffect(()=>{
      amplitude.track(`${name+"_"+gameName.replace("_", " ")+"_start"}`)
    }, []);

    const handleScore=()=>{
      score.current+=point;
      if (!subscription) {
        updateStorage(point);
      }
      if (gameCount.current === count-1 || (gameStore[name] && gameStore[name].find(item=>item.name===gameName)?.all === count-1)) {
          if (subscription) {
            updateState({
              userId: localStorage.getItem('user'),
              profileId: JSON.parse(localStorage.getItem('profile'))?._id,
              game: name,
              score: score.current,
              id: gameId(name, gameName),
              collection: name,
            })
          } else {
            handleUpdateProgress();
            updateGameStorage(name, gameName, point, true);
          }
          setTimeout(()=>{
            setTimeout(()=>{
              handleOpenScore()
            }, animationDurationToMoveCorrectAnswer*1000);
          }, animationDurationToShowscoresPopup*1000);
          localStorage.setItem(`${name+'_'+gameName+'_'+'_score'}`, score.current);
        } else {
          gameCount.current += 1;
          if (!subscription) {
            updateGameStorage(name, gameName, point, false);
            handleUpdateProgress();
          }
          setTimeout(()=>{
              const numbers = generateNumbers(9, 1);
              setNumbers(numbers);
              setImages(generateNumbers(13, 1));
              setAnswers(generateAnswers(numbers))
            }, animationDurationToMoveCorrectAnswer*1000);
        }
    };
    
    return (
        <div className={`flex flex-col relative ${state ? 'md:px-[16px] lg:px-[24px] xl:px-[20px]' : 'md:px-[24px] lg:px-[28px] xl:px-[32px]'} w-full h-full border-[3px] xl:border-[4px] rounded-[16px] lg:rounded-[20px] border-[#2F9EBA] px-[13px] bg-[#FFF] flex`}>
            <MouseDraw
              hintState={hint}
              state={state} 
              thickness={3} 
              numbers = {numbers}
              handleScore={handleScore}
              calcGapY = {calcGapY}
              answers={answers} 
              images = {images}
              answerSound={answerSound}
              calcGapX = {calcGapX}
              anim = {false}
              screenSize = {screenSize}
            />
        </div>
    )
})