import { getGapX } from "../Shape4.data";
import { motion } from "framer-motion";
import { 
    animationDurationToShowQuestion,
} from "../../../../config";
import { useState, useEffect } from "react";
import { DragAndDrop } from "../../../../components/global/DragAndDrop";

let showHint;

export const BottomPart = ({state, answers, level, refresh, selected, rotate, rotateDeg, options, result, hint}) => {
    const [animate, setAnimate] = useState(false);
    const [i, setI] = useState("");
    const [start, setStart] = useState({x:0, y:0});   
    const [end, setEnd] = useState({x:0, y:0});
    const [time, setTime] = useState(0);

    useEffect(()=>{
        if (!animate) return;
        setAnimate(false);
        setI('');
        setTime(0);
    }, [answers]);

    useEffect(()=>{
        showHint = true;
        if(result.indexOf(null)===-1) return;
        const start = document.querySelector('.answer');
        const end = document.querySelector('.drop'+(result.indexOf(null)));
        if (!start || !end) return;
        const startCoord = start.getBoundingClientRect();
        const endCoord = end.getBoundingClientRect();
        setStart({x:startCoord.x, y:startCoord.y});
        setEnd({x:endCoord.x+endCoord.width-endCoord.height, y:endCoord.y});
        setTime(0);
    }, [result]);

    useEffect(()=>{
        if(!hint) {
            setTime(0);
            return;
        }
        if(time===10) return;
        const id = setInterval(()=>setTime(prev=>prev+1), 1000);
        return ()=>clearInterval(id);
    }, [time, hint]);

    return (
        <div className="w-full flex">
            {
                answers.map((item, index)=>{
                    if (i === index) {
                        return (
                            <DragAndDrop
                                key={item} 
                                enableClick = {false}
                                cmp={'shape'} 
                                drop={"drop"}
                                item = {item}
                                refresh={(sound, event) => {
                                    showHint = false;
                                    refresh(event, i, item);
                                }}
                            >                   
                                <motion.div
                                    animate={{y: animate ? [0, 0] : [(index+1)*100, 0], rotate: rotate ? animate ? [rotateDeg, rotateDeg] : [0, rotateDeg] : 0}}
                                    transition={{animationDurationToShowQuestion , type: "tween", ease: "linear"}}
                                    key={item}
                                    style = {{
                                        marginLeft: getGapX(4, state)+'px',
                                        backgroundImage: `url(/backGrounds/shapes/shapes_4/${level}/answers/${item}.svg)`
                                        }}
                                    className={`z-[40] cursor-pointer w-[76px] h-[76px]  bg-[length:100%_100%] ${state ? "md:w-[76px] lg:w-[95px] xl:w-[125px] lg:h-[95px] xl:h-[125px]" : "md:w-[95px] md:h-[95px] lg:w-[114px] xl:w-[155px] lg:h-[114px] xl:h-[155px]"}
                                             ${selected.includes(item) ? "opacity-0" : "opacity-100"}
                                    `}
                                >
                                </motion.div>
                            </DragAndDrop>
                        )
                    } else {
                        return (
                            <motion.div
                            key={item}
                                onMouseEnter={(e)=>{
                                    setI(index);
                                    setAnimate(true);
                                }}
                                onTouchStart={(e)=>{
                                    setI(index);
                                    setAnimate(true);
                                }}
                                style = {{
                                    marginLeft: getGapX(4, state)+'px',
                                }}
                                className={`z-[40] cursor-pointer w-[76px] h-[76px] relative  ${state ? "md:w-[76px] lg:w-[95px] xl:w-[125px] lg:h-[95px] xl:h-[125px]" : "md:w-[95px] md:h-[95px] lg:w-[114px] xl:w-[155px] lg:h-[114px] xl:h-[155px]"}
                                ${selected.includes(item) ? "opacity-0" : "opacity-100"} ${answers[answers.indexOf((options[result.indexOf(null)]))]===item && "answer"}
                               `}
                            >

                                    <motion.div

                                        animate={{y: animate ? [0, 0] : [(index+1)*100, 0], rotate: rotate ? animate ? [rotateDeg, rotateDeg] : [0, rotateDeg] : 0}}
                                        transition={{animationDurationToShowQuestion , type: "tween", ease: "linear"}}
                                        
                                        style = {{
                                            backgroundImage: `url(/backGrounds/shapes/shapes_4/${level}/answers/${item}.svg)`,
                                        }}
                                        className="w-full h-full bg-[length:100%_100%]"
                                    >
                                    
                                    </motion.div>
                                    {hint && showHint && time===10 && answers[answers.indexOf((options[result.indexOf(null)]))]===item && <motion.div
                                        animate={{y: [0, end.y-start.y], x: [0, end.x-start.x]}}
                                        transition={{duration: 2 , type: "tween", ease: "linear", repeat: Infinity}}
                                        className="bg-[url('/backGrounds/hint.svg')] rotate-[180deg] bg-[position:50%_50%] bg-contain bg-no-repeat absolute w-[50px] h-[50px] top-[50%] z-[1000]"></motion.div>}
                            </motion.div>

                        )
                    }
                })
            }
        </div>
    )
}