export const canvasSize = (state) => {
    if (window.innerWidth<1024) {
       return 64;
    }
    if (window.innerWidth>=1024 && window.innerWidth<1440) {
       if (state) {
         return 70;
       } else {
         return 84;
       }
    }
    if (window.innerWidth>=1440 && window.innerWidth<1920) {
       if (state) {
         return 84;
       } else {
         return 102;
       }
    }
    if (window.innerWidth>=1920) {
       if (state) {
         return 106;
       } else {
         return 140;
       }
    }
}

export const getNumber = () => {
    const min = 0;
    const max = 6;
    return Math.floor(Math.random() * (max - min + 1) + min);
}