import { configureStore } from '@reduxjs/toolkit';
import userReducer from './features/userSlice';
import gameReducer from './features/gamesSlice';
import scoresReducer from './features/scoresSlice';

export const store = configureStore({
    reducer: {
        user: userReducer,
        games: gameReducer,
        scores: scoresReducer
    },
})