const getRandomImages = (level) => {
    const size = level==='beginner' ? 8 : level==='intermediate' ? 10 : 15;
    const min = 1;
    const max = 23;
    const images = new Set();
    while (images.size < size) {
        images.add(Math.floor(Math.random() * (max - min + 1) + min));
    }
    return Array.from(images);  
}
  
export const generateArray = (level) => {
    const max =  level==='beginner' ? 15 : level==='intermediate' ? 19 : 29;
    const min = 0;
    const images = getRandomImages(level);
    const arr = new Array(16);
    
    images.forEach((item)=>{
      while(true) {
       const index1 = Math.floor(Math.random() * (max - min + 1) + min);
       const index2 = Math.floor(Math.random() * (max - min + 1) + min);
       if (!arr[index1] && !arr[index2] && index1!==index2) {
         arr[index1] = item;
         arr[index2] = item;
         break;
       } else {
         continue;
       }
      }
    })
    return arr;
}

export const getSizes = (state, level) => {
    if (level === 'beginner') {
        if (state) {
            return 'md:w-[292px] md:h-[292px] lg:w-[370px] lg:h-[370px] xl:w-[454px] xl:h-[454px] w-[266px] h-[266px]';
        } else {
            return 'md:w-[360px] md:h-[360px] lg:w-[452px] lg:h-[452px] xl:w-[543px] xl:h-[543px] w-[266px] h-[266px]';
        }
    } else {
        if (state) {
            return 'md:w-[365px] md:h-[292px] lg:w-[462.5px] lg:h-[370px] xl:w-[567.5px] xl:h-[454px] w-[332.5px] h-[266px]';
        } else {
            return 'md:w-[450px] md:h-[360px] lg:w-[565px] lg:h-[452px] xl:w-[678.75px] xl:h-[543px] w-[332.5px] h-[266px]';
        }
    }

}

export const getDivSize = (state, level) => {
    if (level==='advanced') {
        if (state) {
            return 'md:w-[59px] md:h-[56.4px] lg:w-[75.4px] lg:h-[72px] xl:w-[93px] xl:h-[88.9px] w-[55.4px] h-[51.2px]';
        } else {
            return 'md:w-[73.3px] md:h-[70px] lg:w-[92.5px] lg:h-[88.4px] xl:w-[111px] xl:h-[106.6px] w-[55.4px] h-[51.2px]';
        }
        
    } else {
        if (state) {
            return  'md:w-[70.5px] md:h-[70.5px] lg:w-[90px] lg:h-[90px] xl:w-[111px] xl:h-[111px] w-[64px] h-[64px]';
        } else {
            return' md:w-[87.5px] md:h-[87.5px] lg:w-[110.5px] lg:h-[110.5px] xl:w-[133.25px] xl:h-[133.25px] w-[64px] h-[64px]';
        }
    }

}

