import { IconUserImages } from "../components/index.js";
import { useState } from "react";
import { useNavigate, useParams} from "react-router-dom";
import { useDispatch } from "react-redux";
import { resetPasswordAsyncThunk } from "../redux/features/userSlice";

export const ResetPassword = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {id, token} = useParams()

    const [password, setPassword] = useState('password');
    const [inputPassword, setInputPassword] = useState("");

    const handlePasswordType = (type) => {
        if (type === "password") {
           if (password === "password") {
              setPassword("text");
           } else {
              setPassword("password");
           }
        } else {
            if (confirmPassword === "password") {
                setConfirmPassword("text");
            } else {
                setConfirmPassword("password");
            }
        }
    }

   const handleUpdate = () => {
        if (inputPassword) {
            dispatch(resetPasswordAsyncThunk({id, token, password: inputPassword}))
            .then((response)=>{
                navigate('/registration', {state: {"type": "login"}});
            })
            .catch((error)=>{
                console.log('error', error);
            });
    }
   }
    return(
      <div className="w-full h-full bg-cover bg-[url('/backGrounds/home.svg')] flex items-center justify-center">
           <div className="relative bg-[linear-gradient(0deg,_#FBF5E0_0%,_#FFFEFC_100%)] flex w-[640px] h-[302px] md:w-[652px] md:h-[428px] lg:w-[928px] lg:h-[452px] xl:w-[962px] xl:h-[663px] rounded-[13px] md:rounded-[16px] xl:rounded-[18px] shadow-[1px_1px_24px_7px_rgba(220,_220,_221,_0.31)]">
               <div 
                   className="w-[366px] bg-[position:100%_10%] bg-[length:391px_437px] md:bg-[position:75%_8%] lg:bg-[position:100%_8%] md:bg-[length:410px_600px] md:w-[335px] lg:bg-[length:567px_659px] lg:w-[541.5px] xl:w-[501px] h-full bg-[url('/backGrounds/registration/xs_registration.svg')] md:bg-[url('/backGrounds/registration/sm_registration.svg')] 
                       lg:bg-[url('/backGrounds/registration/lg_registration.svg')] xl:bg-[position:0%_0%] xl:bg-[length:100%_100%] xl:bg-[url('/backGrounds/registration/registration.svg')]"
                />
                <IconUserImages
                    user={"g2"}
                    className={`absolute w-[131px] h-[168px] md:w-[173px] md:h-[231px] left-[94px] md:left-[74px] lg:w-[204px] lg:h-[271px] xl:w-[288px] xl:h-[384px] bottom-0 lg:left-[132px] xl:left-[65px]`}
                />
                <div className="w-[258px] md:w-[301px] lg:w-[355px] xl:w-[448px] h-full py-[10px] md:py-[18px] lg:py-[20px] xl:py-[40px] font-fredokaOne text-[14px] md:text-[20px] xl:text-[32px] text-center">
                    <div className="text-[#7F48C1]">Welcome to MathMinds</div>
                    <div className="w-full h-[72px] md:h-[85px] lg:h-[96px] xl:h-[123px] mt-[5px] lg:mt-[15px] xl:mt-[10px] relative flex">
                       <div className="w-[72px] md:w-[85px] lg:w-[96px] xl:w-[123px] h-full rounded-[50%] bg-[#7F48C1] mx-auto" />
                       <img
                          className="absolute w-[70px] h-[42.5px] md:w-[87.5px] md:h-[53px] lg:w-[104.29px] lg:h-[63px] xl:w-[127px] xl:h-[77px] rotate-[4.15deg] translate-y-[10%] xl:left-[20px]"
                          src="/backGrounds/registration/registration_plane.svg"
                       />
                    </div>
                    
                    <form className="md:mt-[9px] xl:mt-[20px] text-[#736C80]">
                        <p className="w-full text-left text-[12px]">Reset Password</p>
                        <div className="relative flex items-center">
                            <input
                                value={inputPassword}
                                onChange={(e)=>setInputPassword(e.target.value)}
                                type={password}
                                placeholder="New Password"
                                className="px-[5px] mt-[7px] md:mt-[14px] xl:mt-[25px] text-[12px] md:text-[16px] bg-transparent outline-none h-[30px] md:h-[40px] xl:h-[44px] w-full border-b-[1px] border-b-[#D7CEE1]"
                            />
                           <img 
                               onClick={()=>handlePasswordType('password')}
                               src="/backGrounds/registration/password_eye.svg"
                               className="absolute bg-[#FBF5E0] w-[21px] h-[13px] xl:w-[26px] xl:h-[15px] right-[5px] bottom-[8px] md:bottom-[15px] cursor-pointer" 
                            />
                            {password === "password" && <div className="absolute w-[21px] xl:w-[26px] h-[2px] right-[5px] bottom-[15px] md:bottom-[22px] bg-[#736C80] rotate-[-45deg]" />}
                        </div>
                    </form>
                    <div className={`w-full flex justify-between text-[12px] md:text-[16px] xl:text-[20px] mt-[15px] md:mt-[28px] xl:mt-[39px]`}>
                         <button onClick={handleUpdate} className={`w-[94px] h-[26px] md:w-[89px] md:h-[32px] lg:w-[126px] lg:h-[39px] xl:w-[153px] xl:h-[45px] text-[#fff] bg-[length:100%_100%] bg-[url('/backGrounds/registration/registration_btn.svg')] flex items-center justify-center`}>
                            Update
                         </button>
                    </div>
                </div>
           </div>
      </div>
    )
}