import {useParams, NavLink, useLocation} from "react-router-dom";
import {useTranslation} from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {useEffect, useRef, useState, memo} from 'react';
import { getDate } from "../../../hooks/useCurrentDate";
import {
    IconNavbarButtonBg,
    IconProgress,
    IconUser,
    IconUserImages,
    IconUserChristmasImages
} from "../../elements/Icon";
import {styles, cmpStyles, cmps, elems, langs, colors} from "./assets/constants";
import useOutsideAlerter from "../../../hooks/useOutsideAlerter.jsx";
import { checkChristmas } from "../../../hooks/useCheckChristmas";
import Sound from "../Sound";
import { isProd } from "../../../config";
import { LeftBar } from "../LeftBar";
import { resetUser, logOutUserThunk } from "../../../redux/features/userSlice";

const getLang=(name)=>{
    const index = name.indexOf('-');
    return index === -1 ? name: name.slice(0, index);
};

export default memo(function Navbar ({
        handleOpen,
        handleSound,
        screenSize=window.innerWidth, 
        subscription, 
        handleResult, 
        cmp,
        handleOpenAvatar,
        handleBackgroundMusic, 
        point,
        sound,
        refresh,
        backgroundMusic
    })  {
    localStorage.getItem('lang') && localStorage.removeItem('lang');
    const dispatch = useDispatch();
    const {name, gameName} = useParams();
    const {pathname} = useLocation();
    const {t, i18n} = useTranslation();
    const [lang, setLang] = useState(getLang(localStorage.getItem('i18nextLng')) || 'en');
    const [open, setOpen] = useState(false);
    const [index, setIndex] = useState(screenSize.width >= 1024 ? 1 : 0);
    const user = useSelector((state) => state.user);
    const totalScore = useSelector((state) => state.games.totalScore);

    const [userSettings, setUserSettings] = useState(false);

    const [showLeftBar, setShowLeftBar] = useState(false);

    const handleLanguage = (name, i) => {
        i18n.changeLanguage(name);
        setLang(getLang(name));
        const item = document.querySelectorAll('.langItem');
        item.forEach((item, pos)=>{
           if (i === pos) {
             item.classList.remove('after:scale-x-0');
             item.classList.add('after:scale-x-100');
           } else {
             item.classList.remove('after:scale-x-100');
             item.classList.add('after:scale-x-0');
           }
        });
        setTimeout(()=>setOpen(false), 1000);
    }
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, () => {
        setShowLeftBar(false);
    });

    useEffect(() => {
        setIndex(screenSize.width >= 1024 ? 1 : 0);
    });

    const storage = localStorage.getItem('scoreStorage');
    let count = 0;
    if (storage) {
        JSON.parse(storage).forEach((item)=> {
            if (item.date === getDate()) {
                count = item.score
            }
        })
    }

    const handleSmallSize = (val) => {
        if (val) {
            setShowLeftBar(false);
            localStorage.setItem("leftBarIsOpen", JSON.stringify(false));
        } else {
            setShowLeftBar(!showLeftBar);
            localStorage.setItem("leftBarIsOpen", JSON.stringify(!showLeftBar));
        }

    }

    return (
        <div
            id="top" 
            onClick={()=>false}
            className={`h-[37px] md:h-[56px] sm:no-scrollbar lg:h-[54px] items-center xl:h-[80px] flex w-full bg-[#FCF9EE] px-4 shadow-[0px_4px_18px_0px_rgba(188,_188,_188,_0.22)]`}>
            <div className={`${(showLeftBar || localStorage.getItem("leftBarIsOpen") && JSON.parse(localStorage.getItem("leftBarIsOpen"))) && window.innerWidth<1024  ? 'space-x-0' : 'space-x-4'} flex w-[28%] h-[27px] md:h-[35px] lg:h-[40px] xl:h-[52px]`}>
                {elems.slice(index, 4).map((item, i) => {
                    const Component = cmps[item];
                    return (
                        <NavLink key={item} to={item === 'IconHome' ?  "/": pathname==='/about' ? '/about' : `/games/${name}/${gameName || ""}`} 
                          className={`${window.innerWidth<1024 && item === 'IconHome' ? 'hidden' : 'block'} h-[27px] md:h-[36px]`}>
                            <button
                                onClick={() => item === 'IconSettings' ? handleOpen() : item === 'IconLanguage' ? setOpen(!open) : item === 'MenuIconForSmallSize' ? handleSmallSize() : ''}
                                className={`${styles.button}`}>
                                <div className={cmpStyles[item]}>
                                    <Component lang={lang.startsWith("ch") ? "ch" : lang} className={`${styles.animation}`}/>
                                </div>
                                <IconNavbarButtonBg
                                    className={`${styles.buttonBg} ${item === "MenuIconForSmallSize" ? 'md:hidden' : ''}`}/>
                            </button>
                        </NavLink>

                    )
                })}
                {open && <div
                    className="lang z-[99] w-[151px] lg:pl-[28px] lg:pt-[17px] lg:mt-[60px] h-[210px] lg:w-[171px] lg:h-[253px] pl-[17px] pt-[9px] text-[18px] text-[#4F9686] mt-[40px] md:mt-[48px]  text-shadow-[0px_2px_5px_rgba(113,_113,_113,_0.25)] font-fredokaOne lg:text-[24px] xl:pl-[28px] xl:pt-[17px] absolute overflow-y-scroll xl:mt-[76px] xl:w-[189px] xl:h-[281px] bg-[#FFFBED] rounded-[8px] shadow-[6px_14px_28px_0px_rgba(125,_125,_125,_0.25)]">
                    {Object.entries(langs).map(([key, value], i) => <div key={value}
                                                                      className={`${value === lang ? 'after:scale-x-100' : 'after:scale-x-0'} langItem w-[80px] lg:w-[100px] pb-[5px] cursor-pointer`}
                                                                      onClick={() => handleLanguage(value, i)}>{key}</div>)}
                </div>}
                {screenSize.width<1024 &&  <LeftBar handleOpen={()=>handleSmallSize(true)} cmp={"navbar"} openSmallSize={showLeftBar}/>}
                <button onClick={handleResult} className={`relative ${styles.button}`}>
                    <div
                        className="z-10 w-[69px] h-[27px] md:w-[90px] md:h-[36px] lg:w-[104px] lg:h-[40px] xl:w-[132px] xl:h-[52px]">
                        <IconProgress show={gameName} score={point} count={count} className="w-full"/>
                    </div>
                    <div
                        className="flex xl:p-[2px] justify-between items-center absolute z-10  w-[69px] h-[27px] md:w-[90px] md:h-[36px] lg:w-[104px] lg:h-[40px] xl:w-[132px] xl:h-[52px] translate-y-[-100%]"
                    >
                        <div className={`w-[27px] h-[28px] md:w-[37px] md:h-[35px] lg:w-[42px] xl:w-[49px] xl:h-[49px] xl:text-[28px] lg:h-[40px] flex justify-center items-center rounded-[50%] font-fredokaOne ${count>=100? "lg:text-[20px] text-[16px] md:text-[18px]" : "lg:text-[25px] text-[18px] md:text-[20px]"} text-center text-[#FFE49B]`}>{subscription ? totalScore : count}</div>
                        {gameName && <div className={`flex justify-center items-center w-[22px] h-[20px] md:w-[30px] md:h-[30px] lg:w-[33px] lg:h-[30px] xl:w-[38px] xl:h-[35px] lg:py-[2px] rounded-[50%] text-[#FFFFFF] font-fredokaOne text-[13px] md:text-[18px] lg:text-[20px] xl:text-[23px]`}>{point}</div>}
                    </div>
                </button>
            </div>
            <span
                style={{color: cmp ? colors[cmp] : colors.shapes}}
                className={`${cmp ? 'font-chango' : 'font-fredokaOne'} text-center text-[14px] md:text-[20px] lg:text-[24px] xl:text-[32px] w-[44%]`}>
                {/* {name.toUpperCase()} */}
                {cmp ? 'About us' : t(`${name}`)} { cmp  ? '' : !isProd ? window.innerWidth+'x'+window.innerHeight : ""}
            </span>
            <div className="flex space-x-4 justify-end items-center w-[28%]">
                {elems.slice(4).map((item) => {
                    const Component = cmps[item];
                    return (
                        <NavLink key={item} className={'h-[27px] md:h-[36px]'} to={item === 'IconAbout' || pathname === '/about' ? '/about' : `/games/${name}/${gameName || ""}`}>
                            <button className={`${styles.button}`}
                               onClick={()=> item === 'IconVolume' ? handleBackgroundMusic() : item === 'IconMusic' ? handleSound() : ''}
                            >
                                {item === 'IconMusic'  && !sound && <img className={`${styles.buttonBg} hover:scale-110 hover:brightness-110 absolute z-20`} src="/backGrounds/disableSound.svg" />}
                                {item === 'IconVolume' && !backgroundMusic && <img className={`${styles.buttonBg} hover:scale-110 hover:brightness-110 absolute z-20`} src="/backGrounds/disableSound.svg" />}
                                {item === 'IconVolume' && backgroundMusic && <Sound loop={true} url={"/backGrounds/background_sound.mp3"} autoPlay={true}/>}
                                <div className={cmpStyles[item]}>
                                    <Component className={`${styles.animation}`}/>
                                </div>
                                <IconNavbarButtonBg className={`${styles.buttonBg}`}/>
                            </button>
                        </NavLink>
                    )
                })}
                    <div className="relative h-[27px] md:h-[36px]">
                       {!user.selectedProfile && <div>
                            <button className={`${styles.button}`}
                                onClick={(e)=> {
                                    e.preventDefault();
                                    setUserSettings(!userSettings);  
                                }}
                            >       
                                <div className={cmpStyles.IconUser}>
                                    <IconUser className={`${styles.animation}`}/> 
                                </div>
                                <IconNavbarButtonBg className={`${styles.buttonBg}`}/>
                            </button>
                        </div>}
                        { user.selectedProfile && <div
                                onClick={(e)=> {
                                    e.preventDefault();
                                    setUserSettings(!userSettings);  
                                }}
                                className="cursor-pointer ml-[5px] group relative text-center overflow-hidden w-[33px] h-[33px] md:w-[50px] md:h-[50px] lg:w-[52px] lg:h-[52x] xl:w-[67px] xl:h-[70px] rounded-[50%] border-[1px] md:border-[3px] border-[#418777] bg-[#F6F6F6]"
                            >
                                {checkChristmas() ? <IconUserChristmasImages
                                    user={user.selectedProfile?.avatar}
                                    className={`${user.selectedProfile?.avatar==="b2" ? "left-[-10px] xl:left-[-21px]" : ""} absolute w-[40px] md:w-[55px] xl:w-[80px] h-[100px] xl:h-[150px] top-[-90%] md:top-[-50%] xl:top-[-60%] left-[-3px] xl:left-[-5px]`}
                                /> : <IconUserImages
                                user={user.selectedProfile?.avatar}
                                className={`${user.selectedProfile?.avatar==="b2" ? "left-[-10px] xl:left-[-21px]" : ""} absolute w-[40px] md:w-[55px] xl:w-[80px] h-[100px] xl:h-[150px] top-[-90%] md:top-[-50%] xl:top-[-60%] left-[-3px] xl:left-[-5px]`}
                            />}
                        </div>}
                        {userSettings && <div
                           className="font-fredokaOne z-[100] text-[#4F9686] flex flex-col right-[0%] absolute w-[100px] h-[140px] md:w-[120px] md:h-[160px] xl:h-[170px] xl:w-[130px] bg-[#FFFBED] rounded-[8px] shadow-[6px_14px_28px_0px_rgba(125,_125,_125,_0.25)]"
                        >
                             <p className="w-full text-center text-[12px] md:text-[20px] mt-[10px]">{user.user.userName || 'UserName'}</p>
                             <p className={`w-full truncate text-[10px] mb-[5px] text-center md:text-[12px] md:mb-[10px]`}>{user.user.userEmail || 'Email'}</p>
                             <NavLink className="my-[2.5px] mx-[5px]" to='/registration'>Login</NavLink>
                             <div
                                className="cursor-pointer my-[2.5px] mx-[5px]"
                                onClick={(e)=>{
                                    e.stopPropagation();
                                    if (user.login) {
                                        dispatch(logOutUserThunk());
                                        dispatch(resetUser());
                                        localStorage.removeItem("user");
                                        localStorage.removeItem('avatar');
                                        localStorage.removeItem('profile');
                                        handleOpenAvatar();
                                        setUserSettings(!userSettings);
                                    }
        
                                }}
                             >
                                LogOut
                             </div>
                             <div
                                className="cursor-pointer my-[2.5px] mx-[5px]"
                                onClick={(e)=>{
                                    e.stopPropagation();
                                    handleOpenAvatar();
                                }} 
                             >
                                Profiles
                             </div>
                        </div>}
                    </div>    
            </div>
        </div>
    );
})
