const getGapX = (state, level) => {
    if (window.innerWidth <  1024) {
      return (425-4*39)/5;
    }
    if (window.innerWidth>=1024 && window.innerWidth<1440) {
      if (state) {
        return (439-4*39)/5;
      } else {
        return (576-4*49)/5;
      } 
    }
    if (window.innerWidth>=1440 && window.innerWidth<1920) {
      if (state) {
        return (590-4*50)/5;
      } else {
        return (723-4*69)/5;
      } 
    }
    if (window.innerWidth>=1920) {
      if (state) {
        return (768-4*70)/5;
      } else {
        return (909-4*89)/5;
      } 
    }
  }
  const getGapY = (state, level) => {
      let count;
      if (level === 'beginner') {
          count = 1;
      }
      if (level === 'intermediate') {
          count = 2;
      }
      if (level === 'advanced') {
          count = 3;
      }
      if (window.innerWidth <  1024) {
        return (185-count*39)/(count+1);
      }
      if (window.innerWidth>=1024 && window.innerWidth<1440) {
          if (state) {
            return (203-count*39)/(count+1);
          } else {
            return (252-count*49)/(count+1);
          } 
      }
      if (window.innerWidth>=1440 && window.innerWidth<1920) {
          if (state) {
            return (257-count*50)/(count+1);
          } else {
            return (315-count*69)/(count+1);
          } 
      }
      if (window.innerWidth>=1920) {
          if (state) {
            return (316-count*70)/(count+1);
          } else {
            return (380-count*89)/(count+1);
          } 
      }
  }
  
  const getGapAnswersX = (state, level) => {
      if (window.innerWidth <  1024) {
        return (425-7*39)/8;
      }
      if (window.innerWidth>=1024 && window.innerWidth<1440) {
        if (state) {
          return (439-7*39)/8;
        } else {
          return (576-7*49)/8;
        } 
      }
      if (window.innerWidth>=1440 && window.innerWidth<1920) {
        if (state) {
          return (590-7*50)/8;
        } else {
          return (723-7*69)/8;
        } 
      }
      if (window.innerWidth>=1920) {
        if (state) {
          return (768-7*70)/8;
        } else {
          return (909-7*89)/8;
        } 
      }
  }
  
  const getGapAnswersY = (state, level) => {
      if (window.innerWidth <  1024) {
        return (67-39)/2;
      }
      if (window.innerWidth>=1024 && window.innerWidth<1440) {
          if (state) {
            return (73-39)/2;
          } else {
            return (90-49)/2;
          } 
      }
      if (window.innerWidth>=1440 && window.innerWidth<1920) {
          if (state) {
            return (93-50)/2;
          } else {
            return (113-69)/2;
          } 
      }
      if (window.innerWidth>=1920) {
          if (state) {
            return (114-70)/2;
          } else {
            return (136-89)/2;
          } 
      }
  }

  const generateAnswers = (number1, number2, number3, level ) => {
    let uniqueAnswers = new Set();
    uniqueAnswers.add(number1);
    if (number2) uniqueAnswers.add(number2);
    if (number3) uniqueAnswers.add(number3);
    while (uniqueAnswers.size < 7) {
      uniqueAnswers.add(Math.floor(Math.random() * 50) + 1);
    }

    const arr = Array.from(uniqueAnswers).sort(()=>Math.random()-0.5);
   
    return arr;
}

const generateNumbers = (level) => {
    const count = level === 'beginner' ? 4 : level === 'intermediate' ? 8 : level === 'advanced' ? 12 : 0;
    const num = Math.floor(Math.random() * 50) + 1;
    const newNumbers = [num];
    for (let i=1; i<count; i++) {
      newNumbers[i] = newNumbers[i-1]+1;
    }
    return newNumbers;
}

const generateData = (level) => {
    const numbers = generateNumbers(level);
    const displayNumbers = [...numbers];
    let answers = [];
    if (level === 'beginner') {
        displayNumbers[1] = null;
        answers = generateAnswers(numbers[1], undefined, undefined, level);
    }
    if (level === 'intermediate') {
        displayNumbers[2] = null;
        displayNumbers[5] = null;
        answers = generateAnswers(numbers[2], numbers[5], undefined, level);
    }
    if (level === 'advanced') {
        displayNumbers[1] = null;
        displayNumbers[6] = null;
        displayNumbers[8] = null;
        answers = generateAnswers(numbers[6], numbers[8], numbers[1], level );
    }
    return {
        answers,
        displayNumbers,
        numbers,
      }
}

  export {getGapX, getGapY, getGapAnswersY, getGapAnswersX, generateData };