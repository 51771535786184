import {generateArray, getSizes, getDivSize, mixArray } from "./StepByStep.data";
import React, { useState, useRef, useEffect} from "react";
import { 
    animationDurationToShowscoresPopup,
    animationDurationToShowQWrongAnswer
} from "../../../config";
import Sound from '../../../components/global/Sound';
import { useParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import { updateStorage } from "../../../hooks/useStorage";
import { updateGameStorage } from "../../../hooks/useGameStorage";
import * as amplitude from '@amplitude/analytics-browser';
import { useUpdateReduxState } from "../../../hooks/useUpdateReduxState";
import { gameId } from "../../../hooks/getGameId";


export const Schulte2 = ({state, update, handleOpenScore, handleUpdateProgress, answerSound, level, handleTimer, point, hint:hintOn, subscription}) => {
    const size = 25;
    const {name, gameName} = useParams();
    const data = useRef(generateArray(level, gameName.includes("asc") ? 1 : -1));
    const [result, setResult] = useState(mixArray(data.current, level));
    const [clickedIndex, setClickedIndex] = useState();
    const [rightAnswers, setRightAnswers] = useState([]);
    const soundRef = useRef();
    const score = useRef(0);

    const updateState = useUpdateReduxState();

    useEffect(()=>{
        !update && setRightAnswers([data.current[0]]);
    }, [update]);

    useEffect(()=>{
        if (typeof(level) === "string") return;
        amplitude.track(`${name+"_"+gameName.replaceAll("_", " ")+"_"+level+"_start"}`);
    }, []);

    const handleClick = (item, index) => {
        if (item===(rightAnswers[rightAnswers.length-1]+level*(gameName.includes("asc") ? 1 : -1))) {
            soundRef.current && soundRef.current.play();
            setRightAnswers([...rightAnswers, item]);
            setClickedIndex('');
        } else {
            setClickedIndex(index);
            setTimeout(()=>{
                setClickedIndex('');
            }, animationDurationToShowQWrongAnswer*1000);
        }
        setHint(0);
    }

    useEffect(()=>{
        if (rightAnswers.length === size) {
         score.current += point;
         if (!subscription) {
            updateStorage(point);
         }
         if (subscription) {
            updateState({
                userId: localStorage.getItem('user'),
                profileId: JSON.parse(localStorage.getItem('profile'))?._id,
                game: name,
                score: score.current,
                id: gameId(name, gameName),
                collection: name,
              })
         } else {
            updateGameStorage(name, gameName, point, true, level);
            handleUpdateProgress();
         }
         handleTimer();
         setTimeout(()=>handleOpenScore(),animationDurationToShowscoresPopup*1000);
         localStorage.setItem(`${name+'_'+gameName+'_'+level+'_score'}`, score.current);
        }
    }, [rightAnswers]);

    const [hint, setHint] = useState(0);
 
    useEffect(()=>{ 
        if(!hintOn) {
            setHint(0);
            return;
        }
        const id = setInterval(()=>setHint(prev=>prev+1), 1000);
        return ()=>clearInterval(id)
    }, [hint, hintOn]);
    
    return(
        <div className="flex w-full h-full bg-transparent  items-center justify-center z-[30] font-dangrek">
            <div className={`border-[#7643B7] border-collapse xl:border-7px] border-[5px] rounded-[20px] ${getSizes(state)} grid grid-cols-5`}>
                {
                    result && result.map((item, index)=>{
                        return (
                            <motion.div
                            animate={{x: clickedIndex === index ? [0, -Math.random()*5, Math.random()*5, 0] : [0, 0, 0, 0]}}
                            transition={{duration: animationDurationToShowQWrongAnswer, type: "tween", ease: "linear"}} 
                            onClick={()=>handleClick(item, index)}
                            key={index}
                            style={{
                                border: hintOn && data.current && hint>=10 && hint%2 === 0 && data.current[data.current.indexOf(rightAnswers[rightAnswers.length-1])+1] === item? "2px solid #7643B7": "unset",
                                backgroundImage: !rightAnswers.includes(item) ? `url(/backGrounds/schulte2_bg.svg)` : `url(/backGrounds/schulte2_bg_default.svg)`,
                                borderTopLeftRadius: !index && '15px',
                                borderTopRightRadius: index === 4 && '15px',
                                borderBottomLeftRadius: index===20 && '15px',
                                borderBottomRightRadius: index === 24 && '15px',
                                }}
                                className={`text-[20px] ${state ? 'md:text-[20px] lg:text-[24px] xl:text-[36px]' : 'md:text-[24px] lg:text-[32px] xl:text-[45px]'} ${rightAnswers.includes(item) ? 'text-[#E0757D]' : 'text-[#FFF]'} relative bg-[position:50%_50%] bg-[length:100%_100%] bg-no-repeat cursor-pointer flex justify-center items-center  ${getDivSize(state, level)} border-[1px]`}>
                                        {item}
                            </motion.div>
                        )
                    })
                }
            </div>
            {answerSound+""=="true" && <Sound ref={soundRef} url={"/backGrounds/right_answer.wav"} loop={false}/>}
    </div>
    )
}