import React, { useState, useRef, useEffect } from 'react';
import { 
    animationDurationToShowscoresPopup,
    animationDurationToMoveCorrectAnswer
} from "../../../config";
import { useParams } from 'react-router-dom';
import { updateStorage } from "../../../hooks/useStorage";
import { updateGameStorage } from "../../../hooks/useGameStorage";
import {TopPart} from './components/TopPart';
import {BottomPart} from './components/BottomPart';
import * as amplitude from '@amplitude/analytics-browser';
import { getNumber, getAnswer, getAnswers } from "./Grid.data";
import { useUpdateReduxState } from "../../../hooks/useUpdateReduxState";
import { gameId } from "../../../hooks/getGameId";


export const Grid = ({state, handleOpenScore, count, handleUpdateProgress, answerSound, point, hint, subscription}) => {
    const gameStore = JSON.parse(localStorage.getItem('playedGames'));
    const {name, gameName} = useParams();
    const firstNumber = useRef(getNumber());
    const secondNumber = useRef(getNumber());
    const answer = useRef(getAnswer(firstNumber.current, secondNumber.current));
    const answers = useRef(getAnswers());
   
    const updateState = useUpdateReduxState();

    const [result, setResult] = useState('?');
    const score = useRef(0);
    const gameCount = useRef(0);
    const [refresh, setRefresh] = useState(false);

    useEffect(()=>{
        amplitude.track(`${name+"_"+gameName+"_"+"_start"}`)
    }, []);

    const checkAnswer = (event, type, val) => {
        if (event==='mouseup') return;
        score.current += point;
        if (!subscription) {
          updateStorage(point);
        }
        !type && setResult(val);
        if (gameCount.current === count-1 || (gameStore[name] && gameStore[name].find(item=>item.name===gameName)?.all === count-1)) {
            if (subscription) {
              updateState({
                userId: localStorage.getItem('user'),
                profileId: JSON.parse(localStorage.getItem('profile'))?._id,
                game: name,
                score: score.current,
                id: gameId(name, gameName),
                collection: name,
              })
            } else {
              handleUpdateProgress();
              updateGameStorage(name, gameName, point, true);
            }
            setTimeout(()=>handleOpenScore(), animationDurationToShowscoresPopup*1000);
            localStorage.setItem(`${name+'_'+gameName+'_'+'_score'}`, score.current);
          } else {
            if (!subscription) {
              handleUpdateProgress();
              updateGameStorage(name, gameName, point, false);
            }
            setTimeout(()=>{ 
              gameCount.current +=1;
              firstNumber.current = getNumber();
              secondNumber.current = getNumber();
              answer.current = getAnswer(firstNumber.current, secondNumber.current);
              answers.current = getAnswers();
              setResult('?');
              setRefresh(!refresh);
            }, animationDurationToMoveCorrectAnswer*1000);
          }
    }

    return (
        <div className="w-full h-full flex justify-between flex-col relative z-30">
           <TopPart
               firstNumber = {firstNumber.current}
               secondNumber = {secondNumber.current}
               state = {state}
               result = {result}
            />
           <BottomPart 
              state={state}
              hint={hint}
              answer={answer.current}
              answers={answers.current}
              checkAnswer={checkAnswer}
              answerSound={answerSound}
            />
        </div>
    )
}