import { IconUserImages } from "../components/index.js";
import { useState, useEffect } from "react";
import { validateEmail } from "../hooks/emailValidation";
import { useNavigate, useLocation } from "react-router-dom";
import { Recaptcha } from "../components/global/Recaptcha.jsx";
import { useDispatch } from "react-redux";
import { getDate } from "../hooks/useCurrentDate.jsx";
import { registrationAsyncThunk, forgetPasswordAsyncThunk, resetUser } from "../redux/features/userSlice";

export const Registration = () => {
   const dispatch = useDispatch();
   const navigate = useNavigate();
   const location = useLocation();

   const [wrongLoginCount, setWrongLoginCount] = useState(0);
   const [wrongSignUpCount, setWrongSignUpCount] = useState(0);

   const [state, setState] = useState(location.state ? "login": "signup");
   const [password, setPassword] = useState('password');
   const [confirmPassword, setConfirmPassword] = useState('password');

   const [inputName, setInputName] = useState("");
   const [inputEmail, setInputEmail] = useState("");
   const [inputPassword, setInputPassword] = useState("");
   const [inputConfirmPassword, setInputConfirmPassword] = useState("");

   const resetState = () => {
        setInputName("");
        setInputEmail("");
        setInputPassword("");
        setInputConfirmPassword("");
        setCheckValidate(false);  
   }

   const [checkValidate, setCheckValidate] = useState(false);

   const [forgetPassword, setForgetPassword] = useState(false);
     
   //const cookie = useCreateCookie();
   const [token, setToken] = useState('');

   const handleToken = (token) => {
    setToken(token);
   }
   
    const handleStateChange = (type) => {
        if(state === "signup" && type === "signup") {
            if (!inputEmail || !inputPassword || !inputConfirmPassword) {
                setCheckValidate(true);
                setWrongSignUpCount(prev=>prev+1);
                return;
            } else {
                if (inputPassword !== inputConfirmPassword) {
                    setCheckValidate(true);
                    setWrongSignUpCount(prev=>prev+1);
                    return;
                }
                if (!validateEmail(inputEmail)) {
                    setWrongSignUpCount(prev=>prev+1);
                    setCheckValidate(true);
                    return;
                }
            }
            setCheckValidate(false);
            
            dispatch(registrationAsyncThunk({
                userName: inputName,
                userEmail: inputEmail.toLowerCase(),
                userPassword: inputPassword,
                state,
            })).then((res)=>{
                localStorage.setItem('playedGames', JSON.stringify({date: getDate()}));
                localStorage.setItem('user', res.payload.user._id);
                localStorage.removeItem('profile');
                localStorage.removeItem('avatar');
                resetState();
                navigate('/games/counting');
            }).catch(error=>{
                console.log('error', error);
                setCheckValidate(true);
                setWrongSignUpCount(prev=>prev+1);
            });
        }

        if(state === "login" && type === "login") {
            if (!inputEmail || !inputPassword) {
                setCheckValidate(true);
                setWrongLoginCount(prev => prev+1);
                return;
            }
            if (!validateEmail(inputEmail)) {
                setCheckValidate(true);
                setWrongLoginCount(prev => prev+1);
                return;
            }

            dispatch(resetUser());
            dispatch(registrationAsyncThunk({
                userEmail: inputEmail.toLowerCase(),
                userPassword: inputPassword,
                state,
            })).then((res)=>{
                localStorage.setItem('playedGames', JSON.stringify({date: getDate()}));
                localStorage.setItem('user', res.payload.user._id);
                localStorage.removeItem('profile');
                localStorage.removeItem('avatar');
                resetState();
                navigate('/games/counting');
            }).catch(error=>{
                console.log('error', error);
                setCheckValidate(true);
                setWrongSignUpCount(prev=>prev+1);
            });
        }
        
        setState(type);
        if ((state === "signup" && type === "login") || (state === "login" && type === "signup")) {
            resetState();
        }
    }

    const handlePasswordType = (type) => {
        if (type === "password") {
           if (password === "password") {
              setPassword("text");
           } else {
              setPassword("password");
           }
        } else {
            if (confirmPassword === "password") {
                setConfirmPassword("text");
            } else {
                setConfirmPassword("password");
            }
        }
    }

    const handleInput = (e, type) => {
        if (type === "name") {
            setInputName(e.target.value);
        }
        if (type === "password") {
            setInputPassword(e.target.value);
        }
        if (type === "confirmPassword") {
            setInputConfirmPassword(e.target.value);
        }
        if (type === "email") {
            setInputEmail(e.target.value);
        }
    }

    useEffect(() => {
        if (token.length) {
            setWrongLoginCount(0);
            setWrongSignUpCount(0);
            setCheckValidate(false);
        }
    }, [token]);
    
    return(
      <div className="w-full h-full bg-cover bg-[url('/backGrounds/home.svg')] flex items-center justify-center">
           <div className="relative bg-[linear-gradient(0deg,_#FBF5E0_0%,_#FFFEFC_100%)] flex w-[640px] h-[302px] md:w-[652px] md:h-[428px] lg:w-[928px] lg:h-[452px] xl:w-[962px] xl:h-[663px] rounded-[13px] md:rounded-[16px] xl:rounded-[18px] shadow-[1px_1px_24px_7px_rgba(220,_220,_221,_0.31)]">
               <div 
                   className="w-[366px] bg-[position:100%_10%] bg-[length:391px_437px] md:bg-[position:75%_8%] lg:bg-[position:100%_8%] md:bg-[length:410px_600px] md:w-[335px] lg:bg-[length:567px_659px] lg:w-[541.5px] xl:w-[501px] h-full bg-[url('/backGrounds/registration/xs_registration.svg')] md:bg-[url('/backGrounds/registration/sm_registration.svg')] 
                       lg:bg-[url('/backGrounds/registration/lg_registration.svg')] xl:bg-[position:0%_0%] xl:bg-[length:100%_100%] xl:bg-[url('/backGrounds/registration/registration.svg')]"
                />
                <IconUserImages
                    user={"g2"}
                    className={`absolute w-[131px] h-[168px] md:w-[173px] md:h-[231px] left-[94px] md:left-[74px] lg:w-[204px] lg:h-[271px] xl:w-[288px] xl:h-[384px] bottom-0 lg:left-[132px] xl:left-[65px]`}
                />
                <div className="w-[258px] md:w-[301px] lg:w-[355px] xl:w-[448px] h-full py-[10px] md:py-[18px] lg:py-[20px] xl:py-[40px] font-fredokaOne text-[14px] md:text-[20px] xl:text-[32px] text-center">
                    <div className="text-[#7F48C1]">Welcome to MathMinds</div>
                    <div className="w-full h-[72px] md:h-[85px] lg:h-[96px] xl:h-[123px] mt-[5px] lg:mt-[15px] xl:mt-[10px] relative flex">
                       <div className="w-[72px] md:w-[85px] lg:w-[96px] xl:w-[123px] h-full rounded-[50%] bg-[#7F48C1] mx-auto" />
                       <img
                          className="absolute w-[70px] h-[42.5px] md:w-[87.5px] md:h-[53px] lg:w-[104.29px] lg:h-[63px] xl:w-[127px] xl:h-[77px] rotate-[4.15deg] translate-y-[10%] xl:left-[20px]"
                          src="/backGrounds/registration/registration_plane.svg"
                       />
                    </div>
                    
                    {!forgetPassword && <form className="md:mt-[9px] xl:mt-[20px] text-[#736C80]">
                        {state === "signup" && <input
                           value={inputName}
                           onChange={(e)=>handleInput(e, "name")}
                           type="text"
                           placeholder="User Name"
                           className="px-[5px] text-[12px] md:text-[16px] bg-transparent outline-none h-[30px] md:h-[40px] xl:h-[44px] w-full border-b-[1px] border-b-[#D7CEE1]"
                        />}
                        <input
                           value={inputEmail}
                           onChange={(e)=>handleInput(e, "email")}
                           type="email"
                           placeholder="Email@gmail.com"
                           className="px-[5px] mt-[7px] md:mt-[14px] xl:mt-[25px] text-[12px] md:text-[16px] bg-transparent outline-none h-[30px] md:h-[40px] xl:h-[44px] w-full border-b-[1px] border-b-[#D7CEE1]"
                        />
                        <div className="relative flex items-center">
                            <input
                                value={inputPassword}
                                onChange={(e)=>handleInput(e, "password")}
                                type={password}
                                placeholder="Password"
                                className="px-[5px] mt-[7px] md:mt-[14px] xl:mt-[25px] text-[12px] md:text-[16px] bg-transparent outline-none h-[30px] md:h-[40px] xl:h-[44px] w-full border-b-[1px] border-b-[#D7CEE1]"
                            />
                           <img 
                               onClick={()=>handlePasswordType('password')}
                               src="/backGrounds/registration/password_eye.svg"
                               className="absolute bg-[#FBF5E0] w-[21px] h-[13px] xl:w-[26px] xl:h-[15px] right-[5px] bottom-[8px] md:bottom-[15px] cursor-pointer" 
                            />
                            {password === "password" && <div className="absolute w-[21px] xl:w-[26px] h-[2px] right-[5px] bottom-[15px] md:bottom-[22px] bg-[#736C80] rotate-[-45deg]" />}
                        </div>
                        {state==="signup" && <div className="relative flex items-center">
                            <input
                                value={inputConfirmPassword}
                                onChange={(e)=>handleInput(e, "confirmPassword")}
                                type={confirmPassword}
                                placeholder="Confirm Password"
                                className="px-[5px] mt-[7px] md:mt-[14px] xl:mt-[25px] text-[12px] md:text-[16px] bg-transparent outline-none h-[30px] md:h-[40px] xl:h-[44px] w-full border-b-[1px] border-b-[#D7CEE1]"
                            />
                           <img 
                               onClick={()=>handlePasswordType('confirmPassword')} 
                               src="/backGrounds/registration/password_eye.svg"
                               className="bg-[#FBF5E0] absolute w-[21px] h-[13px] xl:w-[26px] xl:h-[15px] right-[5px] bottom-[8px] md:bottom-[15px] cursor-pointer" 
                            />
                            {confirmPassword === "password" && <div className="absolute w-[21px] xl:w-[26px] h-[2px] right-[5px] bottom-[15px] md:bottom-[22px] bg-[#736C80] rotate-[-45deg]" />}
                        </div>}
                    </form>}
                    {state==="login" && !forgetPassword && <div onClick={()=>setForgetPassword(true)} className="text-[#827A8C] text-[11px] md:text-[12px] w-full text-left mt-[11px] font-commisioner cursor-pointer">
                        Forgot Your Password ?
                    </div>}
                    {checkValidate && <div className="text-[#EE4060] text-[10px] md:text-[12px] xl:text-[14px] w-full text-left mt-[3px] font-commisioner cursor-pointer">
                        Incorrect email or password
                    </div>}
                    {!forgetPassword && <div className={`w-full flex justify-between text-[12px] md:text-[16px] xl:text-[20px] ${checkValidate ? "mt-[8px] md:mt-[17px]" : "mt-[15px] md:mt-[28px]"}  xl:mt-[39px]`}>
                         <button 
                            disabled={wrongSignUpCount >=3 ? true : false}  
                            onClick={()=>handleStateChange("signup")} 
                            className={`w-[94px] h-[26px] md:w-[89px] md:h-[26px] lg:w-[126px] lg:h-[39px] xl:w-[153px] xl:h-[45px] ${state==="signup" ? wrongSignUpCount < 3 ? "text-[#fff] bg-[length:100%_100%] bg-[url('/backGrounds/registration/registration_btn.svg')]" : "bg-[#968E9A] text-[#fff] rounded-[10px]" : "underline underline-offset-[5px] text-[#968E9A]"} flex items-center justify-center`}
                        >
                            Sign Up
                         </button>
                         <div className="w-[94px] h-[26px] md:w-[89px] md:h-[26px] xl:w-[153px] lg:w-[126px] lg:h-[39px] xl:h-[45px] text-[#3E3A3A] flex items-center justify-center">
                            or
                         </div>
                         <button 
                            disabled={wrongLoginCount >=3 ? true : false} 
                            onClick={()=>handleStateChange("login")} 
                            className={`w-[94px] h-[26px] md:w-[89px] md:h-[26px] lg:w-[126px] lg:h-[39px] xl:w-[153px] xl:h-[45px] ${state==="login" ? wrongLoginCount < 3 ? "text-[#fff] bg-[length:100%_100%] bg-[url('/backGrounds/registration/registration_btn.svg')]" : "bg-[#968E9A] text-[#fff] rounded-[10px]" : "underline underline-offset-[5px] text-[#968E9A]"} flex items-center justify-center`}
                        >
                            Log In
                         </button>

                    </div>}
                    {forgetPassword && <ForgetPassword />}
                    {(wrongLoginCount>=3 || wrongSignUpCount>=3) && <div className="mt-2">
                        <Recaptcha
                            siteKey={"6Ldu6PMpAAAAAGwo3ux1j_KDMph5W6v5Pn6yVg7k"} 
                            callback={handleToken}
                        />
                    </div>}
                </div>
           </div>
      </div>
    )
}


function ForgetPassword() {
    const dispatch = useDispatch();
    const [email, setEmail] = useState('');

    const [message, setMessage] = useState(false);

    const handlePassword = () => {
       if (email) {
            dispatch(forgetPasswordAsyncThunk({email: email.toLowerCase()}))
            .then(()=>{
                setMessage(false);
                setEmail("");
                alert("Check Email");
            })
            .catch((error)=>{
                console.log('error', error);
            });
       } else {
           setMessage(true);
       }
    }

    return (
        <div>
            <form className="md:mt-[9px] xl:mt-[20px] text-[#736C80]">
                <p className="w-full text-left">Forgot Password</p>
                <input
                    value={email}
                    onChange={(e)=>setEmail(e.target.value)}
                    type="email"
                    placeholder="Email"
                    className="px-[5px] mt-[7px] md:mt-[14px] xl:mt-[25px] text-[12px] md:text-[16px] bg-transparent outline-none h-[30px] md:h-[40px] xl:h-[44px] w-full border-b-[1px] border-b-[#D7CEE1]"
                />
            </form>
            <div className="text-[#EE4060] text-[10px] md:text-[12px] xl:text-[14px] w-full text-left mt-[3px] font-commisioner cursor-pointer">
                {message ? "Incorrect email or password" : "" }
            </div>
            <div className={`w-full flex justify-between text-[12px] md:text-[16px] xl:text-[20px] mt-[15px] md:mt-[28px] xl:mt-[39px]`}>
                <button onClick={handlePassword} className={`w-[94px] h-[26px] md:w-[89px] md:h-[32px] lg:w-[126px] lg:h-[39px] xl:w-[153px] xl:h-[45px] text-[#fff] bg-[length:100%_100%] bg-[url('/backGrounds/registration/registration_btn.svg')] flex items-center justify-center`}>
                    Send
                </button>
            </div> 
        </div>
    )
}