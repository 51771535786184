import { useParams } from "react-router-dom";
import React, {memo, useState, useRef, useEffect } from 'react';
import { updateStorage} from "../../../hooks/useStorage";
import { updateGameStorage } from "../../../hooks/useGameStorage";
import { 
    animationDurationToShowscoresPopup, 
    animationDurationToMoveCorrectAnswer,
} from "../../../config";
import TopSection from "./components/TopSection";
import BottomSection from "./components/BottomSection";
import { generateData } from './FindNumber.styles';
import Sound from '../../../components/global/Sound';
import * as amplitude from '@amplitude/analytics-browser';
import { useUpdateReduxState } from "../../../hooks/useUpdateReduxState";
import { gameId } from "../../../hooks/getGameId";


export default memo(function FindNumber({state, level, handleUpdateProgress, handleTimer, count, answerSound, point, hint, subscription}) {
    const data = generateData(level);
    const {name, gameName} = useParams();
    const [result, setResult] = useState(data.displayNumbers);
    const numbers = useRef(data.numbers);
    const answers = useRef(data.answers);
    const selectedItems = useRef([]);
    const score = useRef(0);
    const [gameCount, setGameCount] = useState(0);
    const soundWrongRef = useRef();
    const soundRef = useRef();

    const updateState = useUpdateReduxState();

    useEffect(()=>{
      amplitude.track(`${name+"_"+gameName.replace("_", " ")+"_"+level+"_start"}`);
    }, []);

    const handleScore=()=>{
        score.current+=point[level];
        if (!subscription) {
          updateStorage(point[level]);
        }
        if (gameCount === count-1) {
            if (subscription) {
              updateState({
                userId: localStorage.getItem('user'),
                profileId: JSON.parse(localStorage.getItem('profile'))?._id,
                game: name,
                score: score.current,
                id: gameId(name, gameName),
                collection: name,
              })
            } else {
              updateGameStorage(name, gameName, point[level], true, level);
              handleUpdateProgress();
            }
            
            setTimeout(()=>{
              const data = generateData(level);
              answers.current = data.answers;
              setResult(data.displayNumbers);
              numbers.current = data.numbers;
              selectedItems.current.length = 0;
              setGameCount(0);
              handleTimer();
              const items = document.querySelectorAll('.item');
              items.forEach((item)=>item.style.opacity=1);
            }, animationDurationToShowscoresPopup*1000);
            localStorage.setItem(`${name+'_'+gameName+'_'+level+'_score'}`, score.current);
          } else {
            setTimeout(()=>{
                const data = generateData(level);
                answers.current = data.answers;
                setResult(data.displayNumbers);
                numbers.current = data.numbers;
                selectedItems.current.length = 0;
                setGameCount(gameCount+1);
                const items = document.querySelectorAll('.item');
                items.forEach((item)=>item.style.opacity=1);
              }, animationDurationToMoveCorrectAnswer*1000);
              if (!subscription) {
                handleUpdateProgress();
                updateGameStorage(name, gameName, point[level], false, level);
              }
             
          }

    };

    const handleRefresh = (selected, sound) => {
        if (answerSound+""=="true" && sound === 'wrong') {
          soundWrongRef.current.play();
          return;
        }
        const index = numbers.current.indexOf(selected);
        if (result[index] === null && numbers.current[index] == selected) {
          answerSound+""=="true" && soundRef.current.play();
          const arr = [...result];
          arr[index] = selected;
          selectedItems.current.push(selected);
          setResult(arr);
        }
    };

    useEffect(()=>{
        if (result.length && !result.includes(null)) {
          handleScore();
        }
    }, [result]);
    
    return (
        <div className={"w-full h-full flex flex-col justify-between relative z-30"}>
            <TopSection 
              state={state} 
              level={level}
              showNumbers={result}
              gameCount = {gameCount}
            />
            <BottomSection
               hint={hint}
               state={state} 
               level={level}
               answers={answers.current}
               showNumbers={result}
               selected = {selectedItems.current}
               gameCount = {gameCount}
               numbers = {numbers.current}
               handleRefresh = {handleRefresh}
            />
            {answerSound+""=="true" && <Sound ref={soundRef} url={"/backGrounds/right_answer.wav"} loop={false}/>}
            {answerSound+""=="true" && <Sound ref={soundWrongRef} url={"/backGrounds/wrong_answer.wav"} loop={false}/>}
        </div>
    )
});