import { DragAndDrop } from '../../../../components/global/DragAndDrop';
import { motion } from 'framer-motion';
import { getCoordinates } from '../Compare1.data';
import { getBottomGapX } from "../Compare1.data";
import { 
    animationDurationToShowQuestion,
} from "../../../../config";
import React, { useState, useEffect, useRef } from 'react';
import Sound from '../../../../components/global/Sound';
import { CircleButton } from '../../../../components/elements/CircleButton';
import { getCircleComponent } from "../../../../hooks/useCircleButtonColor";


export const BottomPart = ({state, answers, checkAnswer, answer, answerSound, hint}) => {
    const colors = useRef(getCircleComponent(answers));
    const [i, setI] = useState('');
    const [time, setTime] = useState(0);
    const [animate, setAnimate] = useState(false);
    const [val, setVal] = useState();
    const [update, setUpdate] = useState(false);
    const soundRef = useRef();
    const soundWrongRef = useRef();
    const [type, setType] = useState(false);

    useEffect(()=>{
        if (!animate) return;
        setVal('');
        setAnimate(false);
        setI('');
        setUpdate(false);
        setTime(0);
        colors.current = getCircleComponent(answers);
    }, [answers]);

    useEffect(()=>{
        if (type) setTimeout(()=>{
         setType(false);
         document.querySelector('.addingresult').style.opacity = 1;
        }, 1000);
     }, [type]);

    useEffect(()=>{
        if (!hint) return;
        const id = setInterval(()=>setTime(prev=>prev+1), 1000);
        return ()=>clearInterval(id);
    }, [time, hint]);
   
   return(
       <div
            style={{columnGap: getBottomGapX(state)+'px'}}
            className={`z-[50] flex items-center justify-center w-full border-[3px] rounded-[16px] border-[#2F9EBA] bg-[#FFF] lg:rounded-[20px]
            ${state ? "h-[67px] md:h-[73px] lg:h-[93px] xl:h-[114px]" : "h-[67px] md:h-[90px] lg:h-[113px] xl:h-[136px]"}`}
       >
            {answers.map((number, index) => {
                if (i===index) {
                   return <DragAndDrop
                     enableClick = {true}
                     drop={'adding'}
                     animateWrong = {(e)=>{
                        setUpdate(index);
                        setI('');
                        if (answerSound) {
                            soundWrongRef.current.currentTime = 0;
                            soundWrongRef.current.play();
                        }
                    }}
                    key={number} numbers={[...answer]} item={number} refresh={(event, type) => {
                        if (event==='mouseup') return;
                        if (val===number) return; //prevnting multiple clicks on one element
                        setType(type);
                        setTime(0);
                        checkAnswer(event, type, number);
                        setVal(number);
                        setUpdate(false);
                        if(type) setTimeout(()=>document.querySelector('.addingresult').style.opacity=0, 200);
                        if (answerSound) {
                            soundRef.current.currentTime = 0;
                            soundRef.current.play();
                        }
                   }}>
                    <motion.div 
                        style={{
                            transform: type && val === number && `translate(${getCoordinates()[0]}px,${getCoordinates()[1]}px)`,
                            transition: `transform ${0.2}s linear`
                        }} 
                        className={`relative answer ${val === number && !type? 'opacity-0' : 'opacity-100'} z-[4000] cursor-pointer`}
                    >
                        <CircleButton key={index} isActive={number} color={colors.current[index]}>
                        <   div className='font-bigfatScript'>
                               {number}
                            </div>
                        </CircleButton>
                        {answer === number && time>=10 && time%2===0 && <div className="bg-[url('/backGrounds/hint.svg')] bg-[position:50%_50%] bg-contain bg-no-repeat absolute w-[50px] h-[50px] top-[50%] z-[100]"></div>}
                    </motion.div>
                   </DragAndDrop>
                } else {
                   return <motion.div 
                        key={number} className={`relative z-[4000] cursor-pointer`} 
                        animate={{y: animate ? [0, 0] : [(index+1)*100, 0], x: update === index ? [-2, 0, 2, 0] : [0, 0]}}
                        transition={{duration: animationDurationToShowQuestion , type: "tween", ease: "linear"}}
                        onPointerEnter={(e)=>{
                            setI(index);
                            setAnimate(true);
                            setUpdate(false);
                        }}
                      >
                        <CircleButton key={index} isActive={number} color={colors.current[index]}>
                            <div className='font-bigfatScript'>
                               {number}
                            </div>
                          
                        </CircleButton>
                        {hint && answer[0] === number && time>=10 && time%2===0 && <div className="bg-[url('/backGrounds/hint.svg')] bg-[position:50%_50%] bg-contain bg-no-repeat absolute w-[50px] h-[50px] top-[50%] z-[100]"></div>}
                    </motion.div>
                }
            })}
            <Sound ref={soundRef} url={"/backGrounds/right_answer.wav"} loop={false}/>
            <Sound ref={soundWrongRef} url={"/backGrounds/wrong_answer.wav"} loop={false}/>
       </div>
   )
}