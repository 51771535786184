

const data = {
    circle: [1, 2, 3, 4],
    diagonal: [1, 2, 3, 4, 5],
    pentagon: [1, 2, 3, 4],
    rectangle: [1, 2, 3, 4, 5],
    square: [1, 2, 3, 4],
    triangle: [1, 2, 3, 4],
}

export const getData = () => {
    const array = new Set();
    while(array.size<4) {
        array.add(Object.keys(data)[Math.floor(Math.random() * Object.keys(data).length)]);
    }
    const newArr = Array.from(array);

    const res = newArr.reduce((aggr, item)=>{
        aggr.push(data[item][Math.floor(Math.random() * data[item].length)]);
        return aggr;
    }, []);
    return {
        answers: newArr,
        images: res
    }
}

export const shapes = {
    triangle: {
        path: 'polygon(50% 0%, 0% 100%, 100% 100%)', 
    },
    pentagon: {
        path: 'polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%)', 
    },
    circle: {
        path: 'circle(50% at 50% 50%)', 
    },
    diagonal: {
        path: 'polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%)',
    },
    square: {path: ''},
    rectangle: {path: ''}
}

export const mixAnswers = (arr) => {
    const newArray = new Set();

    while(newArray.size<=3) {
        newArray.add(arr[Math.floor(Math.random() * arr.length)]);
    }
    return Array.from(newArray);
}