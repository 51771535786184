export const getFirstNumber = () => {
   const array = [2, 4, 6, 8];
   return array[Math.floor(Math.random() * array.length)];
}

export const getSecondNumber = (number) => {
    const array = [1, 2, 3, 4, 6, 8];
    while (true) {
        const randomNum = array[Math.floor(Math.random() * array.length)];
        if (number>=randomNum && number%randomNum===0) {
            return randomNum;
        }
    }
}