import {useTranslation} from 'react-i18next';
import { formatedDate, getDate } from "../../hooks/useCurrentDate";
import { getScoresHistoryThunk, getScoreDetailsThunk } from "../../redux/features/scoresSlice";
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from "react-infinite-scroll-component";

export default function GameResults({state, subscription, cmp}) {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [hasMore, setHasMore] = useState(true);
    const [openDetails, setOpenDetails] = useState(false);
    const [skip, setSkip] = useState(100);
    const data = subscription ? useSelector(state=>state.scores.data) : 
        JSON.parse(localStorage.getItem('scoreStorage')).filter((item)=>item.score || item.date === getDate());
    const profiles = useSelector((state)=>state.user.user.profiles);
    const scoreDetails = useSelector(state=>state.scores.scoreDetails);

    useEffect(()=>{
        if (subscription && !data.length) {
            dispatch(getScoresHistoryThunk({userId: localStorage.getItem('user'), skip: 0}));
        }
    }, []);

    const fetchMoreData = () => {
        if (subscription) {
            dispatch(getScoresHistoryThunk({userId: localStorage.getItem('user'), skip})).then((res)=>{
                setSkip((prevSkip)=>prevSkip+100);
                res.payload.length > 0 ? setHasMore(true) : setHasMore(false);
            })
        }
    };

    return (
        <div className={`${cmp ? 'rounded-0' : 'rounded-[17px] lg:rounded-[22px]'} h-[100%] flex justify-center items-center w-full bg-[rgba(108,_108,_108,_0.37)] absolute z-[100]`}>
            <div  className={`w-[425px] overflow-y-auto h-[266px] ${state ? 'md:w-[439px] md:h-[292px] lg:w-[590px] lg:h-[370px] xl:w-[768px] xl:h-[454px]' : 'md:w-[576px] md:h-[360px] lg:w-[723px] lg:h-[452px] xl:w-[909px] xl:h-[544px]'} rounded-[17px] lg:rounded-[22px] border-[3px] xl:border-[4px] border-[#4F9686] bg-[#FFF]`}>
                <div id="scrollableTable" className='lang overflow-y-auto w-full h-full rounded-[17px] lg:rounded-[22px]'>
                {!openDetails && <InfiniteScroll
                        dataLength={data.length}
                        next={fetchMoreData}
                        hasMore={hasMore}
                        scrollableTarget="scrollableTable"
                        //scrollThreshold={1}
                        //height={150}
                        //loader={subscription ? <h4 className='text-[#4F9686] font-fredokaOne text-center'>Loading more scores...</h4> : ''}
                >
                    <table className='w-full text-[#4F9686] font-fredokaOne [&>*:nth-child(even)]:bg-[#EFF4F4]'>
                        <tr className={`h-[37px] text-[12px] lg:h-[49px] ${state ? 'md:h-[41px] md:text-[14px] lg:text-[16px] xl:h-[54px] xl:text-[20px]' : 'md:h-[51px] md:text-[16px] lg:text-[18px]  xl:h-[59px] xl:text-[24px]'}`}>
                            {
                                data.length && Object.keys(data[0]).map((title)=><th key={title} className='rounded-t-[17px] lg:rounded-t-[22px] capitalize sticky top-0 bg-[#FFF]'>{title}</th>)
                            }
                        </tr>
                        {
                            data.length && data.map((item, i)=>{
                                return (
                                    <tr key={i} className={`w-full text-[12px] h-[37px] lg:h-[49px] ${state ? 'md:h-[41px] md:text-[14px] lg:text-[16px] xl:h-[54px] xl:text-[20px]' : 'md:h-[51px] md:text-[16px] lg:text-[18px]  xl:h-[59px] xl:text-[24px]'} ${i%2 ? 'text-[#BEBEBE]': 'text-[#70BBAC]'}`}>
                                        {
                                            Object.values(item).map((el, index)=><td onClick={(e)=>{
                                                e.stopPropagation();
                                               if (!subscription) return;
                                                dispatch(getScoreDetailsThunk({
                                                    date: item.date, 
                                                    profileId: profiles[index-1]._id, 
                                                    userId: localStorage.getItem('user')
                                                })).then(()=>setOpenDetails(!openDetails));
                                            }} key={index} className={`${index && 'cursor-pointer'} text-center`}>{index || !subscription ? el : formatedDate(el*1000).replaceAll('/', '.')}</td>)
                                        }
                                    </tr>
                                )
                            })
                        }
                    </table>
                </InfiniteScroll>}
                {openDetails && <div>
                       <div className='cursor-pointer text-[20px] mx-[10px] text-[#4F9686] font-fredokaOne' onClick={(e)=>{
                          e.stopPropagation();
                          setOpenDetails(!openDetails)
                       }}>back</div>
                       {
                           Object.entries(scoreDetails).map(([key, value])=>{
                              if(Array.isArray(value) && value.length) {
                                return (
                                    <fieldset key={key} className='mx-auto w-[90%] h-[100px] border-[1px] border-[#4F9686] rounded-[17px] overflow-y-hidden'>
                                       <legend className='font-fredokaOne uppercase text-[#4F9686]'>{key}</legend>
                                       {
                                          Object.values(value[0]).map((elem)=>{
                                            if(typeof(elem) === "object") {
                                                return(
                                                    <p key={elem}>{elem.name}-{elem.points}</p>
                                                  )
                                            }
               
                                          })
                                       }
                                   </fieldset>
                                )

                              }
                           })
                       }
                    </div>}
                </div>

            </div>
        </div>
    );
}