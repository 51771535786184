const scoresPopupDisplayDuration = 4.0;
const animationDurationToShowscoresPopup = 1.0;
const animationDurationToMoveCorrectAnswer = 0.5;
const animationDurationToShowQuestion = 0.5;
const animationDurationToShowQWrongAnswer = 0.4;
const isDev = window.location.hostname === "sandbox.mathmindsgame.com";
const isProd = window.location.hostname === "mathmindsgame.com";
const openGamesCount = 4;
const subscription = false;
const API_URL = isDev || isProd ? `https://${window.location.hostname}:3080/api` : `http://${window.location.hostname}:3000/api`;
const GAMES = {
    counting: [
        { id: '0101', name: 'counting_1' },
        { id: '0102', name: 'counting_2' }, 
        { id: '0103', name: 'learning' },
        { id: '0104', name: 'odd_or_even' }, 
        { id: '0105', name: 'step_by_step' }
    ],     
    shapes: [
        { id: '0201', name: 'shapes_1' }, 
        { id: '0202', name: 'shapes_2' }, 
        { id: '0203', name: 'shapes_3' }, 
        { id: '0204', name: 'shapes_4' }, 
        { id: '0205', name: 'shapes_5' }
    ],
    adding: [
        { id: '0301', name: 'adding_1' }, 
        { id: '0302', name: 'adding_2' }, 
        { id: '0303', name: 'adding_3' },
        { id: '0304', name: 'rapid_math' }, 
        { id: '0305', name: 'adding_mix' }, 
        { id: '0306', name: 'adding_4' }, 
        { id: '0307', name: 'adding_5' },
        { id: '0308', name: 'expression' }
    ],
    subtracting: [
        { id: '0401', name: 'subtracting_1' }, 
        { id: '0402', name: 'subtracting_2' }, 
        { id: '0403', name: 'subtracting_3' }, 
        { id: '0404', name: 'rapid_math' }, 
        { id: '0405', name: 'subtracting_4' }, 
        { id: '0406', name: 'subtracting_5' }, 
        { id: '0407', name: 'expression' }
    ],
    multiplication: [
        { id: '0501', name: 'multiplication_1' }, 
        { id: '0502', name: 'multiplication_2' }, 
        { id: '0503', name: 'multiplication_3' }, 
        { id: '0504', name: 'rapid_math' }, 
        { id: '0505', name: 'multiplication_4' }, 
        { id: '0506', name: 'multiplication_5' }, 
        { id: '0507', name: 'expression' }
    ],
    division: [
        { id: '0601', name: 'division_1' }, 
        { id: '0602', name: 'division_2' }, 
        { id: '0603', name: 'division_3' },
        { id: '0604', name: 'rapid_math' }, 
        { id: '0605', name: 'division_4' }, 
        { id: '0606', name: 'expression' }
    ],
    sorting: [
        { id: '0701', name: 'expression' }, 
        { id: '0702', name: 'ascending' }, 
        { id: '0703', name: 'descending' }, 
        { id: '0704', name: 'find_number'}
    ],
    compare: [
        { id: '0801', name: 'scale' }, 
        { id: '0802', name: 'compare' }, 
        { id: '0803', name: 'expression_1' }, 
        { id: '0804', name: 'expression_2' }, 
        { id: '0805', name: 'blocks' }
    ],
    memory: [
        { id: '0901', name: 'images' }, 
        { id: '0902', name: 'colors' }, 
        { id: '0903', name: 'rapid_colors' }, 
        { id: '0904', name :'numbers' }, 
        { id: '0905', name: 'rapid_numbers' }, 
        { id: '0906', name: 'shapes' }, 
        { id: '0907', name: 'rapid_shapes' }
    ],
    schulte: [
        { id: '1001', name: 'classic_asc' }, 
        { id: '1002', name: 'classic_desc' }, 
        { id: '1003', name: 'step_by_step_asc' }, 
        { id: '1004', name: 'step_by_step_desc' }, 
        { id: '1005', name: 'segment_asc' }, 
        { id: '1006', name: 'segment_desc' }
    ]
}

export {
    scoresPopupDisplayDuration, 
    animationDurationToShowscoresPopup, 
    animationDurationToMoveCorrectAnswer, 
    animationDurationToShowQuestion,
    animationDurationToShowQWrongAnswer,
    isDev,
    isProd,
    openGamesCount,
    subscription,
    API_URL,
    GAMES
};