import { calcGapX } from "../Colors.data";
import { DragAndDrop } from "../../../../components/global/DragAndDrop";
import { animationDurationToShowQuestion } from "../../../../config";
import { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";

let showHint;

export const BottomPart = ({state, answers, refresh, selected, result, colors, hint}) => {
    const [index, setIndex] = useState('');
    const [animate, setAnimate] = useState(false);

    const isDragging =  useRef();

    const [start, setStart] = useState({x:0, y:0});   
    const [end, setEnd] = useState({x:0, y:0});
    const [time, setTime] = useState(0);

    useEffect(()=>{
        if (!animate) return;
        setAnimate(false);
        setIndex('');
        setTime(0);
        isDragging.current = false;
    }, [answers]);

    useEffect(()=>{
        showHint = true;
        if(result.indexOf(undefined)===-1) return;
        const start = document.querySelector('.answer');
        const end = document.querySelector('.drop'+(result.indexOf(undefined)));
        if (!start || !end) return;
        const startCoord = start.getBoundingClientRect();
        const endCoord = end.getBoundingClientRect();
        setStart({x:startCoord.x, y:startCoord.y});
        setEnd({x:endCoord.x+endCoord.width-endCoord.height, y:endCoord.y});
        setTime(0);
   }, [result]);

    useEffect(()=>{
        if (!hint) {
            setTime(0);
            return;
        }
        if (time===10) return;
        const id = setInterval(()=>setTime(prev=>prev+1), 1000);
        return ()=>clearInterval(id);
    }, [time]);
    
    return (
        <div className={`dropAnswers w-full flex items-center border-[3px] border-[#2F9EBA] bg-[#FFF] rounded-[16px]
                       ${state ? "h-[67px] md:h-[73px] lg:h-[93px] xl:h-[114px]" : "h-[67px] md:h-[90px] lg:h-[113px] xl:h-[136px]"}`}>
            {answers.map((answer, i)=>{
                if (index === i) {
                    return (
                        <DragAndDrop
                            key={answer} 
                            enableClick = {false}
                            dragging = {(state)=>{
                                if (!state) return
                                isDragging.current = state;
                            }}
                            cmp={"colors"} 
                            drop={"dropColor"}
                            item = {answer}
                            refresh={(sound, event) => {
                                showHint = false;
                                setIndex('');
                                refresh(event, i, answer, '', isDragging.current)
                            }}
            
                        >
                            <div 
                               style={{
                                    backgroundColor: answer,
                                    marginLeft: calcGapX(state, answers.length)+'px'
                                }}
                                className={`rounded-[4px] z-[50] cursor-pointer lg:rounded-[6px] 
                                            ${state ? 'md:w-[39px] md:h-[39px] lg:w-[50px] lg:h-[50px] xl:w-[70px] xl:h-[70px] ' : ' md:w-[49px] md:h-[49px]  lg:w-[69px] lg:h-[69px] xl:w-[89px] xl:h-[89px] '} w-[39px] h-[39px]
                                            ${selected.includes(answer) ? "opacity-0" : "opacity-100"}
                                        `}
                            >
                            
                            </div>
                        </DragAndDrop>
                    )
                } else {
                    return (
                        <motion.div
                            animate={{y: animate ? [0, 0] : [(i+1)*500, 0]}}
                            transition={{duration: animationDurationToShowQuestion, type: "tween"}} 
                            onMouseEnter={(e)=>{
                                setIndex(i);
                                setAnimate(true);
                            }}
                            onTouchStart={(e)=>{
                                setIndex(i);
                                setAnimate(true);
                            }}
                           key={answer}
                           style={{
                              backgroundColor: answer,
                              marginLeft: calcGapX(state, answers.length)+'px'
                           }}
                           className={`relative rounded-[4px] z-[50] cursor-pointer lg:rounded-[6px] 
                                      ${state ? 'md:w-[39px] md:h-[39px] lg:w-[50px] lg:h-[50px] xl:w-[70px] xl:h-[70px] ' : ' md:w-[49px] md:h-[49px]  lg:w-[69px] lg:h-[69px] xl:w-[89px] xl:h-[89px] '} w-[39px] h-[39px]
                                      ${selected.includes(answer) ? "opacity-0" : "opacity-100"} ${i === answers.indexOf(colors[result.indexOf(undefined)]) && 'answer'}
                                    `}
                        >
                             {hint && showHint && time==10 && i === answers.indexOf(colors[result.indexOf(undefined)]) &&  <motion.div
                                        animate={{y: [0, end.y-start.y], x: [0, end.x-start.x]}}
                                        transition={{duration: 2 , type: "tween", ease: "linear", repeat: Infinity}}
                                        className="bg-[url('/backGrounds/hint.svg')] bg-[position:50%_50%] bg-contain bg-no-repeat absolute w-[50px] h-[50px] top-[50%] z-[1000]"></motion.div>}
                        </motion.div>
                    )
                }
            })}
        </div>
    )
}