import { Canvas } from "../../../components/global/Canvas";
import { getNumber } from "./Subtracting5.data";
import { useRef, useEffect, useState } from "react";
import { updateStorage} from "../../../hooks/useStorage";
import { updateGameStorage } from "../../../hooks/useGameStorage";
import { 
    animationDurationToShowscoresPopup, 
} from "../../../config";
import { useParams } from 'react-router-dom';
import * as amplitude from '@amplitude/analytics-browser';
import { useUpdateReduxState } from "../../../hooks/useUpdateReduxState";
import { gameId } from "../../../hooks/getGameId";


export const Subtracting5 = ({state, handleUpdateProgress, handleOpenScore, answerSound, point, screenSize, subscription}) => {
    const {name, gameName} = useParams();
    const number1 = useRef(getNumber());
    const number2 = useRef(getNumber(number1.current));
    const number3 = useRef(getNumber());
    const number4 = useRef(getNumber(number3.current));
    const number5 = useRef(getNumber());
    const number6 = useRef(getNumber(number5.current));
    const answer1 = useRef(number1.current-number2.current);
    const answer2 = useRef(number3.current-number4.current);
    const answer3 = useRef(number5.current-number6.current);
    const score = useRef(0);

    const updateState = useUpdateReduxState();

    const [result, setResult] = useState([null, null, null]);

    useEffect(()=>{
        amplitude.track(`${'subtract'+"_"+gameName.replace("_", " ")+"_start"}`);
    }, []);
    
    const refresh = (pos, answer) => {
        if (pos === 0) {
            const newResult = [...result];
            newResult[0] = answer;
            setResult(newResult);
        }
        if (pos === 1) {
            const newResult = [...result];
            newResult[1] = answer;
            setResult(newResult);
        }
        if (pos === 2) {
            const newResult = [...result];
            newResult[2] = answer;
            setResult(newResult);
        }
    }

    useEffect(()=> {
      if (!result.includes(null)) {
        score.current += point;
        if (!subscription) {
            updateStorage(point);
        }
        if (subscription) {
            updateState({
                userId: localStorage.getItem('user'),
                profileId: JSON.parse(localStorage.getItem('profile'))?._id,
                game: name,
                score: score.current,
                id: gameId(name, gameName),
                collection: name,
              })
        } else {
            handleUpdateProgress();
            updateGameStorage(name, gameName, point, true);
        }
        setTimeout(()=>handleOpenScore(), animationDurationToShowscoresPopup*1000);
        localStorage.setItem(`${name+'_'+gameName+'_'+'_score'}`, score.current);
      }
    }, [result]);

    return (
        <div
          className={`text-[30px] font-bigfatScript text-[#FFF] w-full h-full border-[3px] bg-[#B58AEA] border-[#7648B5] rounded-[16px] px-[78px] py-[18px] flex flex-col justify-between
            ${state ? "md:px-[82px] md:py-[17px] lg:px-[87px] lg:py-[26px] xl:px-[113px] xl:py-[31px] md:text-[35px] lg:text-[50px] xl:text-[60px]" : "xl:text-[65px] lg:text-[55px] md:text-[40px] md:px-[100px] md:py-[24px] lg:px-[98px] lg:py-[33px] xl:px-[157px] xl:py-[28px]"}
        `}
        >
           <div className={`w-full flex items-center justify-between h-[64px] ${state ? "md:h-[70px] lg:h-[84px] xl:h-[106px]": "md:h-[84px] lg:h-[102px] xl:h-[140px]"}`}>
               <div>{number1.current}</div>
               <div>-</div>
               <div>{number2.current}</div>
               <div>=</div>
               {result[0]!==null && <div className={`z-[100] flex items-center justify-start w-[64px] h-full rounded-[4px] ${state ? "md:w-[70px] lg:w-[84px] xl:w-[106px]": "md:w-[84px] lg:w-[102px] xl:w-[140px]"}`}>{result[0]}</div>}
               {result[0]===null && <Canvas screenSize={screenSize} state={state} answer={answer1.current} refresh={refresh} pos={0}/>}
           </div>
           <div className={`w-full flex items-center justify-between h-[64px] ${state ? "md:h-[70px] lg:h-[84px] xl:h-[106px]": "md:h-[84px] lg:h-[102px] xl:h-[140px]"}`}>
               <div>{number3.current}</div>
               <div>-</div>
               <div>{number4.current}</div>
               <div>=</div>
               {result[1]!==null && <div className={`z-[100] flex items-center justify-start w-[64px] h-full rounded-[4px] ${state ? "md:w-[70px] lg:w-[84px] xl:w-[106px]": "md:w-[84px] lg:w-[102px] xl:w-[140px]"}`}>{result[1]}</div>}
               {result[1]===null && <Canvas screenSize={screenSize} state={state} answer={answer2.current} refresh={refresh} pos={1}/>}
           </div>
           <div className={`w-full flex items-center justify-between h-[64px] ${state ? "md:h-[70px] lg:h-[84px] xl:h-[106px]": "md:h-[84px] lg:h-[102px] xl:h-[140px]"}`}>
               <div>{number5.current}</div>
               <div>-</div>
               <div>{number6.current}</div>
               <div>=</div>
               {result[2]!==null && <div className={`z-[100] flex items-center justify-start w-[64px] h-full rounded-[4px] ${state ? "md:w-[70px] lg:w-[84px] xl:w-[106px]": "md:w-[84px] lg:w-[102px] xl:w-[140px]"}`}>{result[2]}</div>}
               {result[2]===null && <Canvas screenSize={screenSize} state={state} answer={answer3.current} refresh={refresh} pos={2}/>}
           </div>
        </div>
    )
}