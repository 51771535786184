
import { DashedCircle } from "../../../../components/elements/DashedCircle";
import { CircleButton } from "../../../../components/elements/CircleButton";


export const TopPart = ({state, answers, result}) => {
    return (
        <div 
           className={`flex justify-between overflow-hidden w-full border-[3px] rounded-[16px] border-[#7648B5] bg-[#68C0CC] lg:rounded-[20px] 
                      ${state ? "h-[185px] md:h-[203px] lg:h-[257px] xl:h-[316px]" : "h-[185px] md:h-[252px] lg:h-[315px] xl:h-[380px]"}`}>
            <div className={`h-full w-[211px] ${state ? 'xl:w-[381px] md:w-[217px] md:px-[24px] lg:px-[35px] xl:px-[38px] md:py-[17px] lg:text-[36px] lg:w-[292px] xl:text-[46px]' : 'xl:w-[451px] xl:text-[60px] md:w-[285px] md:text-[32px] md:px-[34px] xl:px-[54px] lg:px-[27px] md:py-[19px] lg:text-[46px] lg:w-[358px]'} px-[22px] py-[12px] flex flex-col justify-between text-[24px] text-[#FFF] font-bigfatScript`}>
               {
                 answers.slice(0, 3).map((number, index)=>{
                    return (
                        <div key={Math.random()} className={`w-full h-[39px] ${state ? 'lg:h-[50px] xl:h-[70px]' : 'xl:h-[89px] md:h-[49px] lg:h-[69px]'} flex items-center justify-between`}>
                        {number.index===0 && !result[index] && <DashedCircle className={'drop'+index} state={state}/>}
                        {number.index===0 && result[index] && <CircleButton className={'drop'+index} isActive={number.firstNumber}>
                             {number.firstNumber}
                         </CircleButton>}
                         {number.index!==0 && <div className={`${result[index] ? 'opacity-50' : 'opacity-100'}`}>{number.firstNumber}</div>}
 
                        <div className={`${result[index] ? 'opacity-50' : 'opacity-100'}`}>+</div>
 
 
                        {number.index===1 && !result[index] && <DashedCircle className={'drop'+index} state={state}/>}
                        {number.index===1 && result[index] && <CircleButton className={'drop'+index} isActive={number.secondNumber}>
                             {number.secondNumber}
                         </CircleButton>}
                         {number.index!==1 && <div className={`${result[index] ? 'opacity-50' : 'opacity-100'}`}>{number.secondNumber}</div>}
 
                        <div className={`${result[index] ? 'opacity-50' : 'opacity-100'}`}>=</div>
 
                        {number.index===2 && !result[index] && <DashedCircle className={'drop'+index} state={state}/>}
                        {number.index===2 && result[index] && <CircleButton className={'drop'+index} isActive={number.thirdNumber}>
                             {number.thirdNumber}
                         </CircleButton>}
                         {number.index!==2 && <div className={`${result[index] ? 'opacity-50' : 'opacity-100'}`}>{number.thirdNumber}</div>}
                     </div>
                    )
                 })
               }
            </div>
            <div className={`w-[3px] xl:w-[6px] ${state ? 'xl:w-[288px] md:w-[4px] md:h-[203px] lg:w-[5px] lg:h-[257px] xl:h-[350px]' : 'xl:w-[341px] md:w-[5px] md:h-[250px] lg:w-[6px] lg:h-[350px] xl:h-[380px]'} h-[180px] bg-[url('/backGrounds/dashborder.svg')] bg-cover`} />
            <div className={`h-full w-[211px] ${state ? 'xl:w-[381px] md:w-[217px] md:px-[24px] lg:px-[35px] xl:px-[38px] md:py-[17px] lg:text-[36px] lg:w-[292px] xl:text-[46px]' : 'xl:w-[451px] xl:text-[60px] md:w-[285px] md:text-[32px] md:px-[34px] xl:px-[54px] lg:px-[27px] md:py-[19px] lg:text-[46px] lg:w-[358px]'} px-[22px] py-[12px] flex flex-col justify-between text-[24px] text-[#FFF] font-bigfatScript`}>
               {
                 answers.slice(3).map((number, index)=>{
                    return (
                        <div key={Math.random()} className={`w-full h-[39px] ${state ? 'lg:h-[50px] xl:h-[70px]' : 'xl:h-[89px] md:h-[49px] lg:h-[69px]'} flex items-center justify-between`}>
                        {number.index===0 && !result[index+3] && <DashedCircle className={'drop'+(index+3)} state={state}/>}
                        {number.index===0 && result[index+3] && <CircleButton className={'drop'+(index+3)} isActive={number.firstNumber}>
                             {number.firstNumber}
                         </CircleButton>}
                         {number.index!==0 && <div className={`${result[index+3] ? 'opacity-50' : 'opacity-100'}`}>{number.firstNumber}</div>}
 
                        <div className={`${result[index+3] ? 'opacity-50' : 'opacity-100'}`}>+</div>
 
 
                        {number.index===1 && !result[index+3] && <DashedCircle className={'drop'+(index+3)} state={state}/>}
                        {number.index===1 && result[index+3] && <CircleButton className={'drop'+(index+3)} isActive={number.secondNumber}>
                             {number.secondNumber}
                         </CircleButton>}
                         {number.index!==1 && <div className={`${result[index+3] ? 'opacity-50' : 'opacity-100'}`}>{number.secondNumber}</div>}
 
                        <div className={`${result[index+3] ? 'opacity-50' : 'opacity-100'}`}>=</div>
 
                        {number.index===2 && !result[index+3] && <DashedCircle className={'drop'+(index+3)} state={state}/>}
                        {number.index===2 && result[index+3] && <CircleButton className={'drop'+(index+3)} isActive={number.thirdNumber}>
                             {number.thirdNumber}
                         </CircleButton>}
                         {number.index!==2 && <div className={`${result[index+3] ? 'opacity-50' : 'opacity-100'}`}>{number.thirdNumber}</div>}
                       </div>
                    )
                 })
               }
            </div>

        </div>
    )
}