import React from 'react';
import {motion} from 'framer-motion';

export const BgImage = () => {
    return (
        <motion.svg className={`absolute w-[100%]`}
           width={window.innerWidth >=1595? window.innerWidth : "1592"} height="132" fill="none" xmlns="http://www.w3.org/2000/svg"
           viewBox={window.innerWidth >=1595? window.innerWidth : "0 0 1592 127"}>
            <rect width={window.innerWidth >=1595 ? `${window.innerWidth}` : "1592"} height="132" fill=""/>
            <motion.g 
               //animate={{x: [0, -10, 10, 0], y:[0, 10, 5, 0]}} transition={{duration: 10, type: 'tween', repeat: Infinity, ease: "linear"}}
            >
               <path d="M629 112H694C694 112 691.238 95.2759 677.262 105.959C677.262 105.959 677.751 93.8722 667.35 93.0299C656.949 92.3263 655.81 104.271 655.81 104.271C655.81 104.271 653.213 98.7906 647.037 99.4942C640.86 100.194 641.024 107.785 641.024 107.785C641.024 107.785 639.561 105.536 635.661 106.659C629.81 108.347 629 112 629 112Z" fill="#F6CDB8" fillOpacity="0.51"/>
            </motion.g>
            <motion.g 
              //animate={{x: [0, 10, -15, 0], y:[0, 10, 10, 0]}} transition={{duration: 10, type: 'tween', repeat: Infinity, ease: "linear"}}
            >
              <path d="M1180 91H1245C1245 91 1242.24 75.1561 1228.26 85.2765C1228.26 85.2765 1228.75 73.8263 1218.35 73.0283C1207.95 72.3617 1206.81 83.6773 1206.81 83.6773C1206.81 83.6773 1204.21 78.4858 1198.04 79.1524C1191.86 79.8157 1192.02 87.0071 1192.02 87.0071C1192.02 87.0071 1190.56 84.8759 1186.66 85.9398C1180.81 87.539 1180 91 1180 91Z" fill="#F6CDB8" fillOpacity="0.51"/>
            </motion.g>
            <motion.g 
              //animate={{x: [-1500, -1000, -500, 100], y:[0, -45, 0, -45]}} transition={{duration: 90, type: 'tween', repeat: Infinity, ease: "linear"}}
            >
              <path d="M1516 127H1581C1581 127 1578.24 111.156 1564.26 121.277C1564.26 121.277 1564.75 109.826 1554.35 109.028C1543.95 108.362 1542.81 119.677 1542.81 119.677C1542.81 119.677 1540.21 114.486 1534.04 115.152C1527.86 115.816 1528.02 123.007 1528.02 123.007C1528.02 123.007 1526.56 120.876 1522.66 121.94C1516.81 123.539 1516 127 1516 127Z" fill="#F6CDB8" fillOpacity="0.72"/>
            </motion.g>
            <motion.g 
              //animate={{x: [0, -5, 5, 0], y:[0, 10, 5, 0]}} transition={{duration: 5, type: 'tween', repeat: Infinity, ease: "linear"}}
            >
              <path d="M917 59H982C982 59 979.238 43.1561 965.262 53.2765C965.262 53.2765 965.751 41.8263 955.35 41.0283C944.949 40.3617 943.81 51.6773 943.81 51.6773C943.81 51.6773 941.213 46.4858 935.037 47.1524C928.86 47.8157 929.024 55.0071 929.024 55.0071C929.024 55.0071 927.561 52.8759 923.661 53.9398C917.81 55.539 917 59 917 59Z" fill="#C9B8C3" fillOpacity="0.67"/>
            </motion.g>
            <motion.g 
              //animate={{x: [0, -500, -1000, -1500], y:[0, 35, 0, 35]}} transition={{duration: 100, type: 'tween', repeat: Infinity, ease: "linear"}}
            >
              <path d="M1451 33H1516C1516 33 1513.24 17.1561 1499.26 27.2765C1499.26 27.2765 1499.75 15.8263 1489.35 15.0283C1478.95 14.3617 1477.81 25.6773 1477.81 25.6773C1477.81 25.6773 1475.21 20.4858 1469.04 21.1524C1462.86 21.8157 1463.02 29.0071 1463.02 29.0071C1463.02 29.0071 1461.56 26.8759 1457.66 27.9398C1451.81 29.539 1451 33 1451 33Z" fill="#C9B8C3" fillOpacity="0.67"/>
            </motion.g>
            <motion.g 
              //animate={{x: [0, 15, -15, 0], y:[0, 10, 5, 0]}}  transition={{duration: 10, type: 'tween', repeat: Infinity, ease: "linear"}}
            >
              <path d="M75 102H123C123 102 120.961 90.5572 110.64 97.8664C110.64 97.8664 111.001 89.5967 103.32 89.0205C95.6391 88.539 94.7985 96.7114 94.7985 96.7114C94.7985 96.7114 92.8806 92.962 88.3194 93.4434C83.7582 93.9224 83.8796 99.1162 83.8796 99.1162C83.8796 99.1162 82.7992 97.577 79.9193 98.3454C75.5978 99.5004 75 102 75 102Z" fill="#C9B8C3" fillOpacity="0.69"/>
            </motion.g>
            <motion.g 
               //animate={{x: [0, -15, 15, 0], y:[0, 10, 1, 0]}} transition={{duration: 10, type: 'tween', repeat: Infinity, ease: "linear"}}
            >
              <path d="M330 39H384C384 39 381.706 25.7968 370.095 34.2305C370.095 34.2305 370.501 24.6885 361.86 24.0236C353.219 23.4681 352.273 32.8978 352.273 32.8978C352.273 32.8978 350.116 28.5715 344.984 29.127C339.853 29.6797 339.99 35.6725 339.99 35.6725C339.99 35.6725 338.774 33.8966 335.534 34.7831C330.673 36.1158 330 39 330 39Z" fill="#F6CDB8" fillOpacity="0.51"/>
            </motion.g>
        </motion.svg>
    )
}
