import React from 'react';
import { useEffect } from 'react';
import {useParams} from "react-router-dom";
import {
    IconNavbarButtonBg,
    IconRotate
} from "../elements/Icon";
import {useTranslation} from 'react-i18next';
import { getDate } from '../../hooks/useCurrentDate';
import { scoresPopupDisplayDuration } from "../../config";
import { useSelector } from 'react-redux';


export const Score = ({handleOpenScore, selectedGame, handleRefresh, level, subscription}) => {
   const {name, gameName} = useParams();
   const {t} = useTranslation();
   const storageData = localStorage.getItem(`${name+'_'+gameName+'_'+level}`) ? JSON.parse(localStorage.getItem(`${name+'_'+gameName+'_'+level}`)) : "";

   const score = useSelector((state)=>state.games.totalScore);

   const getPrize = (storageData) => {
    if (storageData) {
      const storageDataToSec = storageData.min*60+storageData.sec;
      const firstToSec = selectedGame.timer[1].min*60+selectedGame.timer[1].sec;
      const secondToSec = selectedGame.timer[2].min*60+selectedGame.timer[2].sec;
      const thirdToSec = selectedGame.timer[3].min*60+selectedGame.timer[3].sec;

      if (storageDataToSec<=firstToSec) {
        return 1;
      } else if (storageDataToSec>firstToSec && storageDataToSec<=secondToSec) {
          return 2;
      } else if (storageDataToSec>secondToSec && storageDataToSec<=thirdToSec) {
          return 3;
      } else {
          return 4;
      }
    } else {
      return 0;
    }
    
  }

   useEffect(()=>{
    if (!selectedGame?.timer || (selectedGame?.name === 'rapid_math') || (selectedGame?.name === 'rapid_colors') || (selectedGame?.name === 'rapid_shapes') || (selectedGame?.name === 'rapid_numbers') || (selectedGame?.name==='memory' && level==='beginner') || (selectedGame?.name==='classic' && level==='beginner')) {
        setTimeout(()=>handleOpenScore(), scoresPopupDisplayDuration*1000);
    }
   });

   const storage = localStorage.getItem('scoreStorage');
   let totalScore = 0;
   if (storage) {
       JSON.parse(storage).forEach((item)=> {
           if (item.date === getDate()) {
            totalScore = item.score
           }
       })
   }
   return(
      <div onClick={() => selectedGame?.timer && handleOpenScore()} className="h-screen flex justify-center items-center w-full bg-bgBlack/49 absolute z-[100]">
          <div className="flex justify-center items-end w-[304px] h-[325px] md:w-[447px] md:h-[491px] lg:w-[512px] lg:h-[548px] xl:w-[753px] xl:h-[806px] bg-[url('/backGrounds/spinner.svg')] bg-[length:304px_325px] md:bg-[length:447px_491px] lg:bg-[length:512px_548px] xl:bg-[length:753px_806px]">
             <div className="w-[131px] h-[181px] md:w-[175px] md:h-[276px] lg:w-[206px] lg:h-[309px] xl:w-[286px] xl:h-[476px] items-center">
                    <div className="w-full text-center text-[14px] leading-[18px] md:leading-[20px] md:text-[20px] lg:text-[24px] xl:text-[34px] text-[#FFF] capitalize font-comingSansFreeTrial lg:leading-[26px] xl:leading-[64px]">
                      {t(`${name}`)}
                    </div>
                
                    <div className="w-full truncate md:mt-[9px] lg:mt-[5px] text-center text-[10px] md:text-[13px] lg:text-[14px] xl:text-[20px] text-[#879D07] capitalize font-commisioner font-bold leading-[15px] lg:leading-[17px]">
                      {t(`${gameName && +gameName[gameName.length-1] ?  t(`${gameName.split("_")[0]}`)+" "+gameName.split("_")[1] : t(`${gameName}`)}`)}
                    </div>
                    {selectedGame?.timer && selectedGame?.name!=='rapid_math' && selectedGame?.timer && selectedGame?.name!== 'rapid_colors' && selectedGame?.name!== 'rapid_shapes' &&  selectedGame?.name!== 'rapid_numbers' && !(selectedGame?.name==='memory' && level==='beginner') && !(selectedGame?.name==='classic' && level==='beginner') && <div className="w-full mt-[15px] md:mt-[30px] lg:mt-[34px] xl:mt-[60px] flex justify-center">
                      <img
                        className="w-[40px] h-[36px] md:w-[51px] md:h-[46px] lg:w-[71px] lg:h-[62px] xl:w-[135px] xl:h-[110px]"
                        src = {`/backGrounds/${getPrize(storageData)}.svg`}
                      />
                    </div>}
                    <div className={`${getPrize(storageData) ? 'mt-[0] xl:leading-[30px]' : 'mt-[29px] md:mt-[43px] lg:mt-[53px] xl:mt-[70px] xl:leading-[50px]'} w-full  text-center text-[10px] md:text-[14px] lg:text-[16px] xl:text-[24px] text-[#C0BAA3] capitalize font-commisioner font-bold leading-[14px]`}>
                       {t("score")}
                    </div>
                    <div className={`text-[#FFF3E1] ${getPrize(storageData) ? 'mt-[6px] md:mt-[18px] xl:mt-[16px]' : 'mt-[16px] md:mt-[31px] lg:mt-[35px] xl:mt-[38px]'} flex items-center justify-center font-extrabold text-[14px] md:text-[18px] lg:text-[20px] xl:text-[30px] font-commisioner w-full h-[36px] md:h-[49px] lg:h-[57px] xl:h-[79px] rounded-[15px] md:rounded-[20px] xl:rounded-[30px] bg-[linear-gradient(0deg,_#7743BA_0%,_#9D6497_100%)]`}>
                        {subscription ? score : totalScore}
                    </div>
                    <div className={`text-[#4B96C9] ${getPrize(storageData) ? 'mt-[8px] md:mt-[15px] lg:mt-[10px] xl:mt-[15px]' : 'mt-[15px] md:mt-[24px]  xl:mt-[36px] lg:mt-[15px]'} w-full flex items-center justify-center font-extrabold text-[10px] md:text-[14px] lg:text-[20px] xl:text-[30px] font-commisioner lg:leading-[34px]`}>
                        + {localStorage.getItem(`${name+'_'+gameName+'_'+level+'_score'}`)}
                    </div>
                    {selectedGame?.timer && selectedGame?.name!=='rapid_math' && selectedGame?.timer && selectedGame?.name!=='rapid_colors' && selectedGame?.name!=='rapid_shapes' && selectedGame?.name!=='rapid_numbers' && !(selectedGame?.name==='memory' && level==='beginner') && !(selectedGame?.name==='classic' && level==='beginner') && <button onClick={(e)=>{
                        e.stopPropagation();
                        e.preventDefault();
                        handleRefresh();
                    }} className={`${getPrize(storageData) ? 'bottom-[10px] md:bottom-[8px] lg:bottom-[16px] xl:bottom-[20px]' : 'bottom-[10px] lg:bottom-[0px]'} group relative  left-[15px] md:left-[30px] xl:bottom-[15px] float-right xl:left-[55px]`}>
                        <div
                            className="absolute left-[50%] top-[47%] z-10 w-[9px] md:w-[13px] lg:w-[15px] xl:w-[28px] -translate-x-1/2 -translate-y-1/2 ">
                            <IconRotate className="w-full"/>
                        </div>
                        <IconNavbarButtonBg className="aspect-square w-[16px] md:w-[24px] lg:w-[27px] xl:w-[42px] text-black "/>
                    </button>}
             </div>
          </div>
      </div>
   )
}