export const getBottomGapX = (state) => {
    if (window.innerWidth <  1024) {
      return (425-3*39)/4;
    }
    if (window.innerWidth>=1024 && window.innerWidth<1440) {
      if (state) {
        return (439-3*39)/4;
      } else {
        return (576-3*49)/4;
      } 
    }
    if (window.innerWidth>=1440 && window.innerWidth<1920) {
      if (state) {
        return (590-3*50)/4;
      } else {
        return (723-3*69)/4;
      } 
    }
    if (window.innerWidth>=1920) {
      if (state) {
        return (768-3*70)/4;
      } else {
        return (909-3*89)/4;
      } 
    }
}

export const getCoordinates = () => {
    const elem = document.querySelector('.adding');
    const answer = document.querySelector('.answer');
    const x1 = elem.getBoundingClientRect().x;
    const y1 = elem.getBoundingClientRect().y;
    const x2 = answer.getBoundingClientRect().x;
    const y2 = answer.getBoundingClientRect().y;
    const xDistance = x1 - x2;
    const yDistance = y1 - y2;
    return [
              xDistance-(answer.getBoundingClientRect().width-elem.getBoundingClientRect().width)/2, 
              yDistance-(answer.getBoundingClientRect().height-elem.getBoundingClientRect().height)/2
           ];
}

export const TopPartNumbers = (level) => {
    const max1 = level === "beginner" ? 10 : level === "advanced" ? 90 : 50;
    const min1 = level === "beginner" ? 5 : level === "advanced" ? 45 : 25;
    
    const firstNumber = Math.floor(Math.random() * (max1 - min1 + 1) + min1);
    const max2 = firstNumber-1;
    const min2 = 1
    const secondNumber = Math.floor(Math.random() * (max2 - min2 + 1) + min2);
    return [firstNumber, secondNumber];
}

export const BottomPartNumbers = (level) => {
    const max1 = level === "beginner" ? 10 : level === "advanced" ? 90 : 50;
    const min1 = level === "beginner" ? 5 : level === "advanced" ? 45 : 25;
    
    const firstNumber = Math.floor(Math.random() * (max1 - min1 + 1) + min1);
    if (level === "advanced") {
        const max2 = firstNumber-1;
        const min2 = 1
        const secondNumber = Math.floor(Math.random() * (max2 - min2 + 1) + min2);
        return [firstNumber, secondNumber];
    } else {
        return [firstNumber];
    }

}

export const getAnswer = (arr1, arr2, action) => {
    const sumTop = arr1.reduce((aggr, item)=>{
          if (action === "+") {
            aggr+=item;
          } else {
            aggr-=item;
          }
          return aggr;
    })
    const sumBottom = arr2.reduce((aggr, item)=>{
        if (action === "+") {
            aggr+=item;
          } else {
            aggr-=item;
          }
        return aggr;
    })


    if (sumTop > sumBottom) {
        return ">"
    }

    if (sumTop < sumBottom) {
        return "<"
    }

    if (sumTop === sumBottom) {
        return "="
    }
}

export const getAnswers = () => {
    return [">", "<", "="].sort(()=>Math.random()-0.5)
}

export const getAction = () => {
    const arr = ["+", "-"];
    return arr[Math.floor(Math.random()*arr.length)];
}