import { CircleButton } from '../../../../components/elements/CircleButton';
import { DragAndDrop } from "../../../../components/global/DragAndDrop.jsx";
import {
    animationDurationToShowQuestion,
} from "../../../../config";
import {useState, useEffect, useRef} from "react";
import { motion } from "framer-motion";
import { calcGapY } from '../OddOrEven.styles';
import { getCircleComponent } from "../../../../hooks/useCircleButtonColor";

let showHint;

export default function RightControl ({state, answers, refresh, odds, evens, hint}) {
    const colors = useRef(getCircleComponent(answers));
    const [index, setIndex] = useState('');
    const [animate, setAnimate] = useState(false);
    const [start, setStart] = useState({x:0, y:0});   
    const [end, setEnd] = useState({x:0, y:0});
    const [time, setTime] = useState(0);

    const [update, setUpdate] = useState(false);

    useEffect(()=>{
        if (!animate) return;
        setAnimate(false);
        setIndex('');
        setTime(0);
        colors.current = getCircleComponent(answers);
    }, [answers]);

    useEffect(()=>{
        showHint = true;
        const start = document.querySelector('.answer');
        const end = document.querySelector(answers[0]%2===0 ? ".dropEven": ".dropOdd");
        if (!start || !end) {
            setUpdate(!update);
            return;
        }
        const startCoord = start.getBoundingClientRect();
        const endCoord = end.getBoundingClientRect();
        setStart({x:startCoord.x, y:startCoord.y});
        setEnd({x:endCoord.x+endCoord.width/2, y:endCoord.y});
        setTime(0);
    }, [odds, evens, update]);

    useEffect(()=>{
        if (!hint) {
            setTime(0);
            return;
        }
        if (time===10) return;
        const id = setInterval(()=>setTime(prev=>prev+1), 1000);
        return ()=>clearInterval(id);
    }, [time, hint]);

    return (
        <div
            className={`select-none ${state ? "w-[67px] md:w-[73px] lg:w-[93px] xl:w-[114px]" : "w-[67px] md:w-[90px] lg:w-[133px] xl:w-[136px]"} bg-white flex flex-col  items-center rounded-[20px] border-4 border-[#2F9EBA] h-full font-bigfatScript text-[30px]`}>
            <motion.div className="h-full flex flex-col">
                {answers.map((item, i) => {
                    if (i===index) {
                        return (
                            <DragAndDrop 
                              key={i} 
                              enableClick = {false}
                              item = {item}
                              cmp={'oddoreven'} 
                              drop={item%2 ? 'dropOdd' : 'dropEven'}
                              refresh={(type) => {
                                showHint = false;
                                setIndex('');
                                refresh(item, type);
                              }}
                            >
                                <motion.div
                                    style={{marginTop: calcGapY(state)+'px'}}
                                    key={i}
                                    className={`item relative flex justify-center items-center text-white cursor-pointer`}
                                >
                                    <CircleButton key={i} isActive={item} color={colors.current[i]}>
                                    {item}
                                    </CircleButton>
                                </motion.div>
                            </DragAndDrop>
                        )
                    } else {
                        return (
                            <motion.div
                                style={{marginTop: calcGapY(state)+'px'}}
                                animate={{y: animate ? [0, 0] : [(index+1)*100, 0]}}
                                transition={{duration: animationDurationToShowQuestion, type: "tween", ease: "linear"}}
                                key={i}
                                className={`${i===0 && 'answer'} item relative flex justify-center items-center text-white cursor-pointer`}
                                onMouseEnter={(e)=>{
                                    setIndex(i);
                                    setAnimate(true);
                                }}
                                onTouchStart={(e)=>{
                                    setIndex(i);
                                    setAnimate(true);
                                }}
                            >
                                <CircleButton key={i} isActive={item} color={colors.current[i]}>
                                   {item}
                                </CircleButton>
                                {hint && showHint && time===10 && i===0 && <motion.div
                                        animate={{y: [0, end.y-start.y], x: [0, end.x-start.x]}}
                                        transition={{duration: answers[0]%2 ? 2 : 1  , type: "tween", ease: "linear", repeat: Infinity}}
                                        className="bg-[url('/backGrounds/hint.svg')] bg-[position:50%_50%] bg-contain bg-no-repeat absolute w-[50px] h-[50px] top-[50%] z-[1000]"></motion.div>}
                           </motion.div>
                        )
                    }

                })}
            </motion.div>
        </div>
    )
}