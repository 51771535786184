
import { NavLink, useNavigate } from "react-router-dom";
import {useTranslation} from "react-i18next";
import { useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import * as amplitude from '@amplitude/analytics-browser';

export const SubscriptionPurchase = (
        {
            calcSave, 
            products, 
            getProduct, 
            handleSubscription, 
            handleType, 
            screenSize, 
            calculateFontSize, 
            type, 
            subscriptionType
        }
    ) => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const textRef = useRef(null);
    const ballonRef = useRef(null);
    const buttonRef = useRef(null);

    const user = useSelector(state=>state.user);

    useEffect(()=>{
        if (subscriptionType) return;
        textRef.current.style.fontSize = calculateFontSize(ballonRef.current.clientWidth-6, textRef.current.clientHeight, textRef.current.textContent)+'px';
        buttonRef.current.style.fontSize = calculateFontSize(buttonRef.current.clientWidth-40, buttonRef.current.clientHeight/2, buttonRef.current.textContent)+'px';
     }, [screenSize]);

     useEffect(()=>{
        amplitude.track(`subscriptionPopUp`);
     }, []);

    return(
        <div onClick={(e)=>e.stopPropagation()} className="relative w-[640px] h-[302px] rounded-[13px] md:w-[652px] md:h-[428px] lg:w-[928px] lg:h-[452px] xl:w-[1142px] xl:h-[576px] md:rounded-[16px]  bg-[url('/backGrounds/subscription_top.svg'),_url('/backGrounds/subscription_main.svg')] bg-no-repeat bg-[position:0%_-60%,60%_50%] md:bg-[position:0%_-20%,60%_50%] bg-[length:100%_220px,cover] md:bg-[length:100%_240px,cover] lg:bg-[length:100%_263px,cover] xl:bg-[length:100%_303px,cover] xl:bg-[position:0%_-5%,60%_50%]">
               <NavLink to="/refund_policy">
                    <div className="underline font-medium absolute bottom-[5px] left-[10px] lg:bottom-[10px] xl:left-[16px] text-[9px] md:text-[13px] lg:text-[14px] xl:text-[16px] font-commisioner text-[#5785CB]">Refund Policy</div>
                </NavLink>
               <button onClick={handleSubscription} className="absolute w-[25px] h-[25px] md:w-[37px] md:h-[37px] xl:w-[46px] xl:h-[46px] bg-[url('/backGrounds/subscription_close_btn.svg')] bg-cover rounded-[50%] top-[20px] left-[20px]" />
               <img src="/backGrounds/subscription/sky.svg" className="absolute w-[71px] h-[57px] hidden xl:block right-[20px] top-[20px]"/>
               <div>
                   <div className="flex font-fredokaOne text-[#FFFFFF] text-[16px] md:text-[24px] items-center xl:text-[32px] mt-[10px] md:mt-[20px] justify-center">
                       <img src="/backGrounds/100.svg" className="w-[72px] h-[20px] md:w-[113px] md:h-[31px] xl:w-[168px] xl:h-[45px]"/>
                       <p>Math Games for Kids</p>
                   </div>
                   <div className="text-[#ffffff] font-commisioner text-[14px] md:text-[20px] flex justify-center mt-[5px] xl:text-[20px]">{t('subscription_info_short')}</div>
               </div>
               <div className="mx-auto w-[494px] h-[77px] md:w-[581px] md:h-[95px] lg:w-[716px] lg:h-[110px] xl:w-[964px] xl:h-[150px] flex justify-between mt-[15px] md:mt-[25px]">
                  <img src="/backGrounds/subscription/image_1.svg" className="w-[144px] md:w-[176px] lg:w-[204px] xl:w-[278px] h-full"/>
                  <img src="/backGrounds/subscription/image_2.svg" className="w-[144px] md:w-[176px] lg:w-[204px] xl:w-[278px] h-full"/>
                  <img src="/backGrounds/subscription/image_3.svg" className="w-[144px] md:w-[176px] lg:w-[204px] xl:w-[278px] h-full"/>
               </div>
               <div  className="flex mx-auto justify-between w-[573px] md:w-[604px] lg:w-[861px] xl:w-[1042px] mt-[15px] md:mt-[30px] relative lg:mt-[27px] ">
                    <div
                      ref={ballonRef}
                      className="text-[#FFFFFF]  text-center z-[10] flex justify-center py-[15px] xl:py-[23px] font-fredokaOne font-bold absolute w-[47px] h-[70px] md:w-[50px] md:h-[84px] lg:w-[56.11px] lg:h-[94.97px] xl:w-[77px] xl:h-[129px] -translate-y-[35%] left-[44%] md:left-[45%] lg:left-[46.5%] md:-translate-y-[50%] lg:-translate-y-[40%] xl:-translate-y-[35%] md:-translate-x-[20%] bg-cover bg-[url('/backGrounds/subscription/ballon.svg')]" 
                    >
                      <pre  ref={textRef} className="-rotate-[25deg] h-[20px] lg:h-[23px] xl:h-[30px]">
                        {t('save')}
                        <br />{products.length && calcSave(products)}%
                      </pre>
                      
                      
                    </div>
                   <div 
                        onClick={()=>{
                            handleType("year");
                            amplitude.track(`subscriptionYearly`);
                        }} 
                        className="cursor-pointer relative w-[270px] h-[58px] md:w-[290px] px-[9px] md:h-[71px] lg:w-[414px] lg:h-[80px] xl:w-[496px] xl:h-[121px] border-[2px] md:border-[3px] border-[#419F15] rounded-[10px] flex items-center justify-between"
                    >
                        <div className="flex items-center absolute">
                            <div className="xl:w-[40px] xl:h-[40px] cursor-pointer w-[20.31px] h-[20.31px] md:w-[26px] md:h-[26px] lg:w-[32px] lg:h-[32px] border-[2px] border-[#EF8615] rounded-[50%] flex items-center justify-center">
                               {type==="year" && <div className="xl:w-[20px] xl:h-[20px] w-[10.56px] h-[10.56px] md:w-[13.41px] md:h-[13.41px] lg:w-[16.66px] lg:h-[16.66px] rounded-[50%] bg-[#EF8615]"/>}
                            </div>
                            <p className="font-fredokaOne text-[#757575] text-[16px] md:text-[20px] xl:text-[28px] pl-[5px] lg:pl-[10px] xl:pl-[16px]">{t('yearly')}</p>
                        </div>
                        <div className="h-[25px] absolute right-[9px]">
                            <p className="font-fredokaOne text-[#616161] text-[16px] md:text-[20px] xl:text-[28px] text-right">
                                &#36; {products.length && getProduct(products, "year").unit_price.amount/100}
                            </p>
                            <p className="text-[11px] md:text-[12px] xl:text-[20px] font-commisioner text-[#757575] mt-[-2px] font-bold">
                                {products.length && t('12months_as').replace('x', `${'$'+(getProduct(products, "year").unit_price.amount/(12*100)).toFixed(2).replace('.', ',')}`)}
                            </p>
                        </div>
                   </div>
                   <div 
                        onClick={()=>{
                            handleType("month");
                            amplitude.track(`subscriptionMonthly`);
                        }} 
                        className="cursor-pointer w-[270px] h-[58px] md:w-[290px] px-[9px] md:h-[71px] border-[2px] md:border-[3px] lg:w-[414px] lg:h-[80px] xl:w-[496px] xl:h-[121px] border-[#419F15] rounded-[10px] flex items-center justify-between"
                    >
                        <div className="flex items-center">
                            <div className="cursor-pointer w-[20.31px] h-[20.31px] md:w-[26px] md:h-[26px] lg:w-[32px] lg:h-[32px] xl:w-[40px] xl:h-[40px] border-[2px] border-[#EF8615] rounded-[50%] flex items-center justify-center">
                               {type==="month" && <div className="w-[10.56px] h-[10.56px] md:w-[13.41px] md:h-[13.41px] lg:w-[16.66px] lg:h-[16.66px] xl:w-[20px] xl:h-[20px] rounded-[50%] bg-[#EF8615]"/>}
                            </div>
                            <p className="font-fredokaOne text-[#757575] text-[16px] md:text-[20px] pl-[5px] xl:text-[28px] lg:pl-[10px] xl:pl-[16px]">{t('monthly')}</p>
                        </div>
                        <div>
                            <p className="font-fredokaOne text-[#616161] text-[16px] md:text-[20px] xl:text-[28px] text-right">
                                &#36; {products.length && getProduct(products, "month").unit_price.amount/100}
                            </p>
                        </div>
                   </div>
               </div>
               <div
                onClick={()=> {
                    if (user.login) {
                        setTimeout(handleSubscription(), 1000);
                        Paddle.Checkout.open({
                            items: [    
                                {
                                   priceId: getProduct(products, type).id,
                                }
                            ],
                            customer: {
                                email: user.user.userEmail,
                            }
                        })
                    } else {
                        navigate('/registration');
                    }

                  }
                }
                  ref={buttonRef} className="xl:w-[420px] xl:h-[70px] cursor-pointer md:bg-[url('/backGrounds/subscription/stars.svg')] xl:bg-[length:19.81px_31px] bg-[position:99%_45%] xl:bg-[position:98%_30%] bg-no-repeat w-[190px] h-[42px] md:w-[280px] md:h-[52px] lg:w-[360px] lg:h-[65px] shadow-[0px_5px_10px_0px_#23232340] rounded-[10px] bg-[#419F15] mt-[10px] md:mt-[20px] lg:mt-[15px] xl:mt-[24px] mx-auto font-fredokaOne text-[#ffffff] flex items-center justify-center" >
                    {t('startFreeTrial')}
               </div>
               <div
                  onClick={()=> {
                    if (user.login) {
                        setTimeout(handleSubscription(), 1000);
                        Paddle.Checkout.open({
                            items: [    
                              {
                                  priceId: getProduct(products, type).id,
                                  quantity: null
                             }],
                            customer: {
                                  email: user.user.userEmail,
                            }
                          })
                    } else {
                        navigate('/registration')
                    }

                  }

                  }
                  className="cursor-pointer font-fredokaOne text-[9px] md:text-[14px] text-[#376DBE] w-full text-center mt-[5px] md:mt-[10px] xl:mt-[14px]"
                >
                    {t('restore_purchase')}
                </div>
            </div>
    )
}