
import { CircleButton } from '../../../../components/elements/CircleButton';
import { 
    animationDurationToShowQuestion,
} from "../../../../config";
import { motion } from 'framer-motion';
import { imageMarginLeft, imageMarginTop } from '../Multiplication.data';


export default function LeftPart ({state, firstNumber, secondNumber, result, image, answer, topValues}) {
    return (
        <div className={`flex items-center justify-center ${state ? "w-[344px] md:w-[350px] lg:w-[477px] xl:w-[630px]" : "w-[344px] md:w-[467px] lg:w-[566px] xl:w-[745px]"} h-full border-[3px] rounded-[16px] lg:rounded-[20px] border-[#85B054] bg-[#FFF]`}>
           <div className={`w-[286px] h-[226px] ${state ? 'md:w-[302px] md:h-[252px] lg:w-[392px] lg:h-[310px] xl:w-[522px] xl:h-[374px]' : 'md:w-[376px] md:h-[300px] lg:w-[470px] lg:h-[372px] xl:w-[626px] xl:h-[458px]'} flex flex-col justify-between`}>
              <div className={`w-full h-[39px] ${state ? 'lg:h-[50px] lg:text-[36px] xl:h-[70px] xl:text-[46px]' : 'md:h-[49px] md:text-[32px] lg:h-[69px] xl:h-[89px] lg:text-[46px] xl:text-[60px]'} flex justify-between items-center text-[24px] font-bigfatScript`}>
                 <div className={`w-[45px]  h-[37px] ${state ? 'lg:h-[44px]' : 'md:h-[44px] lg:h-[44px]'} flex items-center justify-center text-[#85B054]`}>{firstNumber}</div>
                 <div className={`w-[45px]  h-[37px] ${state ? 'lg:h-[44px]' : 'md:h-[44px] lg:h-[44px]'} flex items-center justify-center text-[#FFB43B]`}>x</div>
                 <div className={`w-[45px]  h-[37px] ${state ? 'lg:h-[44px]' : 'md:h-[44px] lg:h-[44px]'} flex items-center justify-center text-[#85B054]`}>{secondNumber}</div>
                 <div className={`w-[45px]  h-[37px] ${state ? 'lg:h-[44px]' : 'md:h-[44px] lg:h-[44px]'} flex items-center justify-center text-[#FFB43B]`}>=</div>
                 <CircleButton className="multiplicationresult" isActive={isNaN(result) ? false : result}>
                     <div className="multiplication">
                        {result}
                    </div>
                 </CircleButton>
              </div>
              <div className={`w-full h-[167px] ${state ? 'md:h-[195px] lg:h-[230px] xl:h-[264px]': 'md:h-[221px] lg:h-[263px] xl:h-[326px]'} rounded-[20px] bg-[#C4A8E3]`}>
                    {answer>2 && <div
                        style={{marginTop: imageMarginTop(state, answer)+'px'}}
                        className={`w-full overflow-hidden flex h-[34.31px] ${state? 'md:h-[40.16px] lg:h-[50px] xl:h-[59px]' : 'md:h-[46px] lg:h-[55px] xl:h-[70px]'}`}>
                            {
                                [...new Array(Math.floor(answer/2))].map((item, i)=>{
                                    return (
                                    <motion.div
                                        animate={{y: [topValues.shift() || 0, 0]}}
                                        transition={{duration: animationDurationToShowQuestion, type: "tween", ease: "linear"}} 
                                        key={i}
                                        style={{marginLeft: imageMarginLeft(state, Math.floor(answer/2))+'px', backgroundImage: `url(/backGrounds/counting/${image}.svg)`, backgroundSize: "100% 100%", backgroundRepeat: "no-repeat"}}
                                        className={`${state ? 'md:w-[57.97px] lg:w-[72px] xl:w-[85px]' : 'md:w-[67px] lg:w-[80px] xl:w-[102px]'} w-[49.53px] h-full`}
                                    />
                                    )
                                })
                            }
                    </div>}
                    {<div
                       style={{marginTop:imageMarginTop(state, answer)+'px'}}
                       className={`w-full overflow-hidden flex h-[34.31px] ${state? 'md:h-[40.16px] lg:h-[50px] xl:h-[59px]' : 'md:h-[46px] lg:h-[55px] xl:h-[70px]'}`}>
                            {
                                [...new Array(Math.ceil(answer>2 ? answer/2 : answer))].map((item, i)=>{
                                    return (
                                    <motion.div
                                        animate={{y: [topValues.shift() || 0, 0]}}
                                        transition={{duration: animationDurationToShowQuestion, type: "tween", ease: "linear"}} 
                                        key={i}
                                        style={{marginLeft: imageMarginLeft(state, Math.ceil(answer>2 ? answer/2 : answer))+'px', backgroundImage: `url(/backGrounds/counting/${image}.svg)`, backgroundSize: "100% 100%", backgroundRepeat: "no-repeat"}}
                                        className={`${state ? 'md:w-[57.97px] lg:w-[72px] xl:w-[85px]' : 'md:w-[67px] lg:w-[80px] xl:w-[102px]'} w-[49.53px] h-full`}
                                    />
                                    )
                                })
                            }
                    </div>}
              </div>
           </div>
        </div>
    )
}