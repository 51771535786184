import { RightPart } from "./components/RightPart";
import { LeftPart } from "./components/LeftPart";
import React, { useState, useRef, useEffect } from 'react';
import { 
    animationDurationToShowscoresPopup,
    animationDurationToMoveCorrectAnswer
} from "../../../config";
import { useParams } from 'react-router-dom';
import { updateStorage } from "../../../hooks/useStorage";
import { updateGameStorage } from "../../../hooks/useGameStorage";
import { getNumber, getAnswer, generateImages } from "./Compare.data";
import * as amplitude from '@amplitude/analytics-browser';
import { useUpdateReduxState } from "../../../hooks/useUpdateReduxState";
import { gameId } from "../../../hooks/getGameId";

export const Compare = ({state, handleOpenScore, count, handleUpdateProgress, answerSound, point, hint, subscription}) => {
    const gameStore = JSON.parse(localStorage.getItem('playedGames'));
    const {name, gameName} = useParams();
    const updateState = useUpdateReduxState();
    const [firstNumber, setFirstNumber] = useState(getNumber());
    const [secondNumber, setSecondNumber] = useState(getNumber());
    const answer = useRef(getAnswer(firstNumber, secondNumber));
    const [result, setResult] = useState();
    const images = useRef(generateImages());
    const timeduration = [100];
    const score = useRef(0);
    const gameCount = useRef(0);

    while(timeduration.length <= (firstNumber+secondNumber)) {
        timeduration.push(100+timeduration[timeduration.length-1])
      }

    useEffect(()=>{
        amplitude.track(`${name+"_"+gameName+"_start"}`)
    }, []);
    const checkAnswer = (event, type, val) => {
        if (event==='mouseup') return;
        score.current += point;
        if (!subscription) {
          updateStorage(point);
        }
        !type && setResult(val);
        if (gameCount.current === count-1 || (gameStore[name] && gameStore[name].find(item=>item.name===gameName)?.all === count-1)) {
            if (subscription) {
              updateState({
                userId: localStorage.getItem('user'),
                profileId: JSON.parse(localStorage.getItem('profile'))?._id,
                game: name,
                score: score.current,
                id: gameId(name, gameName),
                collection: name,
              })
            } else {
              handleUpdateProgress();
              updateGameStorage(name, gameName, point, true);
            }
            setTimeout(()=>handleOpenScore(), animationDurationToShowscoresPopup*1000);
            localStorage.setItem(`${name+'_'+gameName+'_'+'_score'}`, score.current);
          } else {
            if (!subscription) {
              handleUpdateProgress();
              updateGameStorage(name, gameName, point, false);
            }
            
            setTimeout(()=>{ 
              gameCount.current +=1;
              setResult('');
              const firstNumber = getNumber();
              const secondNumber = getNumber();
              setFirstNumber(firstNumber);
              setSecondNumber(secondNumber);
              answer.current = getAnswer(firstNumber, secondNumber);
              images.current = generateImages();
            }, animationDurationToMoveCorrectAnswer*1000);
          }
    }
    
    return (
        <div className="w-full h-full flex justify-between relative z-30">
            <LeftPart 
               state={state}
               result={result}
               firstNumber={firstNumber}
               secondNumber={secondNumber}
               timeduration={timeduration}
               images={images.current}

            />
            <RightPart 
               state={state}
               hint={hint}
               answer={answer}
               checkAnswer={checkAnswer}
               answerSound={answerSound}
               firstNumber={firstNumber}
               secondNumber={secondNumber}

            />
        </div>
    )
}