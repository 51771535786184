export const calcGapY = (state) => {
    if (window.innerWidth<1024) {
        return (260-39*3) / 4;
    }
    if (window.innerWidth>=1024 && window.innerWidth<1440) {
       if (state) {
          return (286-39*3) / 4;
       } else {
          return (354-49*3) / 4;
       }
    }
    if (window.innerWidth>=1440 && window.innerWidth<1920) {
        if (state) {
            return (364-50*3) / 4;
        } else {
            return (446-69*3) / 4;
        }
    }
    if (window.innerWidth>=1920) {
        if (state) {
            return (448-70*3) / 4;
        } else {
            return (538-89*3) / 4;
        }
    }
}

export const shapes = {
    triangle: {
        path: 'polygon(50% 0%, 0% 100%, 100% 100%)',
        color: "#FFB43B",
    },
    trapezoid: {
        path: 'polygon(20% 0%, 80% 0%, 100% 100%, 0% 100%)',
        color: "#32A3CE",
    },
    parallelogram: {
        path: 'polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%)',
        color: "#78A941",
    },
    rhombus: {
        path: 'polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%)',
        color: "#7F48C3", 
    },
    pentagon: {
        path: 'polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%)',
        color: "#32A3CE",
    },
    hexagon: {
        path: 'polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%)',
        color: "#D83E4F", 
    },
    heptagon: {
        path: 'polygon(50% 0%, 90% 20%, 100% 60%, 75% 100%, 25% 100%, 0% 60%, 10% 20%)',
        color: "#78A941",
    },
    octagen: {
        path:'polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%)',
        color: "#7F48C3", 
    },
    nonagon: {
        path: 'polygon(50% 0%, 83% 12%, 100% 43%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0% 43%, 17% 12%)',
        color: "#32A3CE",
    },
    decagon: {
        path: 'polygon(50% 0%, 80% 10%, 100% 35%, 100% 70%, 80% 90%, 50% 100%, 20% 90%, 0% 70%, 0% 35%, 20% 10%)',
        color: "#78A941",
    },
    bevel: {
        path: 'polygon(20% 0%, 80% 0%, 100% 20%, 100% 80%, 80% 100%, 20% 100%, 0% 80%, 0% 20%)',
        color: "#D83E4F", 
    }, 
    star: {
        path: 'polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%)',
        color: "#32A3CE",
    },
    circle: {
        path: '',
        color: "#78A941",
    },
    rectangle: {path: '', color: "#32A3CE",}
}

const shuffleArray = (arr) => {
    return arr.sort(() => Math.random()- 0.5);
    
}

export const getData = () => {
   const arr = new Set();
   const max = Object.keys(shapes).length-1;
   const min = 0;
   while(arr.size<3) {
       arr.add(Object.keys(shapes)[Math.floor(Math.random() * (max - min + 1) + min)]);
   }
   const res = Array.from(arr);
   return [
       shuffleArray([...res]), 
       shuffleArray([...res]), 
       shuffleArray([...res])
    ];
}

export const selectHideIndex = () => {
    const min = 0;
    const max = 2;
    return [
        Math.floor(Math.random() * (max - min + 1) + min),
        Math.floor(Math.random() * (max - min + 1) + min),
    ];
} 

export const getAnswers = (answer) => {
    const arr = new Set();
    const max = Object.keys(shapes).length-1;
    const min = 0;
    while(arr.size<3) {
        arr.add(Object.keys(shapes)[Math.floor(Math.random() * (max - min + 1) + min)]);
    }
    const newArr = Array.from(arr);
    if (!newArr.includes(answer)) {
        newArr[Math.floor(Math.random() * 2) + 0] = answer;
    }
   
    return newArr;
}
