
import { CircleButton } from "../../../../components/elements/CircleButton";
import { DashedCircle } from "../../../../components/elements/DashedCircle";
import { getPosition, getWidth } from "../Scale.data";
import { DragAndDrop } from "../../../../components/global/DragAndDrop";
import React, {useState} from "react";
import { v4 as uuidv4 } from 'uuid';
 
export const ScalePart = React.forwardRef(({state, level, left, right, answer, refresh}, ref) => {
    const [index, setIndex] = useState('');
    const [part, setPart] = useState('');

    return (
        <div className={` z-[10]  mt-[50px] w-[317px] h-[108.33px] mx-auto relative ${state ? "md:w-[357px] md:h-[122px] lg:w-[458px] lg:h-[157.36px] xl:w-[538px] xl:h-[185px]": "md:w-[458px] md:h-[157px] lg:w-[521px] lg:h-[179px] xl:w-[698.29px] xl:h-[240px]"}`}>
        <div ref={ref} className={`mt-[20px] lg:mt-[30px] xl:mt-[40px] w-full h-[52.59px] ${state ? "md:h-[58.49px] lg:h-[74.92px] xl:h-[90.88px]": "md:h-[74.71px] lg:h-[86.41px] xl:h-[116.66px]"} bg-[url('/backGrounds/scale.svg')] bg-[length:100%_100%]`}>
            <div className={`relative mx-auto`}>
              <div className={`drop1 absolute ${getPosition(state, level, "left")} ${getWidth(state, level)} flex flex-wrap-reverse justify-center bottom-[100%]`}>
                 { 
                    left.map((item, i)=>{
                      if (item || item === 0) {
                        if (level!=="beginner") {
                           if (part === "left" && i===index) {
                              return (
                                 <DragAndDrop 
                                    key={uuidv4()}
                                    enableClick = {false}
                                    cmp={"scale1"}
                                    drop={"scale"}
                                    item = {item}
                                    refresh={(sound, event) => refresh(event, item, i, "left")}
                                 >
                                       <CircleButton isActive={item ? item : true}>{item}</CircleButton>   
                                 </DragAndDrop>
                              )
                           } else {
                              return (
                                 <div
                                 key={uuidv4()} 
                                 onMouseEnter={(e)=>{
                                       setIndex(i);
                                       setPart("left");
                                 }}
                              >
                                 <CircleButton isActive={item ? item : true}>{item}</CircleButton>
                              </div>
                              )
                           }

                        } else {
                           return (
                              <CircleButton key={uuidv4()} isActive={item ? item : true}>{item}</CircleButton>
                           )
                        }

                      } else {
                        return(
                           <DashedCircle key={uuidv4()} state={state} className={`${level === "advanced" && i===2 ? "mb-[-4px]" : ""}`} cmp={'scale'}></DashedCircle> 
                        )
                      }
                    })
                 }
              </div>
              <div className={`absolute ${getPosition(state, level, "right")} ${getWidth(state, level)} drop2 flex flex-wrap-reverse justify-center bottom-[100%]`}>
                  {right.map((item, i)=>{
                      if (item || item === 0) {
                        if (level!=="beginner") {
                              if (part === "right" && i===index) {
                                 return (
                                    <DragAndDrop 
                                       key={uuidv4()}
                                       enableClick = {false}
                                       cmp={"scale1"}
                                       drop={"scale"}
                                       item = {item}
                                       refresh={(sound, event) => refresh(event, item, i, "right")}
                                    >
                                          <CircleButton isActive={item ? item : true}>{item}</CircleButton>   
                                    </DragAndDrop>
                                 )
                              } else {
                                 return (
                                    <div
                                    key={uuidv4()} 
                                    onMouseEnter={(e)=>{
                                          setIndex(i);
                                          setPart("right");
                                    }}
                                 >
                                    <CircleButton isActive={item ? item : true}>{item}</CircleButton>
                                 </div>
                                 )
                              }
                        } else {
                           return (
                              <CircleButton key={uuidv4()} isActive={item ? item : true}>{item}</CircleButton>
                           )
                        }

                      } else {
                        return(
                           <DashedCircle key={uuidv4()} state={state} className={`${level === "advanced" && i===2 ? "mb-[-4px]" : ""}`} cmp={'scale'}></DashedCircle> 
                        )
                      }
                    })}
              </div>
            </div> 
        </div>
         <div className={`flex justify-center items-start z-[0] w-[59.52px] h-[69.45px] ${state ? "md:w-[67px] md:h-[78px] lg:w-[85.99px] lg:h-[100.88px] xl:w-[101px] xl:h-[118.6px]": "md:w-[86px] md:h-[100.65px] lg:w-[97.82px] lg:h-[114.75px] xl:w-[131px] xl:h-[153.86px]"} bg-[url('/backGrounds/scale_bg.svg')] bg-[length:100%_100%] absolute bottom-0 left-[41%]`}>
              <CircleButton isActive={true}>
                 {answer}
              </CircleButton>
         </div>
     </div>
    )
});