import {IconBlue, IconRed, IconGreen, IconPurple, IconGray, IconYellow, IconPink, IconOrange, IconTeal} from '../components/elements/Icon';

const iconComponents = [IconBlue, IconYellow, IconRed, IconGreen, IconPurple, IconPink, IconOrange, IconTeal];

export const getCircleComponent = (answers) => {
   const arr = new Set();
   while(arr.size<answers.length) {
      arr.add(iconComponents[Math.floor(Math.random() * iconComponents.length)]);
   }

   return Array.from(arr);
}