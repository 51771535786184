import React, {useState, useEffect, useRef, memo} from 'react';
import { useParams } from 'react-router-dom';
import { updateGameStorage } from '../../hooks/useGameStorage';
import { useUpdateReduxState } from '../../hooks/useUpdateReduxState';
import { gameId } from '../../hooks/getGameId';

const statsStyle = {
    div: "w-[72px] md:w-[69px] lg:w-[97px] xl:w-[105px] flex justify-between h-[31px] md:h-[28px] lg:h-[43px] xl:h-[31px] items-center mb-[5px] md:mb-[2px] lg:mb-[0px] xl:mb-[10px] mx-auto",
    text: "text-center w-[41px] lg:w-[59px] xl:w-[67px] text-[14px] lg:text-[20px] xl:text-[22px] font-normal",
    container: "border-[2px] border-[#2F9EBA] w-full rounded-[5px] bg-[#FFF] lg:border-[3px] xl:border-[4px] xl:border-[#2F9EBA] lg:rounded-[9px] border-solid",
    containerDiv: "w-[72px] md:w-[69px] lg:w-[97px] xl:w-[105px] mx-auto my-[5px] md:my-[8px] justify-between flex items-center lg:my-[7px]",
};

const getBorderColor = (name) => {
    if (name === "counting" || name === "memory") {
        return "border-[#2F9EBA]";
    }
    if (name === "adding") {
        return "border-[#7648B5]";
    }
    if (name === "schulte") {
        return "border-[#7643B7]";
    }
    if (name === "division") {
        return "border-[#D86B20]";
    }
    if (name === "multiplication") {
        return "border-[#85B054]";
    }
    if (name === "subtracting") {
        return "border-[#D14116]";
    }

    if (name === "compare") {
        return "border-[#D36874]";
    }
}

const Stats = ({timer, storageName}) => {
    const {name} = useParams();
    const storageData = localStorage.getItem(storageName) ? JSON.parse(localStorage.getItem(storageName)) : "";
    const getTimer = ({min, sec}) => {
       if (min<10) min = '0'+min;
       if (sec<10) sec = '0'+sec;
       return min+" "+sec;
    }
    const getPrize = (storageData) => {
      if (storageData) {
        const storageDataToSec = storageData.min*60+storageData.sec;
        const firstToSec = timer[1].min*60+timer[1].sec;
        const secondToSec = timer[2].min*60+timer[2].sec;
        const thirdToSec = timer[3].min*60+timer[3].sec;

        if (storageDataToSec<=firstToSec) {
          return 1;
        } else if (storageDataToSec>firstToSec && storageDataToSec<=secondToSec) {
            return 2;
        } else if (storageDataToSec>secondToSec && storageDataToSec<=thirdToSec) {
            return 3;
        } else {
            return 4;
        }

      } else {
        return 0;
      }
      
    }
    return (
        <div
            className={`${getPrize(storageData)===4 ? 'h-[150px] md:h-[140px] lg:h-[178px] xl:h-[176px]' : 'lg:h-[136px] xl:h-[133px] h-[113px] md:h-[100px]'} mt-[12px] md:mt-[10px] lg:mt-[12px] xl:mt-[20px] rounded-[5px] border-[#2F9EBA] border-[2px]  w-full bg-[#FFF] lg:border-[3px] xl:border-[4px] lg:rounded-[9px] border-solid
              ${getBorderColor(name)}
            `}>
            <div className="w-full h-[115px] mx-auto my-[5px] md:my-[8px] lg:my-[2px] xl:my-[10px]">
                <div className={`${statsStyle.div} md:pl-[1px]`}>
                    <img
                        className={`${statsStyle.img} 
                          ${getPrize(storageData) === 1 ? 'w-[40px] h-[40px] md:w-[33px] md:h-[38px] lg:w-[44px] lg:h-[42px] xl:w-[39px] xl:h-[43px] ml-[-8px] md:ml-[-5px] lg:ml-[-8px] xl:ml-[-5px]' : 
                          'h-full w-[26px] md:w-[23px] lg:w-[29px] lg:h-[38px] xl:w-[28px]'} `}
                        src={getPrize(storageData) === 1 ? "/backGrounds/1.svg": "/backGrounds/default_1.svg"}/>
                    <span className={`${statsStyle.text} text-[#FFC346]`}>{getPrize(storageData) === 1 ? getTimer(storageData): getTimer(timer[1])}</span>
                </div>
                <div className={`${statsStyle.div}`}>
                    <img className={`${statsStyle.img}
                        ${getPrize(storageData) === 2 ? 'w-[40px] h-[40px] md:w-[33px] md:h-[38px] lg:w-[44px] lg:h-[42px] xl:w-[39px] xl:h-[43px] ml-[-8px] md:ml-[-5px] lg:ml-[-8px] xl:ml-[-5px]' : 
                        'h-full w-[26px] md:w-[23px] lg:w-[29px] lg:h-[38px] xl:w-[28px]'}
                    `}
                         src={getPrize(storageData) === 2 ? "/backGrounds/2.svg": "/backGrounds/default_2.svg"}/>
                    <span className={`${statsStyle.text} text-[#9C9C9C]`}>{getPrize(storageData) === 2 ? getTimer(storageData): getTimer(timer[2])}</span>
                </div>
                <div className={`${statsStyle.div}`}>
                    <img
                        className={`${statsStyle.img} 
                        ${getPrize(storageData) === 3 ? 'w-[40px] h-[40px] md:w-[33px] md:h-[38px] lg:w-[44px] lg:h-[42px] xl:w-[39px] xl:h-[43px] ml-[-8px] md:ml-[-5px] lg:ml-[-8px] xl:ml-[-5px]' : 
                        'h-full w-[26px] md:w-[23px] lg:w-[29px] lg:h-[38px] xl:w-[28px]'}`}
                        src={getPrize(storageData) === 3 ? "/backGrounds/3.svg": "/backGrounds/default_3.svg"}/>
                    <span className={`${statsStyle.text} text-[#D79A3F]`}>{getPrize(storageData) === 3 ? getTimer(storageData): getTimer(timer[3])}</span>
                </div>
                {getPrize(storageData)===4 && <div className={`${statsStyle.div} md:pl-[1px]`}>
                    <img
                        className={`${statsStyle.img} w-[40px] h-[40px] md:w-[33px] md:h-[38px] lg:w-[44px] lg:h-[42px] xl:w-[39px] xl:h-[43px] ml-[-8px] md:ml-[-5px] lg:ml-[-8px] xl:ml-[-5px]`}
                        src="/backGrounds/4.svg"/>
                    <span className={`${statsStyle.text} text-[#FFC346]`}>{getTimer(storageData)}</span>
                </div>}
            </div>
        </div>
    );
};

const CountTimer = ({state, min, sec, stopTimer, openScore, level, handleOpenScore, timerStorage, storageName, subscription}) => {
    const [minutes, setMinutes] = useState(state === 'up' ? 0 : min);
    const [seconds, setSeconds] = useState(state === 'up' ? 0 : sec);
    const [rotate, setRotate] = useState(true);
    const {name, gameName} = useParams();

    const updateState = useUpdateReduxState();

    const res = () => {
        let val = "";
        if (minutes < 10) {
            val += '0' + minutes;
        } else {
            val += '' + minutes;
        }
        val += " ";
        if (seconds < 10) {
            val += '0' + seconds;
        } else {
            val += '' + seconds;
        }
        return val;
    };

    useEffect(()=>{
        if (timerStorage) {
            if (timerStorage) {
                localStorage.setItem(storageName, JSON.stringify({min:minutes, sec:seconds}));
                handleOpenScore();
            }
        }
    }, [timerStorage]);


    let count = useRef(0);

    useEffect(()=>{
        if (!stopTimer && state==="up") {
            setMinutes(0);
            setSeconds(0);
        }
    }, [stopTimer]);

    useEffect(() => {
        if (stopTimer) return;
        if (openScore) return;
        seconds && seconds <= 5 && !minutes && state === 'down' ? count.current += 250 : count.current += 500;
        const interval = seconds && seconds <= 5 && !minutes && state === 'down' ? 250 : 500;
        const id = setInterval(() => {
            setRotate(!rotate);
            if (count.current % 1000 === 0) {
                if (state === 'up') {
                    if (seconds === 60) {
                        setSeconds(0);
                        setMinutes(minutes + 1);
                    } else {
                        setSeconds(seconds + 1);
                    }
                } else {
                    if (seconds === 0 && minutes !== 0) {
                        setSeconds(60);
                        setMinutes(minutes - 1);
                    }
                    if (seconds) {
                        if (seconds > 5 || minutes || seconds && seconds <= 5 && !minutes) {
                            setSeconds(seconds - 1);
                        }
                    }
                    if(seconds === 0 && minutes === 0) {
                        if (subscription) {
                            updateState({
                                userId: localStorage.getItem('user'),
                                profileId: JSON.parse(localStorage.getItem('profile'))?._id,
                                game: name,
                                score: JSON.parse(localStorage.getItem(`${name+'_'+gameName+'_'+level+'_score'}`)),
                                id: gameId(name, gameName),
                                collection: name,
                              })
                        } else {
                            updateGameStorage(name, gameName, 0, true, level);
                        }
                        handleOpenScore();
                        
                    }
                }
            }
        }, interval);
        return () => clearInterval(id);
    });


    return (
        <div className={`h-[44px] lg:h-[61px] xl:h-[52px] flex items-center ${statsStyle.container} ${getBorderColor(name)}`}>
            <div className={`${statsStyle.containerDiv} h-[24px] md:h-[22px] lg:h-[27px] xl:h-[28px]`}>
                <img
                    className={`w-[25px] md:w-[22px] lg:w-[28px] xl:w-[28px] h-full ${rotate ? seconds === 0 && minutes == 0 ? 'rotate-[0deg]' : 'rotate-[17deg]' : seconds === 0 && minutes == 0 ? 'rotate-[0deg]' : 'rotate-[-17deg]'}`}
                    src="/backGrounds/alarm.svg"/>
                <span
                    className={`${statsStyle.text} ${seconds <= 5 && !minutes && state === 'down' ? 'text-[#BF3238]' : 'text-[#3FA3C8]'} `}>{res()}</span>
            </div>
        </div>
    );
};

const Result = ({timerResult}) => {
    const {name, gameName} = useParams();
    return (
        <div className={`${statsStyle.container} ${getBorderColor(name)} ${gameName === 'rapid_math' || gameName === 'rapid_colors' ||  gameName === 'rapid_shapes' ||  gameName === 'rapid_numbers' ? "mt-[20px]" : ""} h-[69px] md:h-[84px] lg:h-[98px] xl:h-[116px]`}>
            <div
                className={`${statsStyle.containerDiv} h-[24px] md:h-[27px] lg:h-[34px] xl:h-[43px] md:pl-[2px] lg:pl-[4px]`}>
                <img className="w-[24px] md:w-[27px] lg:w-[34px] xl:w-[43px] h-full" src="/backGrounds/success.svg"/>
                <span
                    className="md:w-[33px] ml-[14px]  md:ml-[17px] lg:w-[47px] lg:ml-[20px] xl:ml-[32px] text-[#3FA3C8] lg:text-[24px] xl:text-[26px] font-normal text-center">{timerResult.right}</span>
            </div>
            <div className={`${statsStyle.containerDiv} md:h-[27px] lg:h-[34px] xl:h-[43px] md:pl-[2px] lg:pl-[4px]`}>
                <img className="w-[24px] md:w-[27px] lg:w-[34px] xl:w-[43px] h-full" src="/backGrounds/failure.svg"/>
                <span
                    className="md:w-[33px] ml-[14px] md:ml-[17px] lg:w-[47px] lg:ml-[20px] xl:ml-[32px] text-[#3FA3C8] lg:text-[24px] xl:text-[26px] font-normal text-center">{timerResult.wrong}</span>
            </div>
        </div>
    );
};

export default memo(function Timer({timer, result, price, stopTimer, openScore, level, timerStorage, handleOpenScore, timerResult, subscription}) {
    const {name, gameName} = useParams();
    useEffect(()=>{
        document.querySelector('.timer').style.right = 
          ((document.querySelector('.gamezone').offsetWidth-document.querySelector('.game').offsetWidth)/2-document.querySelector('.timer').offsetWidth)/2+'px';
    });
    const storageName = `${name+'_'+gameName+'_'+level}`;
    return (
        <div
            className={`timer font-righteous absolute w-[88px] md:w-[79px] lg:w-[117px] xl:w-[125px] self-start`}>
            {timer && <CountTimer 
                state = {gameName === 'rapid_math' || gameName === 'rapid_colors' || gameName === 'rapid_shapes' || gameName === 'rapid_numbers'? 'down' : 'up'} min = {0} sec={gameName === 'rapid_math' ? 30 : 60}
                stopTimer={stopTimer} 
                openScore={openScore} 
                level={level} 
                timerStorage={timerStorage} 
                handleOpenScore={handleOpenScore} 
                storageName={storageName}
                subscription = {subscription}
              />
            }
            {price && <Stats timer={timer} storageName={storageName}/>}
            {result && !(gameName=="scale" && level=="beginner") && <Result timerResult={timerResult}/>}
        </div>
    );
});