


export const TermsOfUse = () => {
    return (
        <div
           className="font-concert text-[14px] md:text-[20px] lg:text-[24px] xl:text-[36px] text-[#5C5C5C] select-none flex w-full h-screen md:w-auto overflow-x-scroll no-scrollbar flex-col items-center bg-cover bg-[url('/backGrounds/home.svg')] z-0 relative"
        >
            <div className="w-[90%] text-left mt-[50px] text-[#8048C2]">Terms of Use</div>

            <div className="w-[90%] text-left text-[#2F9EBA] mt-[20px]">
               General
            </div>
            <div className="w-[90%]">
               By installing the Applications developed by WitPlex LLC, you agree to be bound by these terms of use (“app terms”). Please review them carefully before installation and/or acceptance.
            </div>

            <div className="w-[90%] text-left text-[#2F9EBA] mt-[10px]">
               Definitions
            </div>
            <div className="w-[90%]">
               The “Application” shall mean the software provided by WitPlex LLC to offer services related to WitPlex LLC, WitPlex LLC services and its partners’ services, to be used on Apple iOS and Android OS devices and any upgrades from time to time and any other software or documentation which enables the use of the Application
            </div>

            <div className="w-[90%] text-left text-[#2F9EBA] mt-[10px]">
               Basic Terms
            </div>
            <div className="w-[90%]">
               Please be reminded that the Terms constitute an agreement between you and Us. Therefore, we encourage you to carefully familiarize yourself with the Terms. By installing, accessing or using the Apps you confirm that you have read and understood the Terms and any other documents referred to herein, including without limitation our Privacy Policy, and that you agree to be bound by the Terms. You represent and warrant that you have the right, authority and capacity to accept these Terms and to abide by them and that you have fully read and understood the Terms. Your use of the App constitutes your acceptance of the Terms. Consequently, if you do not accept or understand the Terms, please do not use, install, access or register with the App (including any software or application forming part of the App). If you do not agree to these Terms, you are not entitled to use the App, in which case you must promptly uninstall and delete all copies thereof.
            </div>
            
            <div className="w-[90%] text-left text-[#2F9EBA] mt-[10px]">
               Data Protection
            </div>
            <div className="w-[90%]">
               Any personal information you supply to WitPlex LLC when using the Application will be used by WitPlex LLC in accordance with its Privacy Policy.
            </div>

            <div className="w-[90%] text-left text-[#2F9EBA] mt-[10px]">
               Proprietary Rights and License
            </div>
            <div className="w-[90%]">
               All trade marks, copyright, database rights and other intellectual property rights of any nature in the Application together with the underlying software code are owned either directly by WitPlex LLC or by WitPlex LLC’s licensors
            </div>

            <div className="w-[90%] text-left text-[#2F9EBA] mt-[10px]">
               Conditions of Use
            </div>
            <ul className="w-[90%] list-disc ml-[10%] mt-[10px]">
                <li>You will not, nor allow third parties on your behalf to (i) make and distribute copies of the Application (ii) attempt to copy, reproduce, alter, modify, reverse engineer, disassemble, decompile, transfer, exchange or translate the Application; or (iii) create derivative works of the Application of any kind whatsoever.</li>
                <li>The Application is currently made available to you for your personal, non-commercial use. WitPlex LLC reserves the right to amend or withdraw the Application, or charge for the application or service provided to you in accordance with these appterms, at any time and for any reason.</li>
                <li>You acknowledge that the terms of agreement with your respective mobile network provider (‘Mobile Provider’) will continue to apply when using the Application. As a result, you may be charged by the Mobile Provider for access to network connection services for the duration of the connection while accessing the Application or any such third party charges as may arise. You accept responsibility for any such charges that arise.</li>
                <li>If you are not the bill payer for the mobile telephone or handheld device being used to access the Application, you will be assumed to have received permission from the bill payer for using the Application.</li>
            </ul>


            <div className="w-[90%] text-left text-[#2F9EBA] mt-[10px]">
               Availability
            </div>
            <ul className="w-[90%] list-disc ml-[10%] mt-[10px]">
                <li>This Application may available to handheld mobile devices running Apple iOS and Android OS Operating Systems. WitPlex LLC will use reasonable efforts to make the Application available at all times. However you acknowledge the Application is provided over the internet and mobile networks and so the quality and availability of the Application may be affected by factors outside WitPlex LLC’s reasonable control.</li>
                <li>WitPlex LLC, do not accept any responsibility whatsoever for unavailability of the Application, or any difficulty or inability to download or access content or any other communication system failure which may result in the Application being unavailable.</li>
            </ul>

            <div className="w-[90%] text-left text-[#2F9EBA] mt-[10px]">
               System Requirements
            </div>
            <ul className="w-[90%] list-disc ml-[10%] mt-[10px]">
                <li>In order to use the Application, you are required to have a compatible mobile telephone or handheld device, internet access, and the necessary minimum specifications specified in the AppStore.</li>
                <li>The version of the Application software may be upgraded from time to time to add support for new functions and services.</li>
            </ul>

            <div className="w-[90%] text-left text-[#2F9EBA] mt-[10px]">
               Termination
            </div>
            <ul className="w-[90%] list-disc ml-[10%] mt-[10px]">
                <li>WitPlex LLC may terminate use of the Application at any time by giving notice of termination to you.</li>
                <li>Upon any termination, (a) the rights and licenses granted to you herein shall terminate; (b) you must cease all use of the Software;</li>
            </ul>

            <div className="w-[90%] text-left text-[#2F9EBA] mt-[10px]">
               Limitation of liability
            </div>
            <ul className="w-[90%] list-disc ml-[10%] mt-[10px]">
                <li>In no event will WitPlex LLC be liable for any direct, indirect, special, punitive, exemplary or consequential losses or damages of whatsoever kind arising out of your use or access to the Application, including loss of profit or the like whether or not in the contemplation of the parties, whether based on breach of contract, tort (including negligence), product liability or otherwise.</li>
                <li>WitPlex LLC is not liable to you for any damage or alteration to your equipment including but not limited to computer equipment, handheld device or mobile telephones as a result of the installation or use of the Application.</li>
                <li>Nothing in these appterms shall exclude or limit WitPlex LLC’s liability for death or personal injury caused by negligence or for fraud or fraudulent misrepresentation or any other liability which cannot be excluded or limited under applicable law.</li>
            </ul>

            <div className="w-[90%] text-left text-[#2F9EBA] mt-[10px]">
               Disclaimer of warranties
            </div>
            <div className="w-[90%]">
                The Application and software are provided “as is” and “as available” without warranty of any kind.
            </div>

            <div className="w-[90%] my-[20px]">
               Last updated: 11 November 2020
            </div>
            
        </div>
    )
}