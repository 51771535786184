export const generateAnswers = (level) => {
    const arr = new Set();
    const max = level === "beginner" ? 9 : level === "intermediate" ? 49 : level === "advanced" ? 99 : 0;
    const min = level === "beginner" ? 2 : level === "intermediate" ? 10 : level === "advanced" ? 50 : 0;

    while(arr.size<4) {
        arr.add(Math.floor(Math.random() * (max - min + 1) + min));
    }
    return Array.from(arr);
}

export const getRightAnswers = (answers, gameName) => {
    const arr = [...answers]
    return arr.sort((a, b)=> gameName==="ascending" ? a-b : b-a);
}
