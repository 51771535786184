export const getCoordinates = () => {
    const elem = document.querySelector('.shape');
    const answer = document.querySelector('.answer');
    const x1 = elem.getBoundingClientRect().x;
    const y1 = elem.getBoundingClientRect().y;
    const x2 = answer.getBoundingClientRect().x;
    const y2 = answer.getBoundingClientRect().y;
    const xDistance = x1 - x2;
    const yDistance = y1 - y2;
    return [
              xDistance-(answer.getBoundingClientRect().width-elem.getBoundingClientRect().width)/2, 
              yDistance-(answer.getBoundingClientRect().height-elem.getBoundingClientRect().height)/2
           ];
}

export const calcGapX = (state) => {
    if (window.innerWidth <  1024) {
      return (425-4*39)/5;
    }
    if (window.innerWidth>=1024 && window.innerWidth<1440) {
      if (state) {
        return (439-4*39)/5;
      } else {
        return (576-4*49)/5;
      } 
    }
    if (window.innerWidth>=1440 && window.innerWidth<1920) {
      if (state) {
        return (590-4*50)/5;
      } else {
        return (723-4*69)/5;
      } 
    }
    if (window.innerWidth>=1920) {
      if (state) {
        return (768-4*70)/5;
      } else {
        return (909-4*89)/5;
      } 
    }
}

const shapes = {
    triangle: {
        path: 'polygon(50% 0%, 0% 100%, 100% 100%)', 
        answer: 3
    },
    trapezoid: {
        path: 'polygon(20% 0%, 80% 0%, 100% 100%, 0% 100%)', 
        answer: 4
    },
    parallelogram: {
        path: 'polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%)', 
        answer: 4
    },
    rhombus: {
        path: 'polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%)', 
        answer: 4
    },
    pentagon: {
        path: 'polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%)', 
        answer: 5
    },
    hexagon: {
        path: 'polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%)', 
        answer: 6
    },
    heptagon: {
        path: 'polygon(50% 0%, 90% 20%, 100% 60%, 75% 100%, 25% 100%, 0% 60%, 10% 20%)', 
        answer: 7
    },
    octagen: {
        path:'polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%)', 
        answer: 8
    },
    nonagon: {
        path: 'polygon(50% 0%, 83% 12%, 100% 43%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0% 43%, 17% 12%)', 
        answer: 9
    },
    decagon: {
        path: 'polygon(50% 0%, 80% 10%, 100% 35%, 100% 70%, 80% 90%, 50% 100%, 20% 90%, 0% 70%, 0% 35%, 20% 10%)', 
        answer: 10
    },
    bevel: {
        path: 'polygon(20% 0%, 80% 0%, 100% 20%, 100% 80%, 80% 100%, 20% 100%, 0% 80%, 0% 20%)', 
        answer: 8
    }, 
    star: {
        path: 'polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%)', 
        answer: 5
    },
    circle: {
        path: 'circle(50% at 50% 50%)', 
        answer: 0
    },
    rectangle: {path: '', answer: 4}
}

export const getShape = () => {
    const max = Object.values(shapes).length-1;
    const min = 0;
    return Object.values(shapes)[Math.floor(Math.random() * (max - min + 1) + min)];
}

export const getAnswers = (answer) => {
    const arr = new Set();
    while(arr.size<4) {
        arr.add(Math.floor(Math.random() * 11));
    }
    const newArr = Array.from(arr);
    if (!newArr.includes(answer)) {
        newArr[Math.floor(Math.random() * 3) + 0] = answer;
    }
   
    return newArr;
}