import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { RouterProvider } from "react-router-dom";
import router from "./routes.jsx";
import './Translation/i18n';
// import {ampli} from './ampli';
import { isProd } from './config';
import * as amplitude from '@amplitude/analytics-browser';
import * as Sentry from "@sentry/react";

import { store } from './redux/store';
import { Provider } from 'react-redux';

isProd && amplitude.init("ba8dbf104c4bd0a015577acad1dc5ad3");

isProd && Sentry.init({
    dsn: "https://e793bd703850c242cd66e7fbb5e2d209@o4507412530855936.ingest.de.sentry.io/4507412554383440",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});
  

//isProd && ampli.load({environment: 'default'});

ReactDOM.createRoot(document.getElementById("root")).render(
    <Provider store={store}>
        <RouterProvider router={router} />
    </Provider>
    
);

