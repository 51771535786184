
import { CircleButton } from '../../../../components/elements/CircleButton';
import { 
    animationDurationToShowQuestion,
} from "../../../../config";
import { motion } from 'framer-motion';


export default function LeftPart ({state, topNumbers, bottomNumbers, timeduration, result, images, level}) {
    return (
        <div className={`font-bigfatScript text-[32px] text-[#FFF] flex flex-col items-center justify-around h-full border-[3px] rounded-[16px] lg:rounded-[20px] border-[#7648B5] bg-[#FFF] ${state ? "w-[344px] md:w-[350px] md:text-[36px] lg:text-[40px] xl:text-[60px] lg:w-[477px] xl:w-[630px]" : "xl:text-[70px] lg:text-[48px] md:text-[40px] w-[344px] md:w-[467px] lg:w-[566px] xl:w-[745px]"}`}>
            <div className={`w-[320px] h-[89px] ${state ? "md:w-[322px] md:h-[98px] lg:w-[437px] lg:h-[120px] xl:w-[590px] xl:h-[152px]" : "xl:w-[694px] xl:h-[179px] md:w-[436px] md:h-[120px] lg:w-[525px] lg:h-[152px]"} bg-[#F67300] rounded-[17px] flex justify-center items-center`}>
                <div className={`w-[308px] h-[73px] ${state ? "md:w-[310px] md:h-[80px] lg:w-[419px] lg:h-[98px] xl:w-[562px] xl:h-[124px]" : "xl:w-[666px] xl:h-[155px] md:w-[418px] md:h-[98px] lg:w-[501px] lg:h-[124px]"} bg-[#FFB43B] rounded-[17px] flex justify-around items-center`}>
                    <div>{topNumbers[0]}</div>
                    <div className="font-fredokaOne">x</div>
                    <div>{topNumbers[1]}</div>
                </div>
            </div>
            <CircleButton className={"addingresult"} isActive={isNaN(result) ? false : result}>
                <div className='adding'>
                    {result}
                </div>
                
            </CircleButton>
            <div className={`w-[320px] h-[89px] ${state ? "md:w-[322px] md:h-[98px] lg:w-[437px] lg:h-[120px] xl:w-[590px] xl:h-[152px]" : "xl:w-[694px] xl:h-[179px] md:w-[436px] md:h-[120px] lg:w-[525px] lg:h-[152px]"} bg-[#F67300] rounded-[17px] flex justify-center items-center`}>
                <div className={`w-[308px] h-[73px] ${state ? "md:w-[310px] md:h-[80px] lg:w-[419px] lg:h-[98px] xl:w-[562px] xl:h-[124px]" : "xl:w-[666px] xl:h-[155px] md:w-[418px] md:h-[98px] lg:w-[501px] lg:h-[124px]"} bg-[#FFB43B] rounded-[17px] flex justify-around items-center`}>
                    <div>{bottomNumbers[0]}</div>
                    {level==="advanced" && <div className="font-fredokaOne">{"x"}</div>}
                    {level==="advanced" && <div>{bottomNumbers[1]}</div>}
                </div>
            </div>
        </div>
    )
}