const calculateFontSize = (width, height, content) => {
    return Math.sqrt(width * height / content.length);
}

export const getFontSize = (text, state) => {
    if (window.innerWidth < 1024) {
        return calculateFontSize(133, 34, text);
    }
    if (window.innerWidth >= 1024 && window.innerWidth<1440) {
        if (state) {
            return calculateFontSize(130, 42, text);
        } else {
            return calculateFontSize(141, 52, text);
        }   
    }
    if (window.innerWidth >= 1440 && window.innerWidth<1920) {
        if (state) {
            return calculateFontSize(170, 52, text);
        } else {
            return calculateFontSize(215, 52, text);
        }   
    }
    if (window.innerWidth >= 1920) {
        if (state) {
            return calculateFontSize(241, 52, text);
        } else {
            return calculateFontSize(293, 52, text);
        }   
    }
}

export const generateNumbers = (numbers)=>{
    while (true) {
        if (numbers.length === 3) break;
        const number = Math.floor(Math.random() * 9) + 1;
        if (numbers.includes(number)) continue;
        if (!numbers.length) {
            numbers.push(number);
        } else {
            if (numbers[numbers.length - 1] % 2) {
                if (number % 2) continue;
                numbers.push(number);
            } else {
                if (number % 2 === 0) continue;
                numbers.push(number);
            }
        }
    }
    return numbers;
}

export const calcGapY = (state) => {
    if (window.innerWidth<1024) {
        return (260-39*3) / 4;
    }
    if (window.innerWidth>=1024 && window.innerWidth<1440) {
       if (state) {
          return (286-39*3) / 4;
       } else {
          return (354-49*3) / 4;
       }
    }
    if (window.innerWidth>=1440 && window.innerWidth<1920) {
        if (state) {
            return (364-50*3) / 4;
        } else {
            return (446-69*3) / 4;
        }
    }
    if (window.innerWidth>=1920) {
        if (state) {
            return (448-70*3) / 4;
        } else {
            return (538-89*3) / 4;
        }
    }
}