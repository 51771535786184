import {IconArrowSlideBar, TopBarArrowIcon, TopBarArrowRotatedIcon, IconLock} from "../elements/index.js";
import {useEffect, useRef, useState, memo} from "react";
import useOutsideAlerter from "../../hooks/useOutsideAlerter.jsx";
import {BgImage} from './animations/Sky';
import {useParams, NavLink} from "react-router-dom";
import {useTranslation} from 'react-i18next';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { openGamesCount } from "../../config";
import { colors } from "./Navbar/assets/constants";
import { useSelector, useDispatch } from "react-redux";
import { getCollectionId } from "../../hooks/getGameId";
import { getProfileGameThunk, getTotalScoreThunk } from "../../redux/features/gamesSlice";

const getSlideWidth = () => {
    if (window.innerWidth<1024) {
        return 118;
    }
    if (window.innerWidth>=1024 && window.innerWidth<1440) {
        return 152+23;
    }
    if (window.innerWidth>=1440 && window.innerWidth<1920) {
        return 165+39;
    }
    if (window.innerWidth>=1920) {
        return 206+65;
    }
}

const getWidth = (arr, isSettingsOpen) => {
    if (window.innerWidth < 1024) {
      return Math.min(window.innerWidth, 118 * arr.length);
    }
    
    if (window.innerWidth >= 1024 && window.innerWidth < 1440) {
      if (isSettingsOpen) {
        return Math.min(window.innerWidth - 207, (152 + 23) * arr.length);
      } else {
        return Math.min(window.innerWidth - 24, (152 + 23) * arr.length);
      }
    }
    
    if (window.innerWidth>=1440 && window.innerWidth<1920) {
      if (isSettingsOpen) {
        return Math.min(window.innerWidth - 222, (165 + 39) * arr.length);
      } else {
        return Math.min(window.innerWidth - 34, (165 + 39) * arr.length);
      }
    }
    
     if (window.innerWidth>=1920) {
            if (isSettingsOpen) {
      return Math.min(window.innerWidth - 328, (206 + 65) * arr.length);
    } else {
      return Math.min(window.innerWidth - 34, (206 + 65) * arr.length);
    }
    }
   
}



export default memo(function TopBar({items, openScore, gameindex, state, level, screen, handleSubscription, subscription}) {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {name} = useParams();
    const gamesData = useSelector((state)=>state.games.gameData);
    const [index, setIndex] = useState(gameindex);
    const [update, setUpdate] = useState(false);
    const [scrollRight, setScrollRight] = useState(false);
    const [scrollLeft, setScrollLeft] = useState(false);
    const arr = [...items];
    const [isTopBarOpen, setIsTopBarOpen] = useState(() => {
            const storedIsOpen = localStorage.getItem("topBarIsOpen");
            return storedIsOpen ? JSON.parse(storedIsOpen) : true;
        }
    );

    function SampleNextArrow(props) {
        const { className, onClick } = props;
        return (
            <IconArrowSlideBar className={className} direction="left" scroll={scrollRight} onClick={onClick}/>
        );
    }

    function SamplePrevArrow(props) {
        const { className, onClick } = props;
        return (
            <IconArrowSlideBar className={className} direction="right" scroll={scrollLeft} onClick={onClick}/>
        );
    }


    const wrapperRef = useRef(null);
    const sliderRef = useRef(null);
    const [isSettingsOpen, setIsSettingsOpen] = useState(() => {
        const storedIsOpen = localStorage.getItem("leftBarIsOpen");
        return storedIsOpen ? JSON.parse(storedIsOpen) : false;
    });

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: Math.floor(getWidth(arr, isSettingsOpen)/getSlideWidth()),
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        //nextArrow: <IconArrowSlideBar className="justify-start" direction="left" scroll={scrollRight}/>,
        //prevArrow: <IconArrowSlideBar className="justify-start" direction="right" scroll={scrollLeft}/>,
        beforeChange: (current, next) => {
            // console.log('beforecurrent', current)
            // console.log('beforenext', next)
            if(arr.length===settings.slidesToShow) return;
            if(next === arr.length-(settings.slidesToShow)) {
                setScrollRight(false);
                setScrollRight(true);
            } else {
                if (next) {
                    setScrollLeft(true);
                } else {
                    setScrollRight(true);
                }
                
            }
        },
          afterChange: (current, next) => {
            // console.log('aftercurrent', current)
            // console.log('afternext', next)
            if(arr.length===settings.slidesToShow) return;
            if (current === 0) {
                setScrollLeft(false);
            } else {
                if (current === arr.length-(settings.slidesToShow)) {
                    setScrollRight(false)
                    setScrollLeft(true)
                } else {
                    setScrollRight(true)
                }
                
            }
        },
      };

      const gameStorage = JSON.parse(localStorage.getItem('playedGames'));
      const getGameData = (gameName) => {
          if (gameStorage[name]) {
              
              return gameStorage[name].find((game)=>game.name === gameName);
          } else {
              return null;
          }
      }


    useEffect(()=>{
       setUpdate(!update);
    }, [state]);
    useEffect(() => {
        localStorage.setItem("topBarIsOpen", JSON.stringify(isTopBarOpen));
        window.dispatchEvent(new Event("topBarIsOpenChange"));
    }, [isTopBarOpen]);

    useEffect(()=>{
        setIndex("");
        if (subscription && JSON.parse(localStorage.getItem('profile'))) {
            dispatch(getProfileGameThunk({
               userId: localStorage.getItem('user'),
               collection: name,
               profileId: JSON.parse(localStorage.getItem('profile'))._id
            }))
            dispatch(getTotalScoreThunk({
                userId: localStorage.getItem('user'),
                profileId: JSON.parse(localStorage.getItem('profile'))._id
            }))
        }
    }, [items]);

    useEffect(() => {
        // Function to handle the event
        const handleLeftBarIsOpenChange = () => {
            const storedIsOpen = localStorage.getItem("leftBarIsOpen");
            setIsSettingsOpen(storedIsOpen ? JSON.parse(storedIsOpen) : false);
        };

        // Attach the event listener when the component mounts
        window.addEventListener("leftBarIsOpenChange", handleLeftBarIsOpenChange);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener("leftBarIsOpenChange", handleLeftBarIsOpenChange);
        };
    }, []);

    useOutsideAlerter(wrapperRef, () => {
        if (window.innerWidth < 1024) {
            //setIsTopBarOpen(false);
        }
    });

    useEffect(()=>{
        setIndex("");
    }, [openScore])

    useEffect(()=>{
        sliderRef.current && sliderRef.current.slickGoTo(0)
        if (arr.length > settings.slidesToShow) {
            setScrollRight(true);
            setScrollLeft(false);
        } else {
            setScrollRight(false);
            setScrollLeft(false);
        }
    }, [screen, name, isSettingsOpen])
    
    return (
        <div
            className={` no-scrollbar w-full relative transition-all duration-[100] flex items-center z-[-1]`}>
            {isTopBarOpen && <BgImage />}
            <div className={`w-full mx-auto flex flex-col justify-center items-center`} ref={wrapperRef}>
            <div  style={{width: getWidth(arr, isSettingsOpen)+'px'}} className={`
              ${
                isTopBarOpen
                    ? "h-[73px] md:h-[109px] lg:h-[112px] xl:h-[132px] py-[0px] mx-auto"
                    : "h-0  py-0" // px should be half of width to cover buttons
               }
               slider-container`
            }>
                {isTopBarOpen && <Slider ref={sliderRef} {...settings} className="mx-[24px] md:mx-[28px] lg:mx-[60px] xl:mx-[54px]">
                    {arr.map((item, i)=> <div key={i} className="relative cursor-pointer h-[80px] md:h-[109px] lg:h-[116px] xl:h-[138px] w-[118px] md:w-[152px] lg:w-[165px] xl:w-[206px] flex flex-col justify-center items-center">
                                <div style={{color: colors[name]}} className="flex items-center justify-center text-center w-full truncate text-[10px] md:text-[12px] md:pb-[4px] pt-[0px] md:pt-[0px] lg:pb-[4px] lg:pt-[0px] lg:text-[14px] xl:text-[15px] xl:pt-[3px] font-fredokaOne"
                                    >
                                    {item? +item.name[item.name.length-1] ?  t(`${item.name.split("_")[0]}`)+" "+item.name.split("_")[1] : 
                                       name === "schulte" ?  t(`${item.name.slice(0, item.name.lastIndexOf("_"))}`) : t(`${item.name}`): '' }
                                    {name === "schulte" && <img className="ml-[2px]" src={`/backGrounds/Topbar/schulte/${item.name.split("_")[item.name.split("_").length-1]}.svg`}/>}
                                </div>
                                <NavLink onClick={()=>{
                                    if (i===index ) {
                                        setIndex("");
                                    } else {
                                        setIndex(i);
                                    }
                                    if (subscription===false && (i>openGamesCount-1 || (getGameData(item.name) && getGameData(item.name).count>=1))) {
                                        handleSubscription("");
                                    }

                                }}
                                    to={(i>openGamesCount-1 || (getGameData(item.name) && getGameData(item.name).count>=1)) && subscription===false ?  '' : i === index? `/games/${name}` : `/games/${name}/${item? item.name : ''}`}
                                    //style={{backgroundImage: item ? `url(/backGrounds/Topbar/${name}/${item.name}.svg)` : '', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat'}}
                                    className={`mx-auto ${
                                        isTopBarOpen ? "duration-500" : "invisible "
                                    } ${i===gameindex ? 'border-2 border-[#2F9EBA]' : 'border-0 border-transparent'} h-[54px] w-[88px] lg:h-[84px] xl:h-[102px] md:h-[75px] md:w-[120px] lg:w-[131.2px] flex justify-center xl:w-[163.2px] z-20 rounded-[5px] bg-[#ffff]`}
                                >
                                    <img
                                       alt="image"
                                       className="w-[100%] h-[100%] rounded-[5px]" 
                                       src={`/backGrounds/Topbar/${name}/${item.name}.svg`}
                                    />
                                    {subscription && (getCollectionId(name, i) in gamesData) && <div className="rounded-[50%] font-dangrek text-[16px] w-[25px] h-[25px] text-[#78A941] border-[#78A941] bg-[#fff] border-[2px] absolute left-[77%]  xl:left-[80%] xl:translate-x-[-50%] translate-y-[-50%] text-center">
                                         {gamesData[getCollectionId(name, i)]?.count}
                                    </div>}
                                    {subscription === false && (i>openGamesCount-1 || (getGameData(item.name) && getGameData(item.name).count>=1)) && <div style={{borderColor: colors[name]}} className="rounded-[50%] font-dangrek text-[16px] w-[25px] h-[25px] text-[#78A941] bg-[#fff] border-[2px] absolute left-[77%]  xl:left-[80%] xl:translate-x-[-50%] translate-y-[-50%] text-center">
                                         <IconLock color={colors[name]} className="w-[18px] h-[18px] mx-auto"/>
                                    </div>}



                            </NavLink>
                            {subscription === false && getGameData(item.name) && getGameData(item.name).count ? <div className="pl-[2px] pt-[2px] font-dangrek text-center flex justify-center items-center text-[14px] text-[#78A941] w-[29px] h-[29px] bg-[length:29px_29px] bg-[url('/backGrounds/star_score.svg')]  absolute left-[75%] bottom-0 translate-y-[0%] z-[1000]">
                                        {getGameData(item.name).point}
                                    </div> : ""}
                            {subscription && (getCollectionId(name, i) in gamesData) && <div className="pl-[2px] pt-[2px] font-dangrek text-center flex justify-center items-center text-[14px] text-[#78A941] w-[29px] h-[29px] bg-[length:29px_29px] bg-[url('/backGrounds/star_score.svg')]  absolute left-[75%] bottom-0 translate-y-[0%] z-[1000]">
                                   {gamesData[getCollectionId(name, i)]?.points}
                                </div>
                            }
                            </div>
                    )}
               </Slider>}
            </div>
                {isTopBarOpen ?
                    <div
                    onClick={() => {
                        setIsTopBarOpen((prevState) => !prevState);
                    }}
                        className="cursor-pointer z-30 h-[31px] md:h-[34px] lg:h-[36px] xl:h-[43px]  w-[53px] lg:w-[54px] xl:w-[58px] top-[100%]">
                        <TopBarArrowRotatedIcon
                            className={`transition-transform duration-1000 group-hover:duration-500 h-[31px] md:h-[34px] lg:h-[36px]  xl:h-[43px]  w-[53px] lg:w-[54px] xl:w-[58px]`}
                        />
                    </div> :
                    <div
                    onClick={() => {
                        setIsTopBarOpen((prevState) => !prevState);
                    }}
                        className=" cursor-pointer z-30 h-[31px] md:h-[34px] lg:h-[36px] lg:mt-[7px] xl:mt-[10px]  w-[53px] lg:w-[54px] xl:h-[43px] xl:w-[58px]">
                        <TopBarArrowIcon
                            className={`top-1/2 transition-transform duration-1000 group-hover:duration-500 h-[31px] md:h-[34px] lg:h-[36px]  xl:h-[43px] w-[53px] lg:w-[54px] xl:w-[58px]`}
                        /></div>}
            </div>
        </div>

    )
})