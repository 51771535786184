//styles part

export const getWidth = (state, count) => {
    if (window.innerWidth<1024) {
        return (286-286*0.07) / count;
    }
    if (window.innerWidth>=1024 && window.innerWidth<1440) {
        if (state) {
            return (302-302*0.07) / count;
        } else {
            return (376-376*0.07) / count;
        }
    }
    if (window.innerWidth>=1440 && window.innerWidth<1920) {
        if (state) {
            return (392-392*0.07) / count;
        } else {
            return (470-470*0.07) / count;
        }
    }
    if (window.innerWidth>=1920) {
        if (state) {
            return (522-522*0.07) / count;
        } else {
            return (626-626*0.07) / count;
        }
    }
}


// data part

export const getCoordinates = () => {
    const elem = document.querySelector('.division');
    const answer = document.querySelector('.answer');
    const x1 = elem.getBoundingClientRect().x;
    const y1 = elem.getBoundingClientRect().y;
    const x2 = answer.getBoundingClientRect().x;
    const y2 = answer.getBoundingClientRect().y;
    const xDistance = x1 - x2;
    const yDistance = y1 - y2;
    return [
              xDistance-(answer.getBoundingClientRect().width-elem.getBoundingClientRect().width)/2, 
              yDistance-(answer.getBoundingClientRect().height-elem.getBoundingClientRect().height)/2
           ];
}

export const geterateAnswersArray = () => {
    const arr = new Set();
    while(arr.size<3) {
        arr.add(Math.floor(Math.random() * (4 - 2 + 1) + 2));
   
    }
    return Array.from(arr);
}


export const getFirstNumber = (answer) => {
    let firstNumber = 0;
    while(true) {
      const number = Math.floor(Math.random() * (9 - 2 + 1) + 2);
      if (number%answer === 0 && number>answer) {
           firstNumber = number;
           break;
      }
    }
    return firstNumber;
}

export const generateImages = () => {
    const images = new Set();
    images.add(17);
    while (images.size < 3) {
      const num = Math.floor(Math.random() * 16) + 1;
      images.add(num);
    }
    return Array.from(images);
}

export const getAnswers = (answer) => {
    const arr = new Set();
    while(arr.size<3) {
        arr.add(Math.floor(Math.random() * 8) + 2);
   
    }
    const newArr = Array.from(arr);
    if (!newArr.includes(answer)) {
        newArr[Math.floor(Math.random() * 2) + 0] = answer;
    }
   
    return newArr;
}

export const getAnimationValues = (answer) => {
    const topValues = [Math.random()*100];
    while(topValues.length < answer) {
        topValues.push(Math.random()*100+topValues[topValues.length-1])
    }
    return topValues;
}


export const calcMargin = (state, count) => {
    if (window.innerWidth<1024) {
        return (167-35.21*count) / (count+1);
    }
    if (window.innerWidth>=1024 && window.innerWidth<1440) {
       if (state) {
         return (195-38*count) / (count+1);
       } else {
         return (221-43*count) / (count+1);
       }
    }
    if (window.innerWidth>=1440 && window.innerWidth<1920) {
        if (state) {
            return (230-50*count) / (count+1);
        } else {
            return (263-51*count) / (count+1);
        }
    }
    if (window.innerWidth>=1920) {
        if (state) {
            return (264-61*count) / (count+1);
        } else {
            return (326-77*count) / (count+1);
        }
    }
  }

  export const calcGapY = (state) => {
    if (window.innerWidth<1024) {
        return (260-39*3) / 4;
    }
    if (window.innerWidth>=1024 && window.innerWidth<1440) {
       if (state) {
          return (286-39*3) / 4;
       } else {
          return (354-49*3) / 4;
       }
    }
    if (window.innerWidth>=1440 && window.innerWidth<1920) {
        if (state) {
            return (364-50*3) / 4;
        } else {
            return (446-69*3) / 4;
        }
    }
    if (window.innerWidth>=1920) {
        if (state) {
            return (448-70*3) / 4;
        } else {
            return (538-89*3) / 4;
        }
    }
}