import {useEffect, useState, useRef} from 'react'

export const Recaptcha = ({siteKey, callback}) => {
    const [isRecaptchaLoaded, setIsRecaptchaLoaded] = useState(false);
    const recaptchaRef = useRef(null);

    const onRecaptchaLoad = () => {
        setIsRecaptchaLoaded(true);
    };
    useEffect(()=>{
        window.onRecaptchaLoad = onRecaptchaLoad;
        if(!window.grecaptcha) {
            const script = document.createElement('script');
            script.src = "https://www.google.com/recaptcha/api.js?onload=onRecaptchaLoad&render=explicit";
            script.async = true;
            script.defer = true;
            document.head.appendChild(script);
        } else if (window.grecaptcha && window.grecaptcha.render) {
            onRecaptchaLoad();
        }

        return () => {
            window.onRecaptchaLoad = null;
        };
    }, []);

    useEffect(() => {
        if (isRecaptchaLoaded) {
            window.grecaptcha.render(recaptchaRef.current, {
                'sitekey': siteKey,
                'callback': callback,
            });
        }
    }, [isRecaptchaLoaded]);


    return (
        <div
           className="scale-[0.8] w-full flex justify-center"
           ref={recaptchaRef} 
        />
    );
}