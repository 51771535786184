import { getDate } from "./useCurrentDate";
import * as amplitude from '@amplitude/analytics-browser';

export const createGameStorage = () => {
    const path = 'playedGames';
    const store = localStorage.getItem(path);
    if (store) {
      if (JSON.parse(store).date !== getDate()) {
        localStorage.setItem(path, JSON.stringify({date: getDate()}));
      }
    } else {
      localStorage.setItem(path, JSON.stringify({date: getDate()}));
    }
}

export const updateGameStorage = (game, gameName, point, count, level) => {
    const path = 'playedGames';
    const storage = JSON.parse(localStorage.getItem(path));
    
    if (game in storage) {
        const check = storage[game].find(item=>item.name === gameName);

        if (check) {
            const newStorage = storage[game].map(item=>{
                if (item.name === gameName) {
                    if (!level) {
                        //console.log('amplitude', `${`${game.startsWith("subtract") ? "subtract" : game}`+"_"+gameName.replaceAll("_"," ")+"_"+`${gameName!=="learning" ? item.all>=3 ? "3+" : item.all+1 : item.all>=10 ? "10+" : item.all+1 }`}`);
                        amplitude.track(`${`${game.startsWith("subtract") ? "subtract" : game}`+"_"+gameName.replaceAll("_"," ")+"_"+`${gameName!=="learning" ? item.all>=3 ? "3+" : item.all+1 : item.all>=10 ? "10+" : item.all+1 }`}`);
                        return {...item, point: item.point+point, count: (count || item.all===2)? item.count+1 : item.count, all: item.all+1 };
                    } else {
                        if (gameName.startsWith("rapid")) {
                            if (count) {
                                amplitude.track(`${`${game.startsWith("subtract") ? "subtract" : game}`+"_"+gameName.replaceAll("_"," ")+"_"+level+"_"+`${item[level]>=3 ? "3+" : item[level] ? item[level]+1 : 1}`}`)
                                //console.log('amplitude3', `${`${game.startsWith("subtract") ? "subtract" : game}`+"_"+gameName.replaceAll("_"," ")+"_"+level+"_"+`${item[level]>=3 ? "3+" : item[level] ? item[level]+1 : 1}`}`);
                                return {...item, point: item.point+point, count: count ? item.count+1 : item.count, [level]: item[level] && count ? item[level]+1 : 1 };
                            } else {
                                return {...item, point: item.point+point };
                            }
                            
                        } else {
                            amplitude.track(`${`${game.startsWith("subtract") ? "subtract" : game}`+"_"+gameName.replaceAll("_"," ")+"_"+level+"_"+`${item[level]>=3 ? "3+" : item[level] ? item[level]+1 : 1}`}`);
                            //console.log('amplitude3', `${`${game.startsWith("subtract") ? "subtract" : game}`+"_"+gameName.replaceAll("_"," ")+"_"+level+"_"+`${item[level]>=3 ? "3+" : item[level] ? item[level]+1 : 1}`}`);
                            return {...item, point: item.point+point, count: count ? item.count+1 : item.count, [level]: item[level] ? item[level]+1 : 1 };
                        }
                        
                    }
                } else {
                    return {...item};
                }
            })
            storage[game] = newStorage;
            
        } else {
            if (!level) {
                //console.log('amplitude', `${`${game.startsWith("subtract") ? "subtract" : game}`+"_"+gameName.replaceAll("_"," ")+"_"+`${1}`}`);
                amplitude.track(`${`${game.startsWith("subtract") ? "subtract" : game}`+"_"+gameName.replaceAll("_"," ")+"_"+`${1}`}`)
                storage[game] = [...storage[game], {name: gameName, point, count: count ? 1: 0, all: 1}];
            } else {
                if (gameName.startsWith("rapid")) {
                    if (count) {
                        amplitude.track(`${`${game.startsWith("subtract") ? "subtract" : game}`+"_"+gameName.replaceAll("_"," ")+"_"+level+"_"+`${1}`}`);
                        //console.log('amplitude2', `${`${game.startsWith("subtract") ? "subtract" : game}`+"_"+gameName.replaceAll("_"," ")+"_"+level+"_"+`${1}`}`);
                    }
                    storage[game] = [...storage[game], {name: gameName, point, count: count ? 1: 0, [level]: count && 1}];
                } else {
                    //console.log('amplitude2', `${`${game.startsWith("subtract") ? "subtract" : game}`+"_"+gameName.replaceAll("_"," ")+"_"+level+"_"+`${1}`}`);
                    amplitude.track(`${`${game.startsWith("subtract") ? "subtract" : game}`+"_"+gameName.replaceAll("_"," ")+"_"+level+"_"+`${1}`}`);
                    storage[game] = [...storage[game], {name: gameName, point, count: count ? 1: 0, [level]: 1}];
                }
                
            }
        }

    } else {
        if (!level) {
            //console.log('amplitude', `${`${game.startsWith("subtract") ? "subtract" : game}`+"_"+gameName.replaceAll("_"," ")+"_"+`${1}`}`);
            amplitude.track(`${`${game.startsWith("subtract") ? "subtract" : game}`+"_"+gameName.replaceAll("_"," ")+"_"+`${1}`}`);
            storage[game] = [{name: gameName, point, count: count ? 1: 0, all: 1}];
        } else {
            if (gameName.startsWith("rapid")) {
                if (count) {
                    //console.log('amplitude1', `${game+"_"+gameName.replaceAll("_"," ")+"_"+level+"_"+`${1}`}`);
                    amplitude.track(`${game+"_"+gameName.replaceAll("_"," ")+"_"+level+"_"+`${1}`}`);
                }
                storage[game] = [{name: gameName, point, count: count ? 1: 0, [level]: count && 1}];
            } else {
                //console.log('amplitude1', `${game+"_"+gameName.replaceAll("_"," ")+"_"+level+"_"+`${1}`}`);
                amplitude.track(`${game+"_"+gameName.replaceAll("_"," ")+"_"+level+"_"+`${1}`}`);
                storage[game] = [{name: gameName, point, count: count ? 1: 0, [level]: 1}];
            }
            
        }
    }
    localStorage.setItem(path, JSON.stringify(storage));
}