
import { BottomPart } from "./components/BottomPart";
import { TopPart } from "./components/TopPart";
import { getNumber, getAnswers } from "./Multiplication4.data";
import { useRef, useState, useEffect } from "react";
import { updateStorage} from "../../../hooks/useStorage";
import { updateGameStorage } from "../../../hooks/useGameStorage";
import { 
    animationDurationToShowscoresPopup,
    animationDurationToMoveCorrectAnswer 
} from "../../../config";
import { useParams } from 'react-router-dom';
import Sound from "../../../components/global/Sound";
import * as amplitude from '@amplitude/analytics-browser';
import { useUpdateReduxState } from "../../../hooks/useUpdateReduxState";
import { gameId } from "../../../hooks/getGameId";

export const Multiplication4 = ({state, handleUpdateProgress, handleOpenScore, answerSound, point, level, count, hint, subscription}) => {
    const {name, gameName} = useParams();
    const updateState = useUpdateReduxState();
    const score = useRef(0);
    const gameCount = useRef(0);
    
    const selected = useRef([]);
    const number1 = useRef(getNumber(level));
    const number2 = useRef(getNumber(level, "second"));
    const answers = useRef(getAnswers(number1.current*number2.current));
    const [result, setResult] = useState(new Array(String(number1.current*number2.current).length).fill(undefined));

    const soundRef = useRef();
    const soundWrongRef = useRef();

    useEffect(()=>{
        amplitude.track(`${name+"_"+gameName.replace("_", " ")+"_"+level+"_start"}`)
      }, []);
      
    const refresh = (event, i, answer) => {
        const answerArr = String(number1.current*number2.current).split("");
        answerArr.forEach((item, index)=>{
            const elem = document.querySelector(".drop"+index);
            const elemCoord = elem.getBoundingClientRect();
            if (
                elemCoord &&
                event.clientX<elemCoord.x+elemCoord.width && event.clientX>=elemCoord.x && 
                event.clientY<elemCoord.y+elemCoord.height && event.clientY>=elemCoord.y
            ) {

                if (item == answer) {
                    selected.current.push(i);
                    const newArr = [...result];
                    newArr[index] = answer;
                    setResult(newArr);
                } else {
                    if (soundWrongRef.current && answerSound ) {
                        soundWrongRef.current.currentTime = 0;
                        soundWrongRef.current.play();
                     }
                }
            }
        })
    }

    useEffect(()=>{
        if (!result.includes(undefined)) {
            if (soundRef.current && answerSound ) {
                soundRef.current.currentTime = 0;
                soundRef.current.play();
             }
            score.current += point[level];
            if(!subscription) {
                updateStorage(point[level]);
            }
           if (gameCount.current === count-1) {
                if (subscription) {
                    updateState({
                        userId: localStorage.getItem('user'),
                        profileId: JSON.parse(localStorage.getItem('profile'))?._id,
                        game: name,
                        score: score.current,
                        id: gameId(name, gameName),
                        collection: name,
                    })
                } else {
                    handleUpdateProgress();
                    updateGameStorage(name, gameName, point[level], true, level);
                }
                setTimeout(()=>handleOpenScore(), animationDurationToShowscoresPopup*1000);
                localStorage.setItem(`${name+'_'+gameName+'_'+level+'_score'}`, score.current);
           } else {
              setTimeout(()=>{
                gameCount.current+=1;
                number1.current = getNumber(level);
                number2.current = getNumber(level, "second");
                updateGameStorage(name, gameName, point[level], false, level);
                answers.current = getAnswers(number1.current*number2.current);
                setResult(new Array(String(number1.current*number2.current).length).fill(undefined));
                selected.current = [];
             }, animationDurationToMoveCorrectAnswer*1000);
            if (!subscription) {
                handleUpdateProgress();
            } 
           }
        }
      }, [result]);

    
    return (
        <div
           className="w-full h-full flex flex-col justify-between"
        >
            <TopPart
                state={state}
                level={level}
                result={result}
                number1={number1.current}
                number2={number2.current}
            />
            <BottomPart
                hint={hint}
                state={state}
                result={result}
                answer={String(number1.current*number2.current)}
                answers={answers.current}
                refresh={refresh}
                selected={selected.current}
            />
            <Sound ref={soundRef} url={"/backGrounds/right_answer.wav"} loop={false}/>
            <Sound ref={soundWrongRef} url={"/backGrounds/wrong_answer.wav"} loop={false}/>
        </div>
    )
}