export const calcGapY = (state) => {
    if (window.innerWidth<1024) {
        return (260-39*3) / 4;
    }
    if (window.innerWidth>=1024 && window.innerWidth<1440) {
       if (state) {
          return (286-39*3) / 4;
       } else {
          return (354-49*3) / 4;
       }
    }
    if (window.innerWidth>=1440 && window.innerWidth<1920) {
        if (state) {
            return (364-50*3) / 4;
        } else {
            return (446-69*3) / 4;
        }
    }
    if (window.innerWidth>=1920) {
        if (state) {
            return (448-70*3) / 4;
        } else {
            return (538-89*3) / 4;
        }
    }
}

export const getCoordinates = () => {
    const elem = document.querySelector('.adding');
    const answer = document.querySelector('.answer');
    const x1 = elem.getBoundingClientRect().x;
    const y1 = elem.getBoundingClientRect().y;
    const x2 = answer.getBoundingClientRect().x;
    const y2 = answer.getBoundingClientRect().y;
    const xDistance = x1 - x2;
    const yDistance = y1 - y2;
    return [
              xDistance-(answer.getBoundingClientRect().width-elem.getBoundingClientRect().width)/2, 
              yDistance-(answer.getBoundingClientRect().height-elem.getBoundingClientRect().height)/2
           ];
}

export const TopPartNumbers = (level, part) => {
    const numbersArray = level === "beginner" ? [4,6,8,10,12,14,9] :[4,6,8,10,12,14,9,16,18,20];
    const num = numbersArray[Math.floor(Math.random()*numbersArray.length)];

    const max = num-1;
    const min = 2
    
  while (true) {
     const number = Math.floor(Math.random() * (max - min + 1) + min);
     if (num%number === 0) {
        var num2 = number;
        break;
     }
  }
    if (part === "bottom") {
        if (level === "advanced") {
            return [num, num2];
        } else {
            return [num];
        }
    } else {
        return [num, num2];
    }
    
}

export const BottomPartNumbers = (level) => {
   return TopPartNumbers(level, "bottom");
}

export const getAnswer = (arr1, arr2) => {
      return arr1.reduce((aggr, item)=>{
          return aggr/=item;
      })+ arr2.reduce((aggr, item)=>{
        return aggr/=item;
    })
}

export const getAnswers = (answer, level) => {
    const max = 20;
    const min = 1;
    let uniqueAnswers = new Set();
    uniqueAnswers.add(answer);
    while (uniqueAnswers.size < 3) {
      uniqueAnswers.add(Math.floor(Math.random() * (max - min + 1) + min));
    }
    return Array.from(uniqueAnswers).sort(()=>Math.random()-0.5);
}