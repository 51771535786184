export const getBottomGapX = (state) => {
    if (window.innerWidth <  1024) {
      return (425-6*39)/7;
    }
    if (window.innerWidth>=1024 && window.innerWidth<1440) {
      if (state) {
        return (439-6*39)/7;
      } else {
        return (576-6*49)/7;
      } 
    }
    if (window.innerWidth>=1440 && window.innerWidth<1920) {
      if (state) {
        return (590-6*50)/7;
      } else {
        return (723-6*69)/7;
      } 
    }
    if (window.innerWidth>=1920) {
      if (state) {
        return (768-6*70)/7;
      } else {
        return (909-6*89)/7;
      } 
    }
}

export const getWidth = (state, answer, count) => {
    if (window.innerWidth <  1024) {
        return answer.length*34+((answer.length-1+(count || 0))*32);
    }

    if (window.innerWidth>=1024 && window.innerWidth<1440) {
        if (state) {
            return answer.length*40+((answer.length-1+(count || 0))*39);
        } else {
            return answer.length*45+((answer.length-1+(count || 0))*46);
        } 
    }
    if (window.innerWidth>=1440 && window.innerWidth<1920) {
        if (state) {
            return answer.length*50+((answer.length-1+(count || 0))*50);
        } else {
            return answer.length*60+((answer.length-1+(count || 0))*60);
        } 
    }
    if (window.innerWidth>=1920) {
        if (state) {
            return answer.length*70+((answer.length-1+(count || 0))*63);
        } else {
            return answer.length*80+((answer.length-1+(count || 0))*53);
        } 
    }
   
}

export const getNumber = (level) => {
    if (level === "beginner") {
        const max = 99;
        const min = 10;
        return Math.floor(Math.random() * (max - min + 1) + min);
    }
    if (level === "intermediate") {
        const max = 100;
        const min = 999;
        return Math.floor(Math.random() * (max - min + 1) + min);
    }
    if (level === "advanced") {
        const max = 1000;
        const min = 9999;
        return Math.floor(Math.random() * (max - min + 1) + min);
    }
}

export const getAnswers = (answer) => {
    const arr = new Set();
    const min = 0;
    const max = 9;
    while(arr.size<6-String(answer).length) {
      arr.add(Math.floor(Math.random() * (max - min + 1) + min));
    }
    const newArray = [...Array.from(arr), ...String(answer).split("").map(item=>+item)];
    return newArray.sort(()=>Math.random()-0.5);
}