import { NavLink } from "react-router-dom";
import { IconUserImages } from "../../../index";
import {useTranslation} from "react-i18next";
import { useEffect } from "react";
import { formatedDate } from "../../../../hooks/useCurrentDate";
import { useSelector, useDispatch } from "react-redux";
import { getUserDataThunk } from "../../../../redux/features/userSlice";


export const SubscriptionInfo = ({products, getProduct, calcSave, handleSubscription}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const user = useSelector((state) => state.user.user);
    const login = useSelector((state) => state.user.login);
    

    useEffect(()=>{
        if (login) {
            dispatch(getUserDataThunk({userId: localStorage.getItem('user')}));
        }
    }, []);

    return(
            <div
               className="text-center relative w-[640px] bg-cover h-[302px] rounded-[13px] md:w-[652px] md:h-[428px] lg:w-[928px] lg:h-[452px] xl:w-[1142px] xl:h-[576px] md:rounded-[16px]  bg-no-repeat
                            xl:bg-[url('/backGrounds/subscriptionInfo/subscriptionInfo_xl.svg')]
                            md:bg-[url('/backGrounds/subscriptionInfo/subscriptionInfo_md.svg')]
                            lg:bg-[url('/backGrounds/subscriptionInfo/subscriptionInfo_lg.svg')]
                            bg-[url('/backGrounds/subscriptionInfo/subscriptionInfo.svg')]
                        "
            >
                <div className="flex items-end w-[103.65px] h-[195px] top-[19px] md:top-[75px] md:left-[23px] md:w-[119px] md:h-[247px] lg:w-[160px] lg:h-[332px] lg:top-0 lg:left-[33px] bg-contain left-[41px] xl:left-[46px] absolute xl:w-[214.94px] xl:h-[445px] bg-[url('/backGrounds/subscriptionInfo/air_ballon_left.svg')]">
                    <IconUserImages user="b1" className="w-[60px] h-[100px] md:w-[96px] md:h-[140px] lg:w-[102px] lg:h-[160px] xl:w-[136px] xl:h-[210px]"/>
                </div>
                <div className="flex items-end justify-end w-[113px] h-[199px] md:w-[130px] md:h-[252px] top-[21px] md:top-[62px] lg:top-0 lg:w-[175px] lg:h-[339px] lg:right-[30px] bg-contain bg-no-repeat right-[41px] md:right-[22px] xl:right-[32px] absolute xl:w-[234.94px] xl:h-[454px] bg-[url('/backGrounds/subscriptionInfo/air_ballon_right.svg')]">
                    <IconUserImages user="g4" className="w-[60px] h-[100px] md:w-[72px] md:h-[110px] lg:w-[98px] lg:h-[160px] xl:w-[130px] xl:h-[210px]"/>
                </div>
                <p 
                   className="text-[14px] xl:text-[20px] font-commisioner text-[#676766] pt-[22px] md:text-[16px] md:pt-[59px] lg:text-[18px] lg:pt-[72px] xl:pt-[115px]"
                >
                    Unlimited Access to All MathMinds Games
                </p>

                <div className="w-[300px] h-[167px] md:w-[334px] lg:w-[440px] xl:w-[590px] xl:h-[290px] lg:h-[210px] mx-auto mt-[14px] md:mt-[28px] xl:mt-[24px]">
                   <div className="w-full h-[69px] md:h-[86px] lg:h-[90px] xl:h-[120px] flex justify-between">
                       <div className={`${user.subscriptionType === 2 ? "border-[#7F48C3]" : "border-[#E0E0ED]"} md:bg-[position:0%_60%] px-[10px] py-[5px] lg:px-[19px] xl:px-[16px] xl:text-[20px] text-left font-commisioner text-[12px] md:text-[18px] text-[#7F48C3] relative w-[143px] h-full md:w-[156px] lg:w-[209px] xl:w-[280px] rounded-[6px] border-[2px] bg-[url('/backGrounds/subscriptionInfo/btn_left_star.svg')] bg-contain`}>
                              
                          <p className="font-bold">Yearly</p>
                          <p className="mt-[2px] xl:mt-[10px]">&#36; {products.length && getProduct(products, "year").unit_price.amount/100}</p>
                          <p className="text-[10px] mt-[2px] xl:mt-[7px] xl:text-[14px]">
                               {products.length && t('12months_as').replace('x', `${'$'+(getProduct(products, "year").unit_price.amount/(12*100)).toFixed(2).replace('.', ',')}`)}
                          </p>
                          <div
                             className="absolute py-[7px] md:py-[9px] xl:py-[14px] text-[#FFF] text-[10px] lg:text-[13px] xl:text-[14px] font-commisioner text-center w-[35px] h-[69px] md:w-[41px] md:h-[87px] lg:w-[48px] lg:h-[90px] md:text-[12px] xl:w-[64px] bg-[url('/backGrounds/subscriptionInfo/ballon.svg')] xl:h-[120px] right-[5px] lg:right-[20px] xl:right-[30px] top-0 xl:top-[10px] bg-[length:100%_100%]"
                           >
                              <p>Save</p>
                              <p className="mt-[-3px]">{products.length && calcSave(products)}%</p>
                           </div>
                           {user.subscriptionType === 2 && <div className="text-[#BED72D] w-full absolute text-[12px] md:text-[14px] xl:text-[16px] font-commisioner font-bold top-[110%] left-0">
                               {user.purchaseRecipt && user.purchaseRecipt.latest_receipt_info.tral_period_start_ms ? user.purchaseRecipt.latest_receipt_info.is_trial_period ? "Trial "+formatedDate(user.purchaseRecipt.latest_receipt_info.tral_period_start_ms)+"-"+formatedDate(user.purchaseRecipt.latest_receipt_info.tral_period_end_ms): 
                               "Date "+formatedDate(user.purchaseRecipt.latest_receipt_info.purchase_date_ms)+"-"+formatedDate(user.purchaseRecipt.latest_receipt_info.expires_date_ms) :""}
                            </div>}
                       </div>
                       <div className={`${user.subscriptionType === 1 ? "border-[#7F48C3]" : "border-[#E0E0ED]"} relative w-[143px] px-[10px] text-left lg:px-[19px] font-commisioner text-[12px] md:text-[18px] text-[#7F48C3] py-[5px] h-full md:w-[156px] lg:w-[209px] xl:w-[280px] rounded-[6px] border-[2px]  bg-[url('/backGrounds/subscriptionInfo/btn_right_star.svg')] bg-contain`}>
                            <p className="font-bold">Monthly</p>
                            <p className="mt-[2px] xl:mt-[10px]">
                                &#36; {products.length && getProduct(products, "month").unit_price.amount/100}
                            </p>
                            {user.subscriptionType === 1 && <div className="text-[#BED72D] w-full absolute text-[12px] md:text-[14px] xl:text-[16px] font-commisioner font-bold left-0 top-[110%]">
                               {user.purchaseRecipt && user.purchaseRecipt.latest_receipt_info.purchase_date_ms  ? "Date "+formatedDate(user.purchaseRecipt.latest_receipt_info.purchase_date_ms)+"-"+formatedDate(user.purchaseRecipt.latest_receipt_info.expires_date_ms) : ""}
                            </div>}
                       </div>
                   </div>

                   <div
                      onClick={(e)=>{
                        e.stopPropagation();
                        handleSubscription("", true);
                      }}
                      className="cursor-pointer flex items-center justify-center w-[145.56px] h-[26.5px] md:w-[167.7px] md:h-[30px] lg:w-[225px] lg:h-[40px] xl:w-[352px] xl:h-[63px] rounded-[23.4px] bg-[#F67300] text-[#fff] mt-[36px] md:mt-[60px] lg:mt-[51px] xl:mt-[60px] mx-auto font-commisioner font-bold text-[14px] md:text-[16px] xl:text-[20px]"
                   >
                       Start Free Trial
                   </div>

                   <div
                        onClick={(e)=>{
                          e.stopPropagation();
                          handleSubscription("", true)
                        }}
                        className="text-[10px] font-commisioner text-[#4B96C9] mt-[10px] md:mt-[12px] xl:mt-[21px] md:text-[12px] cursor-pointer"
                   >
                       Restore Purchase
                   </div>
                   
                </div>

                <p
                  className="xl:w-[1099px] xl:h-[69px] xl:text-[14px] w-[600px] h-[41px] md:w-[598px] md:h-[52px] lg:w-[862px] mx-auto text-[9px] md:text-[10px] lg:text-[12px] text-[#9E9E9E] font-commisioner md:mt-[50px] lg:mt-[11px]"
                >
                    Your subscription automatically renews unless auto-renew is turned off at least 24-hours before the end of the current period.Payment will be charged to Play Market Account at confirmationof purchase. Your account will be charged for renewal within24-hours prior to the end of the current period and identify thecost of the renewal. Subscriptions can be managed and canceledfrom Account Settings after purchase.
                </p>

                <div
                   className="mt-[5px] w-[159px] mx-auto h-[21px] lg:w-[162px] text-[10px] md:mt-[12px] xl:mt-[5px] font-commisioner flex justify-between items-center"
                >
                   <NavLink to="/terms_of_use">
                       <p className="text-[#4B96C9]">Privacy policy</p>
                    </NavLink>
                    <p className="text-[#9E9E9E]">&#38;</p>
                    <NavLink to="/privacy_policy">
                       <p className="text-[#4B96C9]">Terms of use</p>
                    </NavLink>
                </div>

            </div>
    )
}