

import {CircleButton} from "../../../../components/elements/CircleButton.jsx";
import { DragAndDrop } from "../../../../components/global/DragAndDrop";
import { motion } from "framer-motion";
import { 
    animationDurationToShowQuestion,
} from "../../../../config";
import React, {useEffect, useState} from "react";


let showHint;

export function BottomRowsOfStepByStep({numbers, gameCount, state, sequence, refresh, selected, copySequence, hint, screenSize}) {
    const [i, setI] = useState('');
    const [isDragging, setIsDragging] = useState(false);

    const [animate, setAnimate] = useState(false);
    const [start, setStart] = useState({x:0, y:0});   
    const [end, setEnd] = useState({x:0, y:0});
    const [time, setTime] = useState(0);

    const [update, setUpdate] = useState(false);

    useEffect(()=>{
        if (!animate) return;
        setAnimate(false);
        setI('');
        setTime(0);
    }, [numbers]);

    const amimNumbers = [-100, -101, -103, -104, -105, -106, -107, -108, -109, -110, -111];

    useEffect(()=>{
        showHint = true;
        if(copySequence.indexOf(null)===-1) return;
        setTime(0);
        const start = document.querySelector('.answer');
        const end = document.querySelector('.stepbystep'+(copySequence.indexOf(null)));
        if (!start || !end) {
            setUpdate(!update);
            return;
        }
        const startCoord = start.getBoundingClientRect();
        const endCoord = end.getBoundingClientRect();
        setStart({x:startCoord.x, y:startCoord.y});
        setEnd({x:endCoord.x+endCoord.width-endCoord.height, y:endCoord.y});
    }, [copySequence, update, numbers, screenSize]);

    useEffect(()=>{
        if (!hint) {
            setTime(0);
            setI('');
            return;
        }
        if (time==10) return;
        const id = setInterval(()=>setTime(prev=>prev+1), 1000);
        return ()=>clearInterval(id);
    }, [time, hint]);
    
    return (
        <div
            className={`w-full ${state ? "h-[185px] md:h-[203px] lg:h-[257px] xl:h-[316px]" : "h-[185px] md:h-[252px] lg:h-[315px] xl:h-[380px]"} items-center justify-evenly grid grid-cols-5 bg-white border-4 border-[#2F9EBA] rounded-[20px]`}>
            {numbers.map((number, index) => {
                if (i===index) {
                    return(
                        <DragAndDrop
                            key={index} 
                            enableClick = {false}
                            item = {number}
                            cmp={'stepbystep'}
                            dragging = {(state)=>setIsDragging(state)}
                            drop={sequence.indexOf(number)===-1 ? 'stepbystep0' : 'stepbystep'+sequence.indexOf(number)}
                            refresh={(sound) => {
                                showHint = false;
                                setI('');
                                refresh(number, sound);
                            }}
                        >
                            <motion.div
                                animate={{y: animate ? [0, 0] : [-amimNumbers[gameCount], 0]}}
                                transition={{duration: animationDurationToShowQuestion*(index+1)/numbers.length, type: "tween"}}  
                                className={`flex justify-center relative answer item z-[100] ${ selected.includes(number) ? 'opacity-0 cursor-none' : 'opacity-100 cursor-pointer'}`} id={index}
                            >
                                <CircleButton key={index} isActive={number}>
                                    {number}
                                </CircleButton>
                                {hint && !isDragging && showHint && time==10 &&  numbers.indexOf(sequence[copySequence.indexOf(null)])===index && <motion.div
                                        animate={{y: [0, end.y-start.y], x: [0, end.x-start.x]}}
                                        transition={{duration: 2, type: "tween", ease: "linear", repeat: Infinity}}
                                        className="bg-[url('/backGrounds/hint.svg')] bg-[position:50%_50%] bg-contain bg-no-repeat absolute w-[50px] h-[50px] top-[50%] z-[500]"></motion.div>}
                            </motion.div>
                        </DragAndDrop>
                    )
                } else {
                   return (
                    <motion.div
                        animate={{y: animate ? [0, 0] : [-amimNumbers[gameCount], 0]}}
                        transition={{duration: animationDurationToShowQuestion*(index+1)/numbers.length, type: "tween"}}  
                        className={`flex justify-center relative item z-30 ${ numbers.indexOf(sequence[copySequence.indexOf(null)])===index && 'answer'} ${ selected.includes(number) ? 'opacity-0 cursor-none' : 'opacity-100 cursor-pointer'}`} id={index}
                        onPointerEnter={(e)=>{
                            if (!isDragging) {
                                setI(index);
                                setAnimate(true);
                            }
                        }}
                        onTouchStart={(e)=>{
                            if (!isDragging) {
                                setI(index);
                                setAnimate(true);
                            }
                        }}
                    >
                        <CircleButton key={index} isActive={number}>
                            {number}
                        </CircleButton>
                        {hint && showHint && time==10 &&  numbers.indexOf(sequence[copySequence.indexOf(null)])===index && <motion.div
                                        animate={{y: [0, end.y-start.y], x: [0, end.x-start.x]}}
                                        transition={{duration: 2, type: "tween", ease: "linear", repeat: Infinity}}
                                        className="bg-[url('/backGrounds/hint.svg')] bg-[position:50%_50%] bg-contain bg-no-repeat absolute w-[50px] h-[50px] top-[50%] z-[500]"></motion.div>}
                    </motion.div>
                   )
                }
            })}
        </div>
    );
}
