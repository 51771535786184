import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchData } from '../../hooks/fetchData';
import { dbDate } from '../../hooks/useCurrentDate';

export const updateProfileGameThunk = createAsyncThunk(
    'game/updateData',
    async ({userId, profileId, date=dbDate(), score, game, id}, thunkAPI) => {
      try {
        const response = await fetchData(`games/${game}/${id}`, 'post', {userId, profileId, date, score});
        return thunkAPI.fulfillWithValue(response.data);
      } catch (err) {
        return thunkAPI.rejectWithValue();
      }
    },
);

export const getProfileGameThunk = createAsyncThunk(
  'game/getData',
  async ({userId, profileId, date=dbDate(), collection}, thunkAPI) => {
    try {
      const response = await fetchData(`games/data`, 'post', {userId, profileId, date, collection});
      return thunkAPI.fulfillWithValue(response.data);
    } catch (err) {
      return thunkAPI.rejectWithValue();
    }
  },
);

export const getTotalScoreThunk = createAsyncThunk(
  'game/getScore',
  async ({userId, profileId, date=dbDate()}, thunkAPI) => {
    try {
      const response = await fetchData(`games/score`, 'post', {userId, profileId, date});
      return thunkAPI.fulfillWithValue(response.data);
    } catch (err) {
      return thunkAPI.rejectWithValue();
    }
  },
);
export const gameSlice = createSlice({
    name: 'game',
    initialState: {
       gameData: {},
       totalScore: 0
    },
    extraReducers: (builder) => {
        builder
        .addCase(getProfileGameThunk.fulfilled, (state, action) => {
            state.gameData = action.payload.data || {};
        })
        .addCase(getTotalScoreThunk.fulfilled, (state, action) => {
            state.totalScore = action.payload.score?.totalPoints || 0;
        })
        .addCase(updateProfileGameThunk.fulfilled, (state, action) => {

        })

      },
  });

  export default gameSlice.reducer;