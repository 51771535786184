
import React, {useRef, useState} from "react";
import { IconUserImages, IconUserChristmasImages } from "../../../elements/Icon";
import { checkChristmas } from "../../../../hooks/useCheckChristmas";

export const EditProfile = ({handleSave, profile, handleState, handleDelete}) => {
    const sliderRef= useRef(null);
    const [nickName, setNickName] = useState(profile ? profile.nickName : "");
    const [avatar, setAvatar] = useState(profile ? profile.avatar : "");

    const avatars = [
        "g1", "g3", "g4", "g5",
        "b1", "b2", "b3", "b4", "b5", "b6", "g2",
    ];

    const prev = () => {
        if (sliderRef.current) {
            sliderRef.current.scrollLeft += 79;
        }
    };

    const next = () => {
        if (sliderRef.current) {
            sliderRef.current.scrollLeft -= 79;
        }
    };

    return (
        <div className="w-[445px] h-full py-[20px] md:w-[577px] lg:w-[776px] xl:w-[907px] xl:py-[32px] relative">
            <img 
                onClick={handleState}
                src="/backGrounds/profile/back.svg"
                className="cursor-pointer absolute w-[40px] h-[40px] -translate-y-[30%] translate-x-[-205%] md:translate-x-[-60%] md:w-[50px] md:h-[50px] md:-translate-y-[35%] lg:-translate-y-[20%] lg:w-[60px] lg:h-[60px] lg:translate-x-[-100%] xl:translate-x-[-160%]" 
            />
            <img 
                onClick={handleDelete}
                src="/backGrounds/profile/delete.svg"
                className="w-[20px] h-[20px] -translate-y-[25%] cursor-pointer absolute right-[4%] md:right-[17%] lg:right-[15%] xl:right-[14%] lg:translate-y-[25%] lg:w-[30px] lg:h-[34px]" 
            />
            <button
               onClick={()=>handleSave(avatar, nickName)}
               disabled={avatar && nickName ? false : true} 
               className={`${avatar && nickName ? "bg-[#885AC0]" : "bg-[#CECECE]"} right-0 translate-x-[90%] md:translate-x-[20%] -translate-y-[30%] lg:translate-y-0 lg:translate-x-[35%] xl:translate-x-[45%]  text-[16px] md:text-[20px] lg:text-[24px] absolute w-[89px] h-[25px] md:w-[110px] md:h-[31px] lg:w-[160px] lg:h-[45px] xl:w-[192px] xl:h-[54px] xl:text-[28px] rounded-[8px] lg:rounded-[13px] text-[#FFF] font-fredokaOne`}
            >
                Save
            </button>
            <p className="w-full text-center text-[16px] md:text-[20px] lg:text-[24px] font-fredokaOne text-[#F68C2F] xl:text-[28px]">Edit Profile</p>
            <div className="text-[14px] md:text-[16px] font-commisioner text-[#4B96C9] min-w-[116px] lg:text-[20px] text-center h-[0px] mx-auto mt-[37px] md:mt-[79px] lg:h-[0px] xl:h-[0px] xl:min-w-[174px] lg:mt-[63px] xl:text-[24px]">
            </div>

            <input
                className="bg-transparent border-b-[1px] md:border-b-[3px] border-b-[#CECECE] w-full h-[30px] md:h-[40px] xl:h-[50px]  placeholder:text-[#CECECE] placeholder:font-fredokaOne outline-none
                              placeholder:text-[16px] md:placeholder:text-[20px] lg:placeholder:text-[24px] xl:placeholder:text-[28px] placeholder:text-center placeholder:pb-[5px]
                              text-center font-fredokaOne text-[16px] md:text-[20px] lg:text-[24px] xl:text-[28px] text-[#F68C2F] mt-[16px] md:mt-[37px] lg:mt-[24px] xl:mt-[30px] pb-[12px]
                          "
                placeholder="NickName"
                type="text"
                value={nickName}
                onChange={(e)=>setNickName(e.target.value)}
            />

            <p className="mt-[23px] md:mt-[34px] lg:mt-[42px] w-full text-center text-[14px] lg:text-[16px] xl:text-[24px] xl:mt-[47px] font-commisioner text-[#4B96C9]">Select Avatar</p>
            {/* slider  */}
            <div className="mx-auto mt-[15px] flex w-[420px] md:w-[470px] lg:w-[662px] xl:w-[835px] items-center font-fredokaOne md:mt-[19px] lg:mt-[18px] xl:mt-[30px]">
                <img
                    src={"/backGrounds/profile/arrow.svg"}
                    className="w-[12px] h-[20px] md:w-[15px] md:h-[25px] lg:w-[19px] lg:h-[31px] cursor-pointer"
                    onClick={(e)=>{
                        e.stopPropagation();
                        next();
                    }}
                />
                <div ref={sliderRef} className="w-[351px] h-[79px] md:w-[399px] md:h-[105px] lg:w-[546px] lg:h-[134px] xl:h-[183px] xl:w-[749px] flex justify-between overflow-hidden mx-auto">
                    {avatars.map((item)=>{
                        return <div key={item} onClick={()=>setAvatar(item)} className={`cursor-pointer overflow-hidden min-w-[79px] mr-[57px] md:mr-[42px] md:min-w-[105px] lg:min-w-[134px] xl:min-w-[183px] lg:mr-[72px] xl:mr-[100px] border-[4px] ${avatar===item ? "border-[#885AC0] bg-[#CCAED1]" : "border-[#E3D8D8] bg-[#D7CCD9]"} rounded-[50%]`}>
                            {checkChristmas() && <IconUserChristmasImages
                                user={item}
                                className={`-translate-y-[10%] ${item==="b2" && "-translate-x-[15%]"}`}
                            />}
                            {!checkChristmas() && <IconUserImages
                                user={item}
                                className={`-translate-y-[10%] ${item==="b2" && "-translate-x-[15%]"}`}
                            />}
                        </div>
                    })}
                </div>
                <img
                    src={"/backGrounds/profile/arrow.svg"}
                    className="w-[12px] h-[20px] md:w-[15px] md:h-[25px] lg:w-[19px] lg:h-[31px] rotate-180 cursor-pointer"
                    onClick={(e)=>{
                        e.stopPropagation();
                        prev();
                    }}
                />
            </div>
        </div>
    )
}