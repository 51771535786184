
import React, { useState, useRef, useEffect } from 'react';
import { generateImages, generateAnswers } from './Adding.data';
import { 
    animationDurationToShowscoresPopup,
    animationDurationToMoveCorrectAnswer
} from "../../../config";
import { useParams } from 'react-router-dom';
import { updateStorage } from "../../../hooks/useStorage";
import { updateGameStorage } from "../../../hooks/useGameStorage";
import RightPart from './components/RightPart';
import LeftPart from './components/LeftPart';
import * as amplitude from '@amplitude/analytics-browser';
import { gameId } from "../../../hooks/getGameId";
import { useUpdateReduxState } from "../../../hooks/useUpdateReduxState";


export const Adding = ({state, handleOpenScore, count, handleUpdateProgress, answerSound, point, hint, subscription}) => {
    const gameStore = JSON.parse(localStorage.getItem('playedGames'));
    const {name, gameName} = useParams();
    const [answers, setAnswers] = useState(generateAnswers());
    const number = useRef(answers[Math.floor(Math.random()*answers.length)]);
    const number1 = useRef(Math.floor(Math.random() * number.current) || 1);
    const number2 = useRef(number.current-number1.current);
    const images = useRef(generateImages());
    const timeduration = [100];
    const [result, setResult] = useState('?');
    const score = useRef(0);
    const gameCount = useRef(0);

    const updateState = useUpdateReduxState();

    useEffect(()=>{
      amplitude.track(`${name+"_"+gameName.replace("_", " ")+"_start"}`)
    }, []);

    while(timeduration.length <= number.current) {
      timeduration.push(100+timeduration[timeduration.length-1])
    }

    const checkAnswer = (event, type, val) => {
        if (event==='mouseup') return;
        score.current += point;
        if (!subscription) {
          updateStorage(point);
        }
        !type && setResult(val);
        if (gameCount.current === count-1 || (gameStore[name] && gameStore[name].find(item=>item.name===gameName)?.all === count-1)) {
            
            if (subscription) {
              updateState({
                userId: localStorage.getItem('user'),
                profileId: JSON.parse(localStorage.getItem('profile'))?._id,
                game: name,
                score: score.current,
                id: gameId(name, gameName),
                collection: name,
              })
            } else {
              handleUpdateProgress();
              updateGameStorage(name, gameName, point, true);
            }
            setTimeout(()=>handleOpenScore(), animationDurationToShowscoresPopup*1000);
            localStorage.setItem(`${name+'_'+gameName+'_'+'_score'}`, score.current);
          } else {
            if (!subscription) {
              updateGameStorage(name, gameName, point, false);
              handleUpdateProgress();
            }
            setTimeout(()=>{ 
              gameCount.current +=1
              const data = generateAnswers();
              setAnswers(data);
              setResult('?');
              number.current = data[Math.floor(Math.random()*data.length)];
              number1.current = Math.floor(Math.random() * number.current) || 1;
              number2.current = number.current-number1.current;
              images.current = generateImages();
              
            }, animationDurationToMoveCorrectAnswer*1000);
          }
    }

    return (
        <div className="w-full h-full flex justify-between relative z-30">
           <LeftPart state={state} number1={number1.current} number2={number2.current} timeduration={timeduration} result={result} images={images}/>
           <RightPart hint={hint} state={state} answers={answers} checkAnswer={checkAnswer} answer={number} answerSound={answerSound}/>
        </div>
    )
}
