import { CircleButton } from '../../../../components/elements/CircleButton';
import { 
    animationDurationToShowQuestion,
} from "../../../../config";
import { motion } from 'framer-motion';

export const TopPart = ({state, firstNumber, secondNumber, timeduration, result}) => {
    return(
        <div
            className={`flex overflow-hidden items-center w-full border-[3px] rounded-[16px] border-[#2F9EBA] bg-[#FFF] lg:rounded-[20px] 
                       ${state ? "h-[185px] md:h-[203px] lg:h-[257px] xl:h-[316px]" : "h-[185px] md:h-[252px] lg:h-[315px] xl:h-[380px]"}
                       text-[#FFF] font-bigfatScript justify-around
            `}
        >
            <div style={{transform: `rotateX(180deg)`}} className={`w-[169px] ${firstNumber<10 ? "flex items-center justify-center" : "grid grid-cols-10"} ${state ? "md:w-[172px] lg:w-[238px] xl:w-[313px]" : "xl:w-[370px] md:w-[236px] lg:w-[295px]"}`}>
                    {
                        [...new Array(firstNumber)].map((item, i)=>{
                            return (
                                <div 
                                   key={i} 
                                   className={`rounded-[3px] border-[1px] border-[#BBDEEB] lg:rounded-[4px] ${firstNumber<10 ? "bg-[#ffc15c]": i>=firstNumber-(firstNumber%10) ? "bg-[#ffc15c]" : "bg-[#3FA3C8]"} w-[16.9px] h-[16.9px] ${state ? "md:w-[17.2px] md:h-[17.2px] lg:w-[23.8px] lg:h-[23.8px] xl:w-[31.3px] xl:h-[31.3px]" : "md:w-[23.6px] md:h-[23.6px] lg:w-[29.5px] lg:h-[29.5px] xl:w-[37px] xl:h-[37px]"}`} 
                                />
                            )
                        })
                    }        
            </div>
            <CircleButton className={"addingresult"} isActive={result === "?" ? false : result}>
                <div className='adding'>
                    {result}
                </div>
                
            </CircleButton>
            <div style={{transform: `rotateX(180deg)`}} className={`w-[169px] ${secondNumber<10 ? "flex items-center justify-center" : "grid grid-cols-10"} ${state ? "md:w-[172px] lg:w-[238px] xl:w-[313px]" : "xl:w-[370px] md:w-[236px] lg:w-[295px]"}`}>
                    {
                        [...new Array(secondNumber)].map((item, i)=>{
                            return (
                                <div 
                                   key={i} 
                                   className={`rounded-[3px] border-[1px] border-[#BBDEEB] lg:rounded-[4px] ${secondNumber<10 ? "bg-[#ffc15c]": i>=secondNumber-(secondNumber%10) ? "bg-[#ffc15c]" : "bg-[#3FA3C8]"} w-[16.9px] h-[16.9px] ${state ? "md:w-[17.2px] md:h-[17.2px] lg:w-[23.8px] lg:h-[23.8px] xl:w-[31.3px] xl:h-[31.3px]" : "md:w-[23.6px] md:h-[23.6px] lg:w-[29.5px] lg:h-[29.5px] xl:w-[37px] xl:h-[37px]"}`} 
                                />
                            )
                        })
                    }        
            </div>
        </div>
    )
}