
import { subscriptionAsyncThunk } from "../redux/features/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { isProd } from "../config";

export const PaddleInit = () => {
    const dispatch = useDispatch();
    const user = useSelector(state=>state.user.user);
    Paddle.Environment.set(isProd ? "production" : "sandbox");
    Paddle.Initialize({ 
        token: isProd ? "live_638d94cc118d2eb65971356f7bb" : 'test_23d7882c3b86871ff9c260e18c8', // replace with a client-side token
        eventCallback: function(data) {
            if (data.name == "checkout.completed") {
                console.log('checkout.completed success', data)
                Paddle.Checkout.close();
                dispatch(subscriptionAsyncThunk(
                    {
                        userId: localStorage.getItem("user") || user._id,
                        paddle_customer_id: data.data.customer.id,
                        paddle_email: data.data.customer.email,
                        product_id: data.data.items[0].product.id,
                        product_name: data.data.items[0].product.name,
                        quantity: data.data.items[0].quantity,
                        trial_period: data.data.items[0].trial_period,
                        status: data.data.status,
                        transaction_id: data.data.transaction_id
                    }
                )).then(()=>{
                    navigate(pathname);
                }).catch((err)=>{
                console.log(err);
                })
            } else {
                console.log('checkout.inCompleted error', data)
            }
        }
    });
}