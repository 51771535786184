import { useState, useRef, useEffect } from "react";
import { API_URL } from "../../../config";
import { SubscriptionInfo } from "./components/SubscriptionInfo";
import { SubscriptionPurchase } from "./components/SubscriptionPurchase";

const calculateFontSize = (width, height, content) => {
    return Math.sqrt(width * height / content.length);
}

const getProduct = (products, type) => {
   return products.find((product)=> product.billing_cycle?.interval === type);
}

const calcSave = (products) => {
    const year = getProduct(products, "year").unit_price.amount/(12*100);
    const month = getProduct(products, "month").unit_price.amount/100;
    return parseInt((1-year/month)*100);
}

export const Subscription = ({handleSubscription, screenSize, subscriptionType}) => {
    const [type, setType] = useState("");
    const [products, setProducts] = useState([]);

    const handleType = (val) => {
        if (type === val) {
            setType("");
        } else {
            setType(val);
        }
    }

    useEffect(()=>{
        fetch(`${API_URL}/products`).then(res=>res.json()).then(res=>{
           setProducts(res.data)
        });
    }, []);

    return (
        <div onClick={handleSubscription} className={`absolute h-screen w-full bg-bgBlack/49 z-[100] flex items-center justify-center`}>
            {subscriptionType && <SubscriptionInfo 
                products={products} 
                getProduct={getProduct} 
                calcSave={calcSave} 
                handleSubscription={handleSubscription}
            />}
            {!subscriptionType && <SubscriptionPurchase 
                type={type} 
                subscriptionType={subscriptionType} 
                calculateFontSize={calculateFontSize} 
                screenSize={screenSize} 
                handleType={handleType} 
                products={products} 
                getProduct={getProduct} 
                calcSave={calcSave}
                handleSubscription={handleSubscription} 
            />}
        </div>
    )
}