import { useState, useEffect, useRef } from "react";
import { Main } from "./components/Main";
import { Number } from "./components/Number";
import { 
  animationDurationToShowscoresPopup,
} from "../../../config";
import { useParams } from 'react-router-dom';
import { updateStorage } from "../../../hooks/useStorage";
import { updateGameStorage } from "../../../hooks/useGameStorage";
import * as amplitude from '@amplitude/analytics-browser';
import Sound from "../../../components/global/Sound";
import { useUpdateReduxState } from "../../../hooks/useUpdateReduxState";
import { gameId } from "../../../hooks/getGameId";

export const Learning = ({state, handleOpenScore, handleUpdateProgress, answerSound, point, subscription}) => {
  const {name, gameName} = useParams();
  const [selectedNumber, setSelectedNumber] = useState(0);
  const doneNumbers = useRef([]);
  const score = useRef(0);
  const soundRef = useRef();

  const updateState = useUpdateReduxState();

  useEffect(()=>{
    amplitude.track(`${name+"_"+gameName.replace("_", " ")+"_start"}`)
  }, []);

  const handleSelected = (number) => {
     if (number) {
        score.current+=point;
        if (!subscription) {
          updateStorage(point);
        }
        setSelectedNumber(0);
        doneNumbers.current.push(number);
        if (doneNumbers.current.length === 10) {
          if (answerSound) {
            soundRef.current.currentTime = 0;
            soundRef.current.play();
          }
           if (subscription) {
              updateState({
                userId: localStorage.getItem('user'),
                profileId: JSON.parse(localStorage.getItem('profile'))?._id,
                game: name,
                score: score.current,
                id: gameId(name, gameName),
                collection: name,
              })
           } else {
            updateGameStorage(name, gameName, point, true);
            handleUpdateProgress();
           }
           
           setTimeout(()=> {
            handleOpenScore();
          }, animationDurationToShowscoresPopup*1000);
          localStorage.setItem(`${name+'_'+gameName+'_'+'_score'}`, score.current);
        } else {
          if (!subscription) {
            updateGameStorage(name, gameName, point, false);
          }
          
        }
     } else {
        setSelectedNumber(0);
     }
  }
    
return (
    <div className="w-full h-full">
        {!selectedNumber ? <Main
           numbers={doneNumbers.current}
           handleSelected={(num)=>setSelectedNumber(num)}
           state={state}
        /> : <Number number={selectedNumber} handleSelected={handleSelected}/>}
        <Sound ref={soundRef} url={"/backGrounds/right_answer.wav"} loop={false}/>
    </div>
  )
}