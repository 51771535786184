import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchData } from '../../hooks/fetchData';

export const getUserDataThunk = createAsyncThunk(
    'user/data',
    async ({userId}, thunkAPI) => {
      try {
        const response = await fetchData(`user/${userId}`, 'get', {});
        return thunkAPI.fulfillWithValue(response.data);
      } catch (err) {
        return thunkAPI.rejectWithValue();
      }
    },
);

export const getCountriesDataThunk = createAsyncThunk(
    'countries/data',
    async (_, thunkAPI) => {
      try {
        const response = await fetchData('countries', 'get');
        return thunkAPI.fulfillWithValue(response.data);
      } catch (err) {
        return thunkAPI.rejectWithValue();
      }
    },
);

export const updateProfilesDataThunk = createAsyncThunk(
    'profiles/data',
    async ({userId, profiles, country}, thunkAPI) => {
      try {
        const response = await fetchData('profile', 'post', {userId, profiles, country});
        return thunkAPI.fulfillWithValue(response.data);
      } catch (err) {
        return thunkAPI.rejectWithValue();
      }
    },
);

export const deleteProfileDataThunk = createAsyncThunk(
  'deleteProfile/data',
  async ({userId, profileId}, thunkAPI) => {
    try {
      const response = await fetchData('deleteProfile', 'post', {userId, profileId});
      return thunkAPI.fulfillWithValue(response.data);
    } catch (err) {
      return thunkAPI.rejectWithValue();
    }
  },
);

export const checkLoginThunk = createAsyncThunk(
  'checkLogin',
  async (_, thunkAPI) => {
    try {
      const response = await fetchData('loginState', 'get', {});
      return thunkAPI.fulfillWithValue(response.data);
    } catch (err) {
      return thunkAPI.rejectWithValue();
    }
  },
);

export const logOutUserThunk = createAsyncThunk(
  'logOut',
  async (_, thunkAPI) => {
    try {
      const response = await fetchData('logOut', 'get', {});
      return thunkAPI.fulfillWithValue(response.data);
    } catch (err) {
      return thunkAPI.rejectWithValue();
    }
  },
);

export const registrationAsyncThunk = createAsyncThunk(
  'registration',
  async ({userName, userEmail, userPassword, state}, thunkAPI) => {
    try {
      const response = await fetchData(`${state === 'login' ? 'login' : 'register'}`, 'post', {userName, userEmail, userPassword});
      return thunkAPI.fulfillWithValue(response.data);
    } catch (err) {
      return thunkAPI.rejectWithValue();
    }
  },
);

export const forgetPasswordAsyncThunk = createAsyncThunk(
  'forgetPassword',
  async ({email}, thunkAPI) => {
    try {
      const response = await fetchData('forgetPassword', 'post', {email});
      return thunkAPI.fulfillWithValue(response.data);
    } catch (err) {
      return thunkAPI.rejectWithValue();
    }
  },
);

export const resetPasswordAsyncThunk = createAsyncThunk(
  'resetPassword',
  async ({id, token, password}, thunkAPI) => {
    try {
      const response = await fetchData(`resetPassword/${id}/${token}`, 'post', {password});
      return thunkAPI.fulfillWithValue(response.data);
    } catch (err) {
      return thunkAPI.rejectWithValue();
    }
  },
);
export const subscriptionAsyncThunk = createAsyncThunk(
  'subscription',
  async ({
    userId,
    paddle_customer_id,
    paddle_email,
    product_id,
    product_name,
    quantity,
    trial_period,
    status,
    transaction_id
  }, thunkAPI) => {
    try {
      const response = await fetchData(`subscription`, 'post', {
        userId,
        paddle_customer_id,
        paddle_email,
        product_id,
        product_name,
        quantity,
        trial_period,
        status,
        transaction_id
      });
      return thunkAPI.fulfillWithValue(response.data);
    } catch (err) {
      return thunkAPI.rejectWithValue();
    }
  },
);

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        user: {},
        countries: [],
        selectedProfile: null,
        login: false,
        subscription: false
    },
    reducers: {
      updateSelectedProfile: (state, action) => {
        state.selectedProfile = action.payload;
        localStorage.setItem('profile', JSON.stringify(action.payload));
      },
      resetUser: (state, action) => {
        state.selectedProfile = null;
        state.user = {};;
      },
      updateProfiles: (state, action) => {
        state.user.profiles = action.payload;
      },
    },
    extraReducers: (builder) => {
        builder
         .addCase(getUserDataThunk.fulfilled, (state, action) => {
            state.user = action.payload.user;
            state.login = action.payload.login;
            state.subscription = action.payload.subscription;
         })
         .addCase(getCountriesDataThunk.fulfilled, (state, action) => {
            state.countries = action.payload.countries.sort((a,b)=> a.country.localeCompare(b.country));
         })
         .addCase(updateProfilesDataThunk.fulfilled, (state, action) => {
            state.user = action.payload.user;
            state.login = action.payload.login;
            state.subscription = action.payload.subscription;
         })
        .addCase(checkLoginThunk.fulfilled, (state, action) => {
            state.user = action.payload.user;
            state.login = action.payload.login;
            state.subscription = action.payload.subscription;
        })
        .addCase(logOutUserThunk.fulfilled, (state, action) => {
          state.user = {};
          state.login = false;
          state.subscription = false;
        })
        .addCase(registrationAsyncThunk.fulfilled, (state, action) => {
          state.user = action.payload.user;
          state.login = action.payload.login;
          state.subscription = action.payload.subscription;
        })
        .addCase(forgetPasswordAsyncThunk.fulfilled, (state, action) => {
        })
        .addCase(resetPasswordAsyncThunk.fulfilled, (state, action) => {
        })
        .addCase(deleteProfileDataThunk.fulfilled, (state, action) => {
          state.user = action.payload.user;
          state.login = action.payload.login;
          state.subscription = action.payload.subscription;
        })
        .addCase(subscriptionAsyncThunk.fulfilled, (state, action) => {
          state.user = action.payload.user;
          state.login = action.payload.login;
          state.subscription = action.payload.subscription;
        })
      },
  })
  
  export const { updateSelectedProfile, resetUser, updateProfiles } = userSlice.actions;
  
  export default userSlice.reducer;