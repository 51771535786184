
import { DragAndDrop } from "../../../../components/global/DragAndDrop";
import { motion } from "framer-motion";
import { animationDurationToShowQuestion } from "../../../../config";
import { CircleButton } from "../../../../components/elements/CircleButton";
import { getBottomGapX } from "../AddingMix.data";
import React, { useState, useEffect, useRef } from 'react';
import { getCircleComponent } from "../../../../hooks/useCircleButtonColor";

let showHint;

export const BottomPart = ({state, answers, selected, refresh, result, allAnswers, hint}) => {
    const colors = useRef(getCircleComponent(answers));
   const [i, setI] = useState("");
   const [animate, setAnimate] = useState(false);
   const [start, setStart] = useState({x:0, y:0});   
   const [end, setEnd] = useState({x:0, y:0});
   const [time, setTime] = useState(0);

   useEffect(()=>{ 
    if (!animate) return;
    setAnimate(false);
    setI('');
    setTime(0);
    colors.current = getCircleComponent(answers);
   }, [answers]);

   useEffect(()=>{
        showHint = true;
        if(result.indexOf(null)===-1) return;
        const start = document.querySelector('.answer');
        const end = document.querySelector('.drop'+(result.indexOf(null)+1));
        if (!start || !end) return;
        const startCoord = start.getBoundingClientRect();
        const endCoord = end.getBoundingClientRect();
        setStart({x:startCoord.x, y:startCoord.y});
        setEnd({x:endCoord.x+endCoord.width-endCoord.height, y:endCoord.y});
        setTime(0);
   }, [result]);

    useEffect(()=>{
        if (!hint) {
            setTime(0);
            return;
        }
        if (time===10) return;
        const id = setInterval(()=>setTime(prev=>prev+1), 1000);
        return ()=>clearInterval(id);
    }, [time, hint]);

   return(
       <div
            style={{columnGap: getBottomGapX(state)+'px'}}
            className={`flex items-center justify-center w-full border-[3px] rounded-[16px] border-[#7648B5] bg-[#FFF] lg:rounded-[20px]
            ${state ? "h-[67px] md:h-[73px] lg:h-[93px] xl:h-[114px]" : "h-[67px] md:h-[90px] lg:h-[113px] xl:h-[136px]"}`}
       >
            {answers.map((number, index) => {
                        if (index === i) {
                            return (
                               <DragAndDrop
                                    key={number} 
                                    enableClick = {false}
                                    item = {number}
                                    cmp={'addingMix'} 
                                    drop={'addingMix'}
                                    refresh={(sound, event) => {
                                        setI("");
                                        showHint = false;
                                        refresh(event, index, number)
                                    }}
                               >                          
                                    <motion.div
                                        key={number}
                                        animate={{y: animate ? [0, 0] : [(index+1)*100, 0]}}
                                        transition={{duration: animationDurationToShowQuestion , type: "tween", ease: "linear"}}
                                        className={`${selected.includes(index)? 'opacity-0': 'opacity-100'} item z-[40] cursor-pointer overflow-hidden`}
                                    >
                                        <CircleButton isActive={number} color={colors.current[index]}>
                                            {number}
                                        </CircleButton>
                                    </motion.div>
                               </DragAndDrop>
                            )
                        } else {
                            return (
                                <motion.div
                                   key={number}
                                   animate={{y: animate ? [0, 0] : [(index+1)*100, 0]}}
                                   transition={{duration: animationDurationToShowQuestion , type: "tween", ease: "linear"}}
                                   className={`relative ${allAnswers[result.indexOf(null)]===number && 'answer'} ${selected.includes(index)? 'opacity-0': 'opacity-100'} item z-[40] cursor-pointer`}
                                   onMouseEnter={(e)=>{
                                    setI(index);
                                    setAnimate(true);
                                   }}
                                   onTouchStart={(e)=>{
                                    setI(index);
                                    setAnimate(true);
                                   }}
                                >
                                    <CircleButton isActive={number} color={colors.current[index]}>
                                        {number}
                                    </CircleButton>
                                    {hint && showHint && time==10 && index === answers.indexOf(allAnswers[result.indexOf(null)]) && allAnswers[result.indexOf(null)]===number && <motion.div
                                        animate={{y: [0, end.y-start.y], x: [0, end.x-start.x]}}
                                        transition={{duration: 2 , type: "tween", ease: "linear", repeat: Infinity}}
                                        className="bg-[url('/backGrounds/hint.svg')] bg-[position:50%_50%] bg-contain bg-no-repeat absolute w-[50px] h-[50px] top-[50%] z-[1000]"></motion.div>}
                                </motion.div>
                            )
                        }
                })
            }
       </div>
   )
}