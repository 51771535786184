
import { shapes } from "../Shape2.data";
import { DragAndDrop } from "../../../../components/global/DragAndDrop";
import { useState, useEffect } from "react";
import { motion } from 'framer-motion';
import { 
    animationDurationToShowQuestion,
} from "../../../../config";


let showHint;

export const AnswersPart = ({state, answers, data, refresh, result, animate, hint}) => {
    const [index, setIndex] = useState('');
    const [animateOn, setAnimateOn] = useState(false);

    const [start, setStart] = useState({x:0, y:0});   
    const [end, setEnd] = useState({x:0, y:0});
    const [time, setTime] = useState(0);

    useEffect(()=>{
        if (!animateOn) return;
        setAnimateOn(false);
        setIndex('');
        setTime(0);
    }, [answers]);

    useEffect(()=>{
        showHint = true;
        if(result.indexOf(null)===-1) return;
        const start = document.querySelector('.answer');
        const end = document.querySelector('.drop'+(result.indexOf(null)));
        if (!start || !end) return;
        const startCoord = start.getBoundingClientRect();
        const endCoord = end.getBoundingClientRect();
        setStart({x:startCoord.x, y:startCoord.y});
        setEnd({x:endCoord.x+endCoord.width-endCoord.height, y:endCoord.y});
        setTime(0);
    }, [result]);

    useEffect(()=>{
        if(!hint) {
            setTime(0);
            return;
        }
        if(time===10) return;
        const id = setInterval(()=>setTime(prev=>prev+1), 1000);
        return ()=>clearInterval(id);
    }, [time, hint]);

     return (
        <div className={`z-[40] w-full flex justify-between items-center h-[47px] ${state ? "md:h-[54px] lg:h-[70px] xl:h-[85px]" : "md:h-[70px] lg:h-[85px] xl:h-[100px]"}`}>
            {
                answers.map((answer, i)=>{
                    if (index === i) {
                        return (
                            <DragAndDrop
                                key={answer} 
                                enableClick = {false}
                                cmp={'shape'} 
                                drop={"drop"+data.answers.indexOf(answer)}
                                item = {answer}
                                refresh={(sound, event) => {
                                    showHint = false;
                                    refresh(event, i, answer);
                                }}
                
                            >
                                <div
                                    style={{clipPath: shapes[answer].path}}
                                    className={`bg-[#FBB23B] cursor-pointer 
                                        ${answer==="rectangle" ? "h-[23.5px] md:h-[35px] lg:h-[42.5px] xl:h-[50px]" : state ? "md:h-[54px] lg:h-[70px] xl:h-[85px]" : "md:h-[70px] lg:h-[85px] xl:h-[100px]"} w-[47px] h-[47px]
                                        ${state ? "md:w-[54px] lg:w-[70px] xl:w-[85px]" : "md:w-[70px] lg:w-[85px] xl:w-[100px]"}
                                        ${result.includes(answer) ? "opacity-0" : "opacity-100"}`
                                    }>
                                </div>
                            </DragAndDrop>
                        )

                    } else {
                        return (
                            <motion.div
                                onTouchStart={(e)=>{
                                    setIndex(i);
                                    setAnimateOn(true);
                                }}
                                onMouseEnter={(e)=>{
                                    setIndex(i);
                                    setAnimateOn(true);
                                }}
                                animate={{y: animateOn ? [0, 0] : [animate*500, 0]}}
                                transition={{duration: animationDurationToShowQuestion*(i+1)/4 , type: "tween", ease: "linear"}}
                                key={answer}
                                className={`cursor-pointer relative
                                ${answer==="rectangle" ? "h-[23.5px] md:h-[35px] lg:h-[42.5px] xl:h-[50px]": "h-full"} w-[47px] 
                                ${state ? "md:w-[54px] lg:w-[70px] xl:w-[85px]" : "md:w-[70px] lg:w-[85px] xl:w-[100px]"}
                                ${answers[answers.indexOf((data.answers[result.indexOf(null)]))]===answer && "answer"}
                                ${result.includes(answer) ? "opacity-0" : "opacity-100"}`} 
                            >
                                <div 
                                    style={{clipPath: shapes[answer].path}}
                                    className={`bg-[#FBB23B] cursor-pointer w-full h-full`} 
                                />
                                {hint && showHint && time==10 && answers[answers.indexOf((data.answers[result.indexOf(null)]))]===answer && <motion.div
                                        animate={{y: [0, end.y-start.y], x: [0, end.x-start.x]}}
                                        transition={{duration: 2 , type: "tween", ease: "linear", repeat: Infinity}}
                                        className="bg-[url('/backGrounds/hint.svg')] absolute bg-[position:50%_50%] bg-contain bg-no-repeat  w-[50px] h-[50px] top-[50%] z-[1000]"></motion.div>} 
  
                            </motion.div>
                        )

                    }
                })
            }
    </div>
    )
}