import { DragAndDrop } from "../../../../components/global/DragAndDrop";
import { motion } from "framer-motion";
import { animationDurationToShowQuestion } from "../../../../config";
import { CircleButton } from "../../../../components/elements/CircleButton";
import { getBottomGapX } from "../Multiplication3.data";
import React, { useState, useEffect, useRef } from 'react';
import { getCircleComponent } from "../../../../hooks/useCircleButtonColor";

let showHint;
export const BottomPart = ({state, answers, gameCount, selected, handleRefresh, copyAnswers, result, hint}) => {
    const colors = useRef(getCircleComponent(answers));
    const [i, setI] = useState('');
    const [animate, setAnimate] = useState(false);
    const [start, setStart] = useState({x:0, y:0});   
    const [end, setEnd] = useState({x:0, y:0});
    const [time, setTime] = useState(0);

    useEffect(()=>{
        if (!animate) return;
        setAnimate(false);
        setI('');
        setTime(0);
        colors.current = getCircleComponent(answers);
    }, [answers]);

    useEffect(()=>{
        showHint = true;
        if(result.indexOf(null)===-1) return;
        const start = document.querySelector('.answer');
        const end = document.querySelector('.drop'+(result.indexOf(null)));
        if (!start || !end) return;
        const startCoord = start.getBoundingClientRect();
        const endCoord = end.getBoundingClientRect();
        setStart({x:startCoord.x, y:startCoord.y});
        setEnd({x:endCoord.x+endCoord.width-endCoord.height, y:endCoord.y});
        setTime(0);
    }, [result]);

    useEffect(()=>{
        if(!hint) {
            setTime(0);
            return;
        }
        if(time===10) return;
        const id = setInterval(()=>setTime(prev=>prev+1), 1000);
        return ()=>clearInterval(id);
    }, [time, hint]);

    const animValues = [-100, -101, -103, -104, -105, -106, -107, -108, -109, -110, -111];
    return (
        <div 
            style={{columnGap: getBottomGapX(state)+'px'}}
            className={`flex items-center justify-center w-full border-[3px] rounded-[16px] border-[#60963A] bg-[#FFF] lg:rounded-[20px] 
                ${state ? "h-[67px] md:h-[73px] lg:h-[93px] xl:h-[114px]" : "h-[67px] md:h-[90px] lg:h-[113px] xl:h-[136px]"}`}    
        >
            {copyAnswers.map((number, index) => {
                    if (i === index) {
                        return (
                            <DragAndDrop
                                key={index} 
                                enableClick = {false}
                                item = {number.answer}
                                cmp={'adding3'} 
                                drop={answers.indexOf(number)===-1 ? 'drop0' : 'drop'+answers.indexOf(number)}
                                refresh={(sound) => {
                                    showHint = false;
                                    handleRefresh(number, sound);
                                }}

                            >
                                <motion.div
                                    animate={{y: animate ? [0, 0] : [-animValues[gameCount], 0]}}
                                    transition={{duration: animationDurationToShowQuestion*(index+1)/7, type: "tween"}}  
                                    className={`${selected.includes(number.answer)? 'opacity-0': 'opacity-100'} item z-[40] cursor-pointer`}
                                    key={index}
                                >
                                    <CircleButton isActive={number.answer} color={colors.current[index]}>
                                        {number.answer}
                                    </CircleButton>
                                </motion.div>
                            </DragAndDrop>
                        )
                    } else {
                        return (
                            <motion.div
                              animate={{y: animate ? [0, 0] : [-animValues[gameCount], 0]}}
                              transition={{duration: animationDurationToShowQuestion*(index+1)/7, type: "tween"}}  
                               className={`relative ${copyAnswers[copyAnswers.indexOf((answers[result.indexOf(null)]))]?.answer===number.answer && 'answer'} ${selected.includes(number.answer)? 'opacity-0': 'opacity-100'} item z-[40] cursor-pointer`}
                               key={index}
                               onTouchStart={(e)=>{
                                setI(index);
                                setAnimate(true);
                               }}
                               onMouseEnter={(e)=>{
                                setI(index);
                                setAnimate(true);
                               }}
                            >
                                <CircleButton isActive={number.answer} color={colors.current[index]}>
                                    {number.answer}
                                </CircleButton>
                                {hint && showHint && time>=10 && copyAnswers[copyAnswers.indexOf((answers[result.indexOf(null)]))]?.answer===number.answer && <motion.div
                                        animate={{y: [0, end.y-start.y], x: [0, end.x-start.x]}}
                                        transition={{duration: 2 , type: "tween", ease: "linear", repeat: Infinity}}
                                        className="bg-[url('/backGrounds/hint.svg')] bg-[position:50%_50%] bg-contain bg-no-repeat absolute w-[50px] h-[50px] top-[50%] z-[1000]"></motion.div>}
                            </motion.div>
                          )
                    }
                })
            }

        </div>
    )
}