// const { VITE_ENVIROMENT } = import.meta.env.VITE_SOME_KEY;

export async function loader({ params }) {
  const imageLink = `/imagesForTestAPI/Group ${params.number}.${params.type}`;
  return window.open(imageLink);
  // return redirect("/");
}

export async function action({ params }) {
  const imageLink = `/imagesForTestAPI/Group ${params.number}.${params.type}`;
  return window.open(imageLink);
  // return redirect("/");
}
