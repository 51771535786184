export const getBottomGapX = (state) => {
    if (window.innerWidth <  1024) {
      return (425-5*39)/6;
    }
    if (window.innerWidth>=1024 && window.innerWidth<1440) {
      if (state) {
        return (439-5*39)/6;
      } else {
        return (576-5*49)/6;
      } 
    }
    if (window.innerWidth>=1440 && window.innerWidth<1920) {
      if (state) {
        return (590-5*50)/6;
      } else {
        return (723-5*69)/6;
      } 
    }
    if (window.innerWidth>=1920) {
      if (state) {
        return (768-5*70)/6;
      } else {
        return (909-5*89)/6;
      } 
    }
}

export const getFirstNumber = (level, secondNumber) => {
    const min = secondNumber;
    const max = level === "beginner" ? 40 : level === "intermediate" ? 70 : 100;

    while (true) {
        const number = Math.floor(Math.random() * (max - min + 1) + min);
        if (number%secondNumber === 0) {
            return number;
        }
    }
}

export const getSecondNumber = () => {
    const min = 1;
    const max = 10;
    return Math.floor(Math.random() * (max - min + 1) + min);
}

export const getAnswers = (answer, level) => {
    const arr = new Set();
    const min = 1;
    const max = level === "beginner" ? 10 : level === "intermediate" ? 20 : 99;

    while(arr.size<5) {
        arr.add(Math.floor(Math.random() * (max - min + 1) + min));
    }
    const newArr = Array.from(arr);
    if (!newArr.includes(answer)) {
        const minIndex = 0;
        const maxIndex = 4;
        newArr[Math.floor(Math.random() * (maxIndex - minIndex + 1) + minIndex)] = answer;
    }
   
    return newArr;
}

export const getCoordinates = () => {
    const elem = document.querySelector('.adding');
    const answer = document.querySelector('.answer');
    const x1 = elem.getBoundingClientRect().x;
    const y1 = elem.getBoundingClientRect().y;
    const x2 = answer.getBoundingClientRect().x;
    const y2 = answer.getBoundingClientRect().y;
    const xDistance = x1 - x2;
    const yDistance = y1 - y2;
    return [
              xDistance-(answer.getBoundingClientRect().width-elem.getBoundingClientRect().width)/2, 
              yDistance-(answer.getBoundingClientRect().height-elem.getBoundingClientRect().height)/2
           ];
}

export const getAnimationValues = () => {
    const values = [Math.random()*100];
    while(values.length < 5) {
        values.push(Math.random()*100+values[values.length-1]);
    }
    return values;
}