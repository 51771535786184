

import { useParams } from 'react-router-dom';
import { TopPart } from "./components/TopPart";
import { BottomPart } from "./components/BottomPart";
import { updateStorage } from "../../../hooks/useStorage";
import { updateGameStorage } from "../../../hooks/useGameStorage";
import { 
    animationDurationToMoveCorrectAnswer,
    animationDurationToShowscoresPopup
} from "../../../config";
import Sound from '../../../components/global/Sound';
import { useEffect, useState, useRef } from "react";
import { getShapes, getAnswers } from "./Shapes.data";
import * as amplitude from '@amplitude/analytics-browser';
import { useUpdateReduxState } from "../../../hooks/useUpdateReduxState";
import { gameId } from "../../../hooks/getGameId";


export const Shapes = ({state, level, handleUpdateProgress, answerSound, point, count, handleOpenScore, hint, subscription}) => {
    const {name, gameName} = useParams();
    const [time, setTime] = useState(5);
    const size = 3;
    const answersSize = level === "beginner" ? 4 : level === "advanced" ? 6 : 5; 
    const shapes = useRef(getShapes(size, time));
    const answers = useRef(getAnswers(shapes.current, time, answersSize));
    const [result, setResult] = useState([...new Array(size)]);
    const [checkingState, setCheckingState] = useState([...new Array(size)]);
    const [animate, setAnimate] = useState(true);

    const updateState = useUpdateReduxState();

    const selected = useRef([]);
    const score = useRef(0);
    const gameCount = useRef(0);

    const soundWrongRef = useRef();
    const soundRef = useRef();

    useEffect(()=>{
        if (!time) return;
        const id = setInterval(()=>{
            setTime(time-1);
        }, 1000);
        return ()=>clearInterval(id);
    }, [time]);

    useEffect(()=>{
        amplitude.track(`${name+"_"+gameName.replace("_", " ")+"_"+level+"_start"}`)
    }, []);
    
    const refresh = (event, i, answer, type, isDragging) => {
        if (type) {
            if (!isDragging) return;
            const elem = document.querySelector('.dropAnswers');
            const elemCoord = elem.getBoundingClientRect();
            if (
                event.clientX<elemCoord.x+elemCoord.width && event.clientX>=elemCoord.x && 
                event.clientY<elemCoord.y+elemCoord.height && event.clientY>=elemCoord.y
            ) {
                const newResult = [...result].map((val)=>{
                    if (val === answer) {
                        return undefined
                    } else {
                        return val
                    }
                });
                selected.current = selected.current.filter((val)=>val!==answer)
                setResult(newResult);
                setAnimate(false);
                return;
           }

        } else {
            for (let i=0; i<size; i++) {
                const elem = document.querySelector('.drop'+i);
                const elemCoord = elem.getBoundingClientRect()
                if (
                     event.clientX<elemCoord.x+elemCoord.width && event.clientX>=elemCoord.x && 
                     event.clientY<elemCoord.y+elemCoord.height && event.clientY>=elemCoord.y
                 ) {
                  const newRes = [...result];
                  newRes[i] = answer;
                  selected.current.push(answer);
                  setResult(newRes);
                  setAnimate(false);
                  return;
                }
             }
        }

    }

    useEffect(()=>{
        if (!result.includes(undefined)) {
           const state = [];
           shapes.current.forEach((item, i) => {
              if (item === result[i]) {
                 state[i] = true;
              } else {
                 state[i] = false;
              }
           });
           setCheckingState(state);
           if (gameCount.current === count-1) {
                if (!state.includes(false)) score.current+=point[level];
                if (subscription) {
                    updateState({
                        userId: localStorage.getItem('user'),
                        profileId: JSON.parse(localStorage.getItem('profile'))?._id,
                        game: name,
                        score: score.current,
                        id: gameId(name, gameName),
                        collection: name,
                    })
                } else {
                    handleUpdateProgress();
                    updateGameStorage(name, gameName, !state.includes(false) ? point[level] : 0, true, level);
                }
                setTimeout(()=>handleOpenScore(), animationDurationToShowscoresPopup*2000);
                localStorage.setItem(`${name+'_'+gameName+'_'+level+'_score'}`, score.current);
           } else {
              if (!state.includes(false)) {
                if (soundRef.current  && answerSound) {
                    soundRef.current.currentTime = 0;
                    soundRef.current.play();
                 }
                score.current+=point[level];
                if (!subscription) {
                    updateStorage(point[level]);
                }
              } else {
                if (soundWrongRef.current  && answerSound) {
                    soundWrongRef.current.currentTime = 0;
                    soundWrongRef.current.play();
                }
              }
              setTimeout(()=>{
                gameCount.current+=1;
                setTime(5);
                shapes.current = getShapes(size, 5);
                answers.current = getAnswers(shapes.current, 5, answersSize);
                setResult([...new Array(size)]);
                setCheckingState([...new Array(size)]);
                setAnimate(true);
                selected.current = []; 
             }, animationDurationToMoveCorrectAnswer*3000);
             if (!subscription) {
                handleUpdateProgress();
                updateGameStorage(name, gameName, point[level], false, level);
             }
           }
        }
      }, [result]);

    return(
        <div className = "w-full h-full flex flex-col justify-between">
            <TopPart 
               state={state} 
               level={level} 
               time={time}
               data={shapes.current}
               result={result}
               animate={animate}
               refresh={refresh}
               handleTime={()=>setTime(0)}
               checkingState={checkingState}
            />
            {!time && <BottomPart
                hint={hint}
                selected={selected.current}
                state={state} 
                answers={answers.current}
                refresh={refresh}
                level={level}
                data={shapes.current}
                result={result}
            />}
            <Sound ref={soundRef} url={"/backGrounds/right_answer.wav"} loop={false}/>
            <Sound ref={soundWrongRef} url={"/backGrounds/wrong_answer.wav"} loop={false}/>
        </div>
    )
}