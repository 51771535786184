


export const Main = ({state, handleSelected, numbers}) => {
   return (
        <div
           className={`py-[36px] px-[29px] text-[24px] ${state ? "md:py-[49px] md:px-[30px] lg:py-[58px] lg:px-[40px] xl:py-[74px] xl:px-[59px] lg:text-[36px] xl:text-[46px]" : "xl:py-[85px] xl:px-[93px] lg:text-[46px] lg:py-[73px] lg:px-[42px] md:py-[53px] md:px-[39px] md:text-[36px] xl:text-[60px]"} bg-[#FFF] flex flex-col justify-between  w-full h-full border-[3px] border-[#D2461B] rounded-[16px] lg:rounded-[20px] xl:border-[4px]`}
        >
            <div className="flex justify-between">
                {
                    [1, 2, 3, 4, 5].map((number)=>{
                        return (
                            <button
                                disabled={numbers.includes(number)}
                                onClick={()=>handleSelected(number)}
                                key={number} 
                                className={`z-[40] w-[39px] h-[39px] ${state ? "md:w-[39px] md:h-[39px] lg:w-[50px] lg:h-[50px] xl:w-[70px] xl:h-[70px]" : "md:w-[50px] md:h-[50px] lg:w-[70px] lg:h-[70px] xl:w-[89px] xl:h-[89px]"} text-[#fff] rounded-[8px] ${numbers.includes(number) ? "bg-[#979797]" : "bg-[#F67300] cursor-pointer"}  font-bigfatScript text-center`}
                                
                            >
                                {number}
                            </button>
                        )
                    })
                }
            </div>
        <div className="flex justify-between">
            {
                [6, 7, 8, 9, 10].map((number)=>{
                    return (
                        <button
                            disabled={numbers.includes(number)}
                            onClick={()=>handleSelected(number)}
                            key={number} 
                            className={`z-[40] w-[39px] h-[39px] ${state ? "md:w-[39px] md:h-[39px] lg:w-[50px] lg:h-[50px] xl:w-[70px] xl:h-[70px]" : "md:w-[50px] md:h-[50px] lg:w-[70px] lg:h-[70px] xl:w-[89px] xl:h-[89px]"} text-[#fff] rounded-[8px] ${numbers.includes(number) ? "bg-[#979797]" : "bg-[#F67300] cursor-pointer"} font-bigfatScript text-center`}
                            
                        >
                            {number}
                        </button>
                    )
                })
            }
        </div>
    </div>
   )
}