import {useEffect, useState, useRef} from 'react';
import {IconBlue, IconRed, IconGreen, IconPurple, IconGray, IconYellow, IconPink, IconOrange, IconTeal} from './Icon';

const colors = {};
export const CircleButton = ({children, isActive = true, className, color}) => {
    const [isSettingsOpen, setIsSettingsOpen] = useState(() => {
        const storedIsOpen = localStorage.getItem("leftBarIsOpen");
        return storedIsOpen ? JSON.parse(storedIsOpen) : false;
    });
    useEffect(() => {
        const handleLeftBarIsOpenChange = () => {
            const storedIsOpen = localStorage.getItem("leftBarIsOpen");
            setIsSettingsOpen(storedIsOpen ? JSON.parse(storedIsOpen) : false);
        };
        window.addEventListener("leftBarIsOpenChange", handleLeftBarIsOpenChange);
        return () => {
            window.removeEventListener("leftBarIsOpenChange", handleLeftBarIsOpenChange);
        };
    }, []);

    const [isTopBarOpen, setIsTopBarOpen] = useState(() => {
        const storedIsOpen = localStorage.getItem("topBarIsOpen");
        return storedIsOpen ? JSON.parse(storedIsOpen) : false;
    });


    useEffect(() => {
        const handleTopBarIsOpenChange = () => {
            const storedIsOpen = localStorage.getItem("topBarIsOpen");
            setIsTopBarOpen(storedIsOpen ? JSON.parse(storedIsOpen) : false);
        };
        window.addEventListener("topBarIsOpenChange", handleTopBarIsOpenChange);
        return () => {
            window.removeEventListener("topBarIsOpenChange", handleTopBarIsOpenChange);
        };
    }, []);
    const iconComponents = [IconBlue, IconYellow, IconRed, IconGreen, IconPurple, IconPink, IconOrange, IconTeal];

    let RandomIcon;
   
    if (color) {
        RandomIcon = color;
        colors[isActive] = color;
    } else {
        if (isActive in colors) {
            RandomIcon = colors[isActive];
        } else {
            RandomIcon = iconComponents[Math.floor(Math.random() * iconComponents.length)];
            colors[isActive] = RandomIcon;
        }
    }

    return (
        <div>
            <button
                className={`${className} z-50 ${isTopBarOpen || isSettingsOpen ? 'md:w-[39px] md:h-[39px] lg:w-[50px] lg:h-[50px] xl:w-[70px] xl:h-[70px] ' : ' md:w-[49px] md:h-[49px]  lg:w-[69px] lg:h-[69px] xl:w-[89px] xl:h-[89px] '} flex items-center justify-center border-none cursor-pointer relative h-[39px] w-[39px]`}
            >
                {isActive ? <RandomIcon
                        className={`w-full h-full`}/> :
                    <IconGray
                        className={`w-full h-full`}/>}
                {/* Render the selected icon */}
                <span
                    className={` absolute top-1/2 left-1/2 text-white font-bigfatScript text-[24px] ${isTopBarOpen || isSettingsOpen ? 'lg:text-[36px] xl:text-[46px]' : 'md:text-[32px] lg:text-[46px] xl:text-[60px]'}  -translate-x-1/2 -translate-y-1/2`}
                >
                {children}
            </span>
            </button>
        </div>
    );
};
