// generate datas
export const generateSequence = () => {
    const sequence = [];

    // Generate the first number between 1 and 5
    let firstNumber = Math.floor(Math.random() * 5) + 1;
    sequence.push(firstNumber);

    // Generate the step between 2 and 5
    let step = Math.floor(Math.random() * 4) + 2;

    // Generate the remaining numbers
    for (let i = 1; i < 5; i++) {
        let nextNumber = sequence[i - 1] + step;
        sequence.push(nextNumber);
    }
        
    return sequence;
};

export const generateUniqueNumbers = (sequence) => {
    const uniqueNumbers = new Set();
    while (uniqueNumbers.size < 10) {
        const num = Math.floor(Math.random() * 30) + 1;
        if (num !== sequence[0] && num !== sequence[1] && num !== sequence[2] && num !== sequence[3] && num !== sequence[4] && num !== sequence[5]) {
            uniqueNumbers.add(num)
        }
    }
    for (let i = 0; i <= 5; i++) {
        uniqueNumbers.add(sequence[i]);
    }

    return Array.from(uniqueNumbers)

};

export const generateArray = (sequence, level) => {
    if (level==="beginner") {
        return [sequence[0], sequence[1], null, null, null]
    }
    if (level==="advanced") {
        return [null, sequence[1], null, null, sequence[4]]
    }
    if (level==="intermediate") {
        return [sequence[0], null, sequence[2], null, null]
    }
    if (!level) {
        return [null, null, null, null, null]
    }
};