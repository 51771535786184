import { motion } from "framer-motion";
import { animationDurationToShowQuestion } from "../../../../config";
import { CircleButton } from "../../../../components/elements/CircleButton";
import { getGapAnswersY, getGapAnswersX } from "../FindNumber.styles";
import React, { useState, useEffect, useRef } from 'react';
import { DragAndDrop } from "../../../../components/global/DragAndDrop";
import { getCircleComponent } from "../../../../hooks/useCircleButtonColor";

let showHint;
export default function BottomSection({state, level, answers, selected, gameCount, numbers, handleRefresh, showNumbers, hint}) {
    const colors = useRef(getCircleComponent(answers));
    const [i, setI] = useState('');
    const [animate, setAnimate] = useState(false);
    const [start, setStart] = useState({x:0, y:0});   
    const [end, setEnd] = useState({x:0, y:0});
    const [time, setTime] = useState(0);

    useEffect(()=>{
        if (!animate) return;
        setAnimate(false);
        setI('');
        setTime(0);
        colors.current = getCircleComponent(answers);
    }, [answers]);

    useEffect(()=>{
        showHint = true;
        if(showNumbers.indexOf(null)===-1) return;
        setTime(0);
        const start = document.querySelector('.answer');
        const end = document.querySelector('.drop'+(showNumbers.indexOf(null)));
        if(!start || !end) return; 
        const startCoord = start.getBoundingClientRect();
        const endCoord = end.getBoundingClientRect();
        setStart({x:startCoord.x, y:startCoord.y});
        setEnd({x:endCoord.x+endCoord.width-endCoord.height, y:endCoord.y});
    }, [showNumbers]);

    useEffect(()=>{
        if(!hint) {
            setTime(0);
            return;
        }
        if (time==10) return;
        const id = setInterval(()=>setTime(prev=>prev+1), 1000);
        return ()=>clearInterval(id);
    }, [time, hint]);

    const amimNumbers = [-100, -101, -103, -104, -105, -106, -107, -108, -109, -110, -111];

    return (
        <div
            style={{columnGap: getGapAnswersX(state, level)+'px', padding: `${getGapAnswersY(state, level)-2}px ${getGapAnswersX(state, level)-2}px`}}
            className={`grid grid-cols-7 ${state ? "h-[67px] md:h-[73px] lg:h-[93px] xl:h-[114px]" : "h-[67px] md:h-[90px] lg:h-[113px] xl:h-[136px]"} bg-white border-[2px] border-[#2F9EBA] md:border-[3px] xl:border-[4px] rounded-[14px] md:rounded-[16px] lg:rounded-[20px]`}>
                {answers.map((number, index) => {
                    if (i === index) {
                        return (
                            <DragAndDrop
                                key={index} 
                                enableClick = {false}
                                item = {number}
                                cmp={'findnumber'} 
                                drop={numbers.indexOf(number)===-1 ? 'drop0' : 'drop'+numbers.indexOf(number)}
                                refresh={(sound) => {
                                    showHint= false;
                                    handleRefresh(number, sound)
                                }}

                            >
                                <motion.div
                                    animate={{y: animate ? [0, 0] : [-amimNumbers[gameCount], 0]}}
                                    transition={{duration: animationDurationToShowQuestion*(index+1)/7, type: "tween"}}  
                                    className={`${selected.includes(number)? 'opacity-0': 'opacity-100'} item z-[40] cursor-pointer`}
                                    key={index}
                                >
                                    <CircleButton isActive={number} color={colors.current[index]}>
                                        {number}
                                    </CircleButton>
                                </motion.div>
                            </DragAndDrop>
                        )
                    } else {
                        return (
                            <motion.div
                              animate={{y: animate ? [0, 0] : [-amimNumbers[gameCount], 0]}}
                              transition={{duration: animationDurationToShowQuestion*(index+1)/7, type: "tween"}}  
                               className={`relative ${answers[answers.indexOf(numbers[showNumbers.indexOf(null)])]===number && 'answer'} ${selected.includes(number)? 'opacity-0': 'opacity-100'} item z-[40] cursor-pointer`}
                               key={index}
                               onMouseEnter={(e)=>{
                                setI(index);
                                setAnimate(true);
                               }}
                               onTouchStart={(e)=>{
                                setI(index);
                                setAnimate(true);
                               }}
                            >
                                <CircleButton isActive={number} color={colors.current[index]}>
                                    {number}
                                </CircleButton>
                                {hint && showHint && time==10 && answers[answers.indexOf(numbers[showNumbers.indexOf(null)])]===number && <motion.div
                                        animate={{y: [0, end.y-start.y], x: [0, end.x-start.x]}}
                                        transition={{duration: 2, type: "tween", ease: "linear", repeat: Infinity}}
                                        className="bg-[url('/backGrounds/hint.svg')] bg-[position:50%_50%] bg-contain bg-no-repeat absolute w-[50px] h-[50px] top-[50%] z-[500]"></motion.div>}
                            </motion.div>
                          )
                    }
                })
              }
        
        </div>
    );
}