
import {motion} from 'framer-motion';
import React, { useEffect, useState} from 'react';

export const Ball = ({state, screen}) => {
    // const [sizes, setSizes] = useState(0);
    // useEffect(()=>{
    //     setSizes(screen.height-(document.querySelector('.gamezone').offsetHeight+document.querySelector('.gamezone').offsetTop)-58);
    // });
    
    return (
        <motion.svg 
            className="absolute bottom-0 left-0" 
            width="58" height="58" viewBox="0 0 58 58"
            fill="none" xmlns="http://www.w3.org/2000/svg"
            // animate={{
            //     x: [0, 50, 100, 150, 200, 250, 300, 350, 400, 450, 500, 550, 600, 650, 700, 750, 800, 850, 900, 950, 1000, 1050, 1100, 1150, 1200, 1250, 1300, 1350, 1400, 1450, 1500],
            //     y: [0, -sizes, 0, -sizes, 0, -sizes, 0, -sizes, 0, -sizes, 0, -sizes, 0, -sizes, 0, -sizes, 0, -sizes, 0, -sizes, 0, -sizes, 0, -sizes, 0, -sizes,  0, -sizes, 0, -sizes, 0]}} 
            // transition={{duration: 50, type: 'tween', repeat: Infinity, ease: "linear"}}
        >
            {screen.width>=1920 ?
            <>
                <path d="M12.6875 14.6749C11.7087 17.0292 11.165 19.6371 11.165 22.3536C11.165 23.5126 11.2738 24.6355 11.455 25.7583C11.5275 26.1205 11.6 26.4827 11.6725 26.8811C10.15 28.4748 8.7725 30.3945 7.57625 32.7126C7.35875 31.5173 7.25 30.2858 7.25 29.0543C7.25 23.5126 9.31625 18.5143 12.6875 14.6749Z" fill="#34A5CF"/>
                <path d="M38.4244 46.802C38.4969 46.476 38.5331 46.1501 38.5694 45.7879C38.6056 45.6068 38.6056 45.4257 38.6419 45.2446C38.6781 44.9548 38.7144 44.6288 38.7506 44.3391C38.7869 44.158 38.7869 43.9406 38.7869 43.7595C38.8231 43.4698 38.8231 43.18 38.8594 42.8903C38.8594 42.7092 38.8956 42.4918 38.8956 42.3107C38.8956 42.021 38.9319 41.7674 38.9319 41.4777C38.9319 41.2966 38.9319 41.1155 38.9319 40.9344C39.1856 40.8257 39.4394 40.717 39.6931 40.6084C44.3331 38.4714 48.0669 34.632 49.9881 29.8872C50.2056 30.2494 50.4594 30.6478 50.6406 31.0462C49.8794 38.906 44.9131 45.5705 38.0256 48.6855C38.1344 48.1784 38.2431 47.6713 38.3156 47.2005C38.3519 47.128 38.3881 46.9831 38.4244 46.802Z" fill="#F9D023"/>
                <path d="M22.1136 40.2463C24.8686 41.6588 28.0223 42.4557 31.3211 42.4557C34.0036 42.4557 36.5773 41.9124 38.9336 40.9707C38.9336 41.1518 38.9336 41.3329 38.9336 41.514C38.9336 41.8037 38.8973 42.0573 38.8973 42.347C38.8973 42.5281 38.8973 42.7092 38.8611 42.9266C38.8248 43.2163 38.8248 43.5061 38.7886 43.7958C38.7886 43.9769 38.7523 44.1943 38.7523 44.3754C38.7161 44.6651 38.6798 44.9549 38.6436 45.2809C38.6073 45.462 38.6073 45.6431 38.5711 45.8242C38.5348 46.1501 38.4623 46.4761 38.4261 46.8383C38.3898 46.9832 38.3898 47.1643 38.3536 47.3092C38.2811 47.8163 38.1723 48.2871 38.0636 48.7942C35.3086 50.0619 32.2636 50.7501 29.0373 50.7501C25.4486 50.7501 22.0773 49.8808 19.1048 48.3596C19.2861 45.2446 19.9023 42.2746 20.8086 39.5219C21.2073 39.7754 21.6786 40.0289 22.1136 40.2463Z" fill="#78A941"/>
                <path d="M38.75 44.3761C38.7137 44.6659 38.6775 44.9556 38.6412 45.2816C38.6775 44.9556 38.7137 44.6659 38.75 44.3761Z" fill="#9FB600"/>
                <path d="M38.5703 45.8247C38.5341 46.1507 38.4616 46.4767 38.4253 46.8389C38.4616 46.4767 38.5341 46.1507 38.5703 45.8247Z" fill="#9FB600"/>
                <path d="M38.9336 41.5135C38.9336 41.8033 38.8973 42.0569 38.8973 42.3466C38.8973 42.0931 38.9336 41.8033 38.9336 41.5135Z" fill="#9FB600"/>
                <path d="M38.0264 48.7941C38.1352 48.287 38.2439 47.7799 38.3164 47.3091C38.2439 47.7799 38.1352 48.287 38.0264 48.7941Z" fill="#9FB600"/>
                <path d="M38.8594 42.9269C38.8231 43.2166 38.8231 43.5064 38.7869 43.7962C38.8231 43.5064 38.8594 43.2166 38.8594 42.9269Z" fill="#9FB600"/>
                <path d="M20.8086 39.5217C19.9023 42.2744 19.2861 45.2445 19.1048 48.3594C13.1236 45.2807 8.77359 39.5579 7.61359 32.7123C8.80984 30.3942 10.1873 28.4745 11.7098 26.8809C12.9061 32.2052 16.2411 36.7327 20.8086 39.5217Z" fill="#FF3612"/>
                <path d="M50.7138 27.8232C50.75 28.2217 50.75 28.6201 50.75 29.0185C50.75 29.7429 50.7138 30.4311 50.6413 31.1193C50.4238 30.7208 50.2063 30.3224 49.9888 29.9602C50.2788 29.272 50.5325 28.5476 50.7138 27.8232Z" fill="#3DB7B7"/>
                <path d="M33.0952 7.64746C37.9527 8.55296 42.1939 11.0884 45.3477 14.638C39.4752 14.7104 33.5302 19.3104 33.5302 19.3104C33.5302 19.3104 34.8714 11.7403 33.0952 7.64746Z" fill="#FF6532"/>
                <path d="M17.2912 10.6909C20.6625 8.51771 24.6862 7.25 29 7.25C30.3775 7.25 31.755 7.39487 33.06 7.64841C34.8362 11.7051 33.495 19.3113 33.495 19.3113C33.495 19.3113 28.275 11.4515 17.2912 10.6909Z" fill="#8BBF47"/>
                <path d="M17.2923 10.6909C28.2398 11.4153 33.4961 19.3113 33.4961 19.3113C33.4961 19.3113 20.5186 17.4641 11.6736 26.8451C11.6011 26.4829 11.5286 26.1207 11.4561 25.7223C11.2748 24.5995 11.1661 23.4766 11.1661 22.3176C11.1661 19.6011 11.7098 17.0295 12.6886 14.6389C14.0298 13.1539 15.5886 11.8137 17.2923 10.6909Z" fill="#3EB6DB"/>
                <path opacity="0.9" d="M38.9672 40.1379C38.9672 39.9568 38.9672 39.7757 38.9672 39.5946C38.9672 39.3048 38.9672 39.0512 38.9309 38.7977C38.9309 38.6166 38.9309 38.4355 38.8947 38.2906C38.8947 38.0371 38.8584 37.7473 38.8584 37.4938C38.8584 37.3127 38.8222 37.1678 38.8222 36.9867C38.7859 36.7331 38.7859 36.4434 38.7497 36.1899C38.7497 36.045 38.7134 35.8639 38.7134 35.719C38.6772 35.4654 38.6409 35.1757 38.6047 34.9222C38.5684 34.7773 38.5684 34.6324 38.5322 34.4875C38.4959 34.2339 38.4597 33.9442 38.4234 33.6907C38.3872 33.5458 38.3872 33.4371 38.3509 33.2923C38.3147 33.0025 38.2422 32.7489 38.2059 32.4954C38.1697 32.3867 38.1697 32.2418 38.1334 32.1332C38.0972 31.8434 38.0247 31.5899 37.9522 31.3001C37.9159 31.1915 37.9159 31.0828 37.8797 31.0104C37.8072 30.7206 37.7347 30.4671 37.6984 30.1773C37.6622 30.1049 37.6622 29.9962 37.6259 29.9238C37.5534 29.634 37.4809 29.3442 37.4084 29.0545C37.4084 28.982 37.3722 28.9458 37.3722 28.8734C37.2997 28.5836 37.1909 28.2576 37.1184 27.9679C37.1184 27.9316 37.0822 27.8954 37.0822 27.8592C36.2847 25.2152 35.3784 23.0781 34.6897 21.5931C34.6897 21.5931 34.6897 21.5931 34.6897 21.5569C34.6172 21.3758 34.5447 21.2309 34.4359 21.086C34.4359 21.0498 34.3997 21.0498 34.3997 21.0136C34.3272 20.8687 34.2547 20.7601 34.2184 20.6152C34.2184 20.579 34.1822 20.5789 34.1822 20.5427C34.1097 20.4341 34.0734 20.3254 34.0372 20.2168C34.0372 20.1806 34.0009 20.1443 34.0009 20.1443C33.9647 20.0719 33.9284 19.9994 33.8922 19.927C33.8559 19.8908 33.8559 19.8545 33.8197 19.8183C33.7834 19.7459 33.7472 19.7097 33.7472 19.6735C33.7109 19.6373 33.7109 19.601 33.6747 19.5648C33.6747 19.5286 33.6384 19.5286 33.6384 19.4924C33.5659 19.3475 33.5297 19.275 33.5297 19.275C33.5297 19.275 44.0784 20.2168 50.0234 29.9238C48.0659 34.6686 44.3684 38.5079 39.7284 40.6449C39.4747 40.7536 39.2209 40.8622 38.9672 40.9709V40.9347C38.9309 40.6812 38.9672 40.3914 38.9672 40.1379Z" fill="#F9D023"/>
                <path d="M33.495 19.9638L33.5675 19.3843C33.5675 19.4205 33.6037 19.4567 33.64 19.493C33.64 19.5292 33.6762 19.5292 33.6762 19.5654C33.6762 19.6016 33.7125 19.6378 33.7487 19.6741C33.785 19.7103 33.8212 19.7827 33.8212 19.8189C33.8212 19.8551 33.8575 19.8914 33.8937 19.9276C33.93 20 33.9662 20.0725 34.0025 20.1449C34.0025 20.1811 34.0387 20.2174 34.0387 20.2174C34.075 20.326 34.1475 20.4346 34.1837 20.5433C34.1837 20.5795 34.22 20.5796 34.22 20.6158C34.2925 20.7244 34.365 20.8693 34.4012 21.0142C34.4012 21.0504 34.4375 21.0504 34.4375 21.0866C34.51 21.2315 34.5825 21.4126 34.6912 21.5575C34.6912 21.5575 34.6912 21.5575 34.6912 21.5937C35.38 23.0787 36.2862 25.2157 37.0838 27.8598C37.0838 27.896 37.12 27.9322 37.12 27.9684C37.1925 28.2582 37.3012 28.548 37.3737 28.8739C37.3737 28.9464 37.41 28.9826 37.41 29.055C37.4825 29.3448 37.555 29.6346 37.6275 29.9243C37.6638 29.9968 37.6637 30.1055 37.7 30.1779C37.7725 30.4677 37.845 30.7212 37.8812 31.0109C37.9175 31.1196 37.9175 31.2283 37.9537 31.3007C38.0262 31.5543 38.0625 31.844 38.135 32.1338C38.1712 32.2424 38.1713 32.3511 38.2075 32.496C38.2438 32.7495 38.3163 33.0393 38.3525 33.2928C38.3888 33.4377 38.3887 33.5464 38.425 33.6912C38.4612 33.9448 38.4975 34.2345 38.5338 34.4881C38.57 34.6329 38.57 34.7778 38.6062 34.9227C38.6425 35.1763 38.6788 35.466 38.715 35.7196C38.715 35.8644 38.7513 36.0456 38.7513 36.1904C38.7875 36.444 38.7875 36.7337 38.8237 36.9873C38.8237 37.1684 38.86 37.3133 38.86 37.4944C38.86 37.7479 38.8963 38.0376 38.8963 38.2912C38.8963 38.4723 38.8963 38.6534 38.9325 38.7983C38.9325 39.0518 38.9325 39.3416 38.9688 39.5951C38.9688 39.7762 38.9688 39.9573 38.9688 40.1384C38.9688 40.392 38.9688 40.6817 38.9688 40.9715V41.0077C36.6125 41.9856 34.0387 42.4928 31.3562 42.4928C28.0212 42.4928 24.9037 41.6959 22.1487 40.2833C21.7137 40.066 21.2425 39.8124 20.8075 39.5227C24.65 27.8236 33.495 19.9638 33.495 19.9638Z" fill="#8BBF47"/>
                <path d="M33.4939 19.3114C33.4939 19.3114 33.5302 19.3476 33.5664 19.4201L33.4939 19.9996C33.4939 19.9996 24.6489 27.8593 20.7702 39.5222C16.2389 36.7333 12.8676 32.2058 11.6714 26.8452C20.5526 17.4642 33.4939 19.3114 33.4939 19.3114Z" fill="#FF6532"/>
                <path d="M33.4961 19.3116C33.4961 19.3116 39.4411 14.7116 45.3136 14.6392C48.4311 18.1887 50.4248 22.7887 50.7148 27.8233C50.4973 28.5477 50.2436 29.2721 49.9898 29.9603C44.0811 20.2533 33.4961 19.3116 33.4961 19.3116Z" fill="#3EB6DB"/>
                <path d="M27.4793 14.5662C27.5881 14.3851 27.7331 14.2403 27.8418 14.0954C30.1981 11.1978 34.2943 11.1615 37.6656 13.4434C40.7106 15.5079 42.2331 18.7678 41.2181 21.774C41.0368 22.2811 40.7831 22.8244 40.4568 23.2953C38.2093 26.6638 33.4606 27.4244 29.8718 24.9976C26.2831 22.6071 25.1956 17.9347 27.4793 14.5662Z" fill="#FFF3DE"/>
                <path d="M34.1459 17.8633C34.1822 17.7908 34.2184 17.7546 34.2547 17.6822C35.0522 16.7042 36.4297 16.668 37.5897 17.4648C38.6409 18.153 39.1484 19.2758 38.7859 20.29C38.7134 20.4711 38.6409 20.6522 38.5322 20.7971C37.7709 21.9561 36.1397 22.2097 34.9434 21.3766C33.7472 20.616 33.3847 19.0223 34.1459 17.8633Z" fill="#F4D4AB"/>
            </> : <>
                <path d="M8.96875 10.3738C8.27687 12.038 7.8925 13.8815 7.8925 15.8018C7.8925 16.6211 7.96938 17.4148 8.0975 18.2086C8.14875 18.4646 8.2 18.7206 8.25125 19.0023C7.175 20.1289 6.20125 21.4859 5.35562 23.1245C5.20187 22.2796 5.125 21.409 5.125 20.5385C5.125 16.6211 6.58562 13.0878 8.96875 10.3738Z" fill="#34A5CF"/>
                <path d="M27.1612 33.0842C27.2125 32.8538 27.2381 32.6234 27.2637 32.3673C27.2894 32.2393 27.2894 32.1113 27.315 31.9833C27.3406 31.7784 27.3662 31.548 27.3919 31.3432C27.4175 31.2151 27.4175 31.0615 27.4175 30.9335C27.4431 30.7287 27.4431 30.5238 27.4687 30.319C27.4687 30.191 27.4944 30.0374 27.4944 29.9093C27.4944 29.7045 27.52 29.5253 27.52 29.3204C27.52 29.1924 27.52 29.0644 27.52 28.9364C27.6994 28.8596 27.8787 28.7828 28.0581 28.7059C31.3381 27.1953 33.9775 24.4813 35.3356 21.1272C35.4894 21.3832 35.6687 21.6649 35.7969 21.9465C35.2587 27.5026 31.7481 32.2137 26.8794 34.4156C26.9562 34.0572 27.0331 33.6987 27.0844 33.3659C27.11 33.3147 27.1356 33.2122 27.1612 33.0842Z" fill="#F9D023"/>
                <path d="M15.6334 28.4498C17.5809 29.4484 19.8103 30.0117 22.1422 30.0117C24.0384 30.0117 25.8578 29.6276 27.5234 28.9619C27.5234 29.0899 27.5234 29.2179 27.5234 29.346C27.5234 29.5508 27.4978 29.73 27.4978 29.9349C27.4978 30.0629 27.4978 30.1909 27.4722 30.3445C27.4466 30.5493 27.4466 30.7542 27.4209 30.959C27.4209 31.087 27.3953 31.2407 27.3953 31.3687C27.3697 31.5735 27.3441 31.7783 27.3184 32.0088C27.2928 32.1368 27.2928 32.2648 27.2672 32.3928C27.2416 32.6233 27.1903 32.8537 27.1647 33.1097C27.1391 33.2122 27.1391 33.3402 27.1134 33.4426C27.0622 33.801 26.9853 34.1339 26.9084 34.4923C24.9609 35.3885 22.8084 35.875 20.5278 35.875C17.9909 35.875 15.6078 35.2605 13.5066 34.1851C13.6347 31.9832 14.0703 29.8836 14.7109 27.9377C14.9928 28.117 15.3259 28.2962 15.6334 28.4498Z" fill="#78A941"/>
                <path d="M27.3945 31.3694C27.3689 31.5742 27.3433 31.779 27.3177 32.0095C27.3433 31.779 27.3689 31.5742 27.3945 31.3694Z" fill="#9FB600"/>
                <path d="M27.2656 32.3933C27.24 32.6237 27.1887 32.8542 27.1631 33.1102C27.1887 32.8542 27.24 32.6237 27.2656 32.3933Z" fill="#9FB600"/>
                <path d="M27.5234 29.3457C27.5234 29.5505 27.4978 29.7298 27.4978 29.9346C27.4978 29.7554 27.5234 29.5505 27.5234 29.3457Z" fill="#9FB600"/>
                <path d="M26.8809 34.4924C26.9578 34.1339 27.0347 33.7755 27.0859 33.4426C27.0347 33.7755 26.9578 34.1339 26.8809 34.4924Z" fill="#9FB600"/>
                <path d="M27.4688 30.345C27.4431 30.5498 27.4431 30.7546 27.4175 30.9595C27.4431 30.7546 27.4688 30.5498 27.4688 30.345Z" fill="#9FB600"/>
                <path d="M14.7109 27.9377C14.0703 29.8836 13.6347 31.9831 13.5066 34.1851C9.27843 32.0087 6.20343 27.9633 5.38343 23.1242C6.22906 21.4856 7.20281 20.1285 8.27906 19.002C9.12468 22.7657 11.4822 25.9662 14.7109 27.9377Z" fill="#FF3612"/>
                <path d="M35.8494 19.6682C35.875 19.9499 35.875 20.2315 35.875 20.5131C35.875 21.0252 35.8494 21.5117 35.7981 21.9982C35.6444 21.7165 35.4906 21.4349 35.3369 21.1788C35.5419 20.6923 35.7213 20.1803 35.8494 19.6682Z" fill="#3DB7B7"/>
                <path d="M23.3934 5.40601C26.8272 6.0461 29.8253 7.83839 32.0547 10.3476C27.9034 10.3988 23.7009 13.6505 23.7009 13.6505C23.7009 13.6505 24.6491 8.29925 23.3934 5.40601Z" fill="#FF6532"/>
                <path d="M12.222 7.55737C14.6051 6.02114 17.4495 5.125 20.4989 5.125C21.4726 5.125 22.4464 5.22741 23.3689 5.40664C24.6245 8.27427 23.6764 13.6511 23.6764 13.6511C23.6764 13.6511 19.9864 8.09505 12.222 7.55737Z" fill="#8BBF47"/>
                <path d="M12.2253 7.55737C19.9641 8.06945 23.6797 13.6511 23.6797 13.6511C23.6797 13.6511 14.5059 12.3453 8.25343 18.9767C8.20218 18.7207 8.15093 18.4646 8.09968 18.183C7.97156 17.3893 7.89468 16.5955 7.89468 15.7762C7.89468 13.8559 8.27906 12.0381 8.97093 10.3482C9.91906 9.29844 11.0209 8.35109 12.2253 7.55737Z" fill="#3EB6DB"/>
                <path opacity="0.9" d="M27.5477 28.3734C27.5477 28.2453 27.5477 28.1173 27.5477 27.9893C27.5477 27.7845 27.5477 27.6052 27.522 27.426C27.522 27.298 27.522 27.17 27.4964 27.0675C27.4964 26.8883 27.4708 26.6835 27.4708 26.5043C27.4708 26.3762 27.4452 26.2738 27.4452 26.1458C27.4195 25.9666 27.4195 25.7618 27.3939 25.5825C27.3939 25.4801 27.3683 25.3521 27.3683 25.2497C27.3427 25.0704 27.317 24.8656 27.2914 24.6864C27.2658 24.584 27.2658 24.4815 27.2402 24.3791C27.2145 24.1999 27.1889 23.9951 27.1633 23.8159C27.1377 23.7134 27.1377 23.6366 27.112 23.5342C27.0864 23.3294 27.0352 23.1501 27.0095 22.9709C26.9839 22.8941 26.9839 22.7917 26.9583 22.7149C26.9327 22.51 26.8814 22.3308 26.8302 22.126C26.8045 22.0492 26.8045 21.9724 26.7789 21.9212C26.7277 21.7163 26.6764 21.5371 26.6508 21.3323C26.6252 21.2811 26.6252 21.2042 26.5995 21.153C26.5483 20.9482 26.497 20.7434 26.4458 20.5385C26.4458 20.4873 26.4202 20.4617 26.4202 20.4105C26.3689 20.2057 26.292 19.9753 26.2408 19.7704C26.2408 19.7448 26.2152 19.7192 26.2152 19.6936C25.6514 17.8245 25.0108 16.3139 24.5239 15.2641C24.5239 15.2641 24.5239 15.2642 24.5239 15.2385C24.4726 15.1105 24.4214 15.0081 24.3445 14.9057C24.3445 14.8801 24.3189 14.8801 24.3189 14.8545C24.2677 14.7521 24.2164 14.6753 24.1908 14.5729C24.1908 14.5472 24.1652 14.5472 24.1652 14.5216C24.1139 14.4448 24.0883 14.368 24.0627 14.2912C24.0627 14.2656 24.037 14.24 24.037 14.24C24.0114 14.1888 23.9858 14.1376 23.9601 14.0864C23.9345 14.0608 23.9345 14.0352 23.9089 14.0095C23.8833 13.9583 23.8576 13.9328 23.8576 13.9072C23.832 13.8816 23.832 13.8559 23.8064 13.8303C23.8064 13.8047 23.7808 13.8047 23.7808 13.7791C23.7295 13.6767 23.7039 13.6255 23.7039 13.6255C23.7039 13.6255 31.1608 14.2912 35.3633 21.153C33.9795 24.5072 31.3658 27.2212 28.0858 28.7318C27.9064 28.8086 27.727 28.8854 27.5477 28.9622V28.9366C27.522 28.7574 27.5477 28.5526 27.5477 28.3734Z" fill="#F9D023"/>
                <path d="M23.6775 14.1123L23.7287 13.7026C23.7287 13.7282 23.7544 13.7539 23.78 13.7795C23.78 13.8051 23.8056 13.8051 23.8056 13.8307C23.8056 13.8563 23.8312 13.8819 23.8569 13.9075C23.8825 13.9331 23.9081 13.9843 23.9081 14.0099C23.9081 14.0355 23.9337 14.0611 23.9594 14.0867C23.985 14.1379 24.0106 14.1891 24.0362 14.2403C24.0362 14.2659 24.0619 14.2915 24.0619 14.2915C24.0875 14.3684 24.1387 14.4451 24.1644 14.5219C24.1644 14.5476 24.19 14.5476 24.19 14.5732C24.2412 14.65 24.2925 14.7524 24.3181 14.8548C24.3181 14.8804 24.3437 14.8804 24.3437 14.906C24.395 15.0084 24.4462 15.1365 24.5231 15.2389C24.5231 15.2389 24.5231 15.2389 24.5231 15.2645C25.01 16.3142 25.6506 17.8249 26.2144 19.694C26.2144 19.7196 26.24 19.7451 26.24 19.7707C26.2912 19.9756 26.3681 20.1804 26.4194 20.4108C26.4194 20.4621 26.445 20.4877 26.445 20.5389C26.4962 20.7437 26.5475 20.9485 26.5988 21.1534C26.6244 21.2046 26.6244 21.2814 26.65 21.3326C26.7012 21.5374 26.7525 21.7167 26.7781 21.9215C26.8037 21.9983 26.8037 22.0751 26.8294 22.1263C26.8806 22.3056 26.9062 22.5104 26.9575 22.7152C26.9831 22.792 26.9831 22.8688 27.0088 22.9712C27.0344 23.1505 27.0856 23.3553 27.1113 23.5345C27.1369 23.637 27.1369 23.7138 27.1625 23.8162C27.1881 23.9954 27.2138 24.2002 27.2394 24.3794C27.265 24.4819 27.265 24.5843 27.2906 24.6867C27.3162 24.8659 27.3419 25.0708 27.3675 25.25C27.3675 25.3524 27.3931 25.4804 27.3931 25.5829C27.4188 25.7621 27.4187 25.9669 27.4444 26.1461C27.4444 26.2741 27.47 26.3766 27.47 26.5046C27.47 26.6838 27.4956 26.8886 27.4956 27.0679C27.4956 27.1959 27.4956 27.3239 27.5213 27.4263C27.5213 27.6055 27.5212 27.8104 27.5469 27.9896C27.5469 28.1176 27.5469 28.2457 27.5469 28.3737C27.5469 28.5529 27.5469 28.7577 27.5469 28.9626V28.9882C25.8812 29.6795 24.0619 30.0379 22.1656 30.0379C19.8081 30.0379 17.6044 29.4746 15.6569 28.4761C15.3494 28.3225 15.0162 28.1432 14.7087 27.9384C17.425 19.6683 23.6775 14.1123 23.6775 14.1123Z" fill="#8BBF47"/>
                <path d="M23.6792 13.6511C23.6792 13.6511 23.7048 13.6767 23.7305 13.7279L23.6792 14.1376C23.6792 14.1376 17.4267 19.6936 14.6848 27.9381C11.4817 25.9666 9.09858 22.7661 8.25296 18.9767C14.5311 12.3453 23.6792 13.6511 23.6792 13.6511Z" fill="#FF6532"/>
                <path d="M23.6797 13.6513C23.6797 13.6513 27.8822 10.3996 32.0334 10.3484C34.2372 12.8576 35.6466 16.1093 35.8516 19.6682C35.6978 20.1803 35.5184 20.6923 35.3391 21.1788C31.1622 14.317 23.6797 13.6513 23.6797 13.6513Z" fill="#3EB6DB"/>
                <path d="M19.4222 10.2968C19.4991 10.1688 19.6016 10.0664 19.6785 9.96402C21.3441 7.91571 24.2397 7.89009 26.6229 9.50313C28.7754 10.9626 29.8516 13.2669 29.1341 15.392C29.006 15.7505 28.8266 16.1346 28.596 16.4674C27.0072 18.8486 23.6504 19.3862 21.1135 17.6708C18.5766 15.9809 17.8078 12.678 19.4222 10.2968Z" fill="#FFF3DE"/>
                <path d="M24.1377 12.6274C24.1633 12.5762 24.189 12.5506 24.2146 12.4994C24.7783 11.8081 25.7521 11.7825 26.5721 12.3458C27.3152 12.8322 27.674 13.626 27.4177 14.3429C27.3665 14.4709 27.3152 14.5989 27.2383 14.7013C26.7002 15.5207 25.5471 15.6999 24.7015 15.111C23.8558 14.5733 23.5996 13.4468 24.1377 12.6274Z" fill="#F4D4AB"/>
            </>}
            
        </motion.svg>
    );
}


