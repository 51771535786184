import { DragAndDrop } from '../../../../components/global/DragAndDrop';
import { motion } from 'framer-motion';
import { calcGapX, getCoordinates } from '../Shape1.data';
import { 
    animationDurationToShowQuestion,
} from "../../../../config";
import React, { useState, useEffect, useRef } from 'react';
import Sound from '../../../../components/global/Sound';
import { CircleButton } from '../../../../components/elements/CircleButton';
import { getCircleComponent } from "../../../../hooks/useCircleButtonColor";


export const BottomPart = ({state, answers, answer, answerSound, checkAnswer, hint}) => {
    const colors = useRef(getCircleComponent(answers));
    const [i, setI] = useState('');
    const [animate, setAnimate] = useState(false);
    const [val, setVal] = useState();
    const [update, setUpdate] = useState(false);
    const soundRef = useRef();
    const soundWrongRef = useRef();
    const [type, setType] = useState(false);

    const [time, setTime] = useState(0);

    useEffect(()=>{
        if (!animate) return;
        setVal('');
        setAnimate(false);
        setI('');
        setTime(0);
        setUpdate(false);
        colors.current = getCircleComponent(answers);
    }, [answers]);

    useEffect(()=>{
        if (type) setTimeout(()=>{
         setType(false);
         document.querySelector('.shaperesult').style.opacity = 1;
        }, 1000);
    }, [type]);

    useEffect(()=>{
        if(!hint) return;
        const id = setInterval(()=>setTime(prev=>prev+1), 1000);
        return ()=>clearInterval(id);
    }, [time, hint]);

    return (
        <div className={`w-full flex items-center border-[3px] border-[#2F9EBA] bg-[#FFF] rounded-[16px]
           ${state ? "h-[67px] md:h-[73px] lg:h-[93px] xl:h-[114px]" : "h-[67px] md:h-[90px] lg:h-[113px] xl:h-[136px]"}`}>
            {answers.map((number, index) => {
                if (i===index) {
                   return <DragAndDrop
                     enableClick = {true}
                     drop={'shape'}
                     animateWrong = {(e)=>{
                        setUpdate(index);
                        setI('');
                        answerSound && soundWrongRef.current.play();
                    }}
                    key={index} numbers={[answer]} item={number} 
                        refresh={(event, type) => {
                            if (event==='mouseup') return;
                            if (val===number) return; //prevnting multiple clicks on one element
                            setType(type);
                            setTime(0);
                            checkAnswer(event, type, number);
                            setVal(number);
                            setUpdate(false);
                            if(type) setTimeout(()=>document.querySelector('.shaperesult').style.opacity=0, 200);
                            answerSound && soundRef.current.play();
                        }}
                     >
                    <motion.div 
                        style={{
                            marginLeft: calcGapX(state)+'px', 
                            transform: type && val === number && `translate(${getCoordinates()[0]}px,${getCoordinates()[1]}px)`,
                            transition: `transform ${0.2}s linear`
                        }} 
                        className={`relative answer ${val === number && !type? 'opacity-0' : 'opacity-100'} z-[4000] cursor-pointer`}
                    >
                        <CircleButton key={index} isActive={number ? number : 1} color={colors.current[index]}>
                            {number}
                        </CircleButton>
                        {hint && answer === number && time>=10 && time%2===0 && <div className="bg-[url('/backGrounds/hint.svg')] bg-[position:50%_50%] bg-contain bg-no-repeat absolute w-[50px] h-[50px] top-[50%] z-[50]"></div>}
                    </motion.div>
                   </DragAndDrop>
                } else {
                   return <motion.div 
                        key={index} style={{marginLeft: calcGapX(state)+'px'}} className={`relative z-[50] cursor-pointer`} 
                        animate={{y: animate ? [0, 0] : [(index+1)*100, 0], x:update === index ? [-5, 0, 5, 0] : [0, 0, 0, 0]}}
                        transition={{duration: update === index ? 0.4 : animationDurationToShowQuestion , type: "tween", ease: "linear"}}
                        onPointerEnter={(e)=>{
                            setI(index);
                            setAnimate(true);
                            setUpdate(false);
                        }}
                      >
                        <CircleButton key={index} isActive={number ? number : 1} color={colors.current[index]}>
                           {number}
                        </CircleButton>
                        {hint && answer === number && time>=10 && time%2===0 && <div className="bg-[url('/backGrounds/hint.svg')] bg-[position:50%_50%] bg-contain bg-no-repeat absolute w-[50px] h-[50px] top-[50%] z-[50]"></div>}
                    </motion.div>
                }
            })}
            {answerSound+""=="true" && <Sound ref={soundRef} url={"/backGrounds/right_answer.wav"} loop={false}/>}
            {answerSound+""=="true" && <Sound ref={soundWrongRef} url={"/backGrounds/wrong_answer.wav"} loop={false}/>}
        </div>
    )
}