import { shapes } from "../Shape3.data";
import { motion } from 'framer-motion';
import { 
    animationDurationToShowQuestion,
} from "../../../../config";


export const LeftPart = ({state, answer, result, animate}) => {
    return (
        <div className={`
           border-[3px] rounded-[16px] lg:rounded-[20px] border-[#2F9EBA] bg-[#FFF] flex items-center justify-center font-bigfatScript text-[#FFF]
           ${state ? "w-[344px] md:w-[350px] lg:w-[477px] xl:w-[630px] lg:text-[36px] xl:text-[46px]" : "w-[344px] md:w-[467px] lg:w-[566px] xl:w-[745px] md:text-[32px] lg:text-[46px] xl:text-[60px]"} h-full text-[24px]
        `}>
              <motion.div
                 animate={{opacity: [animate, 1], scale: [animate, 1]}}
                 transition={{duration: animationDurationToShowQuestion, type: "tween", ease: "linear"}} 
                 style={{
                    clipPath: answer!=="circle" && shapes[answer].path,
                    borderRadius: answer === "circle" && "50%",
                }}
                 className={`shape bg-[#FBB23B] w-[145px] h-[137px] flex items-center justify-center
                          ${state ? "md:w-[172px] md:h-[163px] lg:w-[206px] lg:h-[195px] xl:w-[296px] xl:h-[281px]" : "md:w-[197px] md:h-[187px] lg:w-[265px] lg:h-[251px] xl:w-[358px] xl:h-[340px]"}
                       `}
              >
                  <div
                        style={{
                            backgroundColor: result!== "?" && shapes[answer].color,
                            clipPath: result!=="circle" && result!== "?" && shapes[answer].path,
                            borderRadius: result === "circle"  && result!== "?" &&  "50%",
                        }}
                        className={`result text-center ${state ? 'md:w-[39px] md:h-[39px] lg:w-[50px] lg:h-[50px] xl:w-[70px] xl:h-[70px] ' : ' md:w-[49px] md:h-[49px]  lg:w-[69px] lg:h-[69px] xl:w-[89px] xl:h-[89px] '} w-[39px] h-[39px]`}
                  >
                        {result === "?" ? result : ""}
                  </div>
                  
              </motion.div>
        </div>
    )
}