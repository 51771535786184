import { useParams } from "react-router-dom";

export function UnderConstruction() {
  let { gameName } = useParams();
  return (
    <div className="relative flex h-full w-full items-center justify-center bg-gray-500">
      <span className="relative inline-flex">
        <button
          type="button"
          className="inline-flex cursor-not-allowed items-center rounded-md bg-white px-4 py-2 text-sm font-semibold leading-6 text-sky-500 shadow ring-1 ring-slate-900/10 transition duration-150 ease-in-out dark:bg-slate-800 dark:ring-slate-200/20"
          disabled=""
        >
          Under Construction {gameName}
        </button>
        <span className="absolute right-0 top-0 -mr-1 -mt-1 flex h-3 w-3">
          <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-sky-400 opacity-75"></span>
          <span className="relative inline-flex h-3 w-3 rounded-full bg-sky-500"></span>
        </span>
      </span>
    </div>
  );
}
