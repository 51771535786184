import { StepByStep }  from "../games/Counting/StepByStep/StepByStep";
import { OddOrEven } from "../games/Counting/OddOrEven/OddOrEven";
import { Counting } from "../games/Counting/Counting/Counting";
import Classic  from "../games/Counting/Classic/Classic";
import FindNumber from "../games/Sorting/Findnumber/FindNumber";
import { Adding } from "../games/Adding/Adding1/Adding";
import { Adding2 } from "../games/Adding/Adding2/Adding2";
import { Adding4 } from "../games/Adding/Adding4/Adding4";
import { Adding3 } from "../games/Adding/Adding3/Adding3";
import { Adding5 } from "../games/Adding/Adding5/Adding5";
import { Adding6 } from "../games/Adding/Adding6/Adding6";
import { AddingMix } from "../games/Adding/AddingMix/AddingMix";
import { RapidMath } from "../games/Adding/RapidMath/RapidMath";
import { Division } from "../games/Division/Division1/Division";
import { Division2 } from "../games/Division/Division2/Division2";
import { Division3 } from "../games/Division/Division3/Division3";
import { Division4 } from "../games/Division/Division4/Division4";
import { Division5 } from "../games/Division/Expression/Expression";
import { RapidMathDiv } from "../games/Division/RapidMath/RapidMath";
import { Multiplication } from "../games/Multiplication/Multiplication1/Multiplication";
import { Multiplication2 } from "../games/Multiplication/Multiplication2/Multiplication2";
import { Multiplication3 } from "../games/Multiplication/Multiplication3/Multiplication3";
import { Multiplication4 } from "../games/Multiplication/Multiplication4/Multiplication4";
import { Multiplication5 } from "../games/Multiplication/Multiplication5/Multiplication5";
import { Multiplication6 } from "../games/Multiplication/Expression/Expression";
import { RapidMathMult } from "../games/Multiplication/RapidMath/RapidMath";
import { Subtracting } from "../games/Subtracting/Subtracting1/Subtracting";
import { Subtracting2 } from "../games/Subtracting/Subtracting2/Subtracting2";
import { Subtracting3 } from "../games/Subtracting/Subtracting3/Subtracting3";
import { Subtracting4 } from "../games/Subtracting/Subtracting4/Subtracting4";
import { Subtracting5 } from "../games/Subtracting/Subtracting5/Subtracting5";
import { Subtracting6 } from "../games/Subtracting/Expression/Expression";
import { RapidMathSubtr } from "../games/Subtracting/RapidMath/RapidMath";
import { Shape1 } from "../games/Shapes/Shape1/Shape1";
import { Shape2 } from "../games/Shapes/Shape2/Shape2";
import { Shape3 } from "../games/Shapes/Shape3/Shape3";
import { Shape4 } from "../games/Shapes/Shape4/Shape4";
import { Shape5 } from "../games/Shapes/Shape5/Shape5";
import { Compare }  from "../games/Compare/Compare/Compare";
import { Compare1 }  from "../games/Compare/Compare1/Compare1";
import { Scale }  from "../games/Compare/Scale/Scale";
import { Expression }  from "../games/Compare/Expression/Expression";
import { Grid }  from "../games/Compare/Blocks/Grid";
import { Memory } from "../games/Memory/Memory/Memory";
import { RapidColor } from "../games/Memory/RapidColor/RapidColor";
import { Colors } from "../games/Memory/Colors/Colors";
import { Shapes } from "../games/Memory/Shapes/Shapes";
import { Numbers } from "../games/Memory/Numbers/Numbers";
import { RapidNumbers } from "../games/Memory/RapidNumbers/RapidNumbers";
import { RapidShapes } from "../games/Memory/RapidShapes/RapidShapes";
import { Schulte1 } from "../games/Schulte/Classic/Classic";
import { Schulte2 } from "../games/Schulte/StepByStep/StepByStep";
import { Segment } from "../games/Schulte/Segment/Segment";
import { AscendingAndDescending } from "../games/Sorting/AscendingAndDescending/AscendingAndDescending";
import { Sorting1 } from "../games/Sorting/Sorting1/Sorting1";
import { Learning } from "../games/Counting/Learning/Learning";


export const data = {
    counting: [
        {
            game: Classic,
            name: "counting_1",
            timer: false,
            result: false,
            price: false,
            cycle: 3,
            userImage: "g2",
            point: 3
        },
        {
            game: Counting,
            name: "counting_2",
            timer: false,
            result: false,
            price: false,
            cycle: 3,
            userImage: "b4",
            point: 2
        },
        {
            game: Learning,
            name: "learning",
            timer: false,
            result: false,
            price: false,
            cycle: 1,
            userImage: "g5",
            point: 1
        },
        {
            game: OddOrEven,
            name: "odd_or_even",
            timer: false,
            result: false,
            price: false,
            cycle: 1,
            userImage: "g3",
            point: 5
        },
        {
           game: StepByStep,
           name: "step_by_step",
           timer: {1: {min: 0, sec: 30}, 2: {min: 0, sec: 40}, 3: {min: 1, sec: 0}},
           result: false,
           price: true,
           cycle: 1,
           userImage: "b5",
           level: true,
           point: {
              beginner: 15,
              intermediate: 20,
              advanced: 25 
           }
        }
    ],
    adding: [
        {
            game: Adding,
            name: "adding_1",
            timer: false,
            result: false,
            price: false,
            cycle: 3,
            userImage: "g4",
            point: 2
        },
        {
            game: Adding2,
            name: "adding_2",
            timer: false,
            result: false,
            price: false,
            cycle: 3,
            userImage: "g1",
            point: 2
        },
        {
            game: Adding3,
            name: "adding_3",
            timer: false,
            result: false,
            price: false,
            cycle: 1,
            level: true,
            userImage: "b3",
            point: {
                beginner: 5,
                intermediate: 10,
                advanced: 15 
            }
        },
        {
            game: RapidMath,
            name: "rapid_math",
            timer: {min: 0, sec: 30},
            result: true,
            price: false,
            cycle: 3,
            level: true,
            userImage: "g4",
            point: {
                beginner: 2,
                intermediate: 3,
                advanced: 4 
            }
        },
        {
            game: AddingMix,
            name: "adding_mix",
            timer: false,
            result: false,
            price: false,
            cycle: 1,
            userImage: "b4",
            point: 5
        },
        {
            game: Adding4,
            name: "adding_4",
            timer: false,
            result: false,
            price: false,
            cycle: 3,
            level: true,
            userImage: "g1",
            point: {
                beginner: 3,
                intermediate: 5,
                advanced: 10 
            }
        },
        {
            game: Adding5,
            name: "adding_5",
            timer: false,
            result: false,
            price: false,
            cycle: 1,
            userImage: "b1",
            point: 4
        },
        {
            game: Adding6,
            name: "expression",
            timer: false,
            result: false,
            price: false,
            level: true,
            cycle: 3,
            userImage: "g2",
            point: {
                beginner: 2,
                intermediate: 5,
                advanced: 8 
            }
        },
    ],
    multiplication: [
        {
            game: Multiplication,
            name: "multiplication_1",
            timer: false,
            result: false,
            price: false,
            cycle: 3,
            userImage: "b2",
            point: 2
        },
        {
            game: Multiplication2,
            name: "multiplication_2",
            timer: false,
            result: false,
            price: false,
            cycle: 3,
            userImage: "b4",
            point: 2
        },
        {
            game: Multiplication3,
            name: "multiplication_3",
            timer: false,
            result: false,
            price: false,
            cycle: 1,
            level: true,
            userImage: "g4",
            point: {
                beginner: 5,
                intermediate: 10,
                advanced: 15 
            }
        },
        {
            game: RapidMathMult,
            name: "rapid_math",
            timer: {min: 0, sec: 30},
            result: true,
            price: false,
            cycle: 3,
            level: true,
            userImage: "b4",
            point: {
                beginner: 2,
                intermediate: 3,
                advanced: 4 
            }
        },
        {
            game: Multiplication4,
            name: "multiplication_4",
            timer: false,
            result: false,
            price: false,
            cycle: 3,
            level: true,
            userImage: "b5",
            point: {
                beginner: 3,
                intermediate: 5,
                advanced: 10 
            }
        },
        {
            game: Multiplication5,
            name: "multiplication_5",
            timer: false,
            result: false,
            price: false,
            cycle: 1,
            userImage: "b1",
            point: 4
        },
        {
            game:  Multiplication6,
            name: "expression",
            timer: false,
            result: false,
            price: false,
            level: true,
            cycle: 3,
            userImage: "g2",
            point: {
                beginner: 2,
                intermediate: 5,
                advanced: 8 
            }
        },
    ],
    sorting: [
        {
            game: Sorting1,
            name: "expression",
            timer: false,
            result: false,
            price: false,
            cycle: 3,
            level: true,
            userImage: "g1",
            point: {
                beginner: 3,
                intermediate: 5,
                advanced: 7
            }
        },
        {
            game: AscendingAndDescending,
            name: "ascending",
            timer: false,
            result: false,
            price: false,
            cycle: 3,
            level: true,
            userImage: "b6",
            point: {
                beginner: 3,
                intermediate: 5,
                advanced: 7
            }
        },
        {
            game: AscendingAndDescending,
            name: "descending",
            timer: false,
            result: false,
            price: false,
            cycle: 3,
            level: true,
            userImage: "b2",
            point: {
                beginner: 3,
                intermediate: 5,
                advanced: 7
            }
        },
        {
            game: FindNumber,
            name: "find_number",
            timer: {1: {min: 0, sec: 30}, 2: {min: 0, sec: 40}, 3: {min: 1, sec: 0}},
            result: false,
            price: true,
            cycle: 3,
            userImage: "g1",
            level: true,
            point: {
                beginner: 2,
                intermediate: 3,
                advanced: 4 
            }
        },
        
    ],
    memory: [
        {
            game: Memory,
            name: "images",
            timer: {1: {min: 0, sec: 30}, 2: {min: 0, sec: 40}, 3: {min: 1, sec: 0}},
            result: false,
            price: true,
            cycle: 1,
            level: true,
            userImage: "g3",
            point: {
                beginner: 5,
                intermediate: 40,
                advanced: 45
            }
        },
        {
            game: Colors,
            name: "colors",
            timer: false,
            result: false,
            price: false,
            cycle: 3,
            level: true,
            userImage: "b4",
            point: {
                beginner: 4,
                intermediate: 5,
                advanced: 7
            }
        },
        {
            game: RapidColor,
            name: "rapid_colors",
            timer: {min: 0, sec: 30},
            result: true,
            price: false,
            cycle: 3,
            level: true,
            userImage: "g4",
            point: {
                beginner: 4,
                intermediate: 5,
                advanced: 7
            }
        },
        {
            game: Numbers,
            name: "numbers",
            timer: false,
            result: false,
            price: false,
            cycle: 3,
            level: true,
            userImage: "b2",
            point: {
                beginner: 4,
                intermediate: 5,
                advanced: 7
            }
        },
        {
            game: RapidNumbers,
            name: "rapid_numbers",
            timer: {min: 0, sec: 30},
            result: true,
            price: false,
            cycle: 3,
            level: true,
            userImage: "g3",
            point: {
                beginner: 4,
                intermediate: 5,
                advanced: 7
            }
        },
        {
            game: Shapes,
            name: "shapes",
            timer: false,
            result: false,
            price: false,
            cycle: 3,
            level: true,
            userImage: "b6",
            point: {
                beginner: 4,
                intermediate: 5,
                advanced: 7
            }
        },
        {
            game: RapidShapes,
            name: "rapid_shapes",
            timer: {min: 0, sec: 30},
            result: true,
            price: false,
            cycle: 3,
            level: true,
            userImage: "g4",
            point: {
                beginner: 4,
                intermediate: 5,
                advanced: 7
            }
        },
    ],
    shapes: [
        {
            game: Shape1,
            name: "shapes_1",
            timer: false,
            result: false,
            price: false,
            cycle: 3,
            userImage: "b3",
            point: 2
        },
        {
            game: Shape2,
            name: "shapes_2",
            timer: false,
            result: false,
            price: false,
            cycle: 3,
            userImage: "g2",
            point: 3
        },
        {
            game: Shape3,
            name: "shapes_3",
            timer: false,
            result: false,
            price: false,
            cycle: 3,
            userImage: "b4",
            point: 2
        },
        {
            game: Shape4,
            name: "shapes_4",
            timer: false,
            result: false,
            price: false,
            cycle: 3,
            level: true,
            userImage: "g1",
            point: {
                beginner: 2,
                intermediate: 3,
                advanced: 4
            }
        },
        {
            game: Shape5,
            name: "shapes_5",
            timer: false,
            result: false,
            price: false,
            cycle: 3,
            userImage: "b1",
            point: 2
        },
    ],
    subtracting: [
        {
            game: Subtracting,
            name: "subtracting_1",
            timer: false,
            result: false,
            price: false,
            cycle: 3,
            userImage: "b6",
            point: 2
        },
        {
            game: Subtracting2,
            name: "subtracting_2",
            timer: false,
            result: false,
            price: false,
            cycle: 3,
            userImage: "g1",
            point: 2
        },
        {
            game: Subtracting3,
            name: "subtracting_3",
            timer: false,
            result: false,
            price: false,
            cycle: 1,
            level: true,
            userImage: "b3",
            point: {
                beginner: 5,
                intermediate: 10,
                advanced: 15 
            }
        },
        {
            game: RapidMathSubtr,
            name: "rapid_math",
            timer: {min: 0, sec: 30},
            result: true,
            price: false,
            cycle: 3,
            level: true,
            userImage: "b4",
            point: {
                beginner: 2,
                intermediate: 3,
                advanced: 4 
            }
        },
        {
            game: Subtracting4,
            name: "subtracting_4",
            timer: false,
            result: false,
            price: false,
            cycle: 3,
            level: true,
            userImage: "b3",
            point: {
                beginner: 3,
                intermediate: 5,
                advanced: 10 
            }
        },
        {
            game: Subtracting5,
            name: "subtracting_5",
            timer: false,
            result: false,
            price: false,
            cycle: 1,
            userImage: "b1",
            point: 4
        },
        {
            game:  Subtracting6,
            name: "expression",
            timer: false,
            result: false,
            price: false,
            level: true,
            cycle: 3,
            userImage: "g2",
            point: {
                beginner: 2,
                intermediate: 5,
                advanced: 8 
            }
        },
    ],
    division: [
        {
            game: Division,
            name: "division_1",
            timer: false,
            result: false,
            price: false,
            cycle: 3,
            userImage: "b3",
            point: 2
        },
        {
            game: Division2,
            name: "division_2",
            timer: false,
            result: false,
            price: false,
            cycle: 3,
            userImage: "g4",
            point: 2
        },
        {
            game: Division3,
            name: "division_3",
            timer: false,
            result: false,
            price: false,
            cycle: 1,
            level: true,
            userImage: "g4",
            point: {
                beginner: 5,
                intermediate: 10,
                advanced: 15 
            }
        },
        {
            game: RapidMathDiv,
            name: "rapid_math",
            timer: {min: 0, sec: 30},
            result: true,
            price: false,
            cycle: 3,
            level: true,
            userImage: "b4",
            point: {
                beginner: 2,
                intermediate: 3,
                advanced: 4 
            }
        },
        {
            game: Division4,
            name: "division_4",
            timer: false,
            result: false,
            price: false,
            cycle: 1,
            userImage: "b1",
            point: 4
        },
        {
            game:  Division5,
            name: "expression",
            timer: false,
            result: false,
            price: false,
            level: true,
            cycle: 3,
            userImage: "b2",
            point: {
                beginner: 2,
                intermediate: 5,
                advanced: 8 
            }
        },
    ],
    compare: [
        {
            game: Scale,
            name: "scale",
            timer: false,
            result: true,
            price: false,
            cycle: 3,
            level: true,
            userImage: "g2",
            point: {
                beginner: 2,
                intermediate: 5,
                advanced: 8
            }
        },
        {
            game: Compare,
            name: "compare",
            timer: false,
            result: false,
            price: false,
            cycle: 3,
            userImage: "g3",
            point: 2
        },
        {
            game:  Compare1,
            name: "expression_1",
            timer: false,
            result: false,
            price: false,
            level: true,
            cycle: 3,
            userImage: "b2",
            point: {
                beginner: 2,
                intermediate: 5,
                advanced: 8 
            }
        },
        {
            game:  Expression,
            name: "expression_2",
            timer: false,
            result: false,
            price: false,
            level: true,
            cycle: 3,
            userImage: "b2",
            point: {
                beginner: 2,
                intermediate: 5,
                advanced: 8 
            }
        },
        {
            game: Grid,
            name: "blocks",
            timer: false,
            result: false,
            price: false,
            cycle: 3,
            userImage: "b6",
            point: 2
        },
    ],
    schulte: [
        {
            game: Schulte1,
            name: "classic_asc",
            timer: {1: {min: 0, sec: 30}, 2: {min: 0, sec: 40}, 3: {min: 1, sec: 0}},
            result: false,
            price: true,
            cycle: 1,
            level: true,
            userImage: "b2",
            point: {
                beginner: 5,
                intermediate: 40,
                advanced: 45
            }
        },
        {
            game: Schulte1,
            name: "classic_desc",
            timer: {1: {min: 0, sec: 30}, 2: {min: 0, sec: 40}, 3: {min: 1, sec: 0}},
            result: false,
            price: true,
            cycle: 1,
            level: true,
            userImage: "b2",
            point: {
                beginner: 5,
                intermediate: 40,
                advanced: 45
            }
        },
        {
            game: Schulte2,
            name: "step_by_step_asc",
            timer: {1: {min: 0, sec: 30}, 2: {min: 0, sec: 40}, 3: {min: 1, sec: 0}},
            result: false,
            price: true,
            cycle: 1,
            level: true,
            userImage: "b6",
            point: 45
        },
        {
            game: Schulte2,
            name: "step_by_step_desc",
            timer: {1: {min: 0, sec: 30}, 2: {min: 0, sec: 40}, 3: {min: 1, sec: 0}},
            result: false,
            price: true,
            cycle: 1,
            level: true,
            userImage: "b6",
            point: 45
        },
        {
            game: Segment,
            name: "segment_asc",
            timer: {1: {min: 0, sec: 30}, 2: {min: 0, sec: 40}, 3: {min: 1, sec: 0}},
            result: false,
            price: true,
            cycle: 1,
            level: true,
            userImage: "g4",
            point: {
                beginner: 5,
                intermediate: 40,
                advanced: 45
            }
        },
        {
            game: Segment,
            name: "segment_desc",
            timer: {1: {min: 0, sec: 30}, 2: {min: 0, sec: 40}, 3: {min: 1, sec: 0}},
            result: false,
            price: true,
            cycle: 1,
            level: true,
            userImage: "g4",
            point: {
                beginner: 5,
                intermediate: 40,
                advanced: 45
            }
        },
    ]

}