
import { CircleButton } from '../../../../components/elements/CircleButton';
import { 
    animationDurationToShowQuestion,
} from "../../../../config";
import { motion } from 'framer-motion';


export default function LeftPart ({state, firstNumber, secondNumber, result, animation}) {
    return (
        <div className={`flex items-center justify-center ${state ? "w-[344px] md:w-[350px] lg:w-[477px] xl:w-[630px]" : "w-[344px] md:w-[467px] lg:w-[566px] xl:w-[745px]"} h-full border-[3px] rounded-[16px] lg:rounded-[20px] border-[#D14116] bg-[#FFF]`}>
            <div className={`overflow-hidden font-bigfatScript text-[24px] flex items-center justify-between px-[18px] w-[286px] h-[50px] rounded-[16px] bg-[#85B054] ${state ? 'xl:px-[48px] lg:text-[36px] md:w-[304px] md:h-[49px] lg:px-[22px] lg:w-[394px] lg:h-[74px] xl:w-[546px] xl:h-[110px] xl:text-[46px]' : 'xl:px-[31px] lg:text-[46px] lg:px-[17px] md:text-[32px] md:w-[376px] md:h-[68px] lg:w-[486px] lg:h-[101px] xl:w-[632px] xl:h-[129px] xl:text-[60px]'}`}>
                <motion.div
                    animate={{y: [animation.shift() || 0, 0]}}
                    transition={{duration: animationDurationToShowQuestion, type: "tween", ease: "linear"}} 
                    className={`text-[#FFB43B] lg:w-[45px] ${state ? '' : 'md:w-[44px]'} text-center`}
                >
                    {firstNumber}
                </motion.div>
                <motion.div
                    animate={{y: [animation.shift() || 0, 0]}}
                    transition={{duration: animationDurationToShowQuestion, type: "tween", ease: "linear"}} 
                    className={`text-[#7F48C3] lg:w-[45px] ${state ? '' : 'md:w-[44px]'} text-center`}
                >
                    -
                </motion.div>
                <motion.div
                    animate={{y: [animation.shift() || 0, 0]}}
                    transition={{duration: animationDurationToShowQuestion, type: "tween", ease: "linear"}} 
                    className={`text-[#FFB43B] lg:w-[45px] ${state ? '' : 'md:w-[44px]'} text-center`}
                >
                    {secondNumber}
                </motion.div>
                <motion.div
                    animate={{y: [animation.shift() || 0, 0]}}
                    transition={{duration: animationDurationToShowQuestion, type: "tween", ease: "linear"}} 
                    className={`text-[#7F48C3] lg:w-[45px] ${state ? '' : 'md:w-[44px]'} text-center`}
                >
                    =
                </motion.div>
                <motion.div
                    animate={{y: [animation.shift() || 0, 0]}}
                    transition={{duration: animationDurationToShowQuestion, type: "tween", ease: "linear"}} 
                >
                    <CircleButton className="subtractingresult" isActive={isNaN(result) ? false : result}>
                        <div className="subtracting">
                            {result}
                        </div>
                    </CircleButton>
                </motion.div>
            </div>
        </div>
    )
}