export const getBottomGapX = (state) => {
    if (window.innerWidth <  1024) {
      return (425-7*39)/8;
    }
    if (window.innerWidth>=1024 && window.innerWidth<1440) {
      if (state) {
        return (439-7*39)/8;
      } else {
        return (576-7*49)/8;
      } 
    }
    if (window.innerWidth>=1440 && window.innerWidth<1920) {
      if (state) {
        return (590-7*50)/8;
      } else {
        return (723-7*69)/8;
      } 
    }
    if (window.innerWidth>=1920) {
      if (state) {
        return (768-7*70)/8;
      } else {
        return (909-7*89)/8;
      } 
    }
}

export const getNumber = () => {
  const min = 1;
  const max = 10;
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export const getAnswers = (num1, num2, num3) => {
    const arr = new Set();
    const min = 1;
    const max = 20;

    while(arr.size<4) {
      arr.add(Math.floor(Math.random() * (max - min + 1) + min));
    }
    const newArray = [...Array.from(arr), num1, num2, num3];
    return newArray.sort(()=>Math.random()-0.5);
}