import { 
    NumberOne, 
    NumberTwo, 
    NumberThree,
    NumberFour,
    NumberFive,
    NumberSix,
    NumberSeven,
    NumberEight,
    NumberNine,
    NumbertTen

} from "./Images";

export const components = {
    1: NumberOne,
    2: NumberTwo,
    3: NumberThree,
    4: NumberFour,
    5: NumberFive,
    6: NumberSix,
    7: NumberSeven,
    8: NumberEight,
    9: NumberNine,
    10: NumbertTen
}

export const data = {
    1: {
        result: [null, null],
        positions: [["top-[20%] left-[17%]", "top-[0%] left-[52%]"], ["top-[0%] left-[52%]",  "top-[80%] left-[52%]"]],
        middlePosition: ["top-[15%] left-[37%]", "top-[50%] left-[52%]"]
    },
    2: {
        result: [null, null, null],
        positions: [["top-[15%] left-[5%]", "top-[12%] left-[70%]"], ["top-[12%] left-[70%]",  "top-[75%] left-[0%]"], ["top-[75%] left-[0%]",  "top-[75%] left-[72%]"]],
        middlePosition: ["top-[0%] left-[37%]", "top-[50%] left-[40%]", "top-[75%] left-[38%]"]
    },
    3: {
        result: [null, null],
        positions: [["top-[10%] left-[0%]", "top-[35%] left-[17%]"], ["top-[35%] left-[17%]",  "top-[60%] left-[0%]"]],
        middlePosition: ["top-[10%] left-[68%]", "top-[60%] left-[72%]"]
    },
    4: {
        result: [null, null, null],
        positions: [["top-[75%] left-[52%]", "top-[0%] left-[52%]"], ["top-[0%] left-[52%]",  "top-[45%] left-[-4%]"], ["top-[45%] left-[-4%]",  "top-[45%] left-[75%]"]],
        middlePosition: ["top-[40%] left-[52%]", "top-[25%] left-[25%]", "top-[50%] left-[30%]"]
    },
    5: {
        result: [null, null, null],
        positions: [["top-[0%] left-[5%]", "top-[35%] left-[0%]"], ["top-[35%] left-[0%]",  "top-[65%] left-[-4%]"], ["top-[0%] left-[5%]",  "top-[0%] left-[75%]"]],
        middlePosition: ["top-[15%] left-[5%]", "top-[50%] left-[70%]", "top-[0%] left-[40%]"]
    },
    6: {
        result: [null, null, null],
        positions: [["top-[8%] left-[70%]", "top-[35%] left-[0%]"], ["top-[35%] left-[0%]",  "top-[80%] left-[30%]"], ["top-[80%] left-[30%]",  "top-[40%] left-[0%]"]],
        middlePosition: ["top-[0%] left-[25%]", "top-[60%] left-[0%]", "top-[50%] left-[70%]"]
    },
    7: {
        result: [null, null],
        positions: [["top-[-4%] left-[0%]", "top-[-4%] left-[75%]"], ["top-[-4%] left-[75%]",  "top-[75%] left-[22%]"]],
        middlePosition: ["top-[0%] left-[40%]", "top-[40%] left-[35%]"]
    },
    8: {
        result: [null, null],
        positions: [["top-[25%] left-[10%]", "top-[32%] left-[35%]"], ["top-[32%] left-[35%]",  "top-[40%] left-[10%]"]],
        middlePosition: ["top-[0%] left-[40%]", "top-[75%] left-[35%]"]
    },
    9: {
        result: [null, null],
        positions: [["top-[40%] left-[45%]", "top-[40%] left-[72%]"], ["top-[40%] left-[72%]",  "top-[65%] left-[-4%]"]],
        middlePosition: ["top-[0%] left-[40%]", "top-[75%] left-[40%]"]
    },
    10: {
        result: [null, null, null],
        positions: [["top-[30%] left-[-5%]", "top-[0%] left-[25%]"], ["top-[0%] left-[25%]",  "top-[70%] left-[25%]"], ["top-[55%] left-[55%]",  "top-[70%] left-[75%]"]],
        middlePosition: ["top-[15%] left-[15%]", "top-[40%] left-[20%]", "top-[40%] left-[90%]"]
    },
}