import { IconUserImages } from "../components/index.js";
import {NavLink} from "react-router-dom";
import * as amplitude from '@amplitude/analytics-browser';
import { useEffect } from "react";

export const SocialPage = () => {

    useEffect(()=>{
        amplitude.track(`SocialPage Start`);
    }, []);

    return(
        <div className="w-full h-screen bg-[url('/backGrounds/social/background.svg')] bg-contain" >
            <div className="w-full h-[703px] sm:h-[447px] md:h-[456px] lg:h-[656px] xl:h-[842px] bg-[url('/backGrounds/social/background.svg')] bg-contain ">
            <div className={`mx-auto relative xss:w-full xs:w-[414px] sm:w-[736px] md:w-[1024px] lg:w-[1440px] xl:w-[1920px] py-[32px] sm:py-[24px] xl:py-[58px] box-border h-full`}>
                <img
                        className="xl:w-[255px] xl:h-[136px] xl:top-[371px] xl:right-[401px] lg:w-[194px] lg:h-[103px] lg:top-[282px] lg:right-[269px] w-[92px] h-[48px] sm:w-[114px] sm:h-[61px] md:w-[132px] md:h-[70px] absolute top-[32%] sm:top-[47%] right-[15px] sm:right-[119px] md:right-[203px] z-10" 
                        src="/backGrounds/social/plane.svg"
                    />
                    <IconUserImages
                        user={"g5"}
                        className={`xl:w-[340px] xl:h-[523px] lg:w-[260px] lg:h-[398px] w-[210px] h-[323px] sm:w-[152px] sm:h-[233px] md:w-[176px] md:h-[271px] absolute bottom-0 right-0 z-10`}
                    />
                    <img
                        className="xl:w-[86px] xl:h-[179px] xl:left-[54px] xl:bottom-[169px] lg:w-[66px] lg:h-[136px] lg:bottom-[123px] lg:left-[55px] w-[72px] h-[178px] absolute bottom-[145px] right-[127px] sm:left-[31px] sm:bottom-[62px] md:left-[61px] md:bottom-[90px] z-10 sm:w-[38px] sm:h-[80px] md:w-[44px] md:h-[93px]" 
                        src={window.innerWidth<736 ? "/backGrounds/social/blue_ballon.svg" : "/backGrounds/social/sm_blue_ballon.svg"}
                    />

                    <img
                        className="xl:hidden lg:w-[51px] lg:h-[107px] lg:left-[415px] lg:top-[200px] absolute hidden sm:block sm:left-[189px] md:left-[306px] md:top-[147px] sm:top-[139px] z-10 sm:w-[30px] sm:h-[63px] md:w-[34px] md:h-[73px]" 
                        src={"/backGrounds/social/red_ballon.svg"}
                    />
                    <img
                        className="xl:w-[65px] xl:h-[135px] xl:right-[152px] xl:top-[285px] lg:w-[49px] lg:h-[103px] lg:right-[51px] lg:top-[216px] absolute hidden sm:block sm:right-[40px] sm:top-[196px] md:right-[73px] md:top-[158px] z-10 sm:w-[30px] sm:h-[61px] md:w-[33px] md:h-[70px]" 
                        src={"/backGrounds/social/green_ballon.svg"}
                    />

                <div className="mx-auto w-[320px] xs:w-[354px] sm:w-[671px] md:w-[907px] lg:w-[1333px] xl:w-[1750px] h-full">
                    <NavLink to="/">
                        <img
                            className="w-[203.54px] h-[72px] sm:w-[156px] sm:h-[53px] mx-auto md:w-[231px] md:h-[79px] lg:w-[295px] lg:h-[101px] xl:w-[365px] xl:h-[125px]" 
                            src="/backGrounds/social/mathmind_image.svg"
                        />
                    </NavLink>
                    <div className="mt-[32px] sm:mt-[25px] md:mt-[32px] lg:mt-[57px] w-full text-[14px] sm:text-[16px] lg:text-[24px] xl:text-[28px] border-y-[1px] sm:border-t-[3px] sm:border-b-[0px] border-y-[#E2DEE5] py-[24px] lg:py-[36px] xl:py-[48px] flex flex-col justify-center items-center">
                        <a onClick={()=>amplitude.track(`SocialPage_app_store`)} target="_blank" className="w-[181px] h-[59px] sm:w-[194px] sm:h-[63px] md:w-[189px] md:h-[61px] lg:w-[278px] lg:h-[90px] xl:w-[365px] xl:h-[118px]" href="https://apps.apple.com/us/app/kids-math-games-fun-learning/id1570425468">
                            <button className="bg-[#FFF] cursor-pointer w-full h-full  border-[2px] sm:border-[3px] lg:border-[4px] border-[#E2DEE5] rounded-[6px] bg-[url('/backGrounds/social/app_store.svg')] bg-no-repeat bg-[position:14px_50%] sm:bg-[length:44px_44px] lg:bg-[length:64px_64px] xl:bg-[length:84px_84px]">
                                <p className="text-left w-[85px] lg:w-[125px] ml-[72px] lg:ml-[100px] xl:ml-[120px]">
                                    Get it on
                                    App Store
                                </p>
                            </button>
                        </a>
                        <a onClick={()=>amplitude.track(`SocialPage_google_play`)}className="w-[181px] h-[59px] sm:w-[194px] sm:h-[63px] md:w-[189px] md:h-[61px] lg:w-[278px] lg:h-[90px] xl:w-[365px] xl:h-[118px]" href="https://play.google.com/store/apps/details?id=com.witplex.preschoolmathgame" target="_blank">
                            <button className="bg-[#FFF] cursor-pointer mt-[20px] sm:mt-[28px] md:mt-[20px] w-full h-full border-[2px] sm:border-[3px] lg:border-[4px] border-[#E2DEE5] rounded-[6px] bg-[url('/backGrounds/social/google_play.svg')] bg-no-repeat bg-[position:14px_50%] lg:bg-[length:62px_64px] xl:bg-[length:82px_85px]">
                                <p className="text-left w-[96px] lg:w-[147px] xl:w-[200px] ml-[72px] lg:ml-[100px] xl:ml-[120px]">
                                    Get it on
                                    Google Play
                                </p>
                            </button>
                        </a>
                    </div>

                    <div className="w-full py-[24px] sm:pt-[109px] md:pt-[90px] xl:pt-[165px] sm:w-[429px] md:w-[540px] lg:w-[797px] xl:w-[1097px] sm:mx-auto sm:justify-between box-border flex flex-col sm:flex-row justify-left items-left text-[14px] md:text-[16px] lg:text-[28px]">
                        <a onClick={()=>amplitude.track(`SocialPage_instagram`)} className="w-[170px] h-[56px] sm:w-[127px] sm:h-[43px] md:w-[156px] md:h-[51px] lg:w-[229px] lg:h-[74px] xl:w-[315px] xl:h-[102px]" href="https://www.instagram.com/mathfor.kids/" target="_blank">
                            <button className="bg-[#FFF] cursor-pointer w-full h-full border-[2px] border-[#E2DEE5] rounded-[6px] bg-[url('/backGrounds/social/instagram.svg')] bg-no-repeat bg-[position:12px_50%] bg-[length:41.33px_41.33px] sm:bg-[position:8px_50%] sm:bg-[length:30px_30px] md:bg-[length:35px_34px] md:bg-[position:6px_50%] lg:bg-[length:52px_52px] xl:bg-[length:70px_70px] lg:bg-[position:10px_50%]">
                                <p className="text-left ml-[66px] sm:ml-[45px] md:ml-[50px] lg:ml-[75px] xl:ml-[100px]">
                                   Instagram
                                </p>
                            </button>
                        </a>
                        <a onClick={()=>amplitude.track(`SocialPage_facebook`)} className="w-[170px] h-[56px] sm:w-[127px] sm:h-[43px] md:w-[156px] md:h-[51px] lg:w-[229px] lg:h-[74px] xl:w-[315px] xl:h-[102px]" href="https://www.facebook.com/people/MathforKids/100083137760551/" target="_blank">
                            <button className="bg-[#FFF] mt-[20px] sm:mt-[0px] cursor-pointer w-full h-full border-[2px] border-[#E2DEE5] rounded-[6px] bg-[url('/backGrounds/social/facebook.svg')] sm:bg-[url('/backGrounds/social/ms_facebook.svg')] bg-no-repeat bg-[position:17px_50%] sm:bg-[position:8px_50%] sm:bg-[length:30px_30px] md:bg-[length:35px_34px] md:bg-[position:6px_50%] lg:bg-[length:52px_52px] lg:bg-[position:10px_50%] xl:bg-[length:70px_70px]">
                                <p className="text-left ml-[66px] sm:ml-[45px] md:ml-[50px] lg:ml-[75px] xl:ml-[100px]">
                                   Facebook
                                </p>
                            </button>
                        </a>
                        <a onClick={()=>amplitude.track(`SocialPage_youtube`)} className="mt-[20px] sm:mt-[0px] w-[170px] h-[56px] sm:w-[127px] sm:h-[43px] md:w-[156px] md:h-[51px] lg:w-[229px] lg:h-[74px] xl:w-[315px] xl:h-[102px]" target="_blank" href="https://www.youtube.com/channel/UCv_92shNJBi7S81Y2zO15CQ">
                            <button className="bg-[#FFF] mt-[20px] sm:mt-[0px] cursor-pointer w-full h-full border-[2px] border-[#E2DEE5] rounded-[6px] bg-[url('/backGrounds/social/youtube.svg')] bg-no-repeat bg-[position:12px_50%] bg-[length:41.33px_41.33px] sm:bg-[position:8px_50%] sm:bg-[length:30px_30px] md:bg-[length:35px_34px] md:bg-[position:6px_50%] lg:bg-[length:52px_52px] xl:bg-[length:70px_70px] lg:bg-[position:10px_50%]">
                                <p className="text-left ml-[66px] sm:ml-[45px] md:ml-[50px] lg:ml-[75px] xl:ml-[100px]">
                                Youtube
                                </p>
                            </button>
                        </a>
                    </div>
                </div>
            </div>
            </div>

        </div>

    )
}