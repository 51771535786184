
import React, { useState, useEffect, useRef } from 'react';


export const DragAndDrop = (props) => {
  const check = useRef(false);

  const onMouseMove = (event) => {
    setXTranslate(xTranslate+event.clientX-initialMousePosition.current.x);
    setYTranslate(yTranslate+event.clientY-initialMousePosition.current.y);

    if (props.cmp === 'learning') {
      if (check.current) return;
      const middleElem = document.querySelector('.middlePoint');
      if (!middleElem) return;
      const middleElemCoord = middleElem.getBoundingClientRect();
      if (
          event.clientX<middleElemCoord.x+middleElemCoord.width && event.clientX>=middleElemCoord.x && 
          event.clientY<middleElemCoord.y+middleElemCoord.height && event.clientY>=middleElemCoord.y
      ) {
          check.current = true;
      }
    }
  }

  const onMouseUp = (event) => {
    const {x, y, clientX, clientY} = event.changedTouches ? event.changedTouches[0] : event;
    setIsDragging(false);
    props.dragging && props.dragging(false);
    let drop;
    let select;
    if (props.cmp === "scale") {
      const drop1= document.querySelector('.drop1');
      const drop2= document.querySelector('.drop2');
      if (clientX>=drop2.getBoundingClientRect().x){
        drop = document.querySelector('.drop2');
        select = 'right';
      } else if (clientX>=drop1.getBoundingClientRect().x) {
         drop = document.querySelector('.drop1');
         select = 'left';
      }
    } else {
      //console.log('props.drop', props.drop)
      drop = document.querySelector('.'+props.drop);
    }
    
    const position = {
      x: [drop.getBoundingClientRect().x, drop.getBoundingClientRect().x+drop.getBoundingClientRect().width+10],
      y: [drop.getBoundingClientRect().y-(props.cmp!== "scale" ? drop.getBoundingClientRect().height-10 : 0), drop.getBoundingClientRect().y+drop.getBoundingClientRect().height+10]
    }
    //console.log('position', position, drop.getBoundingClientRect(), event.clientX, event.clientY)
    if (clientX>=position.x[0] && clientX<=position.x[1] &&
    clientY>= position.y[0] &&  clientY<=position.y[1]) {
     if (props.cmp) {
          props.refresh(select, event = event.type === 'touchend' ? event.changedTouches[0] : event, check.current);
          setXTranslate(0);
          setYTranslate(0);
         

     } else {
        if (props.numbers.includes(props.item)) {
            //console.log('refresh', props.item)
            props.refresh(event.type, xTranslate===0 && yTranslate===0);
            setXTranslate(0);
            setYTranslate(0);
            return;
        } else {
            setXTranslate(0);
            setYTranslate(0);
        }
     }
    } else {
      if (props.cmp) props.refresh('wrong', event);
      setXTranslate(0);
      setYTranslate(0);
    }
  }
    window.removeEventListener('mousemove', onMouseMove);
    window.removeEventListener('mouseup', onMouseUp);
    window.removeEventListener('touchmove', touchmove);

    const ref = useRef(null);
    const [isDragging, setIsDragging] = useState(false);
    const [xTranslate, setXTranslate] = useState(0);
    const [yTranslate, setYTranslate] = useState(0);
    const initialMousePosition = useRef({});
    
    const onMouseDown = ({type, clientX, clientY}) => {
      initialMousePosition.current = {x:clientX, y:clientY}
      setIsDragging(true);
      check.current = false;
      props.dragging && props.dragging(true);
    }
 
    useEffect(()=>{
      if(isDragging) {
        window.addEventListener('mousemove', onMouseMove);
      }

      return ()=>window.removeEventListener('mousemove', onMouseMove);

    }, [isDragging])
 
    useEffect(()=>{
     window.addEventListener('mouseup', onMouseUp)
  
     return ()=> window.removeEventListener('mouseup', onMouseUp);

    }, [])

    useEffect(()=>{
      window.addEventListener('touchmove', touchmove);
  
      return ()=> window.removeEventListener('touchmove', touchmove);

     }, [])
    

    var touchmove = (e) => {
      const loc = e.touches[0];
      if (ref.current) {
        onMouseMove(loc)
      }
    }


    return(
      <div
        ref={ref}
        className={`${props.className}`} style={{zIndex: 100, transform: `translate(${xTranslate}px,${yTranslate}px)`}}
        onMouseDown={(e) => {
          e.stopPropagation();
          e.preventDefault()
          onMouseDown(e);
        }}
        onPointerUp = {(e)=>{
          e.stopPropagation();
          e.preventDefault()
          if (props.enableClick) {
            if (props.numbers.includes(props.item)) {
              props.refresh(e.type, xTranslate===0 && yTranslate===0);
            } else {
              props.animateWrong(e);
              return;
            }
          }
        }}
        onTouchStart = {(e) => {
          onMouseDown(e.touches[0]);
        }}
        onTouchEnd= {(e) => {
          onMouseUp(e);
        }}
      >
        {props.children}
      </div>
    )
 }