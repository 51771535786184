
import { CircleButton } from '../../../../components/elements/CircleButton';
import { 
    animationDurationToShowQuestion,
} from "../../../../config";
import { motion } from 'framer-motion';

export const TopPart = ({state, result, clipPath, animate}) => {

    return (
        <div className={`flex items-center justify-center w-full border-[3px] border-[#2F9EBA] bg-[#FFF] rounded-[16px] ${state ? "h-[185px] md:h-[203px] lg:h-[257px] xl:h-[316px]" : "h-[185px] md:h-[252px] lg:h-[315px] xl:h-[380px]"}`}>
           <div className={`w-[275px] h-[110px] ${state ? 'md:w-[295px] md:h-[125px] lg:h-[160px] lg:w-[424px] xl:h-[200px] xl:w-[564px]' : 'md:w-[360px] md:h-[140px] lg:h-[200px] lg:w-[463px] xl:h-[235px] xl:w-[563px]'} flex justify-between items-center font-bigfatScript text-[50px] lg:text-[70px]`}>
              <motion.div
                style={{clipPath: clipPath}}
                animate={{opacity: [animate, 1], scale: [animate, 1]}}
                transition={{duration: animationDurationToShowQuestion, type: "tween", ease: "linear"}} 
                className={`w-[109px] h-full ${state ? 'md:w-[125px] lg:w-[161px] xl:w-[200px]' : 'md:w-[142px] lg:w-[196px] xl:w-[237px]' } bg-[#FBB23B]`}
              />
              <div className={`text-[#FBB23B]`}>=</div>
              <CircleButton className="shaperesult" isActive={isNaN(result) ? false : result}>
                    <div className="shape">
                        {result}
                    </div>
              </CircleButton>
           </div>
        </div>
    )
}