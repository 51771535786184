import { DragAndDrop } from '../../../../components/global/DragAndDrop';
import { motion } from 'framer-motion';
import { getCoordinates } from '../Subtracting.data';
import { calcGapY } from '../Subtracting.data';
import { 
    animationDurationToShowQuestion,
} from "../../../../config";
import React, { useState, useEffect, useRef } from 'react';
import Sound from '../../../../components/global/Sound';
import { CircleButton } from '../../../../components/elements/CircleButton';
import { getCircleComponent } from "../../../../hooks/useCircleButtonColor";


export default function RightPart ({state, answers, checkAnswer, answer, answerSound, hint}) {
    const colors = useRef(getCircleComponent(answers));
    const [i, setI] = useState('');
    const [animate, setAnimate] = useState(false);
    const [val, setVal] = useState();
    const [update, setUpdate] = useState(false);
    const soundRef = useRef();
    const soundWrongRef = useRef();
    const [type, setType] = useState(false);

    const [time, setTime] = useState(0);
    useEffect(()=>{
        if (!animate) return;
        setVal('');
        setAnimate(false);
        setI('');
        setTime(0);
        setUpdate(false);
        colors.current = getCircleComponent(answers);
    }, [answers]);

    useEffect(()=>{
        if (type) setTimeout(()=>{
         setType(false);
         document.querySelector('.subtractingresult').style.opacity = 1;
        }, 1000);
     }, [type]);

     useEffect(()=>{
        if(!hint) return;
        const id = setInterval(()=>setTime(prev=>prev+1), 1000);
        return ()=>clearInterval(id);
    }, [time, hint]);

    return (
        <div className={`z-[50] flex flex-col items-center ${state ? "w-[67px] md:w-[73px] lg:w-[93px] xl:w-[114px]" : "w-[67px] md:w-[90px] lg:w-[133px] xl:w-[136px]"} h-full border-[3px] rounded-[16px] lg:rounded-[20px] border-[#D14116] bg-[#FFF]`}>
            {answers.map((number, index) => {
                if (i===index) {
                   return <DragAndDrop
                     enableClick = {true}
                     drop={'subtracting'}
                     animateWrong = {(e)=>{
                        setUpdate(index);
                        setI('');
                        answerSound && soundWrongRef.current.play();
                    }}
                    key={index} numbers={[answer]} item={number} 
                        refresh={(event, type) => {
                            if (event==='mouseup') return;
                            if (val===number) return; //prevnting multiple clicks on one element
                            setType(type);
                            setTime(0);
                            checkAnswer(event, type, number);
                            setVal(number);
                            setUpdate(false);
                            if(type) setTimeout(()=>document.querySelector('.subtractingresult').style.opacity=0, 200);
                            answerSound && soundRef.current.play();
                        }}
                     >
                    <motion.div 
                        style={{
                            marginTop: calcGapY(state)+'px', 
                            transform: type && val === number && `translate(${getCoordinates()[0]}px,${getCoordinates()[1]}px)`,
                            transition: `transform ${0.2}s linear`
                        }} 
                        className={`relative answer ${val === number && !type? 'opacity-0' : 'opacity-100'} z-[4000] cursor-pointer`}
                    >
                        <CircleButton key={index} isActive={number} color={colors.current[index]}>
                            {number}
                        </CircleButton>
                        {hint && answer === number && time>=10 && time%2===0 && <div className="bg-[url('/backGrounds/hint.svg')] bg-[position:50%_50%] bg-contain bg-no-repeat absolute w-[50px] h-[50px] top-[50%] z-[100]"></div>}
                    </motion.div>
                   </DragAndDrop>
                } else {
                   return <motion.div 
                        key={index} style={{marginTop: calcGapY(state)+'px'}} className={`relative z-[4000] cursor-pointer ${update === index ? 'animate-wrong' : ''}`} 
                        animate={{y: animate ? [0, 0] : [(index+1)*100, 0]}}
                        transition={{duration: animationDurationToShowQuestion , type: "tween", ease: "linear"}}
                        onPointerEnter={(e)=>{
                            setI(index);
                            setAnimate(true);
                            setUpdate(false);
                        }}
                      >
                        <CircleButton key={index} isActive={number} color={colors.current[index]}>
                           {number}
                        </CircleButton>
                        {hint && answer === number && time>=10 && time%2===0 && <div className="bg-[url('/backGrounds/hint.svg')] bg-[position:50%_50%] bg-contain bg-no-repeat absolute w-[50px] h-[50px] top-[50%] z-[100]"></div>}
                    </motion.div>
                }
            })}
            {answerSound+""=="true" && <Sound ref={soundRef} url={"/backGrounds/right_answer.wav"} loop={false}/>}
            {answerSound+""=="true" && <Sound ref={soundWrongRef} url={"/backGrounds/wrong_answer.wav"} loop={false}/>}
        </div>
    )
}