
import { getGapX, getGapY } from '../FindNumber.styles';
import { motion } from "framer-motion";
import { animationDurationToShowQuestion } from "../../../../config";
import { CircleButton } from "../../../../components/elements/CircleButton";


export default function TopSection({state, level, showNumbers, gameCount}) {   
    const amimNumbers = [-100, -101, -103, -104, -105, -106, -107, -108, -109, -110, -111];
    return (
      <div
           style={{columnGap: getGapX(state, level)+'px', rowGap: getGapY(state, level)+'px', padding: `${getGapY(state, level)-2}px ${getGapX(state, level)-2}px`}}
           className={`z-0 grid grid-cols-4 ${state ? "h-[185px] md:h-[203px] lg:h-[257px] xl:h-[316px]" : "h-[185px] md:h-[252px] lg:h-[315px] xl:h-[380px]"} bg-[#FEF1B0] border-[2px] md:border-[3px] xl:border-[4px] border-[#2F9EBA] rounded-[14px] md:rounded-[16px] lg:rounded-[20px]`}>
           {showNumbers.map((number, index) => (
                <motion.div className={'drop'+index} key={index}
                   animate={{y: [-amimNumbers[gameCount], 0]}}
                   transition={{duration: animationDurationToShowQuestion*(index+1)/showNumbers.length, type: "tween"}}   
                >
                    <CircleButton key={index} isActive={number}>
                        {number}
                    </CircleButton>
                </motion.div>
           ))}
      </div>
  );
}