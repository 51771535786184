import { CircleButton } from '../../../../components/elements/CircleButton';
import { 
    animationDurationToShowQuestion,
} from "../../../../config";
import { motion } from 'framer-motion';

export const TopPart = ({state, numbers, operator, timeduration, result, action, answerIndex}) => {
    return(
        <div
            className={`flex overflow-hidden items-center w-full border-[3px] rounded-[16px] border-[#2F9EBA] bg-[#FFF] lg:rounded-[20px] 
                       ${state ? "h-[185px] md:h-[203px] lg:h-[257px] xl:h-[316px]" : "h-[185px] md:h-[252px] lg:h-[315px] xl:h-[380px]"}
                       text-[#FFF] font-bigfatScript justify-around
            `}
        >
            <div className={`w-[169px] h-[56px] text-[32px] rounded-[16px] 
               ${state ? "md:w-[172px] md:h-[68px] md:text-[32px] md:rounded-[16px] lg:w-[238px] lg:h-[91px] lg:rounded-[20px] lg:text-[40px] xl:w-[313px] xl:h-[93px] xl:text-[48px] xl:rounded-[20px]" : 
               "xl:w-[370px] xl:h-[129px] xl:text-[64px] xl:rounded-[20px] md:w-[236px] md:h-[91px] md:rounded-[20px] md:text-[40px] lg:w-[295px] lg:h-[91px] lg:rounded-[20px] lg:text-[48px]"} bg-[#85B054] flex justify-around items-center`}>
                    {answerIndex === 0 && <CircleButton className={"addingresult"} isActive={result === "?" ? false : true}>
                            <div className='adding'>
                                {result}
                            </div>    
                     </CircleButton>
                    }
                    {answerIndex !== 0 && <div>{numbers[0]}</div>}
                    <div>{action}</div>
                    {answerIndex === 1 && <CircleButton className={"addingresult"} isActive={result === "?" ? false : true}>
                            <div className='adding'>
                                {result}
                            </div>    
                     </CircleButton>
                    }
                    {answerIndex !== 1 && <div>{numbers[1]}</div>}          
            </div>
            <CircleButton isActive={true}>
                    {operator}  
            </CircleButton>
            <div className={`w-[169px] h-[56px] text-[32px] rounded-[16px] 
               ${state ? "md:w-[172px] md:h-[68px] md:text-[32px] md:rounded-[16px] lg:w-[238px] lg:h-[91px] lg:rounded-[20px] lg:text-[40px] xl:w-[313px] xl:h-[93px] xl:text-[48px] xl:rounded-[20px]" : 
               "xl:w-[370px] xl:h-[129px] xl:text-[64px] xl:rounded-[20px] md:w-[236px] md:h-[91px] md:rounded-[20px] md:text-[40px] lg:w-[295px] lg:h-[91px] lg:rounded-[20px] lg:text-[48px]"} bg-[#85B054] flex justify-around items-center`}>
                    {answerIndex === 2 && <CircleButton className={"addingresult"} isActive={result === "?" ? false : true}>
                            <div className='adding'>
                                {result}
                            </div>    
                     </CircleButton>
                    }
                    {answerIndex !== 2 && <div>{numbers[2]}</div>}
                    <div>{action}</div>
                    {answerIndex === 3 && <CircleButton className={"addingresult"} isActive={result === "?" ? false : true}>
                            <div className='adding'>
                                {result}
                            </div>    
                     </CircleButton>
                    }
                    {answerIndex !== 3 && <div>{numbers[3]}</div>}          
            </div>
        </div>
    )
}