
import React from "react";


const Sound = React.forwardRef(
  ({url, loop, autoPlay}, ref) => {
    return (
        <audio 
            ref={ref} 
            src={url} 
            loop={loop} 
            autoPlay={autoPlay}
        />
      );
 }
);

export default Sound;