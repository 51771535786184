
import LeftPart from "./components/LeftPart";
import { RightPart } from "./components/RightPart";
import { getData, selectHideIndex, getAnswers } from './Shape5.data';
import { useState, useEffect, useRef } from 'react';
import { 
  animationDurationToShowscoresPopup,
  animationDurationToMoveCorrectAnswer,
} from "../../../config";
import { useParams } from 'react-router-dom';
import { updateStorage } from "../../../hooks/useStorage";
import { updateGameStorage } from "../../../hooks/useGameStorage";
import Sound from "../../../components/global/Sound";
import * as amplitude from '@amplitude/analytics-browser';
import { gameId } from "../../../hooks/getGameId";
import { useUpdateReduxState } from "../../../hooks/useUpdateReduxState";


export const Shape5 = ({state, handleUpdateProgress, handleOpenScore, count, answerSound, point, hint, subscription}) => {
    const {name, gameName} = useParams();
    const score = useRef(0);
    const gameCount = useRef(0);
    const data = useRef(getData());
    const index = useRef(selectHideIndex());
    const answers = useRef(getAnswers(data.current[index.current[0]][index.current[1]]));
    const selected = useRef([]);
    const [update, setUpdate] = useState(false);
    const animationArr = [0.1, 0.2, 0.3];
    const updateState = useUpdateReduxState();

    const soundRef = useRef();
    const soundWrongRef = useRef();

    useEffect(()=>{
      amplitude.track(`${name+"_"+gameName.replace("_", " ")+"_start"}`)
    }, []);

    const refresh = (event, i, answer) => {
      const elem = document.querySelector(".drop"+index.current[0]+index.current[1]);
   
      if (
         event.clientX>=elem.getBoundingClientRect().x-elem.getBoundingClientRect().width && 
         event.clientX<=elem.getBoundingClientRect().x+elem.getBoundingClientRect().width &&
         data.current[index.current[0]][index.current[1]] === answer
       ) {
          if (soundRef.current && answerSound ) {
            soundRef.current.currentTime = 0;
            soundRef.current.play();
          }
          setUpdate(true);
          selected.current.push(answer);
       } else {
          if (soundWrongRef.current && answerSound ) {
            soundWrongRef.current.currentTime = 0;
            soundWrongRef.current.play();
          }
       }
   }

   useEffect(()=>{
     if (update) {
        score.current += point;
        if (!subscription) {
          updateStorage(point);
        }
        if (gameCount.current === count-1) {
              if (subscription) {
                updateState({
                  userId: localStorage.getItem('user'),
                  profileId: JSON.parse(localStorage.getItem('profile'))?._id,
                  game: name,
                  score: score.current,
                  id: gameId(name, gameName),
                  collection: name,
                })
            } else {
              handleUpdateProgress();
              updateGameStorage(name, gameName, point, true);
            }

            setTimeout(()=>handleOpenScore(), animationDurationToShowscoresPopup*1000);
            localStorage.setItem(`${name+'_'+gameName+'_'+'_score'}`, score.current);
        } else {
            if (!subscription) {
              handleUpdateProgress();
              updateGameStorage(name, gameName, point, false);
            }
            setTimeout(()=>{ 
                gameCount.current +=1;
                data.current = getData();
                index.current  = selectHideIndex();
                answers.current = getAnswers(data.current[index.current[0]][index.current[1]]);
                selected.current = [];
                setUpdate(false);

            }, animationDurationToMoveCorrectAnswer*1000);
        }
     }
   }, [update]);

  return(
        <div className="w-full h-full flex justify-between">
            <LeftPart 
              state={state} 
              data={data.current} 
              index={index.current} 
              update={update}
              animate={animationArr[gameCount.current]}
            />
            <RightPart
              hint={hint} 
              state={state} 
              answers={answers.current}
              val={data.current[index.current[0]][index.current[1]]}
              drop={index.current} 
              refresh={refresh} 
              selected={selected.current}
              animate={animationArr[gameCount.current]}
            />
            <Sound ref={soundRef} url={"/backGrounds/right_answer.wav"} loop={false}/>
            <Sound ref={soundWrongRef} url={"/backGrounds/wrong_answer.wav"} loop={false}/>
        </div>
  )
}