import {motion} from 'framer-motion';
import React, {memo} from 'react';
import { useParams } from 'react-router-dom';
import { IconIncrease, IconDecrease } from '../../elements/Icon'


export default memo(function Ballon({color}) {
    const {name, gameName} = useParams();
    return (
        <div
        className={`${color==='red' ?  'left-[80%]' : 'left-[2.5%]'} absolute h-full w-full overflow-hidden`}
        >
            <motion.div
                animate={{
                    y:[50, 0, -50, -150, -250, -350, -450, -550, -650, -750, -850],
                    x:[20, 0, 20, 0, 20, 0, 20, 0, 20, 0],
                }} 
                transition={{duration: 50, type: 'tween', repeat: Infinity, ease: "linear"}}
                style={{bottom: -50}}
                className={`${color==='red' ? "bg-[url('/backGrounds/ballon_red.svg')]": "bg-[url('/backGrounds/ballon.svg')]"} absolute w-[5.7%] md:w-[5.4%] h-[23%] lg:w-[5.6%] xl:h-[25%] bg-[length:100%_100%]`}
            >
                {name === "schulte" && gameName?.includes("asc") && <IconIncrease className="absolute top-[25%] left-[15%] w-[19px] h-[11px] md:w-[24px] md:h-[14px] lg:w-[30px] lg:top-[20%] lg:h-[18px] xl:w-[40px]  xl:h-[25px]" color={"white"}/>}
                {name === "schulte" && gameName?.includes("desc") && <IconDecrease className="absolute top-[25%] left-[15%] w-[19px] h-[11px] md:w-[24px] md:h-[14px] lg:w-[30px] lg:top-[20%] lg:h-[18px] xl:w-[40px]  xl:h-[25px]" color={"white"}/>}
            </motion.div>
        </div>

    );
});