export const getBottomGapX = (state) => {
    if (window.innerWidth <  1024) {
      return (425-3*39)/4;
    }
    if (window.innerWidth>=1024 && window.innerWidth<1440) {
      if (state) {
        return (439-3*39)/4;
      } else {
        return (576-3*49)/4;
      } 
    }
    if (window.innerWidth>=1440 && window.innerWidth<1920) {
      if (state) {
        return (590-3*50)/4;
      } else {
        return (723-3*69)/4;
      } 
    }
    if (window.innerWidth>=1920) {
      if (state) {
        return (768-3*70)/4;
      } else {
        return (909-3*89)/4;
      } 
    }
}

export const getCoordinates = () => {
    const elem = document.querySelector('.adding');
    const answer = document.querySelector('.answer');
    const x1 = elem.getBoundingClientRect().x;
    const y1 = elem.getBoundingClientRect().y;
    const x2 = answer.getBoundingClientRect().x;
    const y2 = answer.getBoundingClientRect().y;
    const xDistance = x1 - x2;
    const yDistance = y1 - y2;
    return [
              xDistance-(answer.getBoundingClientRect().width-elem.getBoundingClientRect().width)/2, 
              yDistance-(answer.getBoundingClientRect().height-elem.getBoundingClientRect().height)/2
           ];
}

export const getNumber = () => {
    const max = 99;
    const min = 1;

    return Math.floor(Math.random() * (max - min + 1) + min);
}

export const getAnswer = (firstNumber, secondNumber) => {
    if (firstNumber > secondNumber) {
        return ">";
    }

    if (firstNumber < secondNumber) {
        return "<";
    }

    if (firstNumber === secondNumber) {
        return "=";
    }
}

export const getAnswers = () => {
    return [">", "<", "="].sort(()=>Math.random()-0.5)
}

