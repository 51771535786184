import React, {
    useMemo,
    useState,
    useRef,
    useEffect,
    useCallback
  } from "react";
  import * as d3 from "d3";
  import { animationDurationToMoveCorrectAnswer, animationDurationToShowQuestion } from "../../../../config";
  import {CircleButton} from '../../../../components/elements/CircleButton';
  import { motion } from "framer-motion";
  import Sound from '../../../../components/global/Sound';
  import { getCircleComponent } from "../../../../hooks/useCircleButtonColor";
  
  const Line = ({ thickness, points, color }) => {
    const line = useMemo(() => {
      return d3
        .line()
        .x((d) => d.x)
        .y((d) => d.y)
    }, []);
  
    return (
      <path
        d={line(points)}
        style={{
          zIndex: '0 !important',
          stroke: color,
          strokeWidth: thickness,
          strokeLinejoin: "round",
          strokeLinecap: "round",
          fill: "none"
        }}
      />
    );
  };
  
  let showHint;
  
  export const MouseDraw = ({state, thickness, calcGapX, handleScore, calcGapY, answers, images, numbers, anim, screenSize, answerSound, hintState})=> {
    const coord = {}
    const colors = useRef(getCircleComponent(answers));
    const [drawing, setDrawing] = useState(false);
    const [currentLine, setCurrentLine] = useState({ thickness, points: [] });
    const [lines, setLines] = useState([]);
    const rightCount = useRef(0);
    const [animate, setAnimate] = useState(anim);
    const [answerCoord, setAnswerCoord] = useState();
    const soundWrongRef = useRef();
    const soundRef = useRef();
    const answer = useRef();
    const selectedItems = useRef([]);
    const drawingAreaRef = useRef();
    const [selectNumber, setSelectNumber] = useState(null);

    const [refresh, setRefresh] = useState(false);

    const [start, setStart] = useState({x:0, y:0});   
    const [end, setEnd] = useState({x:0, y:0});
    const [time, setTime] = useState(0);

    const [hint, setHint] = useState(answers[0]);
    const [tab, setTab] = useState(false);

    useEffect(()=>{
      setHint(answers[0])
    }, [answers])
  
    const mouseMove = useCallback((event) => {
        const [x, y] = d3.pointer(event);
        if (drawing) {
            if (x<2 || x>document.querySelector('.game').offsetWidth-10 || y<2 || y>document.querySelector('.game').offsetHeight-10) {
                setDrawing(false);
                setCurrentLine({ thickness, points: [] });
                return
            }
            setCurrentLine((line) => ({
                ...line,
                points: [...line.points, { x, y }]
            }));
        }
    }, [drawing]);
  
    const enableDrawing = (number) => {
      setSelectNumber(number);
      setCurrentLine({ thickness, points: [] });
      setDrawing(!drawing);
      answer.current = null;
    }

    useEffect(()=>{
      showHint = true;
      const start = document.querySelector('.hint');
      const end = document.querySelector('.drop'+hint);
      if (!start || !end) {
        setRefresh(!refresh);
      }
      const startCoord = start.getBoundingClientRect();
      const endCoord = end.getBoundingClientRect();
      setStart({x:startCoord.x, y:startCoord.y});
      setEnd({x:endCoord.x+endCoord.width, y:endCoord.y});
      setTime(0);
  }, [hint, screenSize, tab]);


  useEffect(()=>{
    if (!hintState) {
      setTime(0);
      return;
    }
    if (time===10) return;
    const id = setInterval(()=>setTime(prev=>prev+1), 1000);
    return ()=>clearInterval(id);
  }, [time, hintState]);
  
    useEffect(()=>{
      colors.current = getCircleComponent(answers);
      setAnimate(true);
      const answersCoord = {}
      const answerElements = document.querySelectorAll('.answer');
      answers.forEach((number, index)=>{
        const el = answerElements[index]
        if (!el) return;
        answersCoord[number] = {
         x: document.querySelector('.wrapper').offsetLeft,
         y: [el.offsetTop, el.offsetHeight+el.offsetTop]
       }
      })
      setAnswerCoord(answersCoord);
      if (lines.length) {
        const updateLines = [];
        selectedItems.current.forEach((num)=>{
          const selected = answersCoord[num];
          const wrapper = document.querySelector('.wrapper');
          updateLines.push({thickness, points: [{x: wrapper.offsetLeft-5, y: (selected.y[0]+selected.y[1])/2}, {x: coord[num].x[1]+5, y: (coord[num].y[0]+coord[num].y[1])/2} ]})
        });
        setLines(updateLines);
      }
  
    },[numbers, state, screenSize]);

    const TabChange = () => {
      setTab(!tab);
    }

    useEffect(()=>{
      document.addEventListener('visibilitychange', TabChange, false);
      return ()=>document.removeEventListener('visibilitychange', TabChange)
    });

    useEffect(()=>{
       if (!drawing) {
          showHint = true;
       }
    }, [drawing]);
  
    const disableDrawing = () => {
        
        const end = currentLine.points[currentLine.points.length-1];
        //console.log(selectNumber, coord[selectNumber], answerCoord[selectNumber]);

        if (end.x <= coord[selectNumber].x[1]+10 && end.x >= coord[selectNumber].x[0]-10) {
            if (end.y <= coord[selectNumber].y[1]+5 && end.y >=coord[selectNumber].y[0]-5) {
                setDrawing(false);
                setCurrentLine({ thickness, points: [] });
                setLines((lines) => [...lines, {thickness, points: [{x: answerCoord[selectNumber].x-5, y: (answerCoord[selectNumber].y[0]+answerCoord[selectNumber].y[1])/2}, {x: coord[selectNumber].x[1]+5, y: (coord[selectNumber].y[0]+coord[selectNumber].y[1])/2} ]}]);
                selectedItems.current.push(selectNumber);
                showHint = false;
                const newHint =  [...answers].filter((answer)=>!selectedItems.current.includes(answer))[0];
                setHint(newHint || answers[0]);
                setTime(0);
                rightCount.current += 1;
                answerSound+""=="true" && soundRef.current.play();
                //alert('right')
                if (rightCount.current === 4) {
                  handleScore();
                  setTimeout(()=> {
                    setLines([]);
                  }, animationDurationToMoveCorrectAnswer*2000);
                  rightCount.current = 0;
                  selectedItems.current = [];
                  
                }
            } else {
              soundWrongRef.current.currentTime = 0;
              answerSound+""=="true" && soundWrongRef.current.play();
              answer.current = selectNumber;
            }
        } else {
          if (answerSound+""=="true" ) {
            soundWrongRef.current.currentTime = 0;
            soundWrongRef.current.play();
          }
        }

        setDrawing(false);
        setCurrentLine({ thickness, points: [] });
    }
  
    useEffect(() => {
      const area = d3.select(drawingAreaRef.current);
      area.on("mousemove", mouseMove);
      return () => area.on("mousemove", null);
    }, [mouseMove]);
  
   return(
    <>
      <svg width="100%" height="100%" className={`z-[40] absolute  ${state ? 'md:ml-[-16px] lg:ml-[-24px] xl:ml-[-20px]' : 'md:ml-[-24px] lg:ml-[-28px] xl:ml-[-32px]'} rounded-[16px] lg:rounded-[20px]`}>
        <g
            ref={drawingAreaRef}
            onMouseDown={()=>enableDrawing(null)}
            onMouseUp={disableDrawing}
        >
            <rect
                x={0}
                y={0}
                width="100%"
                height="100%"
                style={{ fill: "transparent"}}
            />
  
  
            {lines.map((line, i) => (
              <>
                {line.points.length && <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
                    <circle cx={line.points[0].x} cy={line.points[0].y} r="4" strokeWidth="2" fill="#87B43F" />
                </svg>}
                {line.points.length && <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
                    <circle cx={line.points[1].x} cy={line.points[1].y} r="4" strokeWidth="2" fill="#87B43F" />
                </svg>}
                <Line thickness={line.thickness} color={"#87B43F"}  points={line.points} key={i} />
              </>
              
            ))}
            {drawing && <Line thickness={currentLine.thickness} color={"red"} points={currentLine.points} />}
        </g>
      </svg>
      {
          numbers.map((number, index)=> (
            <div
              onMouseUp = {disableDrawing}
              ref={el => {
                if (!el) return;
                  coord[number] = {
                    x: [el.offsetLeft, el.offsetLeft+ el.offsetWidth],
                    y: [el.offsetTop, el.offsetHeight+el.offsetTop]
                   }
  
              }}
              key={number}
              style={{marginTop: calcGapY(state, 'rect', 4)+"px"}} 
              className={`${'drop'+number} w-[276px] z-[40] bg-[#FFF] overflow-hidden ${state ? 'md:w-[292px] lg:w-[362px] xl:w-[508px] md:h-[53px] lg:h-[64px] xl:h-[88px]': 'md:w-[348px] lg:w-[463px] xl:w-[566px] md:h-[61px] lg:h-[80px] xl:h-[97px]'} h-[51px] rounded-[10px] md:rounded-[12px] border-[2px] border-[#BF3037] flex items-center`}>
                {
                    [...new Array(number)].map((item, i)=>(
                      <motion.div
                        animate={{y: animate ? [Math.random()*500*0, 0] : [0, 0]}}
                        transition={{duration: animationDurationToShowQuestion*(i+1)/number, type: "tween"}}   
                        key={i}
                        style={{
                          backgroundImage: `url(/backGrounds/counting/${images[index]}.svg)`, 
                          backgroundSize: '100% 100%', backgroundRepeat: "no-repeat",
                          marginLeft: calcGapX(state, number)-1+"px",
                        }}
                        className={`z-[60] ${state ? 'w-[27px] h-[27px] md:h-[30px] lg:w-[32px] lg:h-[38px] xl:w-[44px] xl:h-[52px]' : 'h-[27px] w-[27px] md:w-[30px] md:h-[35px] lg:w-[37px] lg:h-[44px] xl:w-[49px] xl:h-[58px]'}`}
                        />
                      ))
                  }
              </div>
          ))
        }      
        <div 
          className={`wrapper absolute right-[20px] ${state ? 'md:ml-[76px] md:right-[16px] lg:right-[24px] xl:right-[20px] lg:ml-[130px] xl:ml-[150px]' : 'md:ml-[130px] md:right-[25px] lg:right-[30px] xl:right-[32px] lg:ml-[133px] xl:ml-[190px]'} h-full ml-[77px]`}>
          {answers.map((number, index) => (
            <motion.div
              onMouseDown={()=>enableDrawing(number)}
              animate={{y: animate ? [0, 0] : [Math.random()*500, 0], x: number == answer.current? [-10, 0, 10, 0]: 0 }}
              transition={{duration: animationDurationToShowQuestion*(index+1)/4, type: "tween"}}   
  
                className={`relative answer ${hint===number ? "z-[41]" : "z-[40]"}  h-[51px] ${hint===number && "hint"} ${state ? 'md:h-[53px] lg:h-[64px] xl:h-[88px]': 'md:h-[61px] lg:h-[80px] xl:h-[97px]'} select-none flex items-center justify-center`}
                style={{marginTop: calcGapY(state, 'rect', answers.length)+"px"}} 
                key={index}
              >
                  <CircleButton isActive={number} color={colors.current[index]}>
                    {number}
                  </CircleButton>
                  { !drawing && hintState && showHint && time==10 && hint===number && <motion.div
                    animate={{y: [0, end.y-start.y], x: [0, end.x-start.x]}}
                    transition={{duration: 2 , type: "tween", ease: "linear", repeat: Infinity}}
                    className="bg-[url('/backGrounds/hint.svg')] bg-[position:50%_50%] bg-contain bg-no-repeat absolute w-[50px] h-[50px] top-[50%] z-[1000]"></motion.div>}
              </motion.div>
            ))}
        </div>
        <Sound ref={soundRef} url={"/backGrounds/right_answer.wav"} loop={false}/>
        <Sound ref={soundWrongRef} url={"/backGrounds/wrong_answer.wav"} loop={false}/>
    </>
  
   )
  }