import { getDate } from "./useCurrentDate";

export const createStorage = () => {
    const path = 'scoreStorage';
    const store = localStorage.getItem(path);
    if (store) {
      let check = false;
       JSON.parse(store).forEach((item)=>{
        if (item.date === getDate()) {
          check = true;
        }
       });
       if (!check) {
        localStorage.setItem(path, JSON.stringify([{date: getDate(), score: 0}, ...JSON.parse(store)]));
       }
    } else {
      localStorage.setItem(path, JSON.stringify([{date: getDate(), score: 0}]));
    }
}

export const updateStorage = (count) => {
    const path = 'scoreStorage';
    const newStorage = JSON.parse(localStorage.getItem(path)).map((item)=>{
        if (item.date === getDate()) {
           return {...item, score: item.score+count};
        } else {
          return {...item};
        }
      })
      localStorage.setItem(path, JSON.stringify(newStorage));
}