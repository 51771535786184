import { CircleButton } from "../../../../components/elements/CircleButton";
import { animationDurationToShowQuestion } from "../../../../config";
import { motion } from "framer-motion";


export const TopPart = ({state, firstNumber, secondNumber, result, animate}) => {
    return(
        <div
            className={`bg-[url('/backGrounds/rapid_bg.svg')] bg-cover flex overflow-hidden justify-center items-center w-full border-[3px] rounded-[16px] border-[#D86B20] bg-[#FFF] lg:rounded-[20px] 
            ${state ? "h-[185px] md:h-[203px] lg:h-[257px] xl:h-[316px]" : "h-[185px] md:h-[252px] lg:h-[315px] xl:h-[380px]"}`}
        >
           <div className={`text-[#7648B5] flex justify-between items-center text-[28px] w-[267px] h-[39px] font-bigfatScript
                            ${state ? "md:w-[265px] md:h-[39px] md:text-[32px] lg:w-[338px] lg:h-[50px] lg:text-[40px] xl:w-[486px] xl:h-[70px] xl:text-[60px]" : 
                                      "md:w-[376px] md:h-[49px] md:text-[34px] lg:w-[479px] lg:h-[69px] lg:text-[50px]  xl:w-[557px] xl:h-[89px] xl:text-[60px]"
                            }`
                          }
            >
                <motion.div
                    animate={{y: [animate.shift() || 0, 0]}}
                    transition={{duration: animationDurationToShowQuestion , type: "tween", ease: "linear"}}
                >
                   {firstNumber}
                </motion.div>
                <motion.div
                    animate={{y: [animate.shift() || 0, 0]}}
                    transition={{duration: animationDurationToShowQuestion , type: "tween", ease: "linear"}}
                    className="text-[#FFB43B]"
                >
                  /
                </motion.div>
                <motion.div
                    animate={{y: [animate.shift() || 0, 0]}}
                    transition={{duration: animationDurationToShowQuestion , type: "tween", ease: "linear"}}
                >
                   {secondNumber}
                </motion.div>
                <motion.div
                    animate={{y: [animate.shift() || 0, 0]}}
                    transition={{duration: animationDurationToShowQuestion , type: "tween", ease: "linear"}}
                    className="text-[#FFB43B]"
                >
                  =
                </motion.div>
                <motion.div
                    animate={{y: [animate.shift() || 0, 0]}}
                    transition={{duration: animationDurationToShowQuestion , type: "tween", ease: "linear"}}
                >
                    <CircleButton className={"addingresult"} isActive={isNaN(result) ? false : result}>
                        <div className="adding">
                            {result}
                        </div>
                    </CircleButton>
                </motion.div>

           </div>
        </div>
    )
}