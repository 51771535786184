export const getBottomGapX = (state) => {
    if (window.innerWidth <  1024) {
      return (425-6*39)/7;
    }
    if (window.innerWidth>=1024 && window.innerWidth<1440) {
      if (state) {
        return (439-6*39)/7;
      } else {
        return (576-6*49)/7;
      } 
    }
    if (window.innerWidth>=1440 && window.innerWidth<1920) {
      if (state) {
        return (590-6*50)/7;
      } else {
        return (723-6*69)/7;
      } 
    }
    if (window.innerWidth>=1920) {
      if (state) {
        return (768-6*70)/7;
      } else {
        return (909-6*89)/7;
      } 
    }
}

export const mixArrayNumbers = (answers) => {
    const newAnswers = answers.reduce((aggr, item)=>{
      while(true) {
        const index = Math.floor(Math.random() * (5 - 0 + 1) + 0);
        if (!aggr[index]) {
          aggr[index] = item;
          break;
        }
      }
      return aggr;
  }, [...new Array(6)]);
  return newAnswers;
}

export const getIndex = (number, answers) => {
   return answers.findIndex((item)=>item[0]===number[0]);
}


export const getNumbers = (number, index) => {
    const max= index ? 20 : number;
    const min = index ? number : 1;
    const result = {
       firstNumber: 0, 
       secondNumber: 0, 
       thirdNumber: 0
    }
  
    if (index) {
     if (index === 1) {
         result.secondNumber = number;
         while(true) {
           const number = Math.floor(Math.random() * (max - min + 1) + min);
           if(number%result.secondNumber === 0) {
              result.firstNumber = number;
              break;
           }
         }
         result.thirdNumber = result.firstNumber/result.secondNumber;
      } else {
         result.thirdNumber = number;
          while(true) {
           const number = Math.floor(Math.random() * (max - min + 1) + min);
           if(number%result.thirdNumber === 0) {
              result.firstNumber = number;
              break;
           }
         
        
       }
        result.secondNumber = result.firstNumber/result.thirdNumber;
       }
    
    } else {
       result.firstNumber = number;
       while(true) {
         const number = Math.floor(Math.random() * (max - min + 1) + min);
         if(result.firstNumber%number === 0) {
            result.thirdNumber = number;
            break;
         }
       }
       
       result.secondNumber = result.firstNumber/result.thirdNumber;
    }
    return result;
  }
  

export const getIndexArray = (level) => {
  if (level!=="advanced") {
    return [2, 2, 2, 2, 2, 2];
  } 
  const array = [];
  while(array.length<=5) {
     array.push(Math.floor(Math.random() * (2 - 0 + 1) + 0));
  }
  return array;
}

export const getAnswers = (level) => {
  const empty = getIndexArray(level);
  const arr = new Set();
  const min = 5;
  const max = 10;
  while(arr.size<6) {
      arr.add(Math.floor(Math.random() * (max - min + 1) + min));
  }


const answers = Array.from(arr).reduce((aggr, item, index)=>{
  const number = getNumbers(item, empty[index]);
  aggr.push(
     {
        firstNumber: empty[index] === 0 ? item : number.firstNumber, 
        secondNumber: empty[index] === 1 ? item : number.secondNumber,
        thirdNumber: empty[index] === 2 ? item : number.thirdNumber,
        answer: item,
        index: empty[index]
      });
    return aggr;
  }, []);
  
  return answers;
}