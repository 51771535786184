import React, { useState, useRef, useEffect } from "react";
import Tesseract from 'tesseract.js';
import { PSM } from 'tesseract.js';
import { canvasSize } from '../../games/Adding/Adding5/Adding5.style';
//import{ createWorker, PSM } from 'tesseract.js';
import Sound from "./Sound";

const getCanvas = (canvas, state, type) => {
    canvas.width = canvasSize(state)*2;
    canvas.height = canvasSize(state)*2;
    canvas.style.width = `${canvasSize(state)}px`;
    canvas.style.height = `${canvasSize(state)}px`;
    const context = canvas.getContext('2d');
    context.scale(2,2);
    context.lineCap = 'round';
    context.strokeStyle = type === "hidden" ? "black" : "#FFFFFF";
    context.lineWidth = 5;
    return context;
}

export const Canvas = ({state, answer, refresh, pos, screenSize}) => {
    const answerSoundOn = JSON.parse(localStorage.getItem('answerSound'));
    const canvasRef = useRef(null);
    const canvasHiddenRef = useRef(null);
    const contextRef = useRef(null);
    const contextHiddenRef = useRef(null);
    const imgRef = useRef(null);
    const [isDrawing, setIsDrawing] = useState(false);

    const soundRef = useRef();
    const soundWrongRef = useRef();

    useEffect(()=>{
      contextRef.current = getCanvas(canvasRef.current, state);
      contextHiddenRef.current = getCanvas(canvasHiddenRef.current, state, "hidden");
    }, [state, screenSize]);

    const startDrawing = ({nativeEvent}) => {
        const {offsetX, offsetY} = nativeEvent;
        contextRef.current.beginPath();
        contextHiddenRef.current.beginPath();
        contextRef.current.moveTo(offsetX, offsetY);
        contextHiddenRef.current.moveTo(offsetX, offsetY);
        setIsDrawing(true);
    }

    const finishDrawing = () => {
        contextRef.current.closePath();
        contextHiddenRef.current.closePath();
        setIsDrawing(false);
        const dataUrl = canvasHiddenRef.current.toDataURL('image/png');
        imgRef.current.src = dataUrl;
        Tesseract.recognize(
          imgRef.current, 'eng',
            {
               tessedit_char_whitelist: '0123456789',
               tessedit_pageseg_mode: PSM.SINGLE_BLOCK,
            },
            { 
              //logger: m => console.log(m) 
            }
          )
          .catch (err => {
            console.error(err);
          })
          .then(result => {
            //console.log('result', result.data);
            const number = result.data.text.indexOf(""+answer);
            if (number!==-1) {
              if (answerSoundOn && soundRef.current) {
                soundRef.current.currentTime = 0;
                soundRef.current.play();
              }
              refresh(pos, answer);
            } else {
              if (answerSoundOn && soundWrongRef.current) {
                soundWrongRef.current.currentTime = 0;
                soundWrongRef.current.play();
              }
              contextRef.current.clearRect(0, 0, 300, 300);
              contextHiddenRef.current.clearRect(0, 0, 300, 300);
            }
          })
    }

    const draw = ({nativeEvent}) => {
        if (isDrawing) {
            const canvas = document.querySelector('.canvas').getBoundingClientRect();
            const {offsetX, offsetY} = nativeEvent;
            if (offsetX>=1 && offsetX<canvas.width-1 && offsetY>=1 && offsetY<canvas.height-1) {
              contextRef.current.lineTo(offsetX, offsetY);
              contextRef.current.stroke();
              contextHiddenRef.current.lineTo(offsetX, offsetY);
              contextHiddenRef.current.stroke();
            } else {
              finishDrawing();
            }
        }
    }
    
    return (
      <>
        <canvas
            className={`canvas z-[100] w-[64px] h-full rounded-[4px] bg-[#CAA9F3] ${state ? "md:w-[70px] lg:w-[84px] xl:w-[106px]": "md:w-[84px] lg:w-[102px] xl:w-[140px]"}`}
            onMouseDown={startDrawing}
            onMouseUp={finishDrawing}
            onMouseMove={draw}
            ref={canvasRef}
        />
        <canvas
            className={`z-[100] hidden w-[64px] h-full rounded-[4px] bg-[#FFFFFF] ${state ? "md:w-[70px] lg:w-[84px] xl:w-[106px]": "md:w-[84px] lg:w-[102px] xl:w-[140px]"}`}
            onMouseDown={startDrawing}
            onMouseUp={finishDrawing}
            onMouseMove={draw}
            ref={canvasHiddenRef}
        />
        <img ref={imgRef} className="hidden"/>
        <Sound ref={soundRef} url={"/backGrounds/right_answer.wav"} loop={false}/>
        <Sound ref={soundWrongRef} url={"/backGrounds/wrong_answer.wav"} loop={false}/>
      </>

    )
};