
import { useDispatch, useSelector } from 'react-redux';
import { updateProfileGameThunk, getProfileGameThunk, getTotalScoreThunk } from '../redux/features/gamesSlice';
import { getScoresHistoryThunk } from '../redux/features/scoresSlice';

export const useUpdateReduxState = () => {
    const dispatch = useDispatch();
    const profiles = useSelector(state=>state.user.user.profiles);
    
    
    return ({userId, profileId=profiles[0]._id, game, score, id, collection}) => {
        dispatch(updateProfileGameThunk({userId, profileId, game, score, id}))
            .then(()=>dispatch(getProfileGameThunk({collection, userId, profileId})))
            .then(()=>{dispatch(getTotalScoreThunk({userId, profileId}))})
            .then(()=>{dispatch(getScoresHistoryThunk({userId, skip: 0}))});
    };
}