import { animationDurationToShowQuestion } from "../../../../config";
import { motion } from "framer-motion";
import { DragAndDrop } from "../../../../components/global/DragAndDrop";
import { useState, useEffect, useRef } from "react";


export const TopPart = ({state, colors, level, time, result, checkingState, animate, refresh, handleTime}) => {
    const items = time ? colors : result;
    const [i, setI] = useState('');

    const isDragging = useRef();

    useEffect(()=>{
        setI('');
        isDragging.current= false;
    }, [items]);

    return (
        <div className={`flex justify-center items-center relative bg-[url('/backGrounds/rapid_color_bg.svg')] bg-cover w-full border-[3px] border-[#2F9EBA] bg-[#FFF] rounded-[16px] ${state ? time ? "h-full" : "h-[185px] md:h-[203px] lg:h-[257px] xl:h-[316px]" : time ? "h-full" : "h-[185px] md:h-[252px] lg:h-[315px] xl:h-[380px]"}`}>
            {time ? <div className={`font-righteous text-[#DB3E50] text-[20px] md:text-[24px] lg:text-[32px] xl:text-[36px] text-center w-[28.54px] h-[58.5px] md:w-[38px] md:h-[78px] lg:w-[48px] lg:h-[97px] xl:w-[50px] xl:h-[103px] absolute bg-[url('/backGrounds/rapid_ballon.svg')] bg-cover top-[25px] right-[30px]`}>
                {time}
            </div> : ""}
            {time ? <div className={`z-[50] cursor-pointer absolute ${state ? 'md:w-[39px] md:h-[39px] lg:w-[50px] lg:h-[50px] xl:w-[70px] xl:h-[70px] ' : ' md:w-[49px] md:h-[49px]  lg:w-[69px] lg:h-[69px] xl:w-[89px] xl:h-[89px] '} w-[39px] h-[39px] bg-contain bottom-[2%] right-[2%] bg-[url('/backGrounds/reset.svg')]`} onClick={handleTime} /> : ""}
            {!checkingState.includes(undefined) && !checkingState.includes(false) && <div className="absolute w-[30px] h-[30px] lg:w-[50px] lg:h-[50px] bg-[url('/backGrounds/rapid_color_check.svg')] bottom-[13px] right-[14px] bg-contain"/>}
            {(!checkingState.includes(undefined) && checkingState.includes(false)) &&
                <div
                   className={`${colors.length ===4 ? "w-[45px] h-[45px] md:w-[85px] md:h-[85px] lg:w-[105px] lg:h-[105px]" : "w-[70px] h-[45px] lg:w-[130px] lg:h-[85px] xl:w-[160px] xl:h-[105px]"} absolute bottom-[13px] right-[5px] flex gap-[2.5px] flex-wrap`}
                >
                  {colors.map((color)=>{
                      return(
                        <div
                           key={color}
                           style={{backgroundColor: color}}
                           className={`${colors.length ===4 ? "lg:rounded-[8px] w-[20px] h-[20px] md:w-[40px] md:h-[40px] lg:w-[50px] lg:h-[50px]" : "lg:rounded-[8px] w-[20px] h-[20px] lg:w-[40px] lg:h-[40px] xl:w-[50px] xl:h-[50px]"} rounded-[3px]`}
                        >
                        </div>
                      )
                  })}
                </div>
            }
            <div
                className={`dropColor ${level==="advanced" ? "w-[194px] md:w-[257px] lg:w-[271px] xl:w-[330px]" : "w-[127px] md:w-[168px] lg:w-[178px] xl:w-[216px]"}  flex  flex-wrap gap-[7px] md:gap-[10px] xl:gap-[12px] justify-center`}
            >
                {items.map((item, index)=>{
                    if (item && i===index) {
                       return (
                            <DragAndDrop 
                                key={index}
                                enableClick = {false}
                                cmp={"colors"}
                                drop={"dropAnswers"}
                                item = {item}
                                dragging = {(state)=>{
                                    if (!state) return
                                    isDragging.current = state
                                } }
                                refresh={(sound, event) => {
                                    setI('');
                                    refresh(event, i, item, 'top', isDragging.current)
                                }}
                            >
                                <motion.div
                                    animate={{y: !animate ? [0, 0] : [(index+1)*500, 0]}}
                                    transition={{duration: animationDurationToShowQuestion, type: "tween"}} 
                                    style={{backgroundColor: item || "#E4E4E4"}}
                                    className={`cursor-pointer z-[50] font-fredokaOne flex justify-center items-center text-[#FFFFFF] ${"drop"+index} w-[60px] h-[60px] md:w-[79px] md:h-[79px] lg:w-[83px] lg:h-[83px] xl:w-[102px] xl:h-[102px] rounded-[7px] bg-[#E4E4E4]
                                    text-[28px] lg:text-[40px] xl:text-[60px]
                                    `}
                                >
                                    {!checkingState.includes(undefined) ? checkingState[index] ? "" : "x" : item ? "" : "?"}
                                </motion.div>  
                            </DragAndDrop>
                       )
                    } else {
                        return(
                            <motion.div
                                onMouseEnter={(e)=>{
                                    if(time) return;
                                    setI(index);  
                                }}
                                onTouchStart={(e)=>{
                                    if(time) return;
                                    setI(index);  
                                }}
                                key={index}
                                animate={{y: !animate ? [0, 0] : [(index+1)*500, 0]}}
                                transition={{duration: animationDurationToShowQuestion, type: "tween"}} 
                                style={{backgroundColor: item || "#E4E4E4"}}
                                className={`z-[50] font-fredokaOne flex justify-center items-center text-[#FFFFFF] ${"drop"+index} w-[60px] h-[60px] md:w-[79px] md:h-[79px] lg:w-[83px] lg:h-[83px] xl:w-[102px] xl:h-[102px] rounded-[7px] bg-[#E4E4E4]
                                   text-[28px] lg:text-[40px] xl:text-[60px]
                                `}
                            >
                                {!checkingState.includes(undefined) ? checkingState[index] ? "" : "x" : item ? "" : "?"}
                            </motion.div>
                        )
                    }
                })}
            </div>
        </div>
    )
}