
import { LeftPart } from "./components/LeftPart";
import { RightPart } from "./components/RightPart";
import { getShape, getAnswers } from "./Shape3.data";
import { useState, useEffect, useRef } from "react";
import { 
    animationDurationToShowscoresPopup,
    animationDurationToMoveCorrectAnswer
} from "../../../config";
import { useParams } from 'react-router-dom';
import { updateStorage } from "../../../hooks/useStorage";
import { updateGameStorage } from "../../../hooks/useGameStorage";
import * as amplitude from '@amplitude/analytics-browser';
import { gameId } from "../../../hooks/getGameId";
import { useUpdateReduxState } from "../../../hooks/useUpdateReduxState";

export const Shape3 = ({state, handleUpdateProgress, handleOpenScore, count, answerSound, point, hint, subscription}) => {
    const gameStore = JSON.parse(localStorage.getItem('playedGames'));
    const gameCount = useRef(0);
    const {name, gameName} = useParams();
    const [answer, setAnswer] = useState(getShape());
    const answers = useRef(getAnswers(answer));
    const score = useRef(0);
    const [result, setResult] = useState('?');
    const [refresh, setRefresh] = useState(false);
    const updateState = useUpdateReduxState();

    useEffect(()=>{
      amplitude.track(`${name+"_"+gameName.replace("_", " ")+"_start"}`)
    }, []);

    const checkAnswer = (event, type, val) => {
        //console.log('type', type)
        if (event==='mouseup') return;
        score.current += point;
        if(!subscription) {
          updateStorage(point);
        }
        !type && setResult(val);
        if (gameCount.current === count-1 || (gameStore[name] && gameStore[name].find(item=>item.name===gameName)?.all === count-1)) {
            if (subscription) {
              updateState({
                userId: localStorage.getItem('user'),
                profileId: JSON.parse(localStorage.getItem('profile'))?._id,
                game: name,
                score: score.current,
                id: gameId(name, gameName),
                collection: name,
              })
            } else {
              handleUpdateProgress();
              updateGameStorage(name, gameName, point, true);
            }
            setTimeout(()=>handleOpenScore(), animationDurationToShowscoresPopup*1000);
            localStorage.setItem(`${name+'_'+gameName+'_'+'_score'}`, score.current);
          } else {
            if (!subscription) {
              handleUpdateProgress();
              updateGameStorage(name, gameName, point, false);
            }
            setTimeout(()=>{ 
              gameCount.current +=1;
              const answer = getShape();
              setAnswer(answer);
              answers.current = getAnswers(answer);
              setResult('?');
              setRefresh(!refresh);
            }, animationDurationToMoveCorrectAnswer*1000);
          }
    }

    return(
        <div className="w-full h-full flex justify-between">
            <LeftPart
              state = {state}
              answer = {answer}
              result = {result}
              animate = {[0.1, 0.2, 0.3][gameCount.current]}
            />
            <RightPart
              hint={hint}
              state = {state}
              answerSound = {answerSound}
              answers = {answers.current}
              shape = {answer}
              checkAnswer = {checkAnswer}
            />
        </div>
    )
}