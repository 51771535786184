export const getSizes = (state) => {
    if (state) {
        return 'md:w-[292px] md:h-[292px] lg:w-[370px] lg:h-[370px] xl:w-[454px] xl:h-[454px] w-[266px] h-[266px]';
    } else {
        return 'md:w-[360px] md:h-[360px] lg:w-[452px] lg:h-[452px] xl:w-[543px] xl:h-[543px] w-[266px] h-[266px]';
    }
}

export const getDivSize = (state, level) => {
    if (level === 'beginner') {
        if (state) {
            return 'md:w-[70.5px] md:h-[70.5px] lg:w-[90px] lg:h-[90px] xl:w-[111px] xl:h-[111px] w-[64px] h-[64px] text-[20px md:text-[24px] lg:text-[32px] xl:text-[40px]';
        } else {
            return 'md:w-[87.5px] md:h-[87.5px] lg:w-[110.5px] lg:h-[110.5px] xl:w-[133.25px] xl:h-[133.25px] w-[64px] h-[64px] text-[20px md:text-[32px] lg:text-[36px] xl:text-[50px]';
        } 
    }

    if (level === 'intermediate') {
        if (state) {
            return 'md:w-[56.4px] md:h-[56.4px] lg:w-[72px] lg:h-[72px] xl:w-[88.8px] xl:h-[88.8px] w-[51.2px] h-[51.2px]';
        } else {
            return 'md:w-[70px] md:h-[70px] lg:w-[88.4px] lg:h-[88.4px] xl:w-[106.6px] xl:h-[106.6px] w-[51.2px] h-[51.2px]';
        }
    }

    if (level === 'advanced') {
        if (state) {
            return 'md:w-[47px] md:h-[47px] lg:w-[60px] lg:h-[60px] xl:w-[74px] xl:h-[74px] w-[42.6px] h-[42.6px]';
        } else {
            return 'md:w-[58.3px] md:h-[58.3px] lg:w-[73.6px] lg:h-[73.6px] xl:w-[88.8px] xl:h-[88.8px] w-[42.6px] h-[42.6px]';
        }
    }
     
}

export const getNumbers = (level, sort) => {
    const max = level==='beginner' ? 50 : level==='intermediate' ? 100 : 500;
    const min = level==='beginner' ? 5 : level==='intermediate' ? 10 : 50;
    const size = level==='beginner' ? 5 : 10;
    const arr = [Math.floor(Math.random() * (max - min + 1) + min)];

    while(arr.length<size) {
        arr.push(arr[arr.length-1]+1*sort);
    }
    
    return arr;
}

export const generateSegmentArray = (numbers, level) => {
    const size =  level==='beginner' ? 16 : level==='intermediate' ? 25 : 36;
    const max = level==='beginner' ? 50 : level==='intermediate' ? 100 : 500;
    const min = 1;

    const arr = new Set();
    numbers.forEach((number)=>arr.add(number));
    while(arr.size<size) {
        arr.add(Math.floor(Math.random() * (max - min + 1) + min));
    }

    return Array.from(arr);
}

export const generateArray = (level, sort) => {
    const max =  level==='beginner' ? 16 : level==='intermediate' ? 25 : 36;
    const arr = [sort===1 ? 1 : max];

    while(arr.length<max) {
        arr.push(arr[arr.length-1]+1*sort);
    }
    
    return arr;
}

export const mixArray = (arr, level) => {
    const max =  level==='beginner' ? 15 : level==='intermediate' ? 24 : 35;
    const newArr = arr.reduce((aggr, item)=>{
        while(true) {
          const index = Math.floor(Math.random() * (max - 0 + 1) + 0);
          if (!aggr[index]) {
            aggr[index] = item;
            break;
          }
        }
        return aggr;
    }, [...new Array(max)]);
    return newArr;
}