import {useParams, useNavigate} from "react-router-dom";
import {useEffect, useRef, useState, useMemo} from "react";
import Timer from "../components/global/Timer.jsx";
import Ballon from "../components/global/animations/Ballon.jsx";
import {Ball} from "../components/global/animations/Ball.jsx";
import Navbar from "../components/global/Navbar/Navbar";
import {Settings} from "../components/global/Navbar/Settings";
import {Avatar} from "../components/global/Profile/Avatar";
import {Score} from "../components/global/Score";
import TopBar from "../components/global/TopBar.jsx";
import {Levels} from "../components/global/Levels.jsx";
import GameResults from "../components/global/GameResults.jsx";
import {GameTiming} from "../components/global/GameTiming.jsx";
import {BigCloudIcon, IconRainbow, IconUserImages, CloudIcon, IconUserChristmasImages} from "../components/index.js";
import {data} from '../assets/data';
import { useCallback } from "react";
import { createStorage } from '../hooks/useStorage';
import { checkChristmas } from "../hooks/useCheckChristmas";
import { Subscription } from "../components/global/Subscription/Subscription";
import { useSelector } from "react-redux";

export default function GameZone() {
    createStorage();

    const {name, gameName} = useParams();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [openGameResult, setOpenGameResults] = useState(false);
    const [openScore, setOpenScore] = useState(false);
    const [openAvatar, setOpenAvatar] = useState(false);
    const [avatar, setAvatar] = useState([]);
    const [rightAnswers, setRightAnswers] = useState(0);
    const [backgroundMusic, setBackgroundMusic] = useState(JSON.parse(localStorage.getItem("backgroundMusic")));
    const [sound, setSound] = useState(JSON.parse(localStorage.getItem("sound")));
    const [hint, setHint] = useState(true);
    const h = window.innerHeight;
    const [screenSize, setScreenSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });
    const handleResize = () => {
        setScreenSize({
            width: window.innerWidth,
            height: window.innerHeight,
        });

    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const [isSettingsOpen, setIsSettingsOpen] = useState(() => {
        const storedIsOpen = localStorage.getItem("leftBarIsOpen");
        return storedIsOpen ? JSON.parse(storedIsOpen) : false;
    });
    useEffect(() => {
        const handleLeftBarIsOpenChange = () => {
            const storedIsOpen = localStorage.getItem("leftBarIsOpen");
            setIsSettingsOpen(storedIsOpen ? JSON.parse(storedIsOpen) : false);
        };
        window.addEventListener("leftBarIsOpenChange", handleLeftBarIsOpenChange);
        return () => {
            window.removeEventListener("leftBarIsOpenChange", handleLeftBarIsOpenChange);
        };
    }, []);

    const [isTopBarOpen, setIsTopBarOpen] = useState(() => {
        const storedIsOpen = localStorage.getItem("topBarIsOpen");
        return storedIsOpen ? JSON.parse(storedIsOpen) : false;
    });


    useEffect(() => {
        const handleTopBarIsOpenChange = () => {
            const storedIsOpen = localStorage.getItem("topBarIsOpen");
            setIsTopBarOpen(storedIsOpen ? JSON.parse(storedIsOpen) : false);
        };
        window.addEventListener("topBarIsOpenChange", handleTopBarIsOpenChange);
        return () => {
            window.removeEventListener("topBarIsOpenChange", handleTopBarIsOpenChange);
        };
    }, []);

    const selectedGame = useMemo(()=>{
        return data[name].find((item) => item.name === gameName)
    });
  
    const gameindex = useMemo(()=>{
        return data[name].findIndex((item)=>item.name === gameName)
    });
    //console.log('names', data[name])
    const Component = selectedGame ? selectedGame.game : '';
    const [levelName, setLevelname]= useState("");
    const [sort, setSort] = useState(1);
    const [rotate, setRotate] = useState(false);

    const [startTimer, setStartTimer] = useState(false); //for timer component countdown 3-2-1
    //const [timerStorage, setTimerStorage] = useState(false);
    const timerStorage = useRef();
    const [stopTimer, setStopTimer] = useState(true); //timer component working
    const [timerResult, setTimerResult] = useState({right: 0, wrong: 0})

    useEffect(()=>{
            setLevelname("");
            setStartTimer(false);
            setRightAnswers(0);
            setStopTimer(true);
            setOpenGameResults(false);
            setTimerResult({right: 0, wrong: 0});
            //timerStorage.current = false;
            if (openScore) {
                setOpenScore(false);
            }
    }, [gameName]);


    const subscription = useSelector(state=>state.user.subscription);

    const TimerComponent = () => {
        if (gameName && selectedGame.level && levelName || gameName && !selectedGame.level) {
            return <Timer 
                        stopTimer={stopTimer}
                        subscription = {subscription}
                        openScore={openScore}
                        level = {levelName}
                        timerResult = {timerResult}
                        timerStorage = {timerStorage.current}
                        handleOpenScore={() => setOpenScore(!openScore)}
                        timer={selectedGame ? selectedGame.timer : null}
                        result={selectedGame ? selectedGame.result : null}
                        price={selectedGame ? selectedGame.price : null}
                />
        }
        if (gameName && selectedGame.level && !levelName || !gameName) return "";
    }
    //prevent gamecomponent from additional renders
    const handleUpdateProgress = useCallback(()=>{
        setRightAnswers(prev=>prev+1);
    }, [rightAnswers]);
    const handleOpenScore = useCallback(()=>{
        setOpenScore(!openScore);
    }, [openScore]);
    const handleTimer = useCallback(()=>{
        setStopTimer(true);
        timerStorage.current = true;
    }, []);

    const handleTimerResult = (resultVal) => {
        if (selectedGame?.result) {
            if (resultVal) {
                setTimerResult({...timerResult, right:timerResult.right+1})
            } else {
                setTimerResult({...timerResult, wrong:timerResult.wrong+1})
            }   
        }
    }

    const GameComponent = () => {
        if (data[name].length) {
            if (gameName && selectedGame?.level && levelName || gameName && !selectedGame?.level) {
                return <Component
                    subscription = {subscription}
                    start={levelName}
                    answerSound={sound}
                    state={isSettingsOpen || isTopBarOpen} 
                    handleUpdateProgress={handleUpdateProgress}
                    count={selectedGame ? selectedGame.cycle : 0} 
                    handleOpenScore={handleOpenScore}
                    handleTimer={handleTimer}
                    handleTimerResult={handleTimerResult}
                    update = {selectedGame.level && stopTimer}
                    level = {levelName}
                    sort = { sort }
                    hint = {hint}
                    rotate = { rotate }
                    point = {selectedGame?.point}
                    screenSize = {screenSize}
                />
            }
    
            if (gameName && selectedGame.level && !levelName || !gameName) {
                return <img className="w-[318px] lg:w-[498px] xl:w-[512px] h-[100%]" src="/backGrounds/game-bg.svg" />;;
            }
        } else {
            return '';
        }

    }

    const containerRef = useRef(null);
    useEffect(()=>{
            if (screenSize.width<1024) {
                containerRef.current.style.width = "100%";
            } else {
                if (screenSize.width>=1024 && screenSize.width<1440) {
                    var delta = (isSettingsOpen ? 207 : 24);
                }
                if (screenSize.width>=1440 && screenSize.width<1920) {
                    var delta = (isSettingsOpen ? 222 : 34);
                }
                if (screenSize.width>=1920) {
                    var delta = isSettingsOpen ? 328 : 34;
                }
                containerRef.current.style.width =screenSize.width-delta+"px";
            }

    }, [screenSize, isSettingsOpen]);

    const [openSubscription, setOpenSubscription] = useState(false);
    const [subscriptionType, setSubscriptionType] = useState("");

    const handleSubscription = (type, state) => {
        if (!state) {
            setOpenSubscription(!openSubscription);
        }
        setSubscriptionType(type);
    }
    
    return (
        <div
            ref={containerRef}
            onClick = {()=> setOpenGameResults(false)}
            className="select-none flex w-full h-screen md:w-auto overflow-x-scroll no-scrollbar flex-col items-center bg-cover bg-[url('/backGrounds/home.svg')] z-0 relative">
            {openSubscription && <Subscription handleSubscription={(type, state)=>handleSubscription(type, state)} subscriptionType={subscriptionType} screenSize={screenSize}/>}
            {open && <Settings
                hint={hint}
                backgroundMusic={backgroundMusic}
                sound={sound}
                handleSubscription={(type)=>handleSubscription(type)}
                handleBackgroundMusic={()=>{
                    setBackgroundMusic(!backgroundMusic);
                    localStorage.setItem("backgroundMusic", !backgroundMusic);
                }}
                handleSound={()=>{
                    setSound(!sound);
                    localStorage.setItem("sound", !sound);
                }} 
                handleOpen={(hintOn) => {
                    setOpen(!open);
                    setHint(hintOn);
                }}
            />
            }
            {openAvatar && <Avatar 
               avatars={avatar}
               subscription={subscription}
               handleSubscription={(type)=>handleSubscription(type)} 
               handleAvatar={()=>{
                  setOpenAvatar(false);
               }}
               handleOpenAvatar={() => {
                setOpenAvatar(!openAvatar);
              }}
            />}
            {openScore && <Score  subscription={subscription} selectedGame={selectedGame} handleOpenScore={() => {
                setOpenScore(!openScore);
                setRightAnswers(0);
                setLevelname("");
                setStartTimer(false);
                setRightAnswers(0);
                setStopTimer(true);
                timerStorage.current = false;
                return navigate(`/games/${name}`);     
            }}
            handleRefresh={()=>{
                setOpenScore(!openScore);
                navigate(`/games/${name}/${gameName}`);
                setStartTimer(true);
                setRightAnswers(0);
                setStopTimer(true);
                timerStorage.current = false;
            }}
            level={levelName}
            />}
            <Navbar
              refresh={open}
              subscription={subscription}
              sound={sound}
              backgroundMusic={backgroundMusic}
              screenSize={screenSize} 
              rightAnswers={rightAnswers}
              handleOpenAvatar = {()=>setOpenAvatar(!openAvatar)}
              count={selectedGame ? selectedGame.cycle : 0}
              point = {typeof(selectedGame?.point) === "object" ? selectedGame?.point[levelName] : selectedGame?.point}
              handleResult = {(e)=>{
                e.stopPropagation();
                e.preventDefault();
                setOpenGameResults(!openGameResult)
              }}
              handleBackgroundMusic={()=>{
                  setBackgroundMusic(!backgroundMusic);
                  localStorage.setItem("backgroundMusic", !backgroundMusic);
              }}
              handleSound={()=>{
                  setSound(!sound);
                  localStorage.setItem("sound", !sound);
              }}
              handleOpen={() => setOpen(!open)}
              level = {levelName}
            />
            <TopBar 
                subscription={subscription} 
                handleSubscription={(type)=>handleSubscription(type)} 
                items={data[name]} gameindex={gameindex} level={levelName} screen={screenSize}
            />

            {!openAvatar && <div 
                //onClick = {()=> setOpenGameResults(false)}
              className={`${
                (isSettingsOpen || isTopBarOpen)
                    ? 'flex items-center justify-center px-[12vw] py-[7vh] '
                    : 'flex items-center justify-center px-[15vw] py-[10vh]'
            } ${data[name].length === 0 ? 'overflow-hidden': ''} gamezone flex relative m-auto z-[10] rounded-[13px] ${checkChristmas() ? "bg-[url('/backGrounds/christmassecondarybackground.svg')] bg-cover" : "bg-[url('/backGrounds/secondarybackground.svg')]  bg-contain"} xl:bg-cover shadow-[1px_1px_24px_7px_rgba(220,_220,_221,_0.31)]`}
                 style={{backgroundPosition: 'center center'}} // Center background image
            >
                {selectedGame?.level && !levelName && <Levels
                state={isSettingsOpen || isTopBarOpen} 
                handleChange ={(name, sort, rotate)=>{
                    setLevelname(name);
                    setStartTimer(true);
                    setSort(sort);
                    setRotate(rotate);
                    localStorage.setItem('rotate', JSON.stringify(rotate));
                }}
                />}
                {
                    openGameResult && <GameResults state={isSettingsOpen || isTopBarOpen} subscription={subscription}/>
                }
                {startTimer && selectedGame && selectedGame.timer && <GameTiming handleTimer={()=>{
                    setStartTimer(false);
                    setStopTimer(false);
                }}/>}

                <IconRainbow className={'absolute bottom-0 left-[10%] '}/>
                {data[name].length ? <BigCloudIcon
                    className={`hidden md:block ${(isSettingsOpen || isTopBarOpen) ? 'h-[266px] md:h-[292px] lg:h-[370px] lg2:h-[412px] xl:h-[454px]' : 'h-[266px] md:h-[360px] lg:h-[452px] lg2:h-[498px] xl:h-[544px]'} absolute right-[50%] top-[50%] -translate-y-1/2 translate-x-1/2 `}/> : <CloudIcon
                      className={`${(isSettingsOpen || isTopBarOpen) ? 'top-[0px] right-[50px] md:top-[0px] md:right-[50px] lg:top-[20px] xl:top-[48px] lg:right-[180px] xl:right-[330px]' : 'top-[0px] right-[40px] md:top-[40px] md:right-[150px] lg:top-[80px] lg:right-[300px] xl:top-[120px] xl:right-[460px]'} absolute`}
                    />}
                <div 
                    className={`${(isSettingsOpen || isTopBarOpen)
                        ? 'h-[266px] w-[425px] md:h-[292px] md:w-[439px] lg:h-[370px] lg:w-[590px] xl:h-[454px] xl:w-[768px]'
                        : 'h-[266px] w-[425px] md:h-[360px] md:w-[576px] lg:h-[452px] lg:w-[723px] xl:h-[544px] xl:w-[910px]'
                    } game flex items-center overflow-hidden justify-center rounded-[14px] relative`}
                >
                  {GameComponent()}
                </div>
                <Ballon color={'green'}/>
                {data[name].length === 0 ? <Ballon color={'red'}/> : ''}
                {selectedGame?.level ? selectedGame?.name =='memory' || selectedGame?.name =='classic' ? levelName === 'beginner' ? '':  TimerComponent() : TimerComponent() : ''}
                {checkChristmas() ? <IconUserChristmasImages
                    user={data[name].length ? selectedGame ? selectedGame.userImage : "b1" : "b4"}
                    className={`${data[name].length === 0 ? 'w-[186px] h-[248px] absolute right-[50%] top-[50%] -translate-y-1/2 translate-x-1/2' : 'absolute -right-[7%] xl:-bottom-[10%] -bottom-[8%] lg:-bottom-[5%] h-1/2 z-[100000]'} `}
                /> : <IconUserImages
                    user={data[name].length ? selectedGame ? selectedGame.userImage : "b1" : "b4"}
                    className={`${data[name].length === 0 ? 'w-[186px] h-[248px] absolute right-[50%] top-[50%] -translate-y-1/2 translate-x-1/2' : 'absolute -right-[7%] xl:-bottom-[10%] -bottom-[8%] lg:-bottom-[5%] h-1/2 z-[100000]'} `}
                /> }
                {data[name].length === 0 ? <div 
                   className={` ${(isSettingsOpen || isTopBarOpen) ? 'bottom-[10%] md:bottom-[12%] lg:bottom-[17%] xl:bottom-[21%]' : 'bottom-[13%] md:bottom-[20%] lg:bottom-[24%] xl:bottom-[26.5%]'} absolute  font-righteous text-[30px] text-[#313131]`}>
                    under construction
                </div> : ''}
            </div>}
            <Ball state={isTopBarOpen} screen={screenSize}/>
        </div>
    );
}
