import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchData } from '../../hooks/fetchData';

export const getScoresHistoryThunk = createAsyncThunk(
  'scores/data',
  async ({userId, skip}, thunkAPI) => {
    try {
      const response = await fetchData(`scoreHistory/${userId}?skip=${skip}`, 'get', {});
      return thunkAPI.fulfillWithValue(response.data);
    } catch (err) {
      return thunkAPI.rejectWithValue();
    }
  },
);

export const getScoreDetailsThunk = createAsyncThunk(
  'score/details',
  async ({userId, profileId, date}, thunkAPI) => {
    try {
      const response = await fetchData(`scoreDetails/${userId}?profileId=${profileId}&date=${date}`, 'get', {});
      return thunkAPI.fulfillWithValue(response.data);
    } catch (err) {
      return thunkAPI.rejectWithValue();
    }
  },
);
export const scoresSlice = createSlice({
    name: 'scoresHistory',
    initialState: {
       data: [],
       scoreDetails: {}
    },
    extraReducers: (builder) => {
        builder
        .addCase(getScoresHistoryThunk.fulfilled, (state, action) => {
          state.data = action.payload;
        })
        .addCase(getScoreDetailsThunk.fulfilled, (state, action) => {
          state.scoreDetails = action.payload;
      })
      },
  });

  export default scoresSlice.reducer;