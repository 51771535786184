export const getAction = () => {
    const arr = ["+", "-"];
    return arr[Math.floor(Math.random()*arr.length)];
}


const getNumber = (action, item, level) => {
   if (action === "+") {
       const max = item - 1;
       const min = 1;
       const firstNumber = Math.floor(Math.random() * (max - min + 1) + min);
       const secondNumber = item - firstNumber;
       return firstNumber + ' + ' +  secondNumber;
   } else {
       const max = level === "beginner" ? 10 : level === "advanced" ? 99 : 50;
       const min = item;
       const firstNumber = Math.floor(Math.random() * (max - min + 1) + min);
       const secondNumber = firstNumber - item;
       return firstNumber + ' - ' +  secondNumber;
   }
}

export const getNumbersArray = (action, level) => {
       if (action === "+") {
            const min = level === "beginner" ? 3 : level === "advanced" ? 50 : 10;
            const max= level === "beginner" ? 10 : level === "advanced" ? 99 : 50;
    
            const arr = new Set();
            while(arr.size<3) {
            arr.add(Math.floor(Math.random() * (max - min + 1) + min));
            }
    
            return Array.from(arr).sort((a, b)=>a-b).map(item=>getNumber(action, item));
       } else {
            const min = level === "beginner" ? 1 : level === "advanced" ? 50 : 10;
            const max= level === "beginner" ? 8 : level === "advanced" ? 70 : 30;
    
            const arr = new Set();
            while(arr.size<3) {
            arr.add(Math.floor(Math.random() * (max - min + 1) + min));
            }
    
            return Array.from(arr).sort((a, b)=>a-b).map(item=>getNumber(action, item, level));
       }

} 




