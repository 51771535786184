
import { DragAndDrop } from "../../../../components/global/DragAndDrop";
import { useState, useEffect, useRef } from "react";
import { components, data } from "./assets/Number.data";
import { 
    animationDurationToMoveCorrectAnswer
} from "../../../../config";


export const Number = ({number, handleSelected}) => {
    const Component = components[number];

    const [result, setResult] = useState([...data[number].result]);
    const num = useRef([...data[number].result]);


    const refresh = (event, check) => {

        const elem = document.querySelector('.endPoint');
        const elemCoord = elem.getBoundingClientRect();

        if (
             event.clientX<elemCoord.x+elemCoord.width && event.clientX>=elemCoord.x && 
             event.clientY<elemCoord.y+elemCoord.height && event.clientY>=elemCoord.y && check
         ) {
            
            const newResult = [...num.current];
            newResult[newResult.indexOf(null)] = true;
            num.current = newResult;
            setResult(newResult);
         }
    }

    useEffect(()=>{
       if (!result.includes(null)) {
          setTimeout(()=>handleSelected(number), animationDurationToMoveCorrectAnswer*1000);
       }
    }, [result]);
  
    return (
        <div className="bg-[#FFF] learning text-[#D2461B] font-bigfatScript reltive flex justify-center items-center w-full h-full border-[3px] border-[#D2461B] rounded-[16px] lg:rounded-[20px] xl:border-[4px]">
            <div onClick={handleSelected} className="z-[40] absolute top-[4%] left-[4%] text-[40px] cursor-pointer">{"<"}</div>
            <div className="relative w-[100px] h-[150px]">
                <DragAndDrop
                    className={`absolute ${data[number].positions[result.indexOf(null)]?.[0] || data[number].positions[result.lastIndexOf(true)][0]}`}
                    enableClick = {false}
                    cmp={"learning"} 
                    drop={"learning"}
                    item = {""}
                    refresh={(sound, event, check) => refresh(event, check)}
                >
                   <div className={`${result.indexOf(null)===-1 && "opacity-0"} startPoint absolute cursor-pointer z-[40] w-[27px] h-[36px] bg-[url('/backGrounds/startPoint.svg')] bg-cover bg-no-repeat`} />
                </DragAndDrop>
                {data[number].middlePosition && <div className={`z-[0] bg-red-500 invisible middlePoint w-[27px] h-[36px] absolute rounded-[50%] ${data[number].middlePosition[result.indexOf(null)]}`} />}
                <div className={`${result.indexOf(null)===-1 && "opacity-0"} endPoint absolute ${data[number].positions[result.indexOf(null)]?.[1] || data[number].positions[result.lastIndexOf(true)][1]} w-[27px] h-[36px] bg-[url('/backGrounds/endPoint.svg')] bg-cover bg-no-repeat`} />
               <Component result={result} className="w-full h-full"/>
            </div>
            
        </div>
    )
}