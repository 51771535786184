import axios from 'axios';
import {API_URL} from '../config';

axios.defaults.withCredentials = true;

export const fetchData = (path, method, data) => {
   return axios({
      method,
      url: `${API_URL}/${path}`,
      data,
   })
}