import {CircleButton} from "../../../../components/elements/CircleButton.jsx";
import { motion } from "framer-motion";
import { 
    animationDurationToShowQuestion,
} from "../../../../config";

export function TopRowOfStepByStep({gameCount, copySequence, state}) {
    const amimNumbers = [-100, -101, -103, -104, -105, -106, -107, -108, -109, -110, -111];

    return (
        <div
            className={`${state ? "h-[67px] md:h-[73px] lg:h-[93px] xl:h-[114px]" : "h-[67px] md:h-[90px] lg:h-[113px] xl:h-[136px]"} flex items-center ${state ? 'md:px-[30px] lg:px-[40px] xl:px-[55px]' : 'md:px-[41px] lg:px-[45px] xl:px-[52px]'} flex flex-row justify-between z-20 px-[28px] bg-[#FEF1B0] border-4 border-[#2F9EBA] overflow-hidden rounded-[20px]`}>
            {copySequence.map((number, index) => (
                <motion.div
                    animate={{y: [-amimNumbers[gameCount], 0]}}
                    transition={{duration: animationDurationToShowQuestion*(index+1)/copySequence.length, type: "tween"}}   
                    key={index} className={'stepbystep'+index}
                >
                    {
                        <CircleButton isActive={number}>{number ? number : '?'}</CircleButton> 
                    }
                </motion.div>
            ))}
        </div>
    );
}