
import { GAMES } from "../config";

export const gameId = (game, gameName) => {
   const games = GAMES[game];

   return games.find((el)=>el.name === gameName).id;
}

export const getCollectionId = (collection, index) => {
   let id = Object.keys(GAMES).indexOf(collection)+1;
   const game = index+1;
   if (id < 10) {
      id = "0"+id;
   } else {
       id+="";
   }

   if (game < 10) {
      id += "0"+game;
   } else {
      id += game;
   }

   return id;
}