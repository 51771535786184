



export const Delete = ({handleDelete, confirmDelete}) => {
     return (
        <div onClick={handleDelete} className="w-full h-full absolute z-[60] bg-bgBlack/30 flex justify-center items-center">
            <div onClick={(e)=>e.stopPropagation()} className="w-[244px] h-[176px] md:w-[347px] md:h-[221px] lg:w-[401px] lg:h-[282px] rounded-[19px] border-[3px] md:border-[4px] border-[#7743BA] bg-[#FFF]">
                <div className="w-[184px] md:w-[316px] mx-auto font-fredokaOne">
                <p className="text-[16px] md:text-[20px] lg:text-[24px] text-[#F68C2F] text-center lg:mt-[27px] lg:mb-[17px] mt-[11px] mb-[12px] md:mt-[20px] md:mb-[16px]">Delete Profile</p>
                <div className="h-[2px] w-full bg-[#39BA7E]"/>
                <p className="mt-[16px] lg:mt-[34px] md:text-[16px] text-[14px] text-[#4B96C9] text-center md:w-[219px] md:mx-auto lg:text-[20px]">Are you sure you want to delete ?</p>
                <div className="mt-[23px] md:mt-[34px] flex justify-around md:w-[215px] lg:w-[273px] md:justify-between md:mx-auto">
                        <button
                            onClick={confirmDelete}
                            className="w-[61px] h-[26px] md:w-[83px] md:h-[28px] lg:text-[20px] lg:w-[102px] lg:h-[34px] border-[2px] rounded-[7px] text-[#fff] text-center text-[14px] md:text-[16px] border-[#1F6645] bg-[#39BA7E]"
                        >
                            YES
                        </button>
                        <button
                           onClick={(e)=>{
                               e.stopPropagation();
                               handleDelete();
                           }}
                           className="w-[61px] h-[26px] md:w-[83px] md:h-[28px] lg:text-[20px] lg:w-[102px] lg:h-[34px] border-[2px] rounded-[7px] text-[#fff] text-center text-[14px] md:text-[16px] border-[#C11111] bg-[#E84620]"
                        >
                            NO
                        </button>
                </div>
                </div>
            </div>
       </div>
    )
}