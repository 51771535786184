import { motion } from 'framer-motion';
import { CircleButton } from '../../../../components/elements/CircleButton';
import { 
    animationDurationToShowQuestion,
} from "../../../../config";

export const TopPart = ({state, result, selected}) => {
    return (
        <div className={`z-0 w-full h-[39px] flex justify-between absolute top-[48px] 
           ${state ? 'md:h-[39px] md:top-[49px] lg:h-[50px] lg:top-[58px] xl:h-[70px] xl:top-[74px]' : 'md:h-[49px] md:top-[53px] lg:h-[69px] lg:top-[73px] xl:h-[89px] xl:top-[85px]'}`}>
           {
             result.map((number, index)=>{
                return (
                    <motion.div
                        key={index}
                        animate={{y: selected.length ? [0, 0] : [(index+1)*100, 0]}}
                        transition={{duration: animationDurationToShowQuestion , type: "tween", ease: "linear"}}
                        className={`${'drop'+index}`}
                    >
                        <CircleButton className={'ascending'+index} key={index} isActive={number}>
                           {number}
                        </CircleButton>
                    </motion.div>
             )})
           }
        </div>
    )
}