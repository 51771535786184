
import { BottomPart } from "./components/BottomPart";
import { TopPart } from "./components/TopPart";
import { getNumber, getAnswers } from "./AddingMix.data";
import { useRef, useState, useEffect } from "react";
import { updateStorage} from "../../../hooks/useStorage";
import { updateGameStorage } from "../../../hooks/useGameStorage";
import { 
    animationDurationToShowscoresPopup, 
} from "../../../config";
import { useParams } from 'react-router-dom';
import Sound from "../../../components/global/Sound";
import * as amplitude from '@amplitude/analytics-browser';
import { gameId } from "../../../hooks/getGameId";
import { useUpdateReduxState } from "../../../hooks/useUpdateReduxState";

export const AddingMix = ({state, handleUpdateProgress, handleOpenScore, answerSound, point, hint, subscription}) => {
    const {name, gameName} = useParams();
    const updateState = useUpdateReduxState();

    useEffect(()=>{
        amplitude.track(`${name+"_"+gameName.replace("_", " ")+"_start"}`);
    }, []);
    const score = useRef(0);
    const number1 = useRef(getNumber());
    const number2 = useRef(getNumber());
    const number3 = useRef(getNumber());
    const answer1 = useRef(number1.current + number2.current + number3.current);

    const number4 = useRef(getNumber());
    const number5 = useRef(getNumber());
    const number6 = useRef(getNumber());
    const answer2 = useRef(number4.current + number5.current + number6.current);

    const number7 = useRef(getNumber());
    const number8 = useRef(getNumber());
    const number9 = useRef(getNumber());
    const answer3 = useRef(number7.current + number8.current + number9.current);

    const answers = useRef(getAnswers(answer1.current, answer2.current, answer3.current));
    const selected = useRef([]);
    const [result, setResult] = useState([null, null, null]);

    const soundRef = useRef();
    const soundWrongRef = useRef();

    const refresh = (event, i, answer) => {
        const drop1 = document.querySelector(".drop1");
        const drop2 = document.querySelector(".drop2");
        const drop3 = document.querySelector(".drop3");
        if (
            event.clientX>=drop1.getBoundingClientRect().x-drop1.getBoundingClientRect().width && 
            event.clientX<=drop1.getBoundingClientRect().x+drop1.getBoundingClientRect().width &&
            event.clientY>=drop1.getBoundingClientRect().y && 
            event.clientY<=drop1.getBoundingClientRect().y+drop1.getBoundingClientRect().height &&
            answer === answer1.current
        ) {
            if (soundRef.current && answerSound ) {
                soundRef.current.currentTime = 0;
                soundRef.current.play();
            }
           const arr = [...result];
           arr[0] = answer;
           selected.current.push(i);
           setResult(arr);
           return;
        }
        if (
            event.clientX>=drop2.getBoundingClientRect().x-drop2.getBoundingClientRect().width && 
            event.clientX<=drop2.getBoundingClientRect().x+drop2.getBoundingClientRect().width &&
            event.clientY>=drop2.getBoundingClientRect().y && 
            event.clientY<=drop2.getBoundingClientRect().y+drop2.getBoundingClientRect().height &&
            answer === answer2.current
        ) {
            if (soundRef.current && answerSound ) {
                soundRef.current.currentTime = 0;
                soundRef.current.play();
            }
            const arr = [...result];
            arr[1] = answer;
            selected.current.push(i);
            setResult(arr);
            return;
        }
        if (
            event.clientX>=drop3.getBoundingClientRect().x-drop3.getBoundingClientRect().width && 
            event.clientX<=drop3.getBoundingClientRect().x+drop3.getBoundingClientRect().width &&
            event.clientY>=drop3.getBoundingClientRect().y && 
            event.clientY<=drop3.getBoundingClientRect().y+drop3.getBoundingClientRect().height &&
            answer === answer3.current
        ) {
            if (soundRef.current && answerSound ) {
                soundRef.current.currentTime = 0;
                soundRef.current.play();
            }
            const arr = [...result];
            arr[2] = answer;
            selected.current.push(i);
            setResult(arr);
            return;
        }
        if (soundWrongRef.current && answerSound ) {
            soundWrongRef.current.currentTime = 0;
            soundWrongRef.current.play();
        }
    }

    useEffect(()=>{
        if (!result.includes(null)) {
            score.current += point;
            if (!subscription) {
               updateStorage(point);
            }
            if (subscription) {
                updateState({
                    userId: localStorage.getItem('user'),
                    profileId: JSON.parse(localStorage.getItem('profile'))?._id,
                    game: name,
                    score: score.current,
                    id: gameId(name, gameName),
                    collection: name,
                })
            } else {
                handleUpdateProgress();
                updateGameStorage(name, gameName, point, true);
            }
            setTimeout(()=>handleOpenScore(), animationDurationToShowscoresPopup*1000);
            localStorage.setItem(`${name+'_'+gameName+'_'+'_score'}`, score.current);
        }
    }, [result])

    return (
        <div
           className="w-full h-full flex flex-col justify-between"
        >
            <TopPart 
                state={state}
                result={result}
                numbers={{
                   number1: number1.current,
                   number2: number2.current,
                   number3: number3.current,
                   answer1: answer1.current,
                   number4: number4.current,
                   number5: number5.current,
                   number6: number6.current,
                   answer2: answer2.current,
                   number7: number7.current,
                   number8: number8.current,
                   number9: number9.current,
                   answer3: answer3.current
                }}
            />
            <BottomPart
                hint={hint}
                state={state}
                result={result}
                allAnswers={[answer1.current, answer2.current, answer3.current]}
                answers={answers.current}
                refresh={refresh}
                selected={selected.current}
            />
            <Sound ref={soundRef} url={"/backGrounds/right_answer.wav"} loop={false}/>
            <Sound ref={soundWrongRef} url={"/backGrounds/wrong_answer.wav"} loop={false}/>
        </div>
    )
}