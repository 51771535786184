import { useRouteError } from "react-router-dom";

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <div
      id="error-page"
      className="flex h-screen w-screen flex-col justify-center p-3 text-center"
    >
      <h1 className="scale-125 font-bold">Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p className=" text-red-700">
        <i>{error.statusText || error.message}</i>
      </p>
    </div>
  );
}
