import { LeftBar } from "../components";
import { Outlet } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { createGameStorage } from "../hooks/useGameStorage";
import { useEffect } from "react";
import * as amplitude from '@amplitude/analytics-browser';
import { PaddleInit } from "./Paddle";
import { useDispatch, useSelector } from "react-redux";
import { checkLoginThunk } from "../redux/features/userSlice";
import * as Sentry from "@sentry/react";

export default function Layout() {
    const {pathname} = useLocation();
    const dispatch = useDispatch();

    //paddle payment gateway setup
    PaddleInit();
    createGameStorage();

    const user = useSelector(state=>state.user.user);

    useEffect(()=>{
        amplitude.track(`App Start`);
        dispatch(checkLoginThunk()).then(res=>localStorage.setItem('user', res.payload.user._id));
        Sentry.setUser({
            email: user?._id || "",
        })
    }, []);
    
    return (
        <div className={`w-full h-full ${pathname==='/about' ? 'bg-[linear-gradient(180deg,_#F9F4E1_0%,_#FCF9EE_35.87%)]' : ''}`}>
            <div className="flex h-screen min-w-[736px]">
                <LeftBar/>
                <div id="detail" className="w-full md:pl-0">
                    <Outlet/>
                </div>
            </div>
        </div>
    );
}
