export const calcGapY = (state, type, count) => {
    if (window.innerWidth<1024) {
       if (type === 'rect') {
         return (260-51*count) / (count+1);
       } else {
         return (260-39*count) / (count+1);
       }
    }
    if (window.innerWidth>=1024 && window.innerWidth<1440) {
     if (type === 'rect') {
         if (state) {
             return (286-53*count) / (count+1);
         } else {
             return (354-61*count) / (count+1);
         }
       
     } else {
         if (state) {
             return (286-39*count) / (count+1);
         } else {
             return (354-49*count) / (count+1);
         }
     }
    }
    if (window.innerWidth>=1440 && window.innerWidth<1920) {
     if (type === 'rect') {
         if (state) {
             return (364-64*count) / (count+1);
         } else {
             return (446-80*count) / (count+1);
         }
       
     } else {
         if (state) {
             return (364-50*count) / (count+1);
         } else {
             return (446-69*count) / (count+1);
         }
     }
    }
    if (window.innerWidth>=1920) {
     if (type === 'rect') {
         if (state) {
             return (446-88*count) / (count+1);
         } else {
             return (536-97*count) / (count+1);
         }
       
     } else {
         if (state) {
             return (446-64*count) / (count+1);
         } else {
             return (536-89*count) / (count+1);
         }
     }
    }
 }

 export const calcGapX = (state, count) => {
    if (window.innerWidth<1024) {
        return (276-27*count) / (count+1);
    }
    if (window.innerWidth>=1024 && window.innerWidth<1440) {
        if (state) {
           return (292-27*count) / (count+1);
        } else {
            return (348-30*count) / (count+1);
        }
        
    }
    if (window.innerWidth>=1440 && window.innerWidth<1920) {
        if (state) {
           return (362-32*count) / (count+1);
        } else {
            return (463-37*count) / (count+1);
        } 
    }
    if (window.innerWidth>=1920) {
        if (state) {
           return (504-44*count) / (count+1);
        } else {
            return (562-49*count) / (count+1);
        }
        
    }
}

export const generateNumbers = (max, min) => {
    let numbers = new Set();
    while (numbers.size < 4) {
      const num = Math.floor(Math.random() * max) + min;
      numbers.add(num);
    }
    return Array.from(numbers);
}

export const generateAnswers = (numbers) => {
    const arr = new Set();
    while(arr.size<4) {
      arr.add(numbers[Math.floor(Math.random()*numbers.length)]);
    }
    return Array.from(arr);
 }