
import { TopPart } from "./components/TopPart";
import { BottomPart } from "./components/BottomPart";
import React, { useState, useRef, useEffect } from 'react';
import { 
    animationDurationToShowscoresPopup,
    animationDurationToMoveCorrectAnswer
} from "../../../config";
import { useParams } from 'react-router-dom';
import { updateStorage } from "../../../hooks/useStorage";
import { updateGameStorage } from "../../../hooks/useGameStorage";
import { getShape, getAnswers } from "./Shape1.data";
import * as amplitude from '@amplitude/analytics-browser';
import { gameId } from "../../../hooks/getGameId";
import { useUpdateReduxState } from "../../../hooks/useUpdateReduxState";

export const Shape1 = ({state, handleOpenScore, count, handleUpdateProgress, answerSound, point, hint, subscription}) => {
    const gameStore = JSON.parse(localStorage.getItem('playedGames'));
    const {name, gameName} = useParams();
    const animateArr = [0.1, 0.2, 0.3];
    const gameCount = useRef(0);
    const [result, setResult] = useState('?');
    const [randomShape, setRandomShape] = useState(getShape());
    const answers = useRef(getAnswers(randomShape.answer));
    const score = useRef(0);
    const [refresh, setRefresh] = useState(false);
    const updateState = useUpdateReduxState();

    useEffect(()=>{
      amplitude.track(`${name+"_"+gameName.replace("_", " ")+"_start"}`)
    }, []);
    
    const checkAnswer = (event, type, val) => {
        if (event==='mouseup') return;
        score.current += point;
        if (!subscription) {
          updateStorage(point);
        }
        !type && setResult(val);
        if (gameCount.current === count-1 || (gameStore[name] && gameStore[name].find(item=>item.name===gameName)?.all === count-1)) {
            if (subscription) {
              updateState({
                userId: localStorage.getItem('user'),
                profileId: JSON.parse(localStorage.getItem('profile'))?._id,
                game: name,
                score: score.current,
                id: gameId(name, gameName),
                collection: name,
              })
            } else {
                handleUpdateProgress();
                updateGameStorage(name, gameName, point, true);
            }

            setTimeout(()=> {
              handleOpenScore();
              gameCount.current = 0;

            }, animationDurationToShowscoresPopup*1000);
            localStorage.setItem(`${name+'_'+gameName+'_'+'_score'}`, score.current);
          } else {
            if (!subscription) {
               handleUpdateProgress();
               updateGameStorage(name, gameName, point, false);
            }
            setTimeout(()=>{ 
              gameCount.current +=1;
              
              setResult('?');
              const randomShape = getShape();
              setRandomShape(randomShape);
              setRefresh(!refresh);
              answers.current = getAnswers(randomShape.answer);
            }, animationDurationToMoveCorrectAnswer*1000);
          }
    }

    return (
        <div className="w-full h-full flex flex-col justify-between">
            <TopPart
               state={state}
               result = {result}
               clipPath = {randomShape.path}
               animate = {animateArr[gameCount.current]}
            />
            <BottomPart
               hint={hint}
               state={state}
               answer={randomShape.answer}
               answers = {answers.current}
               checkAnswer = {checkAnswer}
               answerSound={answerSound}
            />

        </div>
    )
}