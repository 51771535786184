export const checkHint = (answers, result, number) => {
    const countNumberInResult = result.reduce((aggr, item)=>{
        if (item==number) {
          return aggr+=1;
        } else {
          return aggr
        }
    }, 0);
    const numberIndexInAnswers = answers.reduce((aggr, item, i)=>{
         if (item==number) {
            return [...aggr, i];
         } else {
           return aggr
         }
    }, []);
    return numberIndexInAnswers[countNumberInResult];
   
}