import React, {useState, useEffect} from "react";
export const GameTiming = ({handleTimer}) => {
    const [number, setNumber] = useState(3);
    useEffect(()=>{
      if (number==0) return handleTimer();
      const id = setInterval(()=>{
        setNumber(number-1);
      }, 1000);
      return ()=>clearInterval(id);
    });
    return(
       <div className="h-[100%] flex justify-center items-center w-full bg-[url('/backGrounds/timing.svg')] bg-cover absolute z-[1000] bg-[position:50%_50%]">
         <div className="text-[#FF730E] text-[190px] font-fredokaOne font-normal">
            {number}
         </div>
       </div>
    )
}