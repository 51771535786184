export const getDate = () => {
    const date = new Date(); 
    const year = date.getFullYear();
    let month = date.getMonth()+1
    let day = date.getDate();
    if (day<10) day='0'+day;
    if (month<10) month='0'+month;
    return day+'.'+month+'.'+year;
}

export const formatedDate = (timestamp) => {
    const date = new Date(timestamp);
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };

    return date.toLocaleDateString('en-GB', options);
}

export const dbDate = () => {
    const today = new Date();

    return new Date(
        today.getFullYear(), 
        today.getMonth(), 
        today.getDate(), 
        0, 0, 0).getTime()/1000; 
}