
import Navbar from "../../components/global/Navbar/Navbar";
import {Settings} from "../../components/global/Navbar/Settings";
import GameResults from "../../components/global/GameResults.jsx";
import {useEffect, useState} from "react";
import { Avatar } from "../../components/global/Profile/Avatar";
import { NavLink } from "react-router-dom";


export default function About() {
    if (!localStorage.getItem('answerSound')) {
        localStorage.setItem('answerSound', true);
    }
    const [open, setOpen] = useState(false);
    const [openAvatar, setOpenAvatar] = useState(false);
    const [avatar, setAvatar] = useState(localStorage.getItem('avatar'));
    const [openGameResult, setOpenGameResults] = useState(false);
    const [answerSound, setAnswerSound] = useState(localStorage.getItem('answerSound'));
    const [isSettingsOpen, setIsSettingsOpen] = useState(() => {
        const storedIsOpen = localStorage.getItem("leftBarIsOpen");
        return storedIsOpen ? JSON.parse(storedIsOpen) : false;
    });

    useEffect(() => {
        const handleLeftBarIsOpenChange = () => {
            const storedIsOpen = localStorage.getItem("leftBarIsOpen");
            setIsSettingsOpen(storedIsOpen ? JSON.parse(storedIsOpen) : false);
        };
        window.addEventListener("leftBarIsOpenChange", handleLeftBarIsOpenChange);
        return () => {
            window.removeEventListener("leftBarIsOpenChange", handleLeftBarIsOpenChange);
        };
    }, []);

    return(
        <div
            onClick = {()=> setOpenGameResults(false)}
            className="w-full h-screen text-center bg-[linear-gradient(180deg,_#F9F4E1_0%,_#FCF9EE_35.87%)">  
            <div className={`${open || openGameResult ? 'overflow-y-hidden' : 'overflow-y-scroll' } overflow-x-hidden w-full relative h-screen `}>
                {openAvatar && <Avatar 
                handleAvatar={(img)=> {
                    setAvatar(img);
                    setOpenAvatar(false);
                    localStorage.setItem('avatar', img);
                }}
                handleOpenAvatar={() => {
                    setOpenAvatar(!openAvatar);
                }}
                />}
               <Navbar 
                   cmp={'about'}
                   avatar={avatar}
                   handleOpen={() => setOpen(!open)}
                   handleResult = {(e)=>{
                    e.stopPropagation();
                    e.preventDefault();
                    setOpenGameResults(!openGameResult);
                  }}
                  answerSound={answerSound}
                  handleAnswerSound = {()=>{
                    setAnswerSound(!answerSound);
                    localStorage.setItem('answerSound', !answerSound);
                  }}
                  handleOpenAvatar={() => {
                    setOpenAvatar(!openAvatar);
                  }}
                />
               {open && <Settings handleOpen={() => setOpen(!open)}/>}
               {openGameResult && <GameResults state={isSettingsOpen} cmp={true}/>}
               {/* welcome part */}
        
                <div className="w-[439px] h-[136.26px] md:w-[402.5px] md:h-[136.67px] lg:w-[625px] lg:h-[184.35px] xl:w-[815px] xl:h-[286px] absolute bottom-0 right-0">
                    <img className="right-[8%] bottom-[20%] absolute w-[105.7px] md:w-[106px] lg:w-[143px] xl:w-[208px] h-full z-[10]" src="/backGrounds/about/image-0.svg"/>
                    <div className="bottom-0 absolute w-full h-[100px] md:h-[96px] lg:h-[145px] xl:h-[186px] bg-[url('/backGrounds/about/main_grass.svg')] bg-cover bg-no-repeat"></div>
                </div>
                <img className="w-[30px] h-[30px] lg:w-[40px] md:w-[35px] md:h-[35px] lg:h-[40px] xl:w-[43px] xl:h-[43px] absolute bottom-[50px] left-[50px] cursor-pointer" src="/backGrounds/about/down.svg"/>


               <div className="w-[102px] h-[55px] lg:w-[137px] lg:h-[73px] right-[8%] top-[210px] lg:top-[170px] lg:right-[45%] md:w-[155px] md:h-[83px] md:top-[110px] md:right-[35%] xl:w-[185px] xl:h-[99px] absolute xl:top-[240px] xl:right-[42%] bg-[url('/backGrounds/about/paper.svg')] bg-cover" />
               {/* top part */}
               <div className="w-full h-full bg-[url('/backGrounds/about/main_bg.svg')] bg-[length:100%_100%] bg-no-repeat">
                <div className="w-[668px] md:w-[752px] lg:w-[1068px] mt-[42px] md:mt-[45px] lg:mt-[55px] xl:w-[1435px] xl:h-[509px] mx-auto xl:mt-[80px] md:flex-col flex flex-row-reverse lg:flex-row font-concert justify-between">
                    <div className="w-[305] md:w-[420px] lg:w-[484px] xl:w-[779px] lg:mt-[32px] xl:mt-0">
                        <div className="text-center w-[204px] mx-auto md:text-left text-[16px] lg:text-center md:text-[24px] xl:leading-[58px] lg:text-[32px] md:h-[60px] lg:h-[79px] xl:text-[54px] md:w-full xl:h-[136px] text-[#8048C2]">
                            Welcome to MathMinds - Kids Math Games: Where Learning Meets Fun!
                        </div>
                        <div className="w-[305px] leading-[20px] mt-[15px] text-[14px] md:w-[420px] md:h-[134px] lg:w-full lg:text-[28px] lg:leading-[41px] xl:leading-[58px] lg:mt-[60px] lg:text-center md:leading-[29.4px] md:text-[20px] md:mt-[20px] md:text-left xl:w-[646px] xl:h-[346px] xl:text-[40px] text-[#5C5C5C] xl:mt-[45px] text-center xl:mx-auto ">
                            Are you looking for an exciting way to introduce 
                            your kids to the world of mathematics? Look no 
                            further! MathMinds - Kids Math Games is the perfect solution to 
                            make learning math a joyful adventure.
                        </div>
                    </div>
                    <div className="w-[334px] md:w-[683px] md:h-[161px] lg:w-[454px] lg:h-auto xl:w-[568px] flex md:flex-row-reverse flex-col lg:flex-col md:self-end md:justify-between xl:self-start">
                        <div className="w-full h-[166px] md:w-[329px] md:h-full lg:w-full lg:h-[226px] bg-[length:100%_100%] xl:h-[285.9px] bg-[url('/backGrounds/about/about_phone.png')]"></div>
                        <div className="w-full md:w-[309px] lg:w-[356px] xl:w-[446px] lg:h-[55px] h-[63px] mt-[35px] lg:mt-[30px] xl:mt-[40px] flex self-end justify-between mx-auto md:mx-0 items-end lg:mx-auto">
                            <a target="_blank" href="https://play.google.com/store/apps/details?id=com.witplex.preschoolmathgame">
                                <div className="w-[130px] h-[49px] lg:w-[161px] lg:h-[55px] lg:border-[2px] lg:rounded-[10px] border-[1.5px] rounded-[5px] xl:w-[183px] xl:h-[63px] relative pt-[7px] xl:border-[3px] border-[#0EAFD7] xl:rounded-[10px] bg-[#FFF] bg-[url('/backGrounds/about/google_play.svg')] bg-[position:5%_50%] bg-[length:23px_25px] lg:bg-[length:29px_33px] xl:bg-[length:33px_33px] bg-no-repeat">
                                        <div className="ml-[37px] w-[74px] lg:w-[85px] lg:ml-[45px] xl:w-[87px] text-[12px] lg:text-[13px] xl:text-[14px] text-left font-sans xl:ml-[52px]">Get it on</div>
                                        <div className="ml-[37px] w-[74px] lg:w-[85px] lg:ml-[45px] xl:w-[87px] text-[13px] lg:text-[14px] xl:text-[16px] text-left font-sans xl:ml-[52px]">Google Play</div>
                                </div>
                            </a>
                            <a target="_blank" href="https://apps.apple.com/us/app/kids-math-games-2nd-4th-grade/id1570425468">
                                <div className="w-[130px] h-[49px] lg:w-[161px] lg:h-[55px] lg:border-[2px] lg:rounded-[10px] border-[1.5px] rounded-[5px] xl:w-[183px] xl:h-[63px] relative pt-[7px] xl:border-[3px] border-[#0EAFD7] xl:rounded-[10px] bg-[#FFF] bg-[url('/backGrounds/about/app_store.svg')] bg-[position:5%_50%] bg-[length:25px_25px] lg:bg-[length:34px_33px] xl:bg-[length:36px_36px] bg-no-repeat">
                                        <div className="ml-[37px] w-[74px] lg:w-[85px] lg:ml-[50px] xl:w-[110px] text-[12px] lg:text-[13px] xl:text-[14px] text-left font-sans xl:ml-[55px]">Get it on</div>
                                        <div className="ml-[37px] w-[74px] lg:w-[85px] lg:ml-[50px] xl:w-[110px] text-[13px] lg:text-[14px] xl:text-[16px] text-left font-sans xl:ml-[55px]">App Store</div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
               </div>

            {/* second part */}
            <div className={`w-full bg-[url('/backGrounds/about/background.svg')] flex flex-col`}>
                     <div className="hidden lg:block lg:w-[150px] lg:h-[93px] xl:w-[258px] xl:h-[161px] absolute lg:top-[145%] xl:top-[153%] right-[35%] bg-[url('/backGrounds/about/second_paper.svg')] bg-cover" />
                     {/* section 1 */}
                     <div className="w-[587px] h-[151px] md:w-[749px] md:h-[213px] lg:w-[1045px] lg:h-[253px] xl:w-[1250px] xl:h-[359px] flex justify-between mx-auto items-center">
                       <div className={`w-[120px] h-full md:w-[169px] lg:w-[204px] xl:w-[242px] bg-cover xl:h-[323px] xl:self-end xl:bg-[length:100%_100%] bg-[url('/backGrounds/about/image-1.svg')]`} />
                       <div className="hidden lg:block w-[175px] h-full xl:hidden"/>
                       <div className="w-[27px] h-[55px] md:w-[38px] md:h-[79px] lg:w-[45px] lg:h-[93px] bg-cover xl:w-[50px] self-start xl:h-[103px] bg-[url('/backGrounds/about/purpple_ballon.svg')]"></div>
                       <div className="w-[421px] md:w-[486px] lg:w-[588px] xl:w-[881px] font-concert text-center md:text-left">
                          <div className="text-[16px] md:text-[24px] lg:text-[28px] xl:text-[48px] text-[#327560]">Why Choose MathMinds - Kids Math Games?</div>
                          <div className="text-[14px] md:text-[20px] lg:text-[24px] xl:text-[36px] text-[#5C5C5C] mt-[10px] md:mt-[16px]">
                               Learning through Play: At MathMinds - Kids Math Games, we believe that
                               the best way to learn is by having fun. Our interactive 
                               and engaging games are designed to make math 
                               exciting and enjoyable for kids of all ages. Your
                               children won't even realize they're learning!
                          </div>
                       </div>
                     </div>
                     {/* section 2 */}
                     <div className="mt-[50px] relative w-[711px] md:w-[790px] lg:w-[1184px] xl:w-[1495px] flex md:mt-[90px] xl:mt-[132px] justify-between md:overflow-x-hidden md:pl-[16px] self-end">
                        <div className="absolute right-[55%] top-[-18%] md:top-0 md:right-0 md:relative bg-contain w-[27px] h-[55px] md:w-[34px] md:h-[72px] xl:w-[50px] xl:h-[103px] bg-no-repeat bg-[url('/backGrounds/about/red_ballon.svg')]"></div>
                        <div className="w-[379px] text-center md:ml-[20px] xl:ml-[20px] md:w-[374px] md:text-[24px] lg:text-[28px] xl:text-[48px] lg:ml-0 lg:w-[594px] xl:w-[880px] font-concert md:text-left text-[16px] text-[#327560]">
                          Build Strong Foundations:
                          <div className="text-[14px] mt-[8px] md:text-[20px] lg:text-[24px] lg:mt-[20px] xl:text-[36px] text-[#5C5C5C]">
                              We understand the importance of a strong math 
                              foundation. MathMinds - Kids Math Games covers the basics of addition, 
                              subtraction, multiplication, and division, ensuring your 
                              child has a solid grasp of fundamental math concepts.
                          </div>
                       </div>
                       <div className="w-[332px] h-[120px] md:w-[422px] md:h-[150px] lg:w-[500px] xl:w-[600px] xl:h-[217px] bg-[url('/backGrounds/about/grass.svg')] bg-no-repeat bg-cover self-end" />
                     </div>
                     {/* section 3 */}
                     <div className="w-[718px] mt-[42px] md:w-[761px] lg:w-[1115px] xl:w-[1439px] xl:h-[361px] flex justify-between md:mt-[103px] lg:mt-[113px] xl:mt-[97px] mx-auto relative">
                        <div className="w-[273px] h-[153px] md:w-[362px] md:h-[203px] bg-cover xl:w-[554px] xl:h-[311px] bg-[url('/backGrounds/about/adding.svg')] self-end" />
                        <div className="w-[27px] top-[-15%] h-[55px] right-[38%] absolute md:right-[18%] md:top-[-5%] md:w-[27px] md:h-[57px] lg:w-[45px] lg:h-[93px] lg:right-[0%] lg:top-0 lg:relative bg-cover xl:w-[50px] xl:h-[103px] bg-[url('/backGrounds/about/dark_red_ballon.svg')]" />
                        <div className="w-[384px] text-[16px] text-center md:w-[365px] lg:w-[633px] lg:text-[28px] xl:w-[783px] h-full font-concert md:text-left md:text-[24px] xl:text-[48px] text-[#327560]">
                          Adaptive Learning:
                          <div className="mt-[8px] text-[14px] md:text-[20px] lg:text-[24px] xl:text-[36px] text-[#5C5C5C] xl:mt-[30px] md:mt-[16px]">
                               MathMinds - Kids Math Games adapts to your child's skill level, 
                               ensuring that they are continuously challenged 
                               and never bored. Whether your child is just 
                               starting or looking for more advanced 
                               challenges, MathMinds - Kids Math Games has them covered.
                          </div>
                       </div>
                     </div>
                    {/* section 4 */}
                    <div className="mt-[43px] w-[651px] flex md:w-[748px] lg:w-[1055px] xl:w-[1325px] items-center md:items-start md:mt-[100px] lg:mt-[75px] mx-auto relative">
                        <div className="absolute right-[29%] top-[10%] md:relative md:right-0 md:top-0 w-[34px] h-[70px] md:w-[41px] md:h-[86px] lg:w-[45px] lg:h-[93px] xl:w-[47px] xl:h-[107px] bg-[url('/backGrounds/about/blue_ballon.svg')] bg-cover"></div>
                        <div className="w-[383px] text-center text-[16px] md:w-[401px] lg:w-[589px] xl:w-[880px] font-concert md:text-left md:text-[24px] lg:text-[28px] xl:text-[48px] text-[#327560] md:ml-[40px] lg:ml-[58px] xl:ml-[36px]">
                          Safe and Age-Appropriate:
                          <div className="mt-[8px] text-[14px] md:text-[20px] lg:text-[24px] xl:text-[36px] text-[#5C5C5C] md:mt-[16px]">
                              We prioritize your child's safety. MathMinds - Kids Math Games is a kid-
                              friendly and age-appropriate platform, free from ads 
                              and inappropriate content. Your child can explore and 
                              learn in a safe and secure environment.
                          </div>
                       </div>
                       <div className={`ml-[144px] w-[124px] h-[167px] md:w-[162px] md:h-[217px] lg:w-[180px] lg:h-[236px] bg-cover xl:w-[258px] xl:h-[345px] md:ml-[104px] lg:ml-[183px] xl:ml-[104px] bg-[url('/backGrounds/about/image-2.svg')]`} />
                     </div>
                     {/* rainbow */}
                     <div className="w-[50px] h-[34px] lg:w-[65px] lg:h-[45px] ml-[30%] lg:ml-[25%] xl:mt-[51px] xl:ml-[25%] xl:w-[82px] xl:h-[56px] bg-[url('/backGrounds/about/rainbow.svg')] bg-cover"/>
                     {/* section 5 */}
                     <div className="mt-[13px] w-[696px] md:w-[713px] lg:w-[1067px] xl:w-[1395px] flex md:mt-[15px]  xl:mt-[46px] justify-between items-center md:items-start mx-auto relative">
                        <div className="w-[230px] h-[161px] md:w-[292px] md:h-[210px] lg:w-[339px] lg:h-[245px] xl:w-[430px] xl:h-[307px] bg-cover bg-[url('/backGrounds/about/image-3.svg')] self-start bg-no-repeat" />
                        <div className="w-[36px] right-[52%] h-[73px] lg:ml-[76px] bg-no-repeat md:w-[27px] md:h-[57px] md:right-[22%] lg:w-[45px] lg:h-[93px] md:top-[-5%] absolute lg:relative lg:top-0 lg:right-0 xl:ml-[16px] xl:w-[50px] xl:h-[103px] bg-contain bg-[url('/backGrounds/about/red_ballon.svg')]" />
                        <div className="w-[361px] text-center text-[16px] md:w-[390px] lg:w-[581px] lg:text-[28px] xl:w-[878px] xl:h-full font-concert md:text-left md:text-[24px] xl:text-[48px] text-[#327560] xl:pl-[36px]">
                          Parental Insights:
                          <div className="mt-[8px] text-[14px] xl:text-[36px] text-[#5C5C5C] lg:text-[24px] xl:mt-[36px] md:text-[20px] md:mt-[20px]">
                                We provide parents with insights into their child's 
                                progress. You can track your child's achievements and 
                                see how they are improving in math. It's a great way 
                                to stay involved in their education.
                          </div>
                       </div>
                     </div>
                    {/* section 6 */}
                    <div className="w-[694px] md:w-[738px] mt-[51px] lg:w-[1076px] xl:w-[1428px] flex md:mt-[83px] lg:mt-[92px] justify-between mx-auto">
                        <div className="hidden lg:block lg:w-[45px] lg:h-[93px] xl:w-[50px] xl:h-[103px] bg-[url('/backGrounds/about/dark_red_ballon.svg')] bg-cover"></div>
                        <div className="w-[386px] text-center text-[16px] md:w-[391px] lg:w-[593px] xl:w-[880px] font-concert md:text-left md:text-[24px] lg:text-[28px] xl:text-[48px] text-[#327560]">
                          Start the Learning Adventure Today!
                          <div className="text-[14px] mt-[8px] md:text-[20px] lg:text-[24px] xl:text-[36px] text-[#5C5C5C] md:mt-[13px] lg:mt-[20px]">
                                Don't miss out on the opportunity to make learning 
                                math an exciting journey for your child. Join 
                                MathMinds and watch as your kids develop strong 
                                math skills while having a blast. Education has never 
                                been this enjoyable!
                          </div>
                       </div>
                       <div className={`w-[282px] h-[147px] self-center md:w-[311px] md:h-[163px] lg:w-[396px] lg:h-[208px] bg-cover xl:w-[457px] xl:h-[240px] bg-[url('/backGrounds/about/counting.svg')]`} />
                     </div>
                    {/* section 7 */}
                    <div className="mt-[60px] w-[694px] md:w-[738px] lg:w-[932px] xl:w-[1341px] flex md:mt-[21px] lg:mt-[138px] xl:pr-[58px] xl:mt-[76px] justify-between relative self-center">
                        <div className="w-[112px] md:w-[0px] h-full lg:hidden"></div>
                        <div className="w-[146px] h-[92px] self-center md:w-[186px] md:h-[112px] xl:w-[266px] xl:h-[161px] bg-contain bg-[url('/backGrounds/about/image-4.svg')] bg-no-repeat" />
                        <div className="hidden md:block absolute lg:relative lg:right-0 lg:top-0 lg:w-[45px] lg:h-[93px] md:right-[25%] md:top-[-5%] md:w-[27px] md:h-[57px] xl:ml-[128px] xl:w-[47px] xl:h-[107px] bg-[url('/backGrounds/about/dark_red_ballon.svg')] bg-contain bg-no-repeat" />
                        <div className="w-[376px] text-center text-[16px] md:w-[480px] lg:w-[600px] md:text-[24px] lg:text-[28px] xl:w-[860px] h-full font-concert md:text-left xl:text-[48px] text-[#327560] xl:ml-[40px]">
                          Join MathMinds - Kids Math Games Today!
                          <div className="mt-[8px] text-[14px] md:text-[20px] lg:text-[24px] xl:text-[36px] text-[#5C5C5C] xl:mt-[36px] md:mt-[16px] lg:mt-[20px]">
                               Ready to embark on this educational adventure? 
                               Download the MathMinds - Kids Math Games app now and give your child 
                               the gift of math skills they'll use for a lifetime.
                          </div>
                       </div>
                     </div>
                     {/* footer */}
                     <div className="w-full mt-[54px] h-[236px] md:mt-[54px] lg:mt-[65px] xl:mt-[75px] md:h-[236px] lg:h-[351px] xl:h-[448px] bg-[url('/backGrounds/about/footer.svg')] bg-no-repeat bg-cover flex items-center justify-center relative flex-col">
                        <a href="#top">
                            <img className="rotate-180 w-[30px] h-[30px] lg:w-[40px] md:w-[35px] md:h-[35px] lg:h-[40px] xl:w-[43px] xl:h-[43px] absolute bottom-[50px] right-[50px] cursor-pointer" src="/backGrounds/about/up.svg"/>
                        </a>
                         <div className="w-[324px] lg:w-[447px] xl:w-[661px] xl:h-[97px] flex justify-between absolute top-[55%]">
                             <a target="_blank" href="https://www.facebook.com/people/MathforKids/100083137760551/">
                                <div className="w-[51px] h-[51px] lg:w-[72px] lg:h-[72px] xl:w-[98px] xl:h-[97px] bg-cover bg-[url('/backGrounds/about/facebook.svg')]"></div>
                             </a>
                             <a target="_blank" href="https://www.instagram.com/mathfor.kids/">
                                <div className="w-[51px] h-[51px] lg:w-[72px] lg:h-[72px] xl:w-[98px] xl:h-[97px] bg-cover bg-[url('/backGrounds/about/instagram.svg')]"></div>
                             </a>
                             <a target="_blank" href="https://play.google.com/store/apps/details?id=com.witplex.preschoolmathgame">
                               <div className="bg-cover w-[51px] h-[51px] lg:w-[72px] lg:h-[72px] xl:w-[98px] xl:h-[97px] bg-[url('/backGrounds/about/google.svg')]"></div>
                             </a>
                             <a target="_blank" href="https://apps.apple.com/us/app/kids-math-games-2nd-4th-grade/id1570425468">
                               <div className="bg-cover w-[51px] h-[51px] lg:w-[72px] lg:h-[72px] xl:w-[98px] xl:h-[97px] bg-[url('/backGrounds/about/store.svg')]"></div>
                             </a>
                             
                         </div>
                         <div className="w-[400px] xl:w-[450px] md:text-[14px] h-[24px] flex justify-between font-commisioner xl:text-[20px] absolute  text-[#FAFFF3] top-[85%]">
                             <NavLink to="/terms_of_use">
                                 <div className="w-[136px] h-full cursor-pointer text-center">Terms of Use</div>
                             </NavLink>
                             <NavLink to="/privacy_policy">
                                 <div className="w-[136px] h-full cursor-pointer text-center">Privacy Policy</div>
                             </NavLink>
                             <NavLink to="/refund_policy">
                                <div className="w-[136px] h-full cursor-pointer text-center">Refund Policy</div>
                             </NavLink>
                             
                         </div>
                     </div>
                </div>


            </div>
        </div> 
    );
}