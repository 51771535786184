import { calcGapX, shapes } from "../RapidShapes.data";
import { DragAndDrop } from "../../../../components/global/DragAndDrop";
import { animationDurationToShowQuestion } from "../../../../config";
import { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";


export const BottomPart = ({state, answers, refresh, selected, level}) => {
    const [index, setIndex] = useState('');
    const [animate, setAnimate] = useState(false);

    const isDragging = useRef();

    useEffect(()=>{
        if (!animate) return;
        setAnimate(false);
        setIndex('');
        isDragging.current = false;
    }, [answers]);

    return (
        <div className={`dropAnswers w-full flex items-center border-[3px] border-[#2F9EBA] bg-[#FFF] rounded-[16px]
                       ${state ? "h-[67px] md:h-[73px] lg:h-[93px] xl:h-[114px]" : "h-[67px] md:h-[90px] lg:h-[113px] xl:h-[136px]"}`}>
            {answers.map((answer, i)=>{
                if (index === i) {
                    return (
                        <DragAndDrop
                            key={answer} 
                            enableClick = {false}
                            cmp={"colors"} 
                            drop={"dropColor"}
                            item = {answer}
                            dragging = {(state)=>{
                                if (!state) return
                                isDragging.current = state;
                            }}
                            refresh={(sound, event) => {
                                setIndex('');
                                refresh(event, i, answer, '', isDragging.current);
                            }}
            
                        >
                            <div 
                               style={{
                                    backgroundColor: level=== 'beginner' ? '#FBB23B' : shapes[answer].color,
                                    clipPath: answer && answer!=="circle" && shapes[answer].path,
                                    borderRadius: answer=="circle" && '50%',
                                    marginLeft: calcGapX(state, answers.length)+'px'
                                }}
                                className={`z-[50] cursor-pointer
                                            ${state ? 'md:w-[39px] md:h-[39px] lg:w-[50px] lg:h-[50px] xl:w-[70px] xl:h-[70px] ' : ' md:w-[49px] md:h-[49px]  lg:w-[69px] lg:h-[69px] xl:w-[89px] xl:h-[89px] '} w-[39px] h-[39px]
                                            ${selected.includes(answer) ? "opacity-0" : "opacity-100"}
                                        `}
                            >
                            
                            </div>
                        </DragAndDrop>
                    )
                } else {
                    return (
                        <motion.div
                            animate={{y: animate ? [0, 0] : [(i+1)*500, 0]}}
                            transition={{duration: animationDurationToShowQuestion, type: "tween"}} 
                            onMouseEnter={(e)=>{
                                setIndex(i);
                                setAnimate(true);
                            }}
                            onTouchStart={(e)=>{
                                setIndex(i);
                                setAnimate(true);
                            }}
                           key={answer}
                           style={{
                              backgroundColor: level=== 'beginner' ? '#FBB23B' : shapes[answer].color,
                              clipPath: answer && answer!=="circle" && shapes[answer].path,
                              marginLeft: calcGapX(state, answers.length)+'px',
                              borderRadius: answer=="circle" && '50%'
                           }}
                           className={`z-[50] cursor-pointer
                                      ${state ? 'md:w-[39px] md:h-[39px] lg:w-[50px] lg:h-[50px] xl:w-[70px] xl:h-[70px] ' : ' md:w-[49px] md:h-[49px]  lg:w-[69px] lg:h-[69px] xl:w-[89px] xl:h-[89px] '} w-[39px] h-[39px]
                                      ${selected.includes(answer) ? "opacity-0" : "opacity-100"}
                                    `}
                        >
                         
                        </motion.div>
                    )
                }
            })}
        </div>
    )
}