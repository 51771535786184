
import { IconUserImages, IconUserChristmasImages } from "../../../elements/Icon";
import { checkChristmas } from "../../../../hooks/useCheckChristmas";
import { useDispatch } from "react-redux";
import { updateSelectedProfile } from "../../../../redux/features/userSlice";
import { getProfileGameThunk, getTotalScoreThunk } from "../../../../redux/features/gamesSlice";
import {useParams} from "react-router-dom";

export const AddProfile = ({handleState, selectedAvatars, subscription, handleSubscription, selectedProfile}) => {
    const dispatch = useDispatch();
    const {name, gameName} = useParams();

    return (
        <div className={`w-[352px] h-[79.53px] md:w-[402px] md:h-[105px] lg:h-[134px] lg:w-[546px] xl:w-[749px] xl:h-[183px] flex ${selectedAvatars.length === 1 ? "justify-center" : "justify-around"}`}> 
            {selectedAvatars.map((item, i)=> {
                return (
                    <div onClick={()=>{
                        if(item) {
                            if (subscription) {
                                dispatch(getProfileGameThunk({
                                   userId: localStorage.getItem('user'),
                                   profileId: item._id,
                                   collection: name
                                }))
                                dispatch(getTotalScoreThunk({
                                   userId: localStorage.getItem('user'),
                                   profileId: item._id,
                                }))
                                dispatch(updateSelectedProfile(item));
                             }
                        }
                    }} key={i} className={`${selectedProfile && selectedProfile?.avatar===item?.avatar ? "border-[#885AC0] bg-[#CCAED1]" : "border-[#E3D8D8] bg-[#D7CCD9]"} w-[79.53px] h-full md:w-[105px] lg:w-[134px] xl:w-[183px] relative border-[3px] lg:border-[4px] rounded-[50%] flex justify-end items-end`}>
                        <div onClick={(e)=>{
                            e.stopPropagation();
                            if (subscription || (!subscription && i===0)) {
                                handleState(i);
                            } else {
                                handleSubscription();
                            }
                            
                        }} className={`z-[10] w-[22px] h-[22px] md:w-[29px] md:h-[29px] lg:w-[35px] lg:h-[35px] xl:w-[48px] xl:h-[48px] ${item ? "bg-[url('/backGrounds/profile/edit.svg')]" : "bg-[url('/backGrounds/profile/+.svg')]"} bg-cover bg-no-repeat cursor-pointer`} />
                        {!subscription && selectedAvatars.length>1 && <div 
                            onClick={(e)=>{
                                e.stopPropagation();
                                handleSubscription();
                            }}
                            className={`absolute top-0 z-[10] w-[25px] h-[25px] md:w-[33px] md:h-[33px] lg:w-[40px] lg:h-[40px] xl:w-[52px] xl:h-[52px] ${item ? "" : "bg-[url('/backGrounds/profile/lock.svg')]"} bg-cover bg-no-repeat cursor-pointer`} 
                        />}
                        <div className="absolute w-full h-full overflow-hidden top-0 z-0 rounded-[50%]">
                            {item && checkChristmas() && <IconUserChristmasImages
                                user={item.avatar}
                                className={`translate-y-[-10%] ${item==="b2" && "-translate-x-[15%]"}`}
                            />}
                            {item && !checkChristmas() && <IconUserImages
                                user={item.avatar}
                                className={`translate-y-[-10%] ${item==="b2" && "translate-x-[15%]"}`}
                            />}
                        </div>
                    </div>
                )
            })}
        </div>
    )
}