export function NumberOne(props) {
    return (
        <svg {...props} viewBox="0 0 23 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="13.0742" y="1.58789" width="7.47177" height="47.9701" rx="3.73589" fill={props.result[1] ? "#EB6075" : "#D6D4C8"}/>
            <rect width="7.49844" height="23.7761" rx="3.74922" transform="matrix(0.702596 0.711589 -0.70661 0.707603 16.8008 0)" fill={props.result[0] ? "#EB6075" : "#D6D4C8"}/>
        </svg>
    )
}


export function NumberTwo(props) {
    if(!props.result.includes(true) || !props.result.includes(null)) {
       return (
        <svg {...props} viewBox="0 0 30 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path 
                fillRule="evenodd" 
                clipRule="evenodd" 
                d="M1.03464 15.1534C0.554796 7.50085 5.52124 2.56557 11.1239 0.80268C21.205 -2.36933 27.2472 4.72809 28.4039 7.03178C30.3243 10.8568 30.7999 16.5968 28.4039 21.3717C26.641 24.8848 22.1476 28.1589 18.2348 31.0099C15.7545 32.8171 13.5076 34.4544 12.3372 35.8748C11.2945 37.1402 10.4477 38.5734 9.70255 40H26.0275C28.2367 40 30.0275 41.7909 30.0275 44C30.0275 46.2091 28.2367 48 26.0275 48H5.02751C4.77267 48 4.5234 47.9762 4.28181 47.9306C3.81467 48.0449 3.30461 48.0218 2.73629 47.8331C-3.91523 45.6239 3.0659 33.7972 7.75462 29.6565C9.08508 28.4815 10.7445 27.3263 12.4591 26.1327C17.5397 22.5959 23.1042 18.7222 22.0274 13C20.5873 5.34671 11.1239 3.1951 7.75462 14.675C6.80424 17.9132 2.95412 18.98 1.03464 15.1534Z" 
                fill={!props.result.includes(true) ? "#D6D4C8" : "#EB6075"}
            />
        </svg>
       )
    }
    if(props.result.lastIndexOf(true)===0) {
        return (
         <svg {...props} viewBox="0 0 30 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path 
               fillRule="evenodd" 
               clipRule="evenodd" 
               d="M1.03464 15.1534C0.554796 7.50085 5.52124 2.56557 11.1239 0.80268C21.205 -2.36933 27.2472 4.72809 28.4039 7.03178C30.3243 10.8568 30.7999 16.5968 28.4039 21.3717C26.641 24.8848 22.1476 28.1589 18.2348 31.0099C15.7545 32.8171 13.5076 34.4544 12.3372 35.8748C11.2945 37.1402 10.4477 38.5734 9.70255 40H26.0275C28.2367 40 30.0275 41.7909 30.0275 44C30.0275 46.2091 28.2367 48 26.0275 48H5.02751C4.77267 48 4.5234 47.9762 4.28181 47.9306C3.81467 48.0449 3.30461 48.0218 2.73629 47.8331C-3.91523 45.6239 3.0659 33.7972 7.75462 29.6565C9.08508 28.4815 10.7445 27.3263 12.4591 26.1327C17.5397 22.5959 23.1042 18.7222 22.0274 13C20.5873 5.34671 11.1239 3.1951 7.75462 14.675C6.80424 17.9132 2.95412 18.98 1.03464 15.1534Z" 
               fill="#D6D4C8"
            />
            <path 
               d="M11.1214 0.80268C5.51873 2.56557 0.552286 7.50085 1.03213 15.1534C2.95161 18.98 6.80173 17.9132 7.75211 14.675C11.1214 3.1951 20.5848 5.34671 22.0249 13C22.2244 14.0603 22.1959 15.0571 21.9848 16C23 18 26.5 18.5 29.6649 17.7375C30.4784 13.9039 29.8522 9.92142 28.4014 7.03178C27.2447 4.72809 21.2024 -2.36933 11.1214 0.80268Z" 
               fill="#EB6075"
            />
         </svg>
        )
    }
    if(props.result.lastIndexOf(true)===1) {
        return (
         <svg {...props} viewBox="0 0 30 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="30.0273" y="40" width="8" height="29" rx="4" transform="rotate(90 30.0273 40)" fill="#D6D4C8"/>
                <path 
                   d="M11.1239 0.80268C5.52124 2.56557 0.554795 7.50085 1.03464 15.1534C2.95412 18.98 6.80424 17.9132 7.75462 14.675C11.1239 3.1951 20.5873 5.34671 22.0274 13C23.4676 20.6533 13.0274 25 7.75462 29.6565C3.0659 33.7972 -3.91523 45.6239 2.73629 47.8331C7.05688 49.2681 8.00994 41.1264 12.3372 35.8748C15.354 32.2136 25.5236 27.1116 28.4039 21.3717C30.7999 16.5968 30.3243 10.8568 28.4039 7.03178C27.2472 4.72809 21.205 -2.36933 11.1239 0.80268Z" 
                   fill="#EB6075"
                />
         </svg>
        )
    }   
}

export function NumberThree(props) {
    return (
        <svg {...props} viewBox="0 0 31 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path 
               d="M31.9641 34.7239C31.5328 28.0456 27.6084 21.9708 21.4638 21.5598C14.462 20.6103 15.4745 26.6284 17.501 26.8258C22.5013 27.3126 25.0096 35.2117 22.4641 39.1137C18.9641 44.4789 13.6532 43.5887 10.4641 41.0646C6.64759 38.044 8.18048 35.6053 6.46168 33.2924C5.01183 31.3414 2.10648 32.3169 1.46168 34.2679C-0.700626 40.8104 4.96181 48.955 13.9617 49.8755C26.9616 51.2052 32.4071 41.5834 31.9641 34.7239Z"
               fill={props.result[1] ? "#EB6075" : "#D6D4C8"}
            />
            <path 
               d="M13.4608 0.129432C6.00342 0.908323 1.46069 6.97648 1.46094 12.3131C1.46069 13.3172 2.72749 15.2579 4.96069 15.2682C9.9614 15.2912 7.4608 5.91296 15.9608 6.46573C23.042 6.92625 21.5108 15.2452 20.4608 16.7084C16.9608 21.5858 14.926 19.7471 10.9609 20.6806C7.64673 21.4608 7.64673 26.2188 10.9609 26.826C20.4608 28.5665 29.0143 23.0436 29.9607 14.2723C30.9607 5.00436 23.9607 -0.967236 13.4608 0.129432Z" 
               fill={props.result[0] ? "#EB6075" : "#D6D4C8"}
            />
        </svg>
    )
}

export function NumberFour(props) {
    return (
        <svg {...props} viewBox="0 0 39 49" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="38.9883" y="26.8477" width="7.38461" height="36.6099" rx="3.69231" transform="rotate(90 38.9883 26.8477)" fill={props.result[2] ? "#EB6075" : "#D6D4C8"}/>
            <rect width="7.73281" height="39.5926" rx="3.86641" transform="matrix(0.823919 0.566707 -0.620044 0.784567 24.9531 0.078125)" fill={props.result[1] ? "#EB6075" : "#D6D4C8"}/>
            <rect x="22.168" y="1" width="7.91566" height="48" rx="3.95783" fill={props.result[0] ? "#EB6075" : "#D6D4C8"}/>
        </svg>
    )
}

export function NumberFive(props) {
    return (
        <svg {...props} viewBox="0 0 32 46" fill="none" xmlns="http://www.w3.org/2000/svg">
           <rect x="31.0117" y="0.618164" width="7.35376" height="27" rx="3.67688" transform="rotate(90 31.0117 0.618164)" fill={props.result[2] ? "#EB6075" : "#D6D4C8"}/>
           <path d="M0.121916 36.3422C-0.840309 32.9177 4.11591 30.0537 7.16186 34.6583C10.2078 39.2629 21.5434 39.7459 23.2905 32.8913C24.9517 26.3738 21.2867 22.192 15.2752 22.192C9.26363 22.192 7.95655 28.6128 3.15548 26.2013C1.96385 25.6027 -0.509871 23.8978 9.76496 18.1804C17.78 13.7205 25.9885 16.3966 30.3043 23.5309C32.3082 26.8434 33.4934 36.3147 28.2033 41.3585C21.1899 48.0456 10.2052 45.7997 8.16489 44.925C5.10109 43.6114 1.21124 40.2191 0.121916 36.3422Z" fill={props.result[1] ? "#EB6075" : "#D6D4C8"}/>
           <rect width="7.98666" height="26.7378" rx="3.99333" transform="matrix(-0.99052 -0.13737 0.161131 -0.986933 8.16406 27.4854)" fill={props.result[0] ? "#EB6075" : "#D6D4C8"}/>
        </svg>
    )
}

export function NumberSix(props) {
    if(!props.result.includes(true) || !props.result.includes(null)) {
        return (
         <svg {...props} viewBox="0 0 33 48" fill="none" xmlns="http://www.w3.org/2000/svg">
             <path 
                 fillRule="evenodd" 
                 clipRule="evenodd" 
                 d="M0.561611 32.4747C-0.438685 24.952 -0.706921 12.7285 5.06161 5.67713C10.0619 -0.435128 18.0616 -0.904733 23.0616 0.9758C28.0616 2.85633 29.6464 6.58108 30.0616 7.55716C33 14.4653 25.5462 15.2215 24.5613 12.7285C24.5122 12.6042 24.4634 12.4799 24.4147 12.356C22.977 8.69649 21.6393 5.29138 15.0613 6.61676C10.8911 7.45699 8.22243 13.6587 7.96333 20.8756C9.7255 19.3745 12.0837 18.1612 15.5012 17.3961C27.0012 16.4564 32.0625 23.9187 32.0625 32.487C32.0625 41.0554 25.3991 48.0014 16.5625 48.0014C6.0625 47.5406 1.0625 39.0782 0.5625 32.487C0.572939 32.4707 0.583355 32.4543 0.593746 32.438L0.561611 32.4747ZM23.5625 32.0259C23.5625 37.2189 20.4285 41.4286 16.5625 41.4286C12.6965 41.4286 9.5625 37.2189 9.5625 32.0259C9.5625 26.833 12.6965 22.6233 16.5625 22.6233C20.4285 22.6233 23.5625 26.833 23.5625 32.0259Z" 
                 fill={!props.result.includes(true) ? "#D6D4C8" : "#EB6075"}
             />
         </svg>
        )
    }
    if(props.result.lastIndexOf(true)===0) {
        return (
         <svg {...props} viewBox="0 0 33 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path 
                fillRule="evenodd" 
                clipRule="evenodd" 
                d="M0.63583 32.4816C-0.364466 24.9629 -0.632702 12.746 5.13583 5.69849C10.1361 -0.410479 18.1358 -0.879832 23.1358 0.999688C28.1358 2.87921 29.7207 6.60195 30.1358 7.5775C33.0742 14.4819 25.6204 15.2377 24.6355 12.746C24.5864 12.6218 24.5376 12.4976 24.4889 12.3738C23.0513 8.71621 21.7136 5.31294 15.1355 6.63761C11.0576 7.45881 8.82797 13.4299 8.58874 20.4447C10.2856 19.1491 12.5022 18.0992 15.5754 17.4115C27.0754 16.4723 32.1367 23.9306 32.1367 32.4944C32.1367 41.0581 25.4733 48.0004 16.6367 48.0004C6.13672 47.5398 1.13672 39.082 0.636719 32.4943C0.645665 32.4804 0.654594 32.4664 0.663505 32.4524L0.63583 32.4816ZM23.6367 32.0327C23.6367 37.2228 20.5027 41.4303 16.6367 41.4303C12.7707 41.4303 9.63672 37.2228 9.63672 32.0327C9.63672 26.8425 12.7707 22.6351 16.6367 22.6351C20.5027 22.6351 23.6367 26.8425 23.6367 32.0327Z" 
                fill="#D6D4C8"
            />
            <path 
               d="M4.07349 6.13591C-0.950941 13.6699 0.0891341 18.4774 0.0892113 22.7064C0.0892113 22.7064 1.57353 25.8706 5.07353 25.4007C8.57353 24.9308 8.5891 21.7666 8.5891 21.7666C9.0891 14.7184 10.4194 7.72345 15.1776 6.76526C21.9784 5.39573 23.0168 9.11727 24.6776 12.8737C26.1163 16.128 34.1163 13.6444 30.1163 7.20027C29.5529 6.29271 28.0735 2.84647 23.0735 0.966947C18.0735 -0.912579 8.4848 -0.478713 4.07349 6.13591Z" 
               fill="#EB6075"
            />
         </svg>
        )
    }
    if(props.result.lastIndexOf(true)===1) {
        return (
         <svg {...props} viewBox="0 0 33 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path 
                    fillRule="evenodd" 
                    clipRule="evenodd" 
                    d="M0.588955 32.4741C-0.411341 24.9515 -0.679577 12.7283 5.08896 5.67703C10.0892 -0.43512 18.089 -0.904717 23.089 0.975782C28.089 2.85628 29.6738 6.58096 30.089 7.55702C33.0273 14.465 25.5735 15.2212 24.5886 12.7283C24.5395 12.6039 24.4907 12.4797 24.442 12.3558C23.0044 8.69633 21.6667 5.29128 15.0887 6.61664C10.8328 7.47411 7.74082 13.9154 7.49332 21.3211C9.30445 19.6144 11.7788 18.2353 15.5286 17.3959C27.0286 16.4562 32.0898 23.9184 32.0898 32.4866C32.0898 41.0548 25.4264 48.0006 16.5898 48.0006C6.08984 47.5399 1.08984 39.0776 0.589844 32.4865C0.602959 32.466 0.616036 32.4455 0.629075 32.425L0.588955 32.4741ZM23.5898 32.0255C23.5898 37.2184 20.4558 41.428 16.5898 41.428C12.7239 41.428 9.58984 37.2184 9.58984 32.0255C9.58984 26.8327 12.7239 22.623 16.5898 22.623C20.4558 22.623 23.5898 26.8327 23.5898 32.0255Z" 
                    fill="#D6D4C8"
                />
                <path 
                   d="M0.588783 32.4741C-0.472751 24.4465 -0.679749 12.7283 5.08878 5.67703C10.0891 -0.43512 18.0888 -0.904717 23.0888 0.975782C28.0888 2.85628 29.4638 6.14384 30.0272 7.05187C34.0272 13.4993 26.0272 15.9842 24.5884 12.7283C22.9277 8.96987 21.8893 5.2464 15.0885 6.61664C10.3303 7.57533 7.52703 15.514 8.02734 23.9764C8.19411 26.7971 8.16077 33.2978 11.0272 37.61C13.5272 41.371 16.0273 41.8411 19.5273 40.9008L24.0273 46.0722C19.5273 48.893 10.5272 49.3631 4.52717 42.3112C1.02717 38.1976 0.901579 34.1861 0.588783 32.4741Z" 
                   fill="#EB6075"
                /> 
         </svg>
        )
    } 

}

export function NumberSeven(props) {
    return (
        <svg {...props} viewBox="0 0 31 46" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M29.0643 2.02797C31.9499 2.59906 30.5174 6.35371 29.5486 7.84534C28.5799 9.33697 25.8796 12.5995 23.7361 15.9005C19.3766 22.614 16.4703 30.2209 15.5016 42.7496C15.2574 45.9068 9.68915 48.1224 7.2673 42.7511C3.60843 34.6362 22.2829 0.685868 29.0643 2.02797Z" fill={props.result[1] ? "#EB6075" : "#D6D4C8"}/>
            <rect x="31" y="0.686523" width="7.15984" height="31" rx="3.57992" transform="rotate(90 31 0.686523)" fill={props.result[0] ? "#EB6075" : "#D6D4C8"}/>
        </svg>
    )
}

export function NumberEight(props) {
    return (
        <svg {...props} viewBox="0 0 32 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path 
               fillRule="evenodd" 
               clipRule="evenodd" 
               d="M16 48.0002C24.8366 48.0002 32 41.768 32 34.0802C32 26.3924 24.8366 20.1602 16 20.1602C7.16344 20.1602 0 26.3924 0 34.0802C0 41.768 7.16344 48.0002 16 48.0002ZM16 43.2009C20.4183 43.2009 24 39.1178 24 34.0809C24 29.0441 20.4183 24.9609 16 24.9609C11.5817 24.9609 8 29.0441 8 34.0809C8 39.1178 11.5817 43.2009 16 43.2009Z" 
               fill={props.result[1] ? "#EB6075" : "#D6D4C8"}
            />
            <path 
               fillRule="evenodd" 
               clipRule="evenodd" 
               d="M16.5 24.96C24.5081 24.96 31 19.3725 31 12.48C31 5.58749 24.5081 0 16.5 0C8.49187 0 2 5.58749 2 12.48C2 19.3725 8.49187 24.96 16.5 24.96ZM16 20.1608C19.866 20.1608 23 16.7223 23 12.4808C23 8.23923 19.866 4.80078 16 4.80078C12.134 4.80078 9 8.23923 9 12.4808C9 16.7223 12.134 20.1608 16 20.1608Z" 
               fill={props.result[0] ? "#EB6075" : "#D6D4C8"}
            /> 
        </svg>
    )
}

export function NumberNine(props) {
    return (
        <svg {...props} viewBox="0 0 32 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path 
               fillRule="evenodd" 
               clipRule="evenodd" 
               d="M31.0861 16.1882C32.0389 23.7491 32.5995 35.4786 26.999 42.3237C21.9987 48.4351 13.999 48.9046 8.99896 47.0243C3.99896 45.1441 2.41414 41.4198 1.99896 40.4439C-0.939415 33.5367 6.5144 32.7806 7.49931 35.2733C7.54843 35.3976 7.59723 35.5218 7.64591 35.6457C9.08355 39.3047 10.4212 42.7094 16.9993 41.3842C21.0764 40.5628 23.306 34.5915 23.5459 27.5757C21.8493 28.871 19.6333 29.9209 16.5612 30.6085C5.0612 31.5481 -8.39233e-05 24.0868 -8.39233e-05 15.5197C-8.39233e-05 6.95248 6.66336 0.00744247 15.4999 0.00744247C25.9999 0.468159 30.9999 8.92938 31.4999 15.5197C31.3577 15.742 31.2199 15.9649 31.0861 16.1882ZM8.49992 15.9811C8.49992 10.7889 11.6339 6.57977 15.4999 6.57977C19.3659 6.57977 22.4999 10.7889 22.4999 15.9811C22.4999 21.1734 19.3659 25.3825 15.4999 25.3825C11.6339 25.3825 8.49992 21.1734 8.49992 15.9811Z" 
               fill={props.result[1] ? "#EB6075" : "#D6D4C8"}
            />
            <path 
               fillRule="evenodd" 
               clipRule="evenodd" 
               d="M32 25.8604V25.8604V25.8648C32 28.6852 31.4998 31.5061 27.5 31.5019C23.9999 31.4983 23.2941 28.5249 23.5 27.7505C23.5161 27.69 23.5312 27.6295 23.5452 27.5691C21.8487 28.8642 19.6329 29.9138 16.5613 30.6013C5.06128 31.5409 0 24.0797 0 15.5125C0 6.94529 6.66344 0.000255585 15.5 0.000255585C26 0.46097 31 8.92219 31.5 15.5125C31.4926 15.524 31.4853 15.5355 31.4779 15.5471L31.4998 15.5239C31.9998 18.3386 31.9999 23.0395 32 25.8604ZM8.5 15.9739C8.5 10.7817 11.634 6.57258 15.5 6.57258C19.366 6.57258 22.5 10.7817 22.5 15.9739C22.5 21.1662 19.366 25.3753 15.5 25.3753C11.634 25.3753 8.5 21.1662 8.5 15.9739Z" 
               fill={props.result[0] ? "#EB6075" : "#D6D4C8"}
            /> 
        </svg>
    )
}

export function NumbertTen(props) {
    return (
        <svg {...props} className="w-[120px] h-[150px]" viewBox="0 0 50 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="11.9219" y="1.0293" width="7.07998" height="47.9704" rx="3.53999" fill={props.result[1] ? "#EB6075" : "#D6D4C8"}/>
            <rect width="7.30702" height="23.1621" rx="3.65351" transform="matrix(0.683195 0.730235 -0.687308 0.726366 15.4531 -0.558594)" fill={props.result[0] ? "#EB6075" : "#D6D4C8"}/>
            <path 
               fillRule="evenodd" 
               clipRule="evenodd" 
               d="M36 49C43.1797 49 49 38.2548 49 25C49 11.7452 43.1797 1 36 1C28.8203 1 23 11.7452 23 25C23 38.2548 28.8203 49 36 49ZM36 42C39.3137 42 42 34.3888 42 25C42 15.6112 39.3137 8 36 8C32.6863 8 30 15.6112 30 25C30 34.3888 32.6863 42 36 42Z" 
               fill={props.result[2] ? "#EB6075" : "#D6D4C8"}
            />
        </svg>
    )
}
