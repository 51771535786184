
import React, {useState, useEffect, useRef} from "react";
import { getAnswers, getAnswer, calcSum } from "./Scale.data";
import { useParams } from "react-router-dom";
import { updateStorage } from "../../../hooks/useStorage.jsx";
import { updateGameStorage } from "../../../hooks/useGameStorage";
import { 
   animationDurationToShowscoresPopup,
   animationDurationToMoveCorrectAnswer,
} from "../../../config";
import Sound from '../../../components/global/Sound';
import { AnswersPart } from "./components/AnswersPart";
import { ScalePart } from "./components/ScalePart";
import * as amplitude from '@amplitude/analytics-browser';
import { useUpdateReduxState } from "../../../hooks/useUpdateReduxState";
import { gameId } from "../../../hooks/getGameId";


export const Scale = ({state, handleOpenScore, count, handleUpdateProgress, answerSound, level, handleTimerResult, point, subscription}) => {
    const answersArray = useRef(getAnswer());
    const size = level === "beginner" ? [null] : level === "intermediate" ? [null, null] : [null, null, null];
    const {name, gameName} = useParams();
    const [gameCount, setGameCount] = useState(0);
    const [left, setLeft] = useState(size);
    const [right, setRight] = useState(size);
    const answer = useRef(answersArray.current[gameCount]);
    const update = useRef(true);
    const [answers, setAnswers] = useState(getAnswers(answer.current, level, update.current));
    const selected = useRef([]);

    const updateState = useUpdateReduxState();

    const score = useRef(0);
    const soundWrongRef = useRef();
    const soundRef = useRef();
    const scaleRef = useRef();
 
    useEffect(()=>{
      amplitude.track(`${name+"_"+gameName+"_"+level+"_start"}`)
    }, []);

    const refreshAnswer = (event, item, i, part) => {
      const elem = document.querySelector(".scale");
      const elemCoord = elem.getBoundingClientRect();
      if (
         elem &&
         event.x<elemCoord.x+elemCoord.width && event.x>=elemCoord.x && 
         event.y<elemCoord.y+elemCoord.height && event.y>=elemCoord.y
       ) {

         if (part === "left") {
            const newLeft = [...left].map(num=>{
               if (num === item) {
                  return null;
               } else {
                  return num;
               }
            })

            setLeft(newLeft);
            selected.current = selected.current.filter(number=>number!==item);
            
            if (calcSum(newLeft)>calcSum(right)) {
               if (calcSum(newLeft)<calcSum(right)) {
                  scaleRef.current.style.transform = "rotate(-22deg)";
               }
               if (calcSum(newLeft) === calcSum(right)) {
                  scaleRef.current.style.transform = "rotate(-11deg)";
               }
            }
            if (calcSum(newLeft) === calcSum(right)) {
               scaleRef.current.style.transform = "rotate(0deg)";
            }
         } else {
            const newRight = [...right].map(num=>{
               if (num === item) {
                  return null
               } else {
                  return num
               }
            })
            setRight(newRight);
            selected.current = selected.current.filter(number=>number!==item);
            if (calcSum(left)<calcSum(newRight)) {
               if (calcSum(left)>calcSum(right)) {
                  scaleRef.current.style.transform = "rotate(20deg)";
               }
               if (calcSum(left) === calcSum(newRight)) {
                  scaleRef.current.style.transform = "rotate(11deg)";
               }
            } 
            if (calcSum(left) === calcSum(newRight)) {
               scaleRef.current.style.transform = "rotate(0deg)";
            }
         }
       }
    }
    
    const refresh = (item, type, index) => {
      if (type === 'left') {
         if (level === "beginner") {
            if (answer.current === "<") {
              const res =  answers.reduce((aggr, number)=>{
                   if (right[0]) {
                     if (item < right[0]) {
                        aggr = true;
                     }
                   } else {
                     if (number>item) {
                        aggr = true;
                     }
                   }
                   return aggr;
              }, false);
              if(!res) return;
            }

            if (answer.current === ">") {
               const res =  answers.reduce((aggr, number)=>{
                    if (right[0]) {
                     if (item > right[0]) {
                        aggr = true;
                     }
                    } else {
                     if (number<item) {
                        aggr = true;
                     }
                    }

                    return aggr;
               }, false);
               if(!res) return;
             }
             if (answer.current === "=") {
               if (answers.indexOf(item) === answers.lastIndexOf(item)) {
                  return;
               }
             }
         } 
         const newArr = [...left];
         newArr[left.indexOf(null)] = item;
         setLeft(newArr);
         update.current = false;
         selected.current.push(level==="beginner" ? index : item); 
         if (calcSum(left)+item>calcSum(right)) {
            if (calcSum(left)<calcSum(right)) {
               scaleRef.current.style.transform = "rotate(-22deg)";
            }
            if (calcSum(left) === calcSum(right)) {
               scaleRef.current.style.transform = "rotate(-11deg)";
            }
         }
         if (calcSum(left)+item === calcSum(right)) {
            scaleRef.current.style.transform = "rotate(0deg)";
         }
      }

      if (type === 'right') {
         if (level === "beginner") {
            if (answer.current === "<") {
              const res =  answers.reduce((aggr, number)=>{
                   if (left[0]) {
                      if (item>left[0]) {
                        aggr = true;
                      }
                   } else {
                     if (number<item) {
                        aggr = true;
                     }
                   }

                   return aggr;
              }, false);
              if(!res) return;
            }

            if (answer.current === ">") {
               const res =  answers.reduce((aggr, number)=>{
                    if (left[0]) {
                      if (item<left[0]) {
                        aggr = true;
                      }
                    } else {
                     if (number>item) {
                        aggr = true;
                     }
                    }

                    return aggr;
               }, false);
               if(!res) return;
             }
             if (answer.current === "=") {
               if (answers.indexOf(item) === answers.lastIndexOf(item)) {
                  return;
               }
             }
         } 
         const newArr = [...right];
         newArr[right.indexOf(null)] = item;
         setRight(newArr);
         update.current = false;
         selected.current.push(level==="beginner" ? index : item);
         if (calcSum(left)<calcSum(right)+item) {
            if (calcSum(left)>calcSum(right)) {
               scaleRef.current.style.transform = "rotate(20deg)";
            }
            if (calcSum(left) === calcSum(right)) {
               scaleRef.current.style.transform = "rotate(11deg)";
            }
         } 
         if (calcSum(left) === calcSum(right)+item) {
            scaleRef.current.style.transform = "rotate(0deg)";
         }
      }
   }

   useEffect(()=>{
     if(left.indexOf(null)===-1 && right.indexOf(null)===-1) {
         if (answer.current === "=") {
            if (calcSum(left) === calcSum(right)) {
               score.current += point[level];
               if (!subscription) {
                  updateGameStorage(name, gameName, point[level], false, level);
                  updateStorage(point[level]);
               }
               handleTimerResult(true);
            } else {
               score.current += 0;
               updateStorage(0);
               handleTimerResult(false);
            }
         }
         
         if (answer.current === ">") {
            if (calcSum(left) > calcSum(right)) {
               score.current += point[level];
               if (!subscription) {
                  updateGameStorage(name, gameName, point[level], false, level);
                  updateStorage(point[level]);
               }
               handleTimerResult(true);
            } else {
               score.current += 0;
               updateStorage(0);
               handleTimerResult(false);
            }
         }

         if (answer.current === "<") {
            if (calcSum(left) < calcSum(right)) {
               score.current += point[level];
               if (!subscription) {
                  updateGameStorage(name, gameName, point[level], false, level);
                  updateStorage(point[level]);
               }
               handleTimerResult(true);
            } else {
               score.current += 0;
               updateStorage(0);
               handleTimerResult(false);
            }
         }
         if(gameCount === count-1) {
            if (subscription) {
               updateState({
                  userId: localStorage.getItem('user'),
                  profileId: JSON.parse(localStorage.getItem('profile'))?._id,
                  game: name,
                  score: score.current,
                  id: gameId(name, gameName),
                  collection: name,
               })
            } else {
               handleUpdateProgress();
               updateGameStorage(name, gameName, 0, true, level);
            }

            update.current = true;
            setTimeout(()=>handleOpenScore(), animationDurationToShowscoresPopup*1000);
            localStorage.setItem(`${name+'_'+gameName+'_'+level+'_score'}`, score.current);
            return;
         } else {
            if (answerSound+""=="true" && soundRef.current) {
               soundRef.current.currentTime = 0;
               soundRef.current.play();
            }
            if (!subscription) {
               handleUpdateProgress();
            }
            update.current = true;
            setTimeout(()=>{
               setGameCount(gameCount+1);
               setLeft(size);
               setRight(size);
               scaleRef.current.style.transform = "rotate(0deg)";
               answer.current = answersArray.current[gameCount+1];
               setAnswers(getAnswers(answer.current, level, update.current));
               selected.current = [];
            }, animationDurationToMoveCorrectAnswer*1000);
         }
     }
   }, [left, right]);
   
    return(
        <div className="w-full h-full border-[3px] rounded-[16px] lg:rounded-[20px] border-[#D36874] bg-[#FFF] py-[20px] flex flex-col justify-between">
            <AnswersPart 
               state={state} 
               refresh={refresh} 
               selected={selected} 
               answers={answers}
               level={level}
               gameCount = {gameCount}
            />
            <ScalePart 
               ref={scaleRef} 
               state={state}
               refresh={refreshAnswer} 
               level={level} 
               left={left} 
               right={right} 
               answer={answer.current}
            />
            <Sound ref={soundRef} url={"/backGrounds/right_answer.wav"} loop={false}/>
            <Sound ref={soundWrongRef} url={"/backGrounds/wrong_answer.wav"} loop={false}/>
        </div>
    )
}