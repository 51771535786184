
import { DragAndDrop } from "../../../../components/global/DragAndDrop";
import { motion } from "framer-motion";
import { animationDurationToShowQuestion } from "../../../../config";
import { CircleButton } from "../../../../components/elements/CircleButton";
import { getBottomGapX, getCoordinates } from "../RapidMath.data";
import React, { useState, useEffect, useRef } from 'react';
import Sound from '../../../../components/global/Sound';
import { getCircleComponent } from "../../../../hooks/useCircleButtonColor";

export const BottomPart = ({state, answers, answer, checkAnswer, answerSound}) => {
    const colors = useRef(getCircleComponent(answers));
    const [i, setI] = useState('');
    const [animate, setAnimate] = useState(false);
    const [val, setVal] = useState();
    const [update, setUpdate] = useState(false);
    const soundRef = useRef();
    const soundWrongRef = useRef();
    const [type, setType] = useState(false);

    useEffect(()=>{
        if (!animate) return;
        setVal('');
        setAnimate(false);
        setI('');
        setUpdate(false);
        colors.current = getCircleComponent(answers);
    }, [answers]);

    useEffect(()=>{
        if (type) setTimeout(()=>{
         setType(false);
         document.querySelector('.addingresult').style.opacity = 1;
        }, 1000);
    }, [type]);

   return(
       <div
            style={{columnGap: getBottomGapX(state)+'px'}}
            className={`flex items-center justify-center w-full border-[3px] rounded-[16px] border-[#85B054] bg-[#FFF] lg:rounded-[20px]
            ${state ? "h-[67px] md:h-[73px] lg:h-[93px] xl:h-[114px]" : "h-[67px] md:h-[90px] lg:h-[113px] xl:h-[136px]"}`}
       >
            {answers.map((number, index) => {
                    if (i === index) {
                        return (
                            <DragAndDrop
                                key={index} 
                                enableClick = {true}
                                item = {number}
                                numbers = {[answer]}
                                drop={"adding"}
                                animateWrong = {(e)=>{
                                    checkAnswer(false);
                                    setUpdate(index);
                                    setI('');
                                    if (answerSound) {
                                        soundWrongRef.current.currentTime = 0;
                                        soundWrongRef.current.play();
                                    }
                                }}
                                refresh={(event, type) => {
                                    if (event==='mouseup') return;
                                    if (val===number) return; //prevnting multiple clicks on one element
                                    setType(type);
                                    checkAnswer(true, event, type, number);
                                    setVal(number);
                                    setUpdate(false);
                                    if(type) setTimeout(()=>document.querySelector('.addingresult').style.opacity=0, 200);
                                    if (answerSound) {
                                        soundRef.current.currentTime = 0;
                                        soundRef.current.play();
                                    }
                                }}

                            >
                                <motion.div
                                    style={{
                                        transform: type && val === number && `translate(${getCoordinates()[0]}px,${getCoordinates()[1]}px)`,
                                        transition: `transform ${0.2}s linear`
                                    }} 
                                    className={`${val === number && !type? 'opacity-0' : 'opacity-100'} answer item z-[40] cursor-pointer`}
                                    key={index}
                                >
                                    <CircleButton isActive={number} color={colors.current[index]}>
                                        {number}
                                    </CircleButton>
                                </motion.div>
                            </DragAndDrop>
                        )
                    } else {
                        return (
                            <motion.div
                               animate={{y: animate ? [0, 0] : [(index+1)*100, 0], x: update === index ? [-5, 0, 5, 0] : 0}}
                               transition={{duration: animationDurationToShowQuestion , type: "tween", ease: "linear"}}
                               className={`item z-[40] cursor-pointer overflow-hidden`}
                               key={index}
                               onMouseEnter={(e)=>{
                                  setI(index);
                                  setAnimate(true);
                                  setUpdate(false);
                               }}
                            >
                                <CircleButton isActive={number} color={colors.current[index]}>
                                    {number}
                                </CircleButton>
                            </motion.div>
                          )
                    }
                })
            }
            <Sound ref={soundRef} url={"/backGrounds/right_answer.wav"} loop={false}/>
            <Sound ref={soundWrongRef} url={"/backGrounds/wrong_answer.wav"} loop={false}/>
       </div>
   )
}