export const calcGapY = (state) => {
    if (window.innerWidth<1024) {
        return (260-39*3) / 4;
    }
    if (window.innerWidth>=1024 && window.innerWidth<1440) {
       if (state) {
          return (286-39*3) / 4;
       } else {
          return (354-49*3) / 4;
       }
    }
    if (window.innerWidth>=1440 && window.innerWidth<1920) {
        if (state) {
            return (364-50*3) / 4;
        } else {
            return (446-69*3) / 4;
        }
    }
    if (window.innerWidth>=1920) {
        if (state) {
            return (448-70*3) / 4;
        } else {
            return (538-89*3) / 4;
        }
    }
}

export const getCoordinates = () => {
    const elem = document.querySelector('.compare');
    const answer = document.querySelector('.answer');
    const x1 = elem.getBoundingClientRect().x;
    const y1 = elem.getBoundingClientRect().y;
    const x2 = answer.getBoundingClientRect().x;
    const y2 = answer.getBoundingClientRect().y;
    const xDistance = x1 - x2;
    const yDistance = y1 - y2;
    return [
              xDistance-(answer.getBoundingClientRect().width-elem.getBoundingClientRect().width)/2, 
              yDistance-(answer.getBoundingClientRect().height-elem.getBoundingClientRect().height)/2
           ];
}

export const getNumber = () => {
    const min = 1;
    const max = 9;
    return Math.floor(Math.random() * (max - min + 1) + min);
}

export const getAnswer = (firstNumber, secondNumber) => {
    if (firstNumber > secondNumber) {
        return ">";
    }
    if (firstNumber < secondNumber) {
        return "<";
    }
    if (firstNumber = secondNumber) {
        return "=";
    }
}

export const calcMargin = (state, count) => {
    if (window.innerWidth<1024) {
        return (174-28*count) / (count+1);
    }
    if (window.innerWidth>=1024 && window.innerWidth<1440) {
       if (state) {
         return (198-28*count) / (count+1);
       } else {
         return (230-35*count) / (count+1);
       }
    }
    if (window.innerWidth>=1440 && window.innerWidth<1920) {
        if (state) {
            return (238-39*count) / (count+1);
        } else {
            return (288-45*count) / (count+1);
        }
    }
    if (window.innerWidth>=1920) {
        if (state) {
            return (290-52*count) / (count+1);
        } else {
            return (352-61*count) / (count+1);
        }
    }
 }

 export const generateImages = () => {
    let uniqueAnswers = new Set();
    while (uniqueAnswers.size < 2) {
      const num = Math.floor(Math.random() * 23) + 1;
      uniqueAnswers.add(num);
    }
    return Array.from(uniqueAnswers);
}