import React, { useState, useRef, useEffect } from 'react';
import { 
    geterateAnswersArray, 
    getFirstNumber, 
    generateImages, 
    getAnswers, 
    getAnimationValues,
} from './Division.data';
import { 
    animationDurationToShowscoresPopup,
    animationDurationToMoveCorrectAnswer
} from "../../../config";
import { useParams } from 'react-router-dom';
import { updateStorage } from "../../../hooks/useStorage";
import { updateGameStorage } from "../../../hooks/useGameStorage";
import RightPart from './components/RightPart';
import LeftPart from './components/LeftPart';
import * as amplitude from '@amplitude/analytics-browser';
import { useUpdateReduxState } from "../../../hooks/useUpdateReduxState";
import { gameId } from "../../../hooks/getGameId";

export const Division = ({state, handleOpenScore, count, handleUpdateProgress, answerSound, point, hint, subscription}) => {
    const gameStore = JSON.parse(localStorage.getItem('playedGames'));
    const updateState = useUpdateReduxState();
    const gameCount = useRef(0);
    const {name, gameName} = useParams();
    const allAnswersArray = useRef(geterateAnswersArray());
    const answer = useRef(allAnswersArray.current[gameCount.current]);
    const firstNumber = useRef(getFirstNumber(answer.current));
    const secondNumber = useRef(firstNumber.current/answer.current);
    const answersArray = useRef(getAnswers(answer.current));
    const images = useRef(generateImages());
    const [result, setResult] = useState('?');
    const [topValues, setTopValues] = useState(getAnimationValues(firstNumber.current));
    const copyVal = [...topValues];
    const score = useRef(0);

    useEffect(()=>{
        amplitude.track(`${name+"_"+gameName.replace("_", " ")+"_start"}`)
    }, []);

    const checkAnswer = (event, type, val) => {
        if (event==='mouseup') return;
        score.current += point;
        if (!subscription) {
            updateStorage(point);
        }
        !type && setResult(val);
        setTopValues(copyVal);
        if (gameCount.current === count-1 || (gameStore[name] && gameStore[name].find(item=>item.name===gameName)?.all === count-1)) {
            if (subscription) {
                updateState({
                    userId: localStorage.getItem('user'),
                    profileId: JSON.parse(localStorage.getItem('profile'))?._id,
                    game: name,
                    score: score.current,
                    id: gameId(name, gameName),
                    collection: name,
                })
            } else {
                handleUpdateProgress();
                updateGameStorage(name, gameName, point, true);
            }
            setTimeout(()=>handleOpenScore(), animationDurationToShowscoresPopup*1000);
            localStorage.setItem(`${name+'_'+gameName+'_'+'_score'}`, score.current);
          } else {
            if (!subscription) {
                handleUpdateProgress();
                updateGameStorage(name, gameName, point, false);
            }
           
            setTimeout(()=>{ 
              gameCount.current +=1;
              answer.current = allAnswersArray.current[gameCount.current];
              firstNumber.current = getFirstNumber(answer.current);
              secondNumber.current = firstNumber.current/answer.current;
              setTopValues(getAnimationValues(firstNumber.current));
              setResult('?');
              answersArray.current = getAnswers(answer.current);
              
            }, animationDurationToMoveCorrectAnswer*1000);
          }
    }

    return (
        <div className="w-full h-full flex justify-between relative z-30">
            <LeftPart 
               state={state}
               answer={answer.current}
               result={result}
               topValues={topValues}
               image = {images.current[gameCount.current]}
               firstNumber={firstNumber.current}
               secondNumber={secondNumber.current}
            />
            <RightPart
               hint={hint}
               state={state}
               answer={answer.current}
               checkAnswer={checkAnswer}
               answerSound={answerSound}
               answers={answersArray.current} 
            />
        </div>
    )
}