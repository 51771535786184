import {useEffect, useRef, useState} from "react";
import {Button, IconArrow} from "../elements";
import {NavLink, useParams} from "react-router-dom";
import useOutsideAlerter from "../../hooks/useOutsideAlerter";
import "../../utils.js/fittext.js";
import {useTranslation} from 'react-i18next';
import {data} from "../../assets/data";
import { openGamesCount } from "../../config";
import { IconLock } from "../elements";
import { useSelector } from "react-redux";
import * as amplitude from '@amplitude/analytics-browser';

export function LeftBar({openSmallSize, handleOpen, cmp}) {

    const subscription = useSelector(state=>state.user.subscription);
    const gameStore = JSON.parse(localStorage.getItem('playedGames'));
    const {t} = useTranslation();
    const [isSettingsOpen, setIsSettingsOpen] = useState(() => {
        const storedIsOpen = localStorage.getItem("leftBarIsOpen");
        return storedIsOpen ? JSON.parse(storedIsOpen) : true;
    });

    useEffect(() => {
        localStorage.setItem("leftBarIsOpen", JSON.stringify(isSettingsOpen));
        // Dispatch a custom event to notify other components about the change
        window.dispatchEvent(new Event("leftBarIsOpenChange"));
    }, [isSettingsOpen]);
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, () => {
        if (window.innerWidth < 1024) {
            setIsSettingsOpen(false);
            handleOpen && handleOpen();
        }
    });

    useEffect(()=>{
        cmp && setIsSettingsOpen(openSmallSize || false);
    }, [openSmallSize])

    const baseButtonStyles = 'h-[28px] md:h-[36px] xl:h-[51px] items-center';

    const {name} = useParams();
    const [version, setVersion] = useState(0);

    const getPlayedGameNumber = (game) => {
       if (gameStore && gameStore[game]) {
            if (data[game].length === gameStore[game].length) {
               return gameStore[game].reduce((aggr, item)=>{
                  return Math.min(aggr, item.count);
               }, Infinity); 
            } else {
            return "";
            }
       } else {
          return "";
       }
    }

    const checkLock = (game) => {
        if (gameStore && gameStore[game] && gameStore[game].length === openGamesCount) {
            return gameStore[game].reduce((aggr, item)=>{
                if (item.count!==1) aggr=false;
                return aggr;
            }, true);
           
        }
        return false;
    }

    return (
        <>
            {isSettingsOpen && (
                <div className="md:hidden absolute inset-0 z-[100] h-screen w-screen bg-gray-700/40"></div>
            )}
            <div
                ref={wrapperRef}
                className="md:mr:[24px] left-0 top-0 fixed z-[110] h-full md:relative md:mr-0"
            >
                <div
                     className={`${
                        isSettingsOpen
                            ? "w-[163px] md:w-[207px] lg:w-[222px] xl:w-[328px]"
                            : "w-0 md:w-[24px] md:px-[12px] lg:w-[34px] lg:px-[17px]" // px should be half of width to cover buttons
                    } relative z-20 md:flex  h-[100%] flex-col bg-gradient-to-b from-[#F9B818] from-[41.46%] to-[#E29E19] to-[100%] py-[10px] md:py-5 lg:py-2.5 transition-all duration-300`}
                >

                    <NavLink to="/" className={`${isSettingsOpen ? 'w-[118px] h-[60px] md:w-[162px] xl:w-[263px]' : 'md:w-0'}  block md:h-[92px] xl:h-[150px]  mx-auto lg:mt-[0px] xl:mt-[7px] text-center`}>
                        <img
                          className = "w-[75px] h-[19px] mx-auto mb-[10px] md:w-[100px] md:h-[25px] xl:w-[143px] xl:h-[39px]"
                          src="/backGrounds/logo.svg"
                        />
                        <span className={`text-[16px] px-[7px] md:px-[0px] ${isSettingsOpen ? 'block' : 'hidden'} border-text lg:text-[22px] xl:text-[32px] text-[#FFF] font-chango`}>
                            MathMinds
                        </span>
                        <div className="w-full flex items-center justify-between mt-[5px]">
                            <div className="w-[10px] md:w-[14px] xl:w-[20px] h-[1px] bg-[#283C43]"></div>
                            <span
                                className={`text-[9px] w-full ${isSettingsOpen ? 'pl-[4.5px] whitespace-normal' : 'truncate'} leading-[27px] md:text-[10px] md:leading-[16px] text-[#283C43] md:tracking-[3px] xl:tracking-[7px]  xl:text-[14px]`}>
                                    Kids Math Games
                            </span>
                            <div className="w-[10px] md:w-[14px] xl:w-[20px] h-[1px] bg-[#283C43]"></div>
                        </div>
                    </NavLink>

                    <div
                        className="no-scrollbar container flex w-full h-[80%] font-light flex-col items-center space-y-[9px] overflow-scroll text-[12px] md:text-[14px] lg:space-y-[15px] pt-[25px] lg:text-[16px] xl:space-y-4 xl:pt-[0px] xl:text-[20px]">
                        <NavLink onClick={()=>amplitude.track('menuPage_1')} to="/games/counting">
                            <Button className={`${isSettingsOpen ? "duration-500" : "invisible"} ${baseButtonStyles} ${name==='counting' ? 'scale-105 text-[#FFD15C]' : 'scale-unset text-white'}`}
                                    color="blue">
                                {t("counting")}
                                <div 
                                    className={`w-[20px] md:w-[30px] lg:w-[20px] xl:w-[40px] flex items-center justify-center h-full bg-contain absolute top-0 right-0 ${subscription && getPlayedGameNumber("counting") && "bg-[url('/backGrounds/firework.svg')]"}`}
                                >
                                     {subscription ? getPlayedGameNumber("counting") || "" : checkLock("counting") ? <IconLock color={"white"} className="w-[18px] h-[18px] xl:w-[30px] xl:h-[30px] mx-auto"/> : ""}
                                </div>
                            </Button>
                        </NavLink>
                        <NavLink onClick={()=>amplitude.track('menuPage_2')} to="/games/shapes">
                            <Button
                                className={`${isSettingsOpen ? "duration-500" : "invisible "} ${baseButtonStyles} ${name==='shapes' ? 'scale-105 text-[#FFD15C]' : 'scale-unset text-white'}`}
                                color="teal"
                            >
                                {t("shapes")}
                                <div 
                                    className={`w-[20px] md:w-[30px] lg:w-[20px] xl:w-[40px] flex items-center justify-center h-full bg-contain absolute top-0 right-0 ${subscription && getPlayedGameNumber("shapes") && "bg-[url('/backGrounds/firework.svg')]"}`}
                                >
                                     {subscription ? getPlayedGameNumber("shapes") || "" : checkLock("shapes") ? <IconLock   color={"white"} className="w-[18px] h-[18px] xl:w-[30px] xl:h-[30px] mx-auto"/> : ""}
                                </div>
                            </Button>
                        </NavLink>
                        <NavLink onClick={()=>amplitude.track('menuPage_3')} to="/games/adding">
                            <Button className={`${isSettingsOpen ? "duration-500" : " invisible"} ${baseButtonStyles} ${name==='adding' ? 'scale-105 text-[#FFD15C]' : 'scale-unset text-white'}`}
                                    color="violet">
                                {t("adding")}
                                <div 
                                    className={`w-[20px] md:w-[30px] lg:w-[20px] xl:w-[40px] flex items-center justify-center h-full bg-contain absolute top-0 right-0 ${subscription && getPlayedGameNumber("adding") && "bg-[url('/backGrounds/firework.svg')]"}`}
                                >
                                     {subscription ? getPlayedGameNumber("adding") || "" : checkLock("adding") ? <IconLock   color={"white"} className="w-[18px] h-[18px] xl:w-[30px] xl:h-[30px] mx-auto"/> : ""}
                                </div>
                            </Button>
                        </NavLink>

                        <NavLink onClick={()=>amplitude.track('menuPage_4')} to="/games/subtracting">
                            <Button
                                className={`${isSettingsOpen ? "duration-500" : "invisible "} ${baseButtonStyles} ${name==='subtracting' ? 'scale-105 text-[#FFD15C]' : 'scale-unset text-white'}`}
                                color="red"
                            >
                                {t("subtracting")}
                                <div 
                                    className={`w-[20px] md:w-[30px] lg:w-[20px] xl:w-[40px] flex items-center justify-center h-full bg-contain absolute top-0 right-0 ${subscription && getPlayedGameNumber("subtracting") && "bg-[url('/backGrounds/firework.svg')]"}`}
                                >
                                     {subscription ? getPlayedGameNumber("subtracting") || "" : checkLock("subtracting") ? <IconLock   color={"white"} className="w-[18px] h-[18px] xl:w-[30px] xl:h-[30px] mx-auto"/> : ""}
                                </div>
                            </Button>
                        </NavLink>
                        <NavLink onClick={()=>amplitude.track('menuPage_5')} to="/games/multiplication">
                            <Button
                                className={`${isSettingsOpen ? "duration-500" : "invisible "} ${baseButtonStyles} ${name==='multiplication' ? 'scale-105 text-[#FFD15C]' : 'scale-unset text-white'}`}
                                color="green"
                            >
                                {t("multiplication")}
                                <div 
                                    className={`w-[20px] md:w-[30px] lg:w-[20px] xl:w-[40px] flex items-center justify-center  h-full bg-contain absolute top-0 right-0 ${subscription && getPlayedGameNumber("multiplication") && "bg-[url('/backGrounds/firework.svg')]"}`}
                                >
                                     {subscription ? getPlayedGameNumber("multiplication") || "" : checkLock("multiplication") ? <IconLock   color={"white"} className="w-[18px] h-[18px] xl:w-[30px] xl:h-[30px] mx-auto"/> : ""}
                                </div>
                            </Button>
                        </NavLink>
                        <NavLink onClick={()=>amplitude.track('menuPage_6')} to="/games/division">
                            <Button
                                className={`${isSettingsOpen ? "duration-500" : "invisible "} ${baseButtonStyles} ${name==='division' ? 'scale-105 text-[#FFD15C]' : 'scale-unset text-white'}`}
                                color="ocher"
                            >
                                {t("division")}
                                <div 
                                    className={`w-[20px] md:w-[30px] lg:w-[20px] xl:w-[40px] flex items-center justify-center h-full bg-contain absolute top-0 right-0 ${subscription && getPlayedGameNumber("division") && "bg-[url('/backGrounds/firework.svg')]"}`}
                                >
                                     {subscription ? getPlayedGameNumber("division") || "" : checkLock("division") ? <IconLock   color={"white"} className="w-[18px] h-[18px] xl:w-[30px] xl:h-[30px] mx-auto"/> : ""}
                                </div>
                            </Button>
                        </NavLink>
                        <NavLink onClick={()=>amplitude.track('menuPage_7')} to="/games/sorting">
                            <Button
                                className={`${isSettingsOpen ? "duration-500" : "invisible "} ${baseButtonStyles} ${name==='sorting' ? 'scale-105 text-[#FFD15C]' : 'scale-unset text-white'}`}
                                color="red"
                            >
                                {t("sorting")}
                                <div 
                                    className={`w-[20px] md:w-[30px] lg:w-[20px] xl:w-[40px] flex items-center justify-center h-full bg-contain absolute top-0 right-0 ${subscription && getPlayedGameNumber("sorting") && "bg-[url('/backGrounds/firework.svg')]"}`}
                                >
                                     {subscription ? getPlayedGameNumber("sorting") || "" : checkLock("sorting") ? <IconLock   color={"white"} className="w-[18px] h-[18px] xl:w-[30px] xl:h-[30px] mx-auto"/> : ""}
                                </div>
                            </Button>
                        </NavLink>
                        <NavLink onClick={()=>amplitude.track('menuPage_8')} to="/games/compare">
                            <Button
                                className={`${isSettingsOpen ? "duration-500" : "invisible "} ${baseButtonStyles} ${name==='compare' ? 'scale-105 text-[#FFD15C]' : 'scale-unset text-white'}`}
                                color="pink"
                            >
                                {t("compare")}
                                <div 
                                    className={`w-[20px] md:w-[30px] lg:w-[20px] xl:w-[40px] flex items-center justify-center h-full bg-contain absolute top-0 right-0 ${subscription && getPlayedGameNumber("compare") && "bg-[url('/backGrounds/firework.svg')]"}`}
                                >
                                     {subscription ? getPlayedGameNumber("compare") || "" : checkLock("compare") ? <IconLock   color={"white"} className="w-[18px] h-[18px] xl:w-[30px] xl:h-[30px] mx-auto"/> : ""}
                                </div>
                            </Button>
                        </NavLink>
                        <NavLink onClick={()=>amplitude.track('menuPage_9')} to="/games/memory">
                            <Button
                                className={`${isSettingsOpen ? "duration-500" : "invisible"} ${baseButtonStyles} ${name==='memory' ? 'scale-105 text-[#FFD15C]' : 'scale-unset text-white'}`}
                                color="teal"
                            >
                                {t("memory")}
                                <div 
                                    className={`w-[20px] md:w-[30px] lg:w-[20px] xl:w-[40px] flex items-center justify-center h-full bg-contain absolute top-0 right-0 ${subscription && getPlayedGameNumber("memory") && "bg-[url('/backGrounds/firework.svg')]"}`}
                                >
                                     {subscription ? getPlayedGameNumber("memory") || "" : checkLock("memory") ? <IconLock   color={"white"} className="w-[18px] h-[18px] xl:w-[30px] xl:h-[30px] mx-auto"/> : ""}
                                </div>
                            </Button>
                        </NavLink>
                        <NavLink onClick={()=>amplitude.track('menuPage_10')} to="/games/schulte">
                            <Button
                                className={`${isSettingsOpen ? "duration-500" : "invisible "} ${baseButtonStyles} ${name==='schulte' ? 'scale-105 text-[#FFD15C]' : 'scale-unset text-white'}`}
                                color="violet"
                            >
                                {t("schulte")}
                                <div 
                                    className={`w-[20px] md:w-[30px] lg:w-[20px] xl:w-[40px] flex items-center justify-center h-full bg-contain absolute top-0 right-0 ${subscription && getPlayedGameNumber("schulte") && "bg-[url('/backGrounds/firework.svg')]"}`}
                                >
                                     {subscription ? getPlayedGameNumber("schulte") || "" : checkLock("schulte") ? <IconLock   color={"white"} className="w-[18px] h-[18px] xl:w-[30px] xl:h-[30px] mx-auto"/> : ""}
                                </div>
                            </Button>
                        </NavLink>
                    </div>
                    <div className={`${isSettingsOpen ? 'flex justify-between ' : 'hidden'} w-full h-[15px] px-[10px] text-[8px] xl:text-[10px] absolute xl:px-[16px] bottom-[0px]`}>
                       <div className="md:w-[58px] xl:w-[74px] flex justify-between font-commisioner">
                          <span>WitPlex</span>
                          <img className="w-[6.2px] xl:w-[8px]" src="/backGrounds/copyright.svg"/>
                          <span>{new Date().getFullYear()}</span>
                       </div>
                       <div onClick={()=> {
                          if (version === 4) {
                            alert(import.meta.env.PACKAGE_VERSION);
                            setVersion(0);
                          } else {
                            setVersion(version+1);
                          }
                       }} className="md:w-[67px] xl:w-[87px] font-commisioner first-line:text-right">
                          Math Minds 1.0.17
                       </div>
                    </div>
                </div>
                <div
                    className="group hidden md:flex absolute -right-0 top-1/2 z-10 aspect-square w-[45px] -translate-y-1/2 translate-x-1/2 rounded-[50px] bg-[#F9B818] md:w-[60px] xl:h-[72px] xl:w-[72px]">
                    <IconArrow
                        className={` ${
                            isSettingsOpen ? "rotate-[0deg]" : "rotate-[180deg]"
                        } absolute right-[2px] top-1/2 w-5 -translate-y-1/2 transition-transform duration-300 group-hover:duration-500 md:w-[33px] lg:w-[27px] xl:w-[37px]`}
                    />
                </div>
                <button
                    onClick={() => {
                        setIsSettingsOpen((prevState) => !prevState);
                    }}
                    className="group hidden md:flex absolute -right-0 top-1/2 z-30 aspect-square w-[45px] -translate-y-1/2 translate-x-1/2 rounded-[50px] bg-violetCustom opacity-0 md:w-[55px] lg:w-[82px]"
                ></button>
            </div>
        </>
    );
}