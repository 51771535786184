import { TopPart } from "./components/TopPart";
import { BottomPart } from "./components/BottomPart";
import { generateAnswers, getRightAnswers } from "./AscendingAndDescending.data";
import React, { useState, useEffect, useRef } from "react";
import { updateStorage} from "../../../hooks/useStorage";
import { updateGameStorage } from "../../../hooks/useGameStorage";
import { 
    animationDurationToShowscoresPopup, 
    animationDurationToMoveCorrectAnswer,
} from "../../../config";
import Sound from '../../../components/global/Sound';
import { useParams } from "react-router-dom";
import * as amplitude from '@amplitude/analytics-browser';
import { useUpdateReduxState } from "../../../hooks/useUpdateReduxState";
import { gameId } from "../../../hooks/getGameId";

export const AscendingAndDescending = ({state, level, handleUpdateProgress, handleOpenScore, count, answerSound, point, hint, subscription}) => {
    const {name, gameName} = useParams();
    const updateState = useUpdateReduxState();
    const answers = useRef(generateAnswers(level));
    const rightAnswersArray = useRef(getRightAnswers(answers.current, gameName));
    const [result, setResult] = useState([null, null, null, null]);
    const selectedItems = useRef([]);
    const score = useRef(0);
    const [gameCount, setGameCount] = useState(0);
    const soundWrongRef = useRef();
    const soundRef = useRef();

    useEffect(()=>{
      amplitude.track(`${name+"_"+gameName+"_"+level+"_start"}`)
    }, []);

    const handleScore=()=>{
        score.current+=point[level];
        if (!subscription) {
          updateStorage(point[level]);
        }
        if (gameCount === count-1) {
            if (subscription) {
              updateState({
                userId: localStorage.getItem('user'),
                profileId: JSON.parse(localStorage.getItem('profile'))?._id,
                game: name,
                score: score.current,
                id: gameId(name, gameName),
                collection: name,
              })
            } else {
              handleUpdateProgress();
              updateGameStorage(name, gameName, point[level], true, level);
            }
            setTimeout(()=>handleOpenScore(), animationDurationToShowscoresPopup*1000);
            localStorage.setItem(`${name+'_'+gameName+'_'+level+'_score'}`, score.current);
          } else {
            setTimeout(()=>{
                answers.current = generateAnswers(level);
                rightAnswersArray.current = getRightAnswers(answers.current, gameName);
                setResult([null, null, null, null]);
                selectedItems.current.length = 0;
                setGameCount(gameCount+1);
                const items = document.querySelectorAll('.item');
                items.forEach((item)=>item.style.opacity=1);
              }, animationDurationToMoveCorrectAnswer*1000);
              if (!subscription) {
                  handleUpdateProgress();
                  updateGameStorage(name, gameName, point[level], false, level);
              }

          }
    };

    const handleRefresh = (selected, sound) => {
        if (answerSound+""=="true" && sound === 'wrong') {
          soundWrongRef.current.play();
          return;
        }
        const index = rightAnswersArray.current.indexOf(selected);
        if (result[index] === null && rightAnswersArray.current[index] == selected && !sound) {
          answerSound+""=="true" && soundRef.current.play();
          const arr = [...result];
          arr[index] = selected;
          selectedItems.current.push(selected);
          setResult(arr);
        }
    };

    useEffect(()=>{
        if (result.length && !result.includes(null)) {
          handleScore();
        }
    }, [result]);

    return (
       <div className="bg-[#FFF] w-full h-full border-[3px] border-[#D2461B] rounded-[16px] lg:rounded-[20px] xl:border-[4px]">
          <div className={`w-[319px] mx-auto relative h-full ${state ? 'md:w-[327px] lg:w-[434px] xl:w-[574px]' : 'md:w-[424px] lg:w-[543px] xl:w-[689px]'}`}>
              <TopPart 
                state={state}
                result={result}
                selected = {selectedItems.current}
              />
              <BottomPart
                result={result}
                state={state}
                hint={hint}
                handleRefresh={handleRefresh}
                answers={answers.current}
                selected = {selectedItems.current}
                numbers={rightAnswersArray.current}
              />
          </div>
          {answerSound+""=="true" && <Sound ref={soundRef} url={"/backGrounds/right_answer.wav"} loop={false}/>}
          {answerSound+""=="true" && <Sound ref={soundWrongRef} url={"/backGrounds/wrong_answer.wav"} loop={false}/>}
       </div>
   )
}