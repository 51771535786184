
import { CircleButton } from '../../../../components/elements/CircleButton';
import { 
    animationDurationToShowQuestion,
} from "../../../../config";
import { motion } from 'framer-motion';
import { calcMargin } from '../Compare.data';

export const LeftPart = ({state, firstNumber, secondNumber, result, timeduration, images}) => {
    return (
        <div className={`flex items-center justify-center ${state ? "w-[344px] md:w-[350px] lg:w-[477px] xl:w-[630px]" : "w-[344px] md:w-[467px] lg:w-[566px] xl:w-[745px]"} h-full border-[3px] rounded-[16px] lg:rounded-[20px] border-[#2F9EBA] bg-[#FFF]`}>
           <div className={`w-[286px] h-[226px] flex justify-between items-center ${state ? 'md:w-[302px] md:h-[256px] lg:w-[392px] lg:h-[310px] xl:w-[522px] xl:h-[374px]' : 'md:w-[376px] md:h-[300px] lg:w-[470px] lg:h-[372px] xl:w-[626px] xl:h-[458px]'}`}>
              {/* firstnumber */}
              <div className={`w-[114px] lg:text-[45px] ${state ? 'xl:text-[55px] md:w-[122px] lg:w-[159px] md:text-[30px] xl:w-[210px]' : 'md:w-[154px] md:text-[40px] lg:w-[187px] xl:w-[244px] xl:text-[60px]'} h-full font-bigfatScript text-[#FFF] text-[28px]`}>
                 <div className={`w-full h-[52px] bg-[#F67300] rounded-t-[20px] flex justify-center items-center ${state ? 'md:h-[58px] lg:h-[72px] xl:h-[84px]' : 'md:h-[70px] lg:h-[84px] xl:h-[106px]'}`}>
                    {firstNumber}
                 </div>
                 <div className={`w-full flex px-[20px] xl:px-[30px] ${firstNumber>1 ? 'justify-between': 'justify-center'} h-[174px] bg-[#FFB43B] rounded-b-[20px] ${state ? 'md:h-[198px] lg:h-[238px] xl:h-[290px]' : 'md:h-[230px] lg:h-[288px] xl:h-[352px]'}`}>
                        {firstNumber>1 && <div className={`overflow-hidden w-[28px] h-full ${state ? 'md:w-[28px] lg:w-[39px] xl:w-[52px]' : 'md:w-[35px] lg:w-[45px] xl:w-[62px]'}`}>
                            {
                                [...new Array(Math.floor(firstNumber/2))].map((item, i)=>{
                                    return (
                                    <motion.div
                                        animate={{y: [timeduration.shift() || 0, 0]}}
                                        transition={{duration: animationDurationToShowQuestion, type: "tween", ease: "linear"}} 
                                        key={i}
                                        style={{marginTop: calcMargin(state, Math.floor(firstNumber/2))+"px", backgroundImage: `url(/backGrounds/counting/${images[0]}.svg)`, backgroundSize: "100% 100%", backgroundRepeat: "no-repeat"}}
                                        className={`${state ? 'md:h-[28px] lg:h-[39px] xl:h-[52px]' : 'md:h-[35px]  lg:h-[45px] xl:h-[62px]'} w-full h-[28px]`}
                                    />
                                    )
                                })
                            }
                        </div>}
                        {<div className={`overflow-hidden w-[28px] h-full ${state? 'md:w-[28px] lg:w-[39px] xl:w-[52px]' : 'md:w-[35px] lg:w-[45px] xl:w-[62px]'}`}>
                            {
                                [...new Array(Math.ceil(firstNumber>2 ? firstNumber/2 : firstNumber))].map((item, i)=>{
                                    return (
                                    <motion.div
                                        animate={{y: [timeduration.shift() || 0, 0]}}
                                        transition={{duration: animationDurationToShowQuestion, type: "tween", ease: "linear"}} 
                                        key={i}
                                        style={{marginTop: calcMargin(state, Math.ceil(firstNumber/2))+"px", backgroundImage: `url(/backGrounds/counting/${images[0]}.svg)`, backgroundSize: "100% 100%", backgroundRepeat: "no-repeat"}}
                                        className={`${state ? 'md:h-[28px] lg:h-[39px] xl:h-[52px]' : 'md:h-[35px] lg:h-[45px] xl:h-[62px]'} w-full h-[28px]`}
                                    />
                                    )
                                })
                            }
                        </div>}
                 </div>
              </div>
              {/* answer */}
              <CircleButton className={"compareresult"} isActive={!result ? false : result} >
                    <div className="compare">
                        {result}
                    </div>
              </CircleButton>
              {/* secondnumber */}
              <div className={`w-[114px] lg:text-[45px] ${state ? 'xl:text-[55px] md:w-[122px] lg:w-[159px] md:text-[30px] xl:w-[210px]' : 'md:w-[154px] md:text-[40px] lg:w-[187px] xl:w-[244px] xl:text-[60px]'} h-full font-bigfatScript text-[#FFF] text-[28px]`}>
                 <div className={`w-full h-[52px] bg-[#F67300] rounded-t-[20px] flex justify-center items-center ${state ? 'md:h-[58px] lg:h-[72px] xl:h-[84px]' : 'md:h-[70px] lg:h-[84px] xl:h-[106px]'}`}>
                    {secondNumber}
                 </div>
                 <div className={`px-[20px] xl:px-[30px] w-full flex ${secondNumber>1 ? 'justify-between': 'justify-center'} h-[174px] bg-[#FFB43B] rounded-b-[20px] ${state ? 'md:h-[198px] lg:h-[238px] xl:h-[290px]' : 'md:h-[230px] lg:h-[288px] xl:h-[352px]'}`}>
                        {secondNumber>1 && <div className={`overflow-hidden w-[28px] h-full ${state ? 'md:w-[28px] lg:w-[39px] xl:w-[52px]' : 'md:w-[35px] lg:w-[45px] xl:w-[62px]'}`}>
                            {
                                [...new Array(Math.floor(secondNumber/2))].map((item, i)=>{
                                    return (
                                    <motion.div
                                        animate={{y: [timeduration.shift() || 0, 0]}}
                                        transition={{duration: animationDurationToShowQuestion, type: "tween", ease: "linear"}} 
                                        key={i}
                                        style={{marginTop: calcMargin(state, Math.floor(secondNumber/2))+"px", backgroundImage: `url(/backGrounds/counting/${images[1]}.svg)`, backgroundSize: "100% 100%", backgroundRepeat: "no-repeat"}}
                                        className={`${state ? 'md:h-[28px] lg:h-[39px] xl:h-[52px]' : 'md:h-[35px]  lg:h-[45px] xl:h-[62px]'} w-full h-[28px]`}
                                    />
                                    )
                                })
                            }
                        </div>}
                        {<div className={`overflow-hidden w-[28px] h-full ${state? 'md:w-[28px] lg:w-[39px] xl:w-[52px]' : 'md:w-[35px] lg:w-[45px] xl:w-[62px]'}`}>
                            {
                                [...new Array(Math.ceil(secondNumber>2 ? secondNumber/2 : secondNumber))].map((item, i)=>{
                                    return (
                                    <motion.div
                                        animate={{y: [timeduration.shift() || 0, 0]}}
                                        transition={{duration: animationDurationToShowQuestion, type: "tween", ease: "linear"}} 
                                        key={i}
                                        style={{marginTop: calcMargin(state, Math.ceil(secondNumber/2))+"px", backgroundImage: `url(/backGrounds/counting/${images[1]}.svg)`, backgroundSize: "100% 100%", backgroundRepeat: "no-repeat"}}
                                        className={`${state ? 'md:h-[28px] lg:h-[39px] xl:h-[52px]' : 'md:h-[35px] lg:h-[45px] xl:h-[62px]'} w-full h-[28px]`}
                                    />
                                    )
                                })
                            }
                        </div>}
                 </div>
              </div>
           </div>
        </div>
    )
}